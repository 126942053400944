  import { Box, Paper, Snackbar, Stack, Typography } from '@mui/material';
import AuthServices from 'api/services/auth-services';
import NotificationChart from 'app/dashboard/Components/notification-trend/notification-chart/NotifcationChart';
import SelectMenuFilled from 'components/select-menu-filled/SelectMenuFilled';
import React, { useEffect, useState } from 'react';
import "./NotificationTrend.scss";

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const medium = ['email', 'Push', 'SMS'];

// const type = ['Technical', 'Critical'];

const NotificationTrend = () => {
  const [month, setMonth] = useState('');
  const [notificationData, setNotificationData] = useState([]);
  const [notificationMedium, setNotificationMedium] = useState('email');
  // const [notificationType, setNotificationType] = useState('Technical');
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'success' })

  const snackbarNotification = (data) => {
    setSnackBarContent(data)
    setShowSnackBar(true)
  }

  useEffect(() => {
    if( month && notificationMedium)
      getTrend(month, notificationMedium);
  }, [month, notificationMedium]);

  useEffect(() => {
    const date = new Date();
    const month = date.getMonth();
    setMonth(months[month] || "January")
  }, [])

  const getTrend = async (month, medium) => {
    const monthNo = months.indexOf(month, medium) + 1;
    const newMedium = medium === 'email' ? 'Email' : medium
    try {
      const { data } = await AuthServices.getDashboardChart(monthNo, newMedium);
      const { notification } = data;
      const notifications = []
      for (let ele of notification) {
        const dayNo = Object.keys(ele)[0]
        notifications.push({
          day: dayNo,
          count: ele[dayNo],
        })
      }
      setNotificationData(notifications);
    }
    catch (err) {
      snackbarNotification({
        message: err?.message.toString().replace("Error: ", ""),
        class: 'error'
      });
    }
  }
  let dateTrend = new Date();
  let dateTrending = dateTrend.getFullYear()
  
  return (
    <Paper sx={{ borderRadius: '10px' }}>
      <Stack>
        <Box>
          <Stack>
            <Typography sx={{ marginBottom: "0.2rem" }} fontWeight="700">Notification Trend of {dateTrending}</Typography>
            <Stack direction="row" justifyContent="space-between" sx={{ marginTop: "0.3rem" }}>
              <SelectMenuFilled
                value={notificationMedium} 
                onChange={(e) => setNotificationMedium(e.target.value )}
                options={medium}
                where="Notification"
              />
              <SelectMenuFilled
                sx={{
                  '.MuiSvgIcon-root-MuiSelect-icon': {
                    color: '#d8d8d8',
                    fill: '#d8d8d8',
                  },
                }}
                value={month}
                onChange={(e) => setMonth(e.target.value)}
                options={months}
                where="Notification"
              />
            </Stack>
          </Stack>
        </Box>
        <Box className="box-notification">
          <NotificationChart notificationData={notificationData} />
        </Box>
      </Stack>
      <Snackbar open={showSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={snackbarContent?.message || ''}
        autoHideDuration={4000}
        onClose={() => setShowSnackBar(false)}
        sx={{
          '.MuiSnackbarContent-root': {
            color: '#fff',
            fontWeight: '400',
            backgroundColor: snackbarContent.class === 'error' ? 'red' : (snackbarContent.class === 'warning' ? '#ffcc00' : '#00b89f'),
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '10rem'
          }
        }}
      />
    </Paper>
  );
};

export default NotificationTrend;
