import React, { useState } from 'react'
import { ReactComponent as WatchIcon } from "assets/img/watch.svg";
import { ReactComponent as SleepIcon } from "assets/img/sleep_icon.svg";
import { ReactComponent as PillIcon } from "assets/img/pill.svg";
import { ReactComponent as DoorIcon } from "assets/img/door_icon.svg";
import { ReactComponent as HeartRateIcon } from "assets/img/heart_rate_gray.svg";
import { ReactComponent as BPMIcon } from "assets/img/bpm_icon.svg";
import { ReactComponent as StepsIcon } from "assets/img/steps_icon.svg";
import { ReactComponent as BatteryIcon } from "assets/img/battery_icon.svg";
import { ReactComponent as CloseIcon } from "assets/img/circle-close.svg";
import { ReactComponent as Spo2Icon } from "assets/img/spo2_icon.svg";
import { ReactComponent as EditIcon } from "assets/img/edit_icon_primary.svg";
import { ReactComponent as SOSIcon } from "assets/img/sos.svg";
import { ReactComponent as BeltIcon } from 'assets/img/belt.svg';
import { ReactComponent as CouchIcon } from 'assets/img/couch.svg';
import {ReactComponent as FallDetectionIcon} from 'assets/img/falldetection28x28green.svg';
import { ReactComponent as BathRoomIcon } from 'assets/img/24px_Bathroom_Sensor_Icon_Active.svg';

import { ReactComponent as ShowerIcon } from "assets/img/shower.svg";

import DeleteConfirmationDialog from 'app/notification/components/dialogs/DeleteConfirmation';
import { Box, Dialog, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { IconDisplay } from 'app/notification/components/styled-componets/style';
import './configured.scss';
import moment from "moment";
import { CustomDialogTitle } from 'components/dialogs/custom-dialog-title/CustomDialogTitle';

const ConfiguredNotifications = ({ deleteConfig, data, open, handleClose, notifications: centricData }) => {
  const [openDelete, setOpenDelete] = useState([false, -1]);
  const pillsCount = (pill) => {
    return !!pill.length ? pill.filter(item => item.time)?.length || 0 : 0
  }
  return (
    <Dialog
      fullWidth
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "55rem",
          },
        },
      }}
      open={open}
      onClose={() => handleClose(false)}
      BackdropProps={{ style: { backgroundColor: 'black', opacity: "0.9" } }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div style={{position:"relative" , display: 'flex', direction: 'row', justifyContent: 'center' }}>
          <Typography sx={{mt:".3rem", mb: '2rem', color: 'primary.main', fontWeight: 'bold', fontSize: '1rem' }}>
          Configured Notifications
            </Typography>
            <CustomDialogTitle onClose={() => {handleClose()}} style={{padding:0,margin:0 , position:"absolute" , right:"0" , top:"0"}} ></CustomDialogTitle>
        </div>
      <Box className="configured-notification">
        {
          centricData?.length <= 0 ? (
            <Box className="no-notifications-configured">
              <Typography>No notifications configured</Typography>
            </Box>
          ) : (
            centricData?.map((setting, index) => {
              return (
								<Paper key={index} className="paper-configured">
									<Box className="w-100">
										<Box className="space-between">
											<Box className="box-configured-first  w-xs-100">
												{setting?.sensor === "heart_rate" ? (
													<Tooltip
														placement="left"
														color="primary"
														title="Heart Rate"
													>
														<IconDisplay
															className={`icon-display`}
															sx={{ boxShadow: "none" }}
														>
															<HeartRateIcon className="active-icons" />
														</IconDisplay>
													</Tooltip>
												) : setting?.sensor === "blood_pressure" ? (
													<Tooltip placement="left" color="primary" title="BPM">
														<IconDisplay
															className={`icon-display`}
															sx={{ boxShadow: "none" }}
														>
															<BPMIcon className="active-icons" />
														</IconDisplay>
													</Tooltip>
												) : setting?.sensor === "sleep" ? (
													<Tooltip
														placement="left"
														color="primary"
														title="Sleep"
													>
														<IconDisplay
															className={`icon-display`}
															sx={{ boxShadow: "none" }}
														>
															<SleepIcon className="active-icons" />
														</IconDisplay>
													</Tooltip>
												) : setting?.sensor === "steps" ? (
													<Tooltip
														placement="left"
														color="primary"
														title="Steps"
													>
														<IconDisplay
															className={`icon-display`}
															sx={{ boxShadow: "none" }}
														>
															<StepsIcon className="active-spcl" />
														</IconDisplay>
													</Tooltip>
												) : setting?.sensor === "blood_oxygen" ? (
													<Tooltip
														placement="left"
														color="primary"
														title="SPO2"
													>
														<IconDisplay
															className={`icon-display`}
															sx={{ boxShadow: "none" }}
														>
															<Spo2Icon className="active-diff" />
														</IconDisplay>
													</Tooltip>
												) : setting?.sensor === "battery" ? (
													<Tooltip
														placement="left"
														color="primary"
														title="Battery"
													>
														<IconDisplay
															className={`icon-display`}
															sx={{ boxShadow: "none" }}
														>
															<BatteryIcon className="active-diff" />
														</IconDisplay>
													</Tooltip>
												) : setting?.device === "Door" ? (
													<Tooltip
														placement="left"
														color="primary"
														title="Door"
													>
														<IconDisplay
															className={`icon-display`}
															sx={{ boxShadow: "none" }}
														>
															<DoorIcon className="active-diff" />
														</IconDisplay>
													</Tooltip>
												) : setting?.device === "PillBox" ? (
													<Tooltip
														placement="left"
														color="primary"
														title="Pill Box"
													>
														<IconDisplay
															className={`icon-display`}
															sx={{ boxShadow: "none" }}
														>
															<PillIcon className="active-diff-pill primary" />
														</IconDisplay>
													</Tooltip>
												) : setting?.device === "SOS" ? (
													<Tooltip placement="left" color="primary" title="SOS">
														<IconDisplay
															className={`icon-display`}
															sx={{
																boxShadow: "none",
																"& svg": { height: "30px" },
															}}
														>
															<SOSIcon className="active-diff" />
														</IconDisplay>
													</Tooltip>
												) : setting?.device === "SleepBelt" ? (
													<Tooltip
														placement="left"
														color="primary"
														title="Sleep Belt"
													>
														<IconDisplay
															className={`icon-display`}
															sx={{
																boxShadow: "none",
																"& svg": { width: "30px" },
															}}
														>
															<BeltIcon
																className="active-diff"
																sx={{ width: "30px" }}
															/>
														</IconDisplay>
													</Tooltip>
												) : setting?.device === "SleepBelt-Chair" ? (
													<Tooltip
														placement="left"
														color="primary"
														title="Sleep Belt"
													>
														<IconDisplay
															className={`icon-display`}
															sx={{
																boxShadow: "none",
																"& svg": { width: "30px" },
															}}
														>
															<CouchIcon
																className="active-diff"
																sx={{ width: "30px" }}
															/>
														</IconDisplay>
													</Tooltip>
												) : setting?.device === "Shower" ? (
													<Tooltip
														placement="left"
														color="primary"
														title="Shower"
													>
														<IconDisplay
															className={`icon-display`}
															sx={{
																boxShadow: "none",
																"& svg": { width: "30px" },
															}}
														>
															<ShowerIcon className="active-diff" />
														</IconDisplay>
													</Tooltip>
												) : setting?.device === "FallDetection" ? (
													<Tooltip
														placement="left"
														color="primary"
														title="Fall Detection"
													>
														<IconDisplay
															className={`icon-display`}
															sx={{ boxShadow: "none" }}
														>
															<FallDetectionIcon className="active-diff" />
														</IconDisplay>
													</Tooltip>
												) : setting?.device === "BathRoom" ? (
													<Tooltip
														placement="left"
														color="primary"
														title="BathRoom"
													>
														<IconDisplay
															className={`icon-display`}
															sx={{ boxShadow: "none" }}
														>
															<BathRoomIcon className="active-diff" />
														</IconDisplay>
													</Tooltip>
												) : null}

												<Box className="gt-lt">
													{setting?.device === "watch" ? (
														setting?.sensor === "blood_pressure" ? (
															<Box>
																<Typography>
																	Sys (Max, Min): {setting?.max_sys || ""},{" "}
																	{setting?.min_sys || ""}
																</Typography>
																<Typography>
																	Dia (Max, Min): {setting?.max_dia || ""},{" "}
																	{setting?.thresholds?.min_dia || ""}
																</Typography>
															</Box>
														) : (
															<Box>
																{setting?.sensor !== "battery" && (
																	<Typography>
																		Greater Than: {setting?.max || ""}
																	</Typography>
																)}
																<Typography>
																	Lesser Than: {setting?.min || ""}
																</Typography>
															</Box>
														)
													) : setting?.device === "Door" ? (
														<Box>
															<Typography>
																Opened:{" "}
																{moment(
																	new Date(setting?.after[0]?.start_time).toLocaleString()
																).format("hh:mm A")+"-"+moment(new Date(setting?.after[0]?.end_time).toLocaleString()).format("hh:mm A") || ""}
															</Typography>
															<Typography>
																Not Opened By :{" "}
																{moment(
																	new Date(setting?.till).toLocaleString()
																).format("hh:mm A") || ""}
															</Typography>
														</Box>
													) : setting?.device === "PillBox" ? (
														<Box>
															<Typography>
																Medications Per Day:{" "}
																{pillsCount(setting?.pills) || ""}
															</Typography>
															<Typography>
																Refill Interval:{" "}
																{`${setting?.refill_interval} days` || ""}
															</Typography>
														</Box>
													) : setting?.device === "SleepBelt" ? (
														<Box>
															<Typography>
																Day Time Interval :{" "}
																{setting?.day_interval || ""}
															</Typography>
															<Typography>
																Night Time Interval :{" "}
																{setting?.night_interval || ""}
															</Typography>
														</Box>
													) : setting?.device === "SleepBelt-Chair" ? (
														<Box>
															<Typography>
																Day Time Interval :{" "}
																{setting?.day_interval || ""}
															</Typography>
															<Typography>
																Night Time Interval :{" "}
																{setting?.night_interval || ""}
															</Typography>
														</Box>
													) : setting?.device === "Shower" ? (
														<Box>
															{/* <Typography>
                                Open After:{" "}
                                {setting?.thresholds?.after || ""}
                              </Typography> */}
															<Typography>
																No Shower Taken :{" "}
																{moment(
																	new Date(setting?.time).toLocaleString()
																).format("hh:mm A") || ""}
															</Typography>
														</Box>
													) : setting?.device === "BathRoom" ? (
														<Box>
															<Typography>
																Start Time :{" "}
																{moment(
																	new Date(
																		setting?.start_time
																	).toLocaleString()
																).format("hh:mm A") || ""}
															</Typography>
															<Typography>
																End Time :{" "}
																{moment(
																	new Date(
																		setting?.end_time
																	).toLocaleString()
																).format("hh:mm A") || ""}
															</Typography>
															<Typography>
																Min Visits: {setting?.min}
															</Typography>
															<Typography>
																Max Visits: {setting?.max}
															</Typography>
														</Box>
													) : null}
												</Box>
											</Box>
											<Box className="box-configured-second  w-xs-100">
												{setting?.device === "PillBox" ? (
													<Box className="box-pill-message">
														{/* <Tooltip
                              placement="top"
                              title={setting?.schedule_message}
                            >
                              <Typography>
                                Pill Schedule Message:{" "}
                                {setting?.schedule_message}
                              </Typography>
                            </Tooltip> */}
														<Tooltip
															placement="top"
															title={setting?.refill_message}
														>
															<Typography>
																Pill Refill Message: {setting?.refill_message}
															</Typography>
														</Tooltip>
													</Box>
												) : setting?.device === "SleepBelt" ? (
													<Box className="box-message">
														<Tooltip
															placement="top"
															title={setting?.day_message}
														>
															<Typography>{setting?.day_message}</Typography>
														</Tooltip>
														<Tooltip
															placement="top"
															title={setting?.night_message}
														>
															<Typography>{setting?.night_message}</Typography>
														</Tooltip>
													</Box>
												) : setting?.device === "SleepBelt-Chair" ? (
													<Box className="box-message">
														<Tooltip
															placement="top"
															title={setting?.day_message}
														>
															<Typography>{setting?.day_message}</Typography>
														</Tooltip>
														<Tooltip
															placement="top"
															title={setting?.night_message}
														>
															<Typography>{setting?.night_message}</Typography>
														</Tooltip>
													</Box>
												) : setting?.device === "fall detection" ? (
													<Box className="box-message">
														<Tooltip
															placement="top"
															title={setting?.day_message}
														>
															<Typography>{setting?.day_message}</Typography>
														</Tooltip>
														<Tooltip
															placement="top"
															title={setting?.night_message}
														>
															<Typography>{setting?.night_message}</Typography>
														</Tooltip>
													</Box>
												) : (
													<Box className="box-message">
														<Tooltip placement="top" title={setting?.message}>
															<Typography>{setting?.message}</Typography>
														</Tooltip>
														{setting?.device === "Door" && (
															<Tooltip
																placement="top"
																title={setting?.till_message}
															>
																<Typography>{setting?.till_message}</Typography>
															</Tooltip>
														)}
														{setting?.sensor !== "battery" && (
															<Tooltip
																placement="top"
																title={setting?.lesser_message}
															>
																<Typography>
																	{setting?.lesser_message}
																</Typography>
															</Tooltip>
														)}
                            { setting?.device === "BathRoom" && (
                                      <Box className="box-message">
                                        <Tooltip
                                          placement="top"
                                          title={setting?.min_message}
                                        >
                                          <Typography>
                                            {setting?.min_message}
                                          </Typography>
                                        </Tooltip>
                                        <Tooltip
                                          placement="top"
                                          title={setting?.max_message}
                                        >
                                          <Typography>
                                            {setting?.max_message}
                                          </Typography>
                                        </Tooltip>
                                      </Box>   )  
                            }
													</Box>
												)}
												<Box className="box-configured-third">
													{/* <Tooltip
                            placement="left"
                            color="primary"
                            title="Edit Configurations"
                          >
                            <IconButton
                              // onClick={() => handleClickEdit(index)}
                              className={`icon-display`}
                            >
                              <EditIcon className="edit-green" />
                            </IconButton>
                          </Tooltip> */}
													<Tooltip
														placement="right"
														color="primary"
														title="Delete Configurations"
													>
														<IconButton
															onClick={() => setOpenDelete([true, index])}
															className={`icon-display`}
														>
															<CloseIcon className="close-red" />
														</IconButton>
													</Tooltip>
												</Box>
											</Box>
										</Box>
									</Box>
								</Paper>
							);
            }))
        }
      </Box>
      <DeleteConfirmationDialog
        func={() => {deleteConfig(openDelete[1]); setOpenDelete([false, null])}}
        open={openDelete[0]}
        handleClose={() => setOpenDelete([false, -1])}
      />
    </Dialog>
  );
}

export default ConfiguredNotifications;