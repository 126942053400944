import { Box, Button, Paper, Stack, styled, Typography, Snackbar, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, TablePagination, TableFooter, Grid } from '@mui/material';
import AddUserInput from 'components/add-user-input/AddUserInput';
import DashHeader from 'components/dash-header/DashHeader';
import InputField from 'components/inputfield/InputField';
import React, { useContext, useEffect, useState } from 'react';
import GooglePlayImg from "app/utility/assets/google-play.png"
import AppleStoreImg from "app/utility/assets/AppleStore.png"
import { BsFillStarFill, BsStarHalf, BsStar } from 'react-icons/bs';
import UtilityServices from 'api/services/utility-services';
import { ReactComponent as SendIcon } from '../../assets/img/SendIconWhite.svg';
import { actions, GlobalContext } from 'context';
import SearchBar from 'components/search-bar/SearchBar';
import AuthServices from 'api/services/auth-services';
import { BiRefresh } from "react-icons/bi";
import CreateUpdate from './dialogs/CreateUpdate/CreateUpdate';
import "./Utility.scss";
import { isEmailValid, isMobileValid, mobileFormatter } from 'utils/validator';
import { MdFormatShapes } from 'react-icons/md';
import moment from 'moment';
import { TablePaginationActions } from 'components/table-pagination/table-pagination-actions';
import SmsTemplateCard from 'app/notification/components/sms-template/SmsTemplateCard';
import smsTemplate from "utils/sms_template";
import CENSensorConfigurable from './CENSensorConfigurable';
import { ReactComponent as NhubUpdates } from "assets/img/nhubupdates.svg";
import { ReactComponent as VNhub } from "assets/img/vnubexecutive.svg";

const Heading = styled(Typography)({
  textAlign: 'center',
  color: '#d8d8d8',
  marginBottom: '1rem',
});
const TabBtn = styled(Button)({
  backgroundColor: 'transparent',
  border: '0 solid',
  fontSize: '1rem',
  color: '#6C7175',
  fontWeight: '300',
  flexBasis: '30%',
  marginBottom: '0'
});
const ActiveTabBtn = styled(Button)({
  backgroundColor: '#00B89F',
  border: '0 solid',
  fontSize: '1rem',
  color: '#D8D8D8',
  fontWeight: '300',
  flexBasis: '30%',
  marginBottom: '0'
});
const TabNav = ({ activeIn, changeActiveIn }) => {
  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center', margin: '1.5rem 0' }}
    >
      <Box
        sx={{
          width: '80%',
          backgroundColor: '#1A1A1A',
          display: 'flex',
          justifyContent: 'space-between',
          borderRadius: '0.5rem'
        }}
      >
        {activeIn === 0 ? (
          <ActiveTabBtn variant='contained'>SMS</ActiveTabBtn>
        ) : (
          <TabBtn onClick={() => changeActiveIn(0)}>SMS</TabBtn>
        )}
        {activeIn === 1 ? (
          <ActiveTabBtn variant='contained'>PUSH</ActiveTabBtn>
        ) : (
          <TabBtn onClick={() => changeActiveIn(1)}>PUSH</TabBtn>
        )}
        {activeIn === 2 ? (
          <ActiveTabBtn variant='contained'>EMAIL</ActiveTabBtn>
        ) : (
          <TabBtn onClick={() => changeActiveIn(2)}>EMAIL</TabBtn>
        )}
      </Box>
    </Box>
  );
};


const Notification = {
  passwordLenErr: { message: 'Password should be minimum 8 characters', class: 'error' },
  incomplete: { message: 'Please fill all the fields', class: 'error' },
  saveVpnCredSuccess: { message: 'VPN credentials saved successfully', class: 'success' },
  addSuccess: { message: 'User added successfully', class: 'success' },
  error: { message: 'We are facing some issues, Please try later!!', class: 'error' },
  testSmsSuccess: { message: 'SMS connection tested successfully!!', class: 'success' },
  testPushSuccess: { message: 'PUSH connection tested successfully!!', class: 'success' },
  testEmailSuccess: { message: 'EMAIL connection tested successfully!!', class: 'success' },
  saveSmsSuccess: { message: 'SMS connection saved.', class: 'success' },
  savePushSuccess: { message: 'PUSH notification connection saved.', class: 'success' },
  saveEmailSuccess: { message: 'EMAIL connection saved.', class: 'success' },
}

const initialField = { connection: '', conn_str: '' };

const ratings = '4'

const Utility = () => {

  const { dispatch } = useContext(GlobalContext);
  const [activeIn, setActiveIn] = useState(0);

  const [vpnCredentials, setVpnCredentials] = useState({})
  const vpnInputFields = ['username', 'password'];
  const [notificationField, setNotificationField] = useState({ ...initialField });
  const notifInputFields = ['connection', 'conn_str'];
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });
  const [refreshLoader, setRefreshLoader] = useState(false);

  const [smsField, setSmsField] = useState({ ...initialField });
  const [pushField, setPushField] = useState({ ...initialField });
  const [emailField, setEmailField] = useState({ ...initialField });
  const [vpnTableData, setVpnTableData] = useState({});
  const [filteredVpnTableData, setFilteredVpnData] = useState([]);
  const [vpnTableRows, setVpnTableRows] = useState([]);
  const [nhubsList, setNhubsList] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [openCreate, setOpenCreate] = useState(false);
  const [fullStars, setFullStars] = useState(0)
  const [halfStars, setHalfStars] = useState(0)


  const [emptyStars, setEmptyStars] = useState(0)
  const [patch, setPatch] = useState();
  const [opacity, setOpacity] = useState();

  const [android, setAndroid] = useState({});
  const [apple, setApple] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [macpage, setmacPage] = useState(0);
  const [rowsPermacPage, setRowsPermacPage] = useState(3);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangemacPage = (event, newPage) => {
    setmacPage(newPage);
  };

  const handleChangeRowsPermacPage = (event) => {
    setRowsPermacPage(parseInt(event.target.value, 10));
    setmacPage(0);
  };

  const storeHandler = (type, payload) => dispatch({ type, payload });

  const snackbarNotification = (data) => {
    setSnackBarContent(data);
    setShowSnackBar(true);
  }

  const notifValidate = (type) => {
    let invalidFields = [];
    notifInputFields.forEach(field => {
      if (field === "connection") {
        if (activeIn === 0 || activeIn === 1) {
          const newField = notificationField[field]
          const val = newField?.split(".")?.join("");
          if (!isMobileValid(val)) {
            console.log("notvalid")
            invalidFields.push(field);
          }
        }
        else {
          console.log(notificationField[field])
          if (!(isEmailValid(notificationField[field]))) {
            invalidFields.push(field);
          }
        }
      }
      if (!notificationField[field]) {
        invalidFields.push(field);
      }
    });
    if (invalidFields.length > 0) {
      snackbarNotification(Notification.incomplete);
      return;
    }
    if (type === 'test') {
      testConnection();
    }
    else {
      saveConnection();
    }
  }

  const testConnection = () => {
    switch (activeIn) {
      case 0:
        testSMS();
        break;
      case 1:
        testPUSH();
        break;
      case 2:
        testEMAIL();
        break;
      default:
        throw new Error('Invalid activeIn');
    }
  }

  const saveConnection = () => {
    switch (activeIn) {
      case 0:
        saveSMS();
        break;
      case 1:
        savePUSH();
        break;
      case 2:
        saveEMAIL();
        break;
      default:
        throw new Error('Invalid activeIn');
    }
  }

  const testEMAIL = async () => {
    const formData = new FormData();
    formData.append('connection', notificationField.connection);
    formData.append('conn_str', notificationField.conn_str);
    storeHandler(actions.SHOW_LOADER, true);
    try {
      await UtilityServices.testEmailConnection(formData);
      snackbarNotification(Notification.testEmailSuccess);
      storeHandler(actions.SHOW_LOADER, false);
      setNotificationField({ ...initialField })
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      })
      storeHandler(actions.SHOW_LOADER, false)
      setNotificationField({ ...initialField })
    }
  }

  const saveEMAIL = async () => {
    const formData = new FormData();
    formData.append('connection', notificationField.connection);
    formData.append('conn_str', notificationField.conn_str);
    storeHandler(actions.SHOW_LOADER, true);
    try {
      await UtilityServices.saveEmailConnection(formData);
      snackbarNotification(Notification.saveEmailSuccess);
      setTimeout(() => { setEmailField({ ...initialField }); setNotificationField({ ...initialField }) }, 3000);
      storeHandler(actions.SHOW_LOADER, false);
      setNotificationField({ ...initialField })
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      })
      storeHandler(actions.SHOW_LOADER, false)
      setNotificationField({ ...initialField })
    }
  }

  const testPUSH = async () => {
    const formData = new FormData();
    formData.append('connection', notificationField.connection.split(".").join(''));
    formData.append('conn_str', notificationField.conn_str);
    storeHandler(actions.SHOW_LOADER, true);
    try {
      await UtilityServices.testPushConnection(formData);
      snackbarNotification(Notification.testPushSuccess);
      storeHandler(actions.SHOW_LOADER, false);
      setNotificationField({ ...initialField })
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      })
      storeHandler(actions.SHOW_LOADER, false)
      // setNotificationField({...initialField})
    }
  }

  const savePUSH = async () => {
    const formData = new FormData();
    formData.append('connection', notificationField.connection.split(".").join(''));
    formData.append('conn_str', notificationField.conn_str);
    storeHandler(actions.SHOW_LOADER, true);
    try {
      await UtilityServices.savePushConnection(formData);
      snackbarNotification(Notification.savePushSuccess);
      setTimeout(() => { setPushField({ ...initialField }); setNotificationField({ ...initialField }) }, 3000);
      storeHandler(actions.SHOW_LOADER, false)
      setNotificationField({ ...initialField })
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      })
      storeHandler(actions.SHOW_LOADER, false)
      setNotificationField({ ...initialField })
    }
  }

  const testSMS = async () => {
    const formData = new FormData();
    formData.append('connection', notificationField.connection.split(".").join(''));
    formData.append('conn_str', notificationField.conn_str);
    storeHandler(actions.SHOW_LOADER, true);
    try {
      await UtilityServices.testSmsConnection(formData);
      snackbarNotification(Notification.testSmsSuccess);
      storeHandler(actions.SHOW_LOADER, false);
      setNotificationField({ ...initialField })
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      })
      storeHandler(actions.SHOW_LOADER, false)
      // setNotificationField({...initialField})
    }
  }

  const saveSMS = async () => {
    const formData = new FormData();
    formData.append('connection', notificationField.connection.split(".").join(''));
    formData.append('conn_str', notificationField.conn_str);
    storeHandler(actions.SHOW_LOADER, true);
    try {
      await UtilityServices.saveSmsConnection(formData);
      snackbarNotification(Notification.saveSmsSuccess);
      setTimeout(() => { setSmsField({ ...initialField }); setNotificationField({ ...initialField }) }, 3000);
      storeHandler(actions.SHOW_LOADER, false);
      setNotificationField({ ...initialField })
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      })
      storeHandler(actions.SHOW_LOADER, false)
      // setNotificationField({...initialField})
    }
  }

  const notificationInfo = (key, value) => {
    if (activeIn === 0 || activeIn === 1) {
      const val = value?.split(".")?.join("");
      let newVal = val.replaceAll(/[a-zA-Z !@#\$%\^\&*\)\(+=_-\{\}\[\]\<\>\?\,\.\/\|\\\'\"\;\:\-\~]/ig, '')
      const data = { ...notificationField, [key]: mobileFormatter(newVal) };
      setNotificationField(data);
    }

    else {
      const data = { ...notificationField, [key]: value };
      setNotificationField(data);
    }
    if (key === "conn_str") {
      const data = { ...notificationField, [key]: value };
      setNotificationField(data);
    }
  }


  const vpnInfo = (key, value) => {
    const data = { ...vpnCredentials, [key]: value };
    setVpnCredentials({ ...data });
  };

  const VpnCredValidate = () => {
    let invalidFields = [];
    vpnInputFields.forEach(item => {
      if (!vpnCredentials[item]) {
        invalidFields.push(item)
      }
    })
    if (invalidFields.length) {
      snackbarNotification(Notification.incomplete)
    } else {
      if (vpnCredentials.password.length < 8) {
        snackbarNotification(Notification.passwordLenErr)
      }
      else {
        saveVpnCred();
      }
    }
  }

  const saveVpnCred = async () => {
    const formdata = new FormData();
    formdata.append('username', vpnCredentials.username);
    formdata.append('password', vpnCredentials.password);
    storeHandler(actions.SHOW_LOADER, true)
    try {
      await UtilityServices.saveVpnCred(formdata);
      saveVpnCredSuccess();
      storeHandler(actions.SHOW_LOADER, false)
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      })
      storeHandler(actions.SHOW_LOADER, false)
    }
  }

  const saveVpnCredSuccess = () => {
    snackbarNotification(Notification.saveVpnCredSuccess)
    setTimeout(() => {
      setVpnCredentials({});
    }, 2000);
  }

  const changeTab = (tab) => {
    switch (activeIn) {
      case 0:
        setSmsField(notificationField);
        break;
      case 1:
        setPushField(notificationField);
        break;
      case 2:
        setEmailField(notificationField);
        break;
      default:
        throw new Error('Invalid tab');
    }
    setActiveIn(tab);
    setNotificationField(tab === 0 ? smsField : tab === 1 ? pushField : emailField);
  }

  const getVpnTable = async () => {
    setRefreshLoader(true);
    try {
      const { data } = await AuthServices.getVpnTable();
      const filteredData = data.filter((item) => moment.utc().diff(item.last_update, 'minutes') < 5 && item);

      setVpnTableData(filteredData)
      const rows = filteredData?.map((ele, index) => {
        return ele?.mac_addr !== "None" && ele?.mac_addr;
      })
      setVpnTableRows(rows)
      console.log(rows);
      setRefreshLoader(false);
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      })
      setRefreshLoader(false);
    }
  }

  const searchMac = (value) => {
    console.log(value)
    setSearchValue(value)
    const filteredData = vpnTableData?.filter((data) => {
      return data.mac_addr?.toLowerCase()?.replaceAll(':', '')?.includes(value?.toLowerCase()?.replaceAll(':', ''))
    })
    setFilteredVpnData(filteredData)
  }

  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  function changeUnit(x) {

    let l = 0, n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
  }



  const getNhubsList = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const { data } = await AuthServices.getNhubsList();
      const nhubData = data?.map((ele) => {
        return {
          ...ele,
          size: changeUnit(ele?.size)
        }
      })
      setNhubsList(nhubData.reverse());
      storeHandler(actions.SHOW_LOADER, false);
    } catch ({ message }) {
      snackbarNotification({
        message,
        class: 'error'
      });
      storeHandler(actions.SHOW_LOADER, false);
    }
  }

  const addPatch = async (patch) => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const formData = new FormData();
      formData.append('patch', patch)
      const { message } = await AuthServices.uploadNhub(formData);
      snackbarNotification({
        message,
        class: 'success'
      });
      setOpenCreate(false);
      getNhubsList();
      storeHandler(actions.SHOW_LOADER, false);
    } catch ({ data:{message = "Irrelevant update file"} }) {
      snackbarNotification({
        message,
        class: 'error'
      });
      storeHandler(actions.SHOW_LOADER, false);
    }
  }

  const getAppStats = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const { data } = await AuthServices.getAppStats();
      setAndroid(data?.android)
      const ratings = `${data?.android?.ratings}`
      const arr = ratings?.split('.')

      const val1 = Number(ratings) <= 5 ? Number(arr[0]) : 5
      const val3 = Number(ratings) <= 5 ? Math.floor(5 - Number(ratings)) : null
      const val2 = (Number(val1) + Number(val3) < 5) ? (Number(ratings) <= 5 ? (Number(arr[1]?.substring(0, 1)) >= 5 ? 0.5 : 0) : null) : null

      setFullStars(val1)
      setHalfStars(val2)
      setEmptyStars(val3)
      setApple(data?.apple)
      storeHandler(actions.SHOW_LOADER, false);
    } catch ({ message }) {
      snackbarNotification({
        message,
        class: 'error'
      });
      storeHandler(actions.SHOW_LOADER, false);
    }
  }

  const handleAndroidConnect = () => {
    const url = android?.link;
    const link = document.createElement("a");
    link.href = url;
    link.target = "blank";
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };
  const handleAppleConnect = () => {
    const url = apple?.link;
    const link = document.createElement("a");
    link.href = url;
    link.target = "blank";
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  useEffect(() => {
    getVpnTable()
    getNhubsList()
    getAppStats()
  }, [])

  const handleOTA=()=>{
    const url = `${process.env.REACT_APP_OTA}`;
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }
  const handleFile = async () => {
    storeHandler(actions.SHOW_LOADER, true)
    try {
      const { data } = await AuthServices.vnhubdownloadfile();
      const link = document.createElement("a");
      link.href = data;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      storeHandler(actions.SHOW_LOADER, false)
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      })
      storeHandler(actions.SHOW_LOADER, false)
    }
  }
  return (
    <>
      <DashHeader pageTitle="Utility" />
      <Grid container my={4} rowSpacing={2} columnSpacing={1}
        sx={{
          //   display: { md: 'flex' },
          //   justifyContent: { md: 'space-between' },
          //   alignItems: { md: 'flex-start' },
          //   alignContent: {md:'stretch'},
          height: '90%',
          padding: '0.5rem 2rem',
          overflowY: "scroll",
        }}
      >
        <Box
          sx={{
            display: { md: 'flex' },
            width: '100%',
            alignItems: { md: 'stretch' },
            justifyContent: { md: 'space-between' },
            paddingBottom: '1rem'
          }}
        >
          <Box item className='stack-first' sx={{ width: { xs: '100%', sm: '100%', md: '30%' } }}>
            <Paper sx={{ width: '100%', marginBottom: '1rem', height: 'fit-content' }}>
              <Heading>Notification</Heading>
              <TabNav activeIn={activeIn} changeActiveIn={changeTab} />
              <Box>
                <AddUserInput placeholder={activeIn === 2 ? "Enter an email address" : "Enter a mobile number"} value={notificationField.connection} onChange={(e) => notificationInfo('connection', e.target.value)} labelText={activeIn === 2 ? "Email Address" : "Mobile Number"} />
                <AddUserInput placeholder="Enter a message" value={notificationField.conn_str} onChange={(e) => notificationInfo('conn_str', e.target.value)} labelText="Broadcast Message" />
                <Stack sx={{
                  display: 'flex',
                  alignItems: 'center',
                  // justifyContent: 'space-between',
                }}>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      margin: "1rem 0",
                      fontSize: '1rem',
                      fontWeight: '300',
                      padding: '0.75rem 1rem',
                      outline: '2px solid',
                      // width: '40%',
                    }}
                    onClick={() => notifValidate('test')}
                  >
                    Test Connection
                  </Button>
                  {/* <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ color: '#fff' }}
                  onClick={() => notifValidate('save')}
                >
                  <SendIcon style={{ marginRight: "1rem" }} />
                  Send
                </Button> */}
                </Stack>
              </Box>
            </Paper>
          </Box>
          <Box className='stack-first' sx={{
            display: { md: 'flex' },
            alignItems: { md: 'stretch' },
            width: { xs: '100%', sm: '100%', md: '30%' }
          }}>
            <Paper
              sx={{ width: '100%', marginBottom: '1rem' }}
            >
              <Stack direction="row" justifyContent="space-between" marginBottom="1rem" >
                <Stack direction="column" spacing={3}
                  sx={{
                    marginLeft: '1rem',
                  }}
                >
                  <img style={{
                    width: '7rem',
                    height: '7rem',
                    margin: '-2rem 0 -3rem 0',
                  }} src={GooglePlayImg} alt="GooglePlayImg" className='pointer' onClick={handleAndroidConnect} />
                  <Box>
                    <Typography
                      sx={{
                        color: '#00B89F',
                        fontSize: '0.6rem',
                      }}
                    >
                      Downloads
                    </Typography>
                    <Typography
                      sx={{
                        color: '#d8d8d8',
                        fontSize: '1rem',
                      }}
                    >
                      {android?.installs || '0'}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: '#00B89F',
                        fontSize: '0.6rem',
                      }}
                    >
                      Rating
                    </Typography>
                    <Stack direction="row" spacing={1}>
                      {
                        fullStars ? Array.from(Array(fullStars))?.map((_, index) => (
                          <BsFillStarFill key={index} color='orange' />
                        )) : ''
                      }
                      {
                        halfStars !== null && (halfStars === 0.5 ? <BsStarHalf color='orange' /> : <BsStar color='orange' />)
                      }
                      {
                        emptyStars ? Array.from(Array(emptyStars))?.map((_, index) => (
                          <BsStar key={index} color='orange' />
                        )) : ''
                      }
                      {/* {
                      Number(ratings) <= 5 ? Array.from(Array(Math.floor(Number(ratings))))?.map((_, index) => (
                        <BsFillStarFill key={index} color='orange' />
                      )) : Array.from(Array(5))?.map((_, index) => (
                        <BsFillStarFill key={index} color='orange' />
                      ))
                    }
                    {
                      Number(ratings) < 5 && Number(ratings) >= 0 && (Math.round(Number(ratings) - Math.floor(Number(ratings))) > Number(ratings) - Math.floor(Number(ratings)) ?
                        <BsStarHalf color='orange' /> :
                        <BsStar color='orange' />)
                    }
                    {
                      Number(ratings) < 5 && Number(ratings) >= 0 && Array.from(Array(Math.floor(5 - Number(ratings))))?.map((_, index) => (
                        <BsStar color='orange' />
                      ))
                    } */}
                    </Stack>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: '#00B89F',
                        fontSize: '0.6rem',
                      }}
                    >
                      Reviews
                    </Typography>
                    <Typography
                      sx={{
                        color: '#d8d8d8',
                        fontSize: '1rem',
                      }}
                    >
                      {android?.reviews || '0'}
                    </Typography>
                  </Box>
                </Stack>
                <Stack direction="column" spacing={3}>
                  <img style={{
                    width: '7rem',
                    margin: '0.4rem 1rem 0.3rem 0',
                    // height: '2.5rem',
                  }} src={AppleStoreImg} alt="GooglePlayImg" className='pointer' onClick={handleAppleConnect} />
                </Stack>
              </Stack>
            </Paper>
          </Box>
          <Grid item className='stack-first' sx={{ width: { xs: '100%', sm: '100%', md: '30%' }, paddingBottom: '1rem' }}>
            <TableContainer className='table-container rpi-updates' component={Paper} sx={{ width: { xs: '100%', md: '100%' } }}>
              <Box className="header-box">
                <Heading>Connect to NHub</Heading>
                <Box className='d-flex flex-row align-items-center'>
                  <SearchBar search={searchMac} utility={true} />
                  <BiRefresh onClick={getVpnTable} className={`refresh ${refreshLoader && 'refresh-loader'}`} />
                </Box>
              </Box>
              <Box className={`table-scroll ${(vpnTableData.length === 0 && searchValue.length === 0) ? "nodevicemsg" : ''}`}>
                {
                  vpnTableData.length > 0 ? (<Table className='table-mac'>
                    <TableBody className='table-body'>
                      {
                        searchValue.length > 0 ?
                          (
                            filteredVpnTableData.map((data, index) => {
                              return data?.mac_addr !== "None" && data?.ip && !(data?.ip?.includes('None')) && <TableRow className="tr" key={index}>
                                <TableCell className='td table-mac-cell'><a className="a" href={`${process.env.REACT_APP_VPN}/${data?.ip?.trim()}/`} target='blank'>{data?.mac_addr}</a></TableCell>
                              </TableRow>
                            })) :
                          vpnTableData.length > 0 ? (
                            vpnTableData.map((data, index) => {
                              return data?.mac_addr !== "None" && data?.ip && !(data?.ip?.includes('None')) && <TableRow className="tr" key={index}>
                                <TableCell className='td table-mac-cell'><a className="a" href={`${process.env.REACT_APP_VPN}/${data?.ip?.trim()}/`} target='blank'>{data?.mac_addr}</a></TableCell>
                              </TableRow>
                            })) :
                            null
                      }
                    </TableBody>
                  </Table>) : <p style={{ color: "#9c9c9c" }}>No device active</p>
                }

              </Box>
            </TableContainer>
          </Grid>
        </Box>
        <Grid direction='column' className='stack-first' sx={{ width: { xs: '100%', sm: '100%', md: '100%' }, height: '60rem' }}>
          <Box sx={{ display: 'flex', flexDirection: { md: 'row', sm: 'column', xs: 'column' }, width: "100%", gap: '1rem', mb: '1rem' }}>
            <Paper sx={{ width: "100%", justifyItems: 'center', overflowy: 'hidden' }}>
              <Heading>SMS Template</Heading>
              <Box sx={{ mt: "2rem", overflow: 'scroll', maxHeight: '15rem' }}>
                {
                  smsTemplate.map((sms) => {
                    return <SmsTemplateCard
                      name={sms.name}
                      message={sms.message}
                      icon={sms.icon} />
                  })
                }
              </Box>
            </Paper>
            <Paper sx={{ width: "100%", justifyItems: 'center', overflowy: 'hidden' }}>
              <Heading>CEN Configuration</Heading>
              <Box>
                <CENSensorConfigurable />
              </Box>

            </Paper>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: { md: 'row', sm: 'column', xs: 'column' }, width: "100%", gap: '1rem', mb: '1rem' }}>
            <Paper className='table-container' component={Paper} sx={{ width: { xs: '100%', md: '100%' } }}>
              {/* <Box className="header-updates-box">
              <Heading>NHUB Updates</Heading>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                sx={{
                  fontSize: '1rem',
                  fontWeight: '300',
                  padding: '0.75rem 1rem',
                  outline: '2px solid',
                }}
                onClick={() => setOpenCreate(true)}
              >
                Create New Update
              </Button>
            </Box> */}
              {/* <Box className="table-scroll">
              <Table>
                <TableHead>
                  <TableRow className='tr'>
                    <TableCell align='center th table-mac-cell'>ID</TableCell>
                    <TableCell align='center th table-mac-cell'>Date of Release</TableCell>
                    <TableCell align='center th table-mac-cell'>File Name</TableCell>
                    <TableCell align='center th table-mac-cell'>Memory</TableCell>
                    <TableCell align='center th table-mac-cell'>RPI Updated (%)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className='table-body'>

                  {
                    nhubsList?.length ? (rowsPerPage > 0
                      ? nhubsList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : nhubsList
                    ).map((ele, index) => <TableRow className='bb-0 tr' key={index}>
                      <TableCell align='center' className='bb-0 td table-mac-cell'>{ele?.version_id}</TableCell>
                      <TableCell align='center' className='bb-0 td table-mac-cell'>{moment(moment.utc(ele?.date, 'ddd, DD MMM YYYY HH:mm:ss')).local().format('ddd, DD MMM YYYY HH:mm:ss')}</TableCell>
                      <TableCell align='center' className='bb-0 td table-mac-cell'>{ele?.filename}</TableCell>
                      <TableCell align='center' className='bb-0 td table-mac-cell'>{ele?.size}</TableCell>
                      <TableCell align='center' className='bb-0 td table-mac-cell'>{ele?.nhub_percent}</TableCell>
                    </TableRow>) : null
                  }


                </TableBody>
                {
                  nhubsList?.length > 0 && <TableFooter>
                    <TableRow>
                      <TablePagination
                        color='00B89F'
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        // colSpan={3}
                        count={nhubsList?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                          color: '#D8D8D8'
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                }
              </Table>
            </Box> */}
              <Box className="header-updates-box">
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',mb:'-0.8rem'}}>
                  <Box sx={{mb:'3rem'}}>
                  <NhubUpdates style={{mr:'2rem',padding:'30px 20px 0px 0px' }} />
                  </Box>
                  
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{display: 'flex', flexDirection: 'column', fontWeight: 700, mb: '10px'}}>Nhub Updates</Typography>
                    <Typography >update your Nhub in OTA portal, Use the <span style={{ fontWeight: 700 }}>OTA Portal</span> to navigate and create updates</Typography>
                    <Button
                      onClick={handleOTA}
                      variant="outlined"
                      size="small"
                      marginBottom='-10px'
                      sx={{
                        width: '240px',
                        margin: "1rem 0",
                        fontSize: '1rem',
                        fontWeight: '300',
                        // padding: '0.5rem 2rem',
                        outline: '1.5px solid',
                        "&:hover": {
                          outline: '2px solid'
                        }
                      }}>
                      OTA Portal
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Paper>
            <Paper className='table-container' component={Paper} sx={{ width: { xs: '100%', md: '100%' } }}>
            <Box className="header-updates-box">
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',mb:'-0.8rem'}}>
                  <Box sx={{mb:'3rem'}}>
                  <VNhub style={{mr:'2rem',padding:'30px 30px 0px 0px' }} />
                  </Box>
                  
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{display: 'flex', flexDirection: 'column', fontWeight: 700, mb: '10px',mt:'10px'}}>To flash fall detection on VNhub</Typography>
                    <Typography sx={{mb:'20px'}}>Tool to flash MQTT and Wi-Fi credential into fall detection sensor
                    </Typography>
                    <Button
                      onClick={handleFile}
                      variant="outlined"
                      size="small"
                      margintop='-10px'
                      sx={{
                        width: '240px',
                        margin: "1rem 0",
                        fontSize: '1rem',
                        fontWeight: '300',
                        outline: '1.5px solid',
                        "&:hover": {
                          outline: '2px solid'
                        }
                      }}>
                      Download
                    </Button>
                  </Box>
                  
                </Box>
              </Box>
            </Paper>
          </Box>
        </Grid>
        {/* <Paper sx={{ width: { xs: '100%', md: '30%' }, marginBottom: '1rem' }}>
          <Heading >VPN Credentials</Heading>
          <InputField label="Username" variant="filled" type="text" fullWidth size="small" value={vpnCredentials?.username || ''}
            onChange={(event) => vpnInfo('username', event.target.value)}
            sx={{
              margin: '1.2rem 0rem 0.7rem 0',
            }}
          />
          <InputField
            label="Password"
            variant="filled"
            type="password"
            passwordfield={true}
            fullWidth
            size="small"
            value={vpnCredentials?.password || ''}
            onChange={(event) => vpnInfo('password', event.target.value)}
            sx={{
              margin: '0 0rem 1.5rem 0',
            }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ color: '#fff' }}
            onClick={VpnCredValidate}
          >
            <SendIcon style={{ marginRight: "1rem" }}/>
            Save
          </Button>
        </Paper> */}
        <CreateUpdate open={openCreate} handleClose={() => setOpenCreate(false)} addPatch={addPatch} />
        <Snackbar open={showSnackBar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          message={snackbarContent?.message || ''}
          autoHideDuration={4000}
          onClose={() => setShowSnackBar(false)}
          sx={{
            '.MuiSnackbarContent-root': {
              color: '#fff',
              fontWeight: '400',
              backgroundColor: snackbarContent.class === 'error' ? 'red' : (snackbarContent.class === 'warning' ? '#ffcc00' : '#00b89f'),
              display: 'flex',
              justifyContent: 'center',
              borderRadius: '10rem'
            }
          }}
        />
      </Grid>
    </>
  );
};

export default Utility;
