import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import "./ConfirmationDialog.scss";
import SeniorCards from './SeniorCard/SeniorCards';

export default function ConfirmationDialog({ open, handleClose, text, id, currentSelected, seniorList }) {

    return (
        <div className='dialog-div'>
            <Dialog
                open={open}
                onClose={() => handleClose(false)}
                BackdropProps={{ style: { backgroundColor: 'black', opacity: "0.9" } }}
                className="confirmation-dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box
                    sx={{
                        color: '#D8D8D8',
                        margin: '0 2rem',
                        marginTop: '0.5rem',
                        justifyContent: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography>
                        {text}
                    </Typography>

                    {seniorList?.length > 0 && <SeniorCards list={seniorList} />}

                    {currentSelected === "seniorSpecific" ?
                        <Box className="flex-row">
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    color: '#D8D8D8',
                                    margin: '1rem 1rem 0 0',
                                    width: '100%',
                                    whiteSpace: 'noWrap',
                                    fontWeight: '300',
                                    fontSize: '1.1rem',
                                }}
                                // endIcon={<NextIcon />}
                                onClick={() => handleClose(false)}
                            >
                                Ok
                            </Button>
                        </Box>

                        :
                        <Box className="flex-row">
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    color: '#D8D8D8',
                                    margin: '1rem 1rem 0 0',
                                    width: '100%',
                                    whiteSpace: 'noWrap',
                                    fontWeight: '300',
                                    fontSize: '1.1rem',
                                }}
                                // endIcon={<NextIcon />}
                                onClick={() => handleClose(true, id)}
                            >
                                Yes
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    color: '#D8D8D8',
                                    margin: '1rem 0 0 0',
                                    width: '100%',
                                    whiteSpace: 'noWrap',
                                    fontWeight: '300',
                                    fontSize: '1.1rem',
                                }}
                                // endIcon={<NextIcon />}
                                onClick={() => handleClose(false)}
                            >
                                No
                            </Button>
                        </Box>}
                </Box>
            </Dialog>
        </div>
    );
}