import { Box, Paper } from '@mui/material';
import React from 'react';
import NaviLink from 'components/navilink/NaviLink';
import { ReactComponent as DashIcon } from 'assets/img/dashboard-nav.svg';
import { ReactComponent as AccountsIcon } from 'assets/img/accounts-nav.svg';
import { ReactComponent as NHUBIcon } from 'assets/img/nhub-nav.svg';
import { ReactComponent as ReportsIcon } from 'assets/img/reports-nav.svg';
import { ReactComponent as SettingsIcon } from 'assets/img/settings-nav.svg';
import { ReactComponent as StaffRolesIcon } from 'assets/img/users-roles-nav.svg';
import shortLogo from 'assets/img/logo_short.png';
const SideNav = () => {
  return (
    <Paper
      sx={{
        width: '5rem',
        height: '97.7vh',
        textAlign: 'center',
        padding: '0.5rem 0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '10px',
      }}
    >
      <Box>
        <img
          sx={{ maxWidth: '100%', display: 'block' }}
          src={shortLogo}
          alt=""
        />
      </Box>
      <Box>
        <NaviLink to="/dashboard" exact icon={DashIcon} text="Dashboard" />
        <NaviLink to="/accounts" exact icon={AccountsIcon} text="Accounts" />
        <NaviLink to="/staff-roles" exact icon={StaffRolesIcon} text="Staff Roles" />
        <NaviLink to="/nhub" exact icon={NHUBIcon} text="Nhub" />
        <NaviLink to="/utility" exact icon={SettingsIcon} text="Utility" />
        <NaviLink to="/notification" exact icon={AccountsIcon} text="Notification" />
        <NaviLink to="/reports" exact icon={ReportsIcon} text="Reports" />
      </Box>
      <Box></Box>
    </Paper>
  );
};

export default SideNav;
