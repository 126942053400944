import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'app/App';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from 'components/theme/theme';
import GlobalProvider from 'context/GlobalProvider';
import '../src/assets/scss/main.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <GlobalProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </GlobalProvider>
    </React.StrictMode>
  </BrowserRouter>
);
