import React, { useState } from 'react';
import { BsArrowLeftShort } from 'react-icons/bs';
import sendIcon from 'assets/img/SendIcon.svg';
import OTPInput from 'otp-input-react';
import styles from './sendotp.module.scss';
import {
  Button,
  IconButton,
  Paper,
  Stack,
  Typography,
  Box,
  Snackbar,
} from '@mui/material';
import { Link } from 'react-router-dom';
import AuthServices from 'api/services/auth-services';
import ChangePassword from 'app/forgot-password/components/change-password/ChangePassword';

const SendOTP = ({ email }) => {

  const [otp, setOtp] = useState('');
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });
  const [showChangePassword, setShowChangePassword] = useState(false);

  const validateOtp = (event) => {
    event.preventDefault()
    if (otp.length === 6) {
      verifyOtp();
    }
    else {
      const data = { message: 'Enter a valid OTP', class: 'error' }
      setSnackBarContent({ ...data })
      setShowSnackBar(true)
    }
  }

  const verifyOtp = async () => {
    const formData = new FormData();
    formData.append('email', email)
    formData.append('otp', otp)

    try {
      const { message } = await AuthServices.verifyOtp(formData);
      const data = { message, class: 'success' }
      setSnackBarContent(data)
      setShowSnackBar(true)
      setTimeout(() => {
        setShowChangePassword(true)
      }, 1000)
    } catch (err) {
      const { data } = err || {}
      const { message } = data || {}
      if (message) {
        const data = { message, class: 'error' }
        setSnackBarContent({ ...data })
        setShowSnackBar(true)
      }
    }
  }

  const resendOtp = () => {
    setOtp('')
    setTimeout(() => {
      getNewOtp()
    }, 1000)
  }

  const getNewOtp = async () => {
    try {
      const formData = new FormData();
      formData.append('email', email)
      const { message } = await AuthServices.forgetPassword(formData);
      if (message) {
        const data = { message, class: 'success' }
        setShowSnackBar(true)
        setSnackBarContent({ ...data })
      }
    } catch (err) {
      const { data } = err || {}
      const { message } = data || {}
      if (message) {
        const data = { message, class: 'error' }
        setSnackBarContent({ ...data })
        setShowSnackBar(true)
      }
    }
  }

  return (
    showChangePassword ? <ChangePassword email={email} /> :
      <>
        <Paper elevation={0} style={{ width: '27.5rem' }}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems="center"
            mb={0}
          >
            <IconButton p={0} disableRipple={true} color="primary" flex={1}>
              <Link to="/login">
                <BsArrowLeftShort size="35px" />
              </Link>
            </IconButton>
            <Typography textAlign="center" variant="h3" flex={1} sx={{ paddingRight: '3rem' }}>
              Verify email OTP
            </Typography>
          </Stack>
          <Typography textAlign="center">
            Please check your email for a 6-digit security code and enter below
          </Typography>
          <form onSubmit={validateOtp}>
            <Stack alignItems="center" justifyContent="center">
              <OTPInput
                className={styles.sendOtp}
                value={otp}
                onChange={setOtp}
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={false}
                secure
              />
              <Typography mb={2}>
                Didn't receive a code?{' '}
                <button
                  className={styles.resendBtn}
                  type='button'
                  onClick={(e) => {
                    e.preventDefault();
                    resendOtp();
                  }}
                >
                  Resend code
                </button>
              </Typography>
            </Stack>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={validateOtp}
              startIcon={<Box mr={1.5} pb={0} mb={-1}><img src={sendIcon} alt="" /></Box>}
              type='submit'
              mt={2}
            >
              Confirm
            </Button>
          </form>
        </Paper>
        <Snackbar open={showSnackBar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          message={snackbarContent?.message || ''}
          autoHideDuration={4000}
          onClose={() => setShowSnackBar(false)}
          sx={{
            '.MuiSnackbarContent-root': {
              color: '#fff',
              fontWeight: '400',
              backgroundColor: snackbarContent.class === 'error' ? 'red' : (snackbarContent.class === 'warning' ? '#ffcc00' : '#00b89f'),
              display: 'flex',
              justifyContent: 'center',
              borderRadius: '10rem'
            }
          }}
        />
      </>
  );
};

export default SendOTP;
