import React, { useContext, useState, useEffect } from "react";
import { Button, Box, Stack } from "@mui/material";
import moment from "moment";
import { TransparentBtn } from "../../components/styled-componets/style";
import SimpleInputField from "components/simple-inputfield/SimpleInputField";
import AuthServices from "api/services/auth-services";
import { actions, GlobalContext } from "context";
import { ReactComponent as TickIcon } from "assets/img/tick_icon.svg";
import Timepicker from "components/time-picker/Timepicker";
import { getTimeZoneOffset, getUserTimeZome } from "utils/datetime-formatter";
// import { getTimein24HrFormat } from "utils/datetime-formatter";

const SOSInputs = ({
  currentSelected,
  selected,
  setting,
  snackbarNotification,
  handleFinish,
  isGeneric,
  macId,
  ...rest
}) => {
  const { dispatch } = useContext(GlobalContext);
  const storeHandler = (type, payload) => dispatch({ type, payload });

  const [invalidModes, setInvalidModes] = useState(false);

  const [notificatonModes, setNotificationModes] = useState({ email: setting?.notification_type?.email, sms: setting?.notification_type?.sms, push: setting?.notification_type?.push });
  const handleModeClick = (e) => {
    const mode = e.target.name;
    const prev = notificatonModes[mode];
    setNotificationModes({ ...notificatonModes, [mode]: !prev });
    invalid.modes = false;
    setInvalidModes(false)

  }

  const [settings, setSettings] = useState({});

  const [bool, setBool] = useState(false);
  const [invalid, setInvalid] = useState({});

  useEffect(() => {
    const data = {
      ...setting,
    }
    setSettings({ ...data })
  }, [isGeneric, setting])


  const validate = () => {
    let data = settings;
    let invalid = {};
    let valid = true;
    if (data?.message === "") {
      invalid.message = true;
      valid = false;
    }
    setInvalid(invalid);
    const isValidModes = Object.values(notificatonModes).some(item => item)
    setInvalidModes(!isValidModes)
    if (valid && (isValidModes || currentSelected === "generic")) handleEdit();
  }

  const handleChange = (value, key) => {
    const newInvalid = { ...invalid };
    newInvalid[key] = false;
    setInvalid(newInvalid);
    let data = settings;
    data[key] = value;
    setSettings(data);
    setBool((prev) => !prev);
  };

  const handleEdit = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const data = { ...settings, timezone: getUserTimeZome(), origin: getTimeZoneOffset() };
      if (currentSelected === "generic") {
        const { message } = await AuthServices.editGenericNotifications(data);
        snackbarNotification({
          message,
          class: "success",
        });
      } else {
        const { message } = await AuthServices.editCentricNotifications(
          selected?.id,
          { ...data, noti_type: notificatonModes, timezone: getUserTimeZome(), origin: getTimeZoneOffset(),mac_id:macId }
        );
        snackbarNotification({
          message,
          class: "success",
        });
      }
      handleFinish(true);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  return (
    <>
      <Stack>
        <Box sx={{ paddingTop: '1.5rem' }}>
          <SimpleInputField
            labelText="CEN"
            multiline={8}
            placeholder="Enter Critical Event Notification"
            type="Other"
            helperText={invalid?.message ? "Please enter a valid message" : ""}
            value={settings?.message}
            onChange={(event) => handleChange(event.target.value, "message")}
          />
        </Box>
      </Stack>
      {
        currentSelected !== "generic" && (
          <>
            {invalidModes && <Box sx={{ color: 'red', fontSize: '0.85rem' }}>Please select at least one medium</Box>}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.8rem' }}>
              <TransparentBtn sx={{ color: notificatonModes?.email ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='email' variant="outlined">Email</TransparentBtn>
              <TransparentBtn sx={{ color: notificatonModes?.sms ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='sms' variant="outlined">SMS</TransparentBtn>
              <TransparentBtn sx={{ color: notificatonModes?.push ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='push' variant="outlined">Push</TransparentBtn>
            </Box>
          </>
        )
      }
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{ color: "#fff", width: "15rem", mb: "1rem" }}
          startIcon={<TickIcon />}
          onClick={validate}
        >
          Update
        </Button>
      </Box>
    </>
  );
};

export default SOSInputs;
