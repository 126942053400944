import {
  MenuItem,
  Stack,
  Badge,
  Typography,
  Box,
  Tooltip,
  IconButton,
  Avatar,
  Menu,
  Drawer,
  Breadcrumbs,
  Snackbar,
} from '@mui/material';
import AuthServices from 'api/services/auth-services';
import SearchBar from 'components/search-bar/SearchBar';
import SideNav from 'components/sidenav/SideNav';
import { actions, GlobalContext } from 'context';
import React, { useContext, useState } from 'react';
import { ReactComponent as LogoutIcon } from 'components/dash-header/assets/logout.svg';
import { ReactComponent as ProfileIcon } from 'components/dash-header/assets/profile.svg';
import { ReactComponent as AddIcon } from 'components/dash-header/assets/addDevice.svg';
import { useEffect } from 'react';
import { BiBell, BiMenu } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';
import "./DashHeader.scss";
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog/ConfirmationDialog';
import LogoutDialog from './LogoutDialog/ConfirmationDialog';

const DashHeader = ({ pageTitle, searchBar, searchFn, name, deviceName, tab }) => {

  const { state: { userProfile }, dispatch } = useContext(GlobalContext);
  const { profile: userImg } = userProfile || {};
  const [navOpen, setNavOpen] = useState(false);
  // const [profile, setProfile] = useState({})
  const [profileImg, setProfileImg] = useState()
  const [nots, setNots] = useState([]);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [activeState, setActiveState] = useState([
    false,
    false
  ])
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });
  const [handleDialogOpen, setHandleDialogOpen] = useState(false);
  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const [data, setData] = useState();

  const storeHandler = (type, payload) => dispatch({ type, payload })

  const snackbarNotification = (data) => {
    setSnackBarContent(data);
    setShowSnackBar(true);
  }

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const toggleDrawer = (open) => {
    setNavOpen(open);
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    window.location.reload();
  }

  const getProfile = async () => {
    storeHandler(actions.SHOW_LOADER, true)
    try {
      const { data } = await AuthServices.getProfile();
      setData(data)
      storeHandler(actions.USER_PROFILE, { ...data });
      if (!!data.profile) {
        getBase64Image(data.profile)
      }
      storeHandler(actions.SHOW_LOADER, false)
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      })
      storeHandler(actions.SHOW_LOADER, false)
    }
  }

  useEffect(() => {
    getBase64Image(userImg)
  }, [userImg])

  useEffect(() => {
    if (!userImg) {
      getProfile();
    }
  }, [userImg])

  const getBase64Image = (url) => {
    const img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/png");
      setProfileImg(dataURL)
    }
    img.src = url
  }

  const history = useHistory()
  useEffect(() => {
    let arr = [];
    switch (history.location.pathname) {
      case "/edit-profile": arr = [true, false]
        setActiveState(arr)
        break;
      case "/add-staff-role": arr = [false, true]
        setActiveState(arr)
        break;
      default: break;
    }
  }, [history.location.pathname])

  const handleResize = () => {
    if (window.innerWidth > 768) {
      toggleDrawer(false)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  }, [])

  return (
    <Stack
      className="dash-stack"
    >
      <IconButton
        onClick={() => toggleDrawer(true)}
        sx={{
          color: 'primary.main',
          display: { xs: 'block', md: 'none' },
          marginTop: '0.24rem'
        }}
      >
        <BiMenu size={'2rem'} />
      </IconButton>
      <Drawer anchor="left" sx={{ display: { xs: 'block', md: 'none' } }} open={navOpen} onClose={() => toggleDrawer(false)}>
        <SideNav />
      </Drawer>
      <Typography className='title-typography' variant="h2" color="secondary">
        {
          tab === "accounts" ?
            name !== undefined ?
              (<Breadcrumbs className='breadcrumbs' aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/accounts">
                  <Typography variant="h2" color="#00b89f">{pageTitle}</Typography>
                </Link>
                <Typography variant="h2" color="secondary">{name}</Typography>
              </Breadcrumbs>) :
              pageTitle :
            tab === "nhub" ?
              deviceName !== "" ?
                (<Breadcrumbs className='breadcrumbs' aria-label="breadcrumb">
                  <Link underline="hover" color="inherit" to="/nhub">
                    <Typography variant="h2" color="#00b89f">{pageTitle}</Typography>
                  </Link>
                  <Typography variant="h2" color="secondary">{deviceName}</Typography>
                </Breadcrumbs>) :
                pageTitle :
              pageTitle
        }
      </Typography>
      <div>
        <Box className='box-parent-dash'>
          {searchBar && <Box className='search-box-box'><SearchBar search={searchFn} tab={tab} /></Box>}
          <Box className='icons-box'>
            {/* <Tooltip color="primary" title="Notifications">
              <IconButton
                onClick={handleClick1}
                sx={{ ml: 2, background: '#1c2125' }}
                aria-haspopup="true"
                aria-expanded={open1 ? 'true' : undefined}
              >
                <Badge badgeContent={nots.length} color="error">
                  <BiBell />
                </Badge>
              </IconButton>
            </Tooltip> */}
            <Menu
              anchorEl={anchorEl1}
              open={open1}
              onClose={handleClose1}
              onClick={handleClose1}
              PaperProps={{
                elevation: 0,
                sx: {
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  outline: '1px solid primary',
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {nots.size > 0 ? nots.map((not, index) => {
                return <MenuItem key={index}>{not}</MenuItem>;
              }) :
                <MenuItem disabled>No notifications</MenuItem>
              }


            </Menu>
            <Tooltip title="Profile settings">
              <IconButton
                onClick={handleClick2}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open2 ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open2 ? 'true' : undefined}
              >
                <Avatar
                  sx={{ width: 32, height: 32 }}
                  src={profileImg}
                  alt="Person Being"
                />
              </IconButton>
            </Tooltip>
            <Menu

              anchorEl={anchorEl2}
              id="account-menu"
              open={open2}
              onClose={handleClose2}
              onClick={handleClose2}
              PaperProps={{
                elevation: 0,
                sx: {
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  outline: '1px solid primary',
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <Link to="/edit-profile">
                <MenuItem className="menu-item"><span className="span"><ProfileIcon className={`${activeState[0] && "active-profile"}`} /></span><Box className={`box ${activeState[0] && "active-profile-box"}`}>{data?.fname || 'Edit Profile'}</Box></MenuItem>
              </Link>
              <Link to="/add-staff-role">
                <MenuItem className="menu-item"><span className="span"><AddIcon className={`${activeState[1] && "active-addnew"}`} /></span><Box className={`box ${activeState[1] && "active-addnew-box"}`}>Add new</Box></MenuItem>
              </Link>
              <MenuItem onClick={() => setHandleDialogOpen(true)}><span className="span"><LogoutIcon className='svg-third' /></span><Box className='box'>Log out</Box></MenuItem>
            </Menu>
            <LogoutDialog text="Do you really want to log out?" func={logout} open={handleDialogOpen} handleClose={() => setHandleDialogOpen(false)} />
          </Box>
        </Box>
      </div>
      <Snackbar open={showSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={snackbarContent?.message || ''}
        autoHideDuration={4000}
        onClose={() => setShowSnackBar(false)}
        sx={{
          '.MuiSnackbarContent-root': {
            color: '#fff',
            fontWeight: '400',
            backgroundColor: snackbarContent.class === 'error' ? 'red' : (snackbarContent.class === 'warning' ? '#ffcc00' : '#00b89f'),
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '10rem'
          }
        }}
      />
    </Stack>
  );
};

export default DashHeader;
