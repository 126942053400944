import {
	Box,
	Button,
	Divider,
	IconButton,
	Paper,
	Stack,
	Switch,
	Typography,
	Grid,
	Avatar,
	Snackbar,
	Tooltip,
	Badge,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import spO2 from "assets/img/pulse-oximeter.png";
import axios from "axios";
import { ReactComponent as Location } from "assets/img/location.svg";
import { ReactComponent as EditIcon } from "assets/img/edit_icon_primary.svg";
import { ReactComponent as EditIconSmall } from "assets/img/edit_icon_small.svg";
import { ReactComponent as EmailIcon } from "assets/img/email_icon.svg";
import { ReactComponent as PhoneIcon } from "assets/img/phone_icon.svg";
import { ReactComponent as CameraIcon } from "assets/img/camera_big_icon.svg";
import { ReactComponent as WatchIcon } from "assets/img/watch_big_icon.svg";
import { ReactComponent as HeartRateIcon } from "assets/img/heart_rate_icon.svg";
import { ReactComponent as BPMIcon } from "assets/img/bpm_icon.svg";
import { ReactComponent as StepsIcon } from "assets/img/steps_icon.svg";
import { ReactComponent as CaloriesIcon } from "assets/img/calories_icon.svg";
import { ReactComponent as BatteryIcon } from "assets/img/battery_icon.svg";
import { ReactComponent as BathRoomIcon } from "assets/img/24px_Bathroom_Sensor_Icon_Default.svg";
import { ReactComponent as DoorIcon } from "assets/img/door_icon.svg";
import { ReactComponent as PillIcon } from "assets/img/pill_icon.svg";
import { ReactComponent as NHUBIcon } from "assets/img/nhub_icon.svg";
import { ReactComponent as SleepIcon } from "assets/img/sleep_icon.svg";
import { ReactComponent as ShowerIcon } from "assets/img/shower-icon.svg";
import { ReactComponent as WatchSmallIcon } from "assets/img/watch.svg";
import { ReactComponent as FallDetectionIcon } from "assets/img/Falldetectionwhite.svg";
import { ReactComponent as CamerasmallIcon } from "assets/img/camera.svg";
import { ReactComponent as GpsIcon } from "assets/img/gps.svg";
import { ReactComponent as FallIcon } from "assets/img/falldetection28x28.svg";
import { ReactComponent as FluidIcon } from "assets/img/fluid.svg";
import { ReactComponent as BeltIcon } from "assets/img/belt.svg";
import { ReactComponent as CouchIcon } from "assets/img/couch.svg";
import { ReactComponent as SOSIcon } from "assets/img/sos.svg";
import { ReactComponent as Executive } from "assets/img/executive_icon.svg";
import { ReactComponent as AddAccountIcon } from "assets/img/add_account_icon.svg";
import { ReactComponent as DeleteIcon } from "assets/img/delete-icon.svg";
import { ReactComponent as NewFallIcon } from "assets/img/28x28_px_Fall_detection_outlined_green.svg";
import InfoIcon from "assets/img/info_icon.svg";

import { MdDelete } from "react-icons/md";
import DashHeader from "components/dash-header/DashHeader";
import ExecutiveMemberLink from "app/accounts/components/executive-member-link/ExecutiveMemberLink";
import { useHistory, useLocation } from "react-router-dom";
import { actions, GlobalContext } from "context";
import AuthServices from "api/services/auth-services";
import "./AccountDetails.scss";
import AddExecutiveDialog from "./components/AddExecutiveMember/AddExecutiveDialog";
import AddMemberDialog from "./components/AddMember/AddMemberDialog";
import ConfirmationDialog from "components/dialogs/ConfirmationDialog/ConfirmationDialog";
import EditMemberDialog from "./components/EditMember/EditMember";
import EditSeniorDialog from "./components/EditSenior/EditSenior";
import EditExecutiveMemberDialog from "./components/EditExecutiveMember/EditExecutiveMember";
import ConfirmationExecutiveDialog from "components/dialogs/ConfirmationExecutiveDialog/ConfirmationExecutiveDialog";
import ConnectToNHUBDialog from "./components/ConnectToNHUBDialog/ConnectToNHUBDialog";
import FallDetectionStepper from "./components/FallDetectionStepper/FallDetectionStepper";
import moment from "moment/moment";
import { vpnValidator } from "utils/validator";
import GoodWifi from "../../../assets/img/icon _wifi__Good Strength (1).svg";
import ModerateWifi from "../../../assets/img/icon _wifi__Moderate1.svg";
import WeakWifi from "../../../assets/img/icon _wifi__Moderate2.svg";
import veryWeakWifi from "../../../assets/img/icon _wifi__Weak.svg";
import WifiOff from "../../../assets/img/wifi-off.svg";
import VpnOnIcon from "../../../assets/img/vpn_on.svg";
import VpnOffIcon from "../../../assets/img/vpn_off.svg";
import LAN_Connected from "assets/img/LAN_Connected.svg";
import LAN_Not_Connected from "assets/img/LAN_Not_Connected.svg";
import newGoodWifi from "../../../assets/img/icon_newpatch_wifi_goodstrength.svg";
import newModerateWifi from "../../../assets/img/icon_newpatch_Wifi_Moderate1.svg";
import newWeakWifi from "../../../assets/img/icon_newpatch_Wifi_Moderate2.svg";
import newVeryWeakWifi from "../../../assets/img/Icon_newpatch_wifi_Weak.svg";
const userIm = "https://randomuser.me/api/portraits/men/79.jpg";

const Notification = {
	AtleastOneExecutive: {
		message: "Atleast One Executive Member Should Be Present",
		class: "error",
	},
	addMemberSuccess: { message: "Member added successfully", class: "success" },
	editMemberSuccess: {
		message: "Member details updated successfully",
		class: "success",
	},
	memberLimitReached: { message: "Member limit reached", class: "error" },
};

const AccountDetails = () => {
	const { dispatch } = useContext(GlobalContext);
	const [details, setDetails] = useState();
	let location = useLocation();
	const [executiveMembers, setExecutiveMembers] = useState([]);
	const [activeExecutiveMember, setActiveExecutiveMember] = useState(null);
	const [members, setMembers] = useState([]);
	const [activeMember, setActiveMember] = useState(null);
	const [noData, setNoData] = useState(false);
	const [sensors, setSensors] = useState([]);
	const [memberLimit, setMemberLimit] = useState(5);
	const [handleDeleteSeniorDialogOpen, setHandleDeleteSeniorDialogOpen] = useState(false);
	const [handleDeleteExecutiveMemberDialogOpen, setHandleDeleteExecutiveMemberDialogOpen,] = useState(false);
	const [handleCreateVnhubDialogOpen, setHandleCreateVnhubDialogOpen,] = useState(false);
	const [handleDeleteMemberDialogOpen, setHandleDeleteMemberDialogOpen] = useState(false);
	const [handleAddAccountDialogOpen, setHandleAddAccountDialogOpen] = useState(false);
	const [handleAddMemberDialogOpen, setHandleAddMemberDialogOpen] = useState(false);
	const [handleEditSeniorDialogOpen, setHandleEditSeniorDialogOpen] = useState(false);
	const [handleEditExecutiveMemberDialogOpen, setHandleEditExecutiveMemberDialogOpen,] = useState(false);
	const [handleEditMemberDialogOpen, setHandleEditMemberDialogOpen] = useState(false);
	const [handleNhubDialog, setHandleNhubDialog] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const [executiveCount, setExecutiveCount] = useState(0);
	const [memberCount, setMemberCount] = useState(0);
	const [showSnackBar, setShowSnackBar] = useState(false);
	const [snackbarContent, setSnackBarContent] = useState({
		message: "",
		class: "error",
	});
	const [countries, setCountries] = useState([]);
	const [relations, setRelations] = useState([]);
	const [showWatch, setShowWatch] = useState();
	const [innerWidth, setInnerWidth] = useState();
	const history = useHistory();

	const id = location.pathname.split("/account-details/")[1];

	const storeHandler = (type, payload) => dispatch({ type, payload });

	const formatDate = (date) => {
		const d = new Date(date);
		// check if d is valid date
		if (isNaN(d)) {
			return "N/A";
		}
		const month = `0${d.getMonth() + 1}`.slice(-2);
		const day = `0${d.getDate()}`.slice(-2);
		const year = d.getFullYear();
		return `${month}-${day}-${year}`;
	};

	const getCountries = () => {
		storeHandler(actions.SHOW_LOADER, true);
		AuthServices.getDashboard()
			.then(({ data }) => {
				setCountries(data.countries);
				storeHandler(actions.SHOW_LOADER, false);
			})
			.catch((err) => {
				const { data } = err || {};
				const { message } = data || {};
				snackbarNotification({
					message,
					class: "error",
				});
				storeHandler(actions.SHOW_LOADER, false);
			});
	};

	const getRelations = () => {
		storeHandler(actions.SHOW_LOADER, true);
		AuthServices.getRelations()
			.then(({ data }) => {
				setRelations(data);
				storeHandler(actions.SHOW_LOADER, false);
			})
			.catch((err) => {
				const { data } = err || {};
				const { message } = data || {};
				snackbarNotification({
					message,
					class: "error",
				});
				storeHandler(actions.SHOW_LOADER, false);
			});
	};

	const updateWindowDimensions = () => {
		const newWidth = window.innerWidth;
		setInnerWidth(newWidth);
	};

	useEffect(() => {
		if (id) getAccountDetails(id);
		else {
			setNoData(true);
		}
		getCountries();
		getRelations();
		window.addEventListener("resize", updateWindowDimensions);
		return () => window.removeEventListener("resize", updateWindowDimensions);
	}, []);

	const snackbarNotification = (data) => {
		setSnackBarContent(data);
		setShowSnackBar(true);
	};
	const [WatchUpdateDate, setWatchUpdateDate] = useState(false);
	const getAccountDetails = async (id) => {
		storeHandler(actions.SHOW_LOADER, true);
		try {
			const { data } = await AuthServices.getSeniorDetails(id);
			setDetails(data);
			if (
				data?.contract_name !== undefined ||
				data?.contract_name !== null ||
				data?.contract_name !== ""
			) {
				switch (data?.contract_name) {
					case "Bronze":
						setMemberLimit(3);
						break;
					case "Silver":
						setMemberLimit(4);
						break;
					case "Gold":
						setMemberLimit(5);
						break;
					case "Platinum":
						setMemberLimit(5);
						break;
					default:
						break;
				}
			}
			const d1 = data.watch_last_update;
			const d2 = data.watch_last_update && d1.split(" ");

			data.watch_last_update && setWatchUpdateDate(d2[0] + "T" + d2[1] + "Z");
			setTotalCount(data?.members?.length);
			getExecutiveMembers(data?.members);
			getMembers(data?.members);
			setSensors(data?.sensors);
			let flag = 0;
			Object.keys(data?.watch_status)?.forEach((key) => {
				if (data?.watch_status[key] !== null) {
					flag = 1;
				}
			});
			if (flag === 1) {
				setShowWatch(true);
			} else {
				setShowWatch(false);
			}
			storeHandler(actions.SHOW_LOADER, false);
		} catch (err) {
			const { data } = err || {};
			const { message } = data || {};
			snackbarNotification({
				message,
				class: "error",
			});
			storeHandler(actions.SHOW_LOADER, false);
		}
	};

	const getExecutiveMembers = (members) => {
		const executiveMembers = members.filter((member) => member.Execeutive);
		setExecutiveMembers(executiveMembers);
		if (executiveMembers.length > 0) {
			setActiveExecutiveMember(executiveMembers[0]);
		} else {
			setActiveExecutiveMember(null);
		}
		setExecutiveCount(executiveMembers?.length);
	};

	const getMembers = (members) => {
		const nonExecutiveMembers = members.filter((member) => !member.Execeutive);
		setMembers(nonExecutiveMembers);
		if (nonExecutiveMembers.length > 0) {
			setActiveMember(nonExecutiveMembers[0]);
		} else {
			setActiveMember(null);
		}
		setMemberCount(nonExecutiveMembers?.length);
	};

	const handleConnect = async () => {
		storeHandler(actions.SHOW_LOADER, true);
		let URL =
			process.env.REACT_APP_VPN.replace("lookup2", "lookup") +
			"/" +
			details?.nhub?.vpn.trim() +
			"/";
		AuthServices.getNhubStatus(URL)
			.then((res) => {
				storeHandler(actions.SHOW_LOADER, false);
				const url = `${process.env.REACT_APP_VPN
					}/${details?.nhub?.vpn.trim()}/`;
				const link = document.createElement("a");
				link.href = url;
				link.target = "_blank";
				document.body.appendChild(link);
				link.click();
				link.parentNode.removeChild(link);
			})
			.catch((err) => {
				snackbarNotification({
					message:
						"Nhub is Rebooting,Please wait for sometime, refresh the page, and try connecting again",
					class: "error",
				});
				storeHandler(actions.SHOW_LOADER, false);
			});
	};


	const handleNHUBDelete = () => {
		storeHandler(actions.SHOW_LOADER, true);
		AuthServices.deleteNhubDevice(details?.nhub?.id)
			.then(({ message }) => {
				snackbarNotification({
					message,
					class: "success",
				});
				if (id) getAccountDetails(id);
				else {
					setNoData(true);
				}
				storeHandler(actions.SHOW_LOADER, false);
			})
			.catch((err) => {
				const { data } = err || {};
				const { message } = data || {};
				snackbarNotification({
					message,
					class: "error",
				});
				storeHandler(actions.SHOW_LOADER, false);
			});
	};
	
    const handleWifiStrength = (wifi_strength, external_wifi) => {
		if(external_wifi=== true ){
		  if (wifi_strength === "Amazing") { return GoodWifi; }
		  else if (wifi_strength === "Very Good") { return ModerateWifi; }
		  else if (wifi_strength === "Okay") { return WeakWifi; }
		  else if (wifi_strength === "Not Good") { return veryWeakWifi; }
		}
		else if(external_wifi=== false || external_wifi===null ){
		  if (wifi_strength === "Amazing") { return newGoodWifi; }
		  else if (wifi_strength === "Very Good") { return newModerateWifi; }
		  else if (wifi_strength === "Okay") { return newWeakWifi; }
		  else if (wifi_strength === "Not Good") { return newVeryWeakWifi; }
		}
		else return veryWeakWifi
	  }
	const strVal = (value) =>
		!!value && value !== "--:--"
			? value.split(":").reduce((first, second) => `${first} Hrs ${second} Min`)
			: "--:--";

	const vNhub = () => {
		return details?.nhub?.virtual ? true : false
	}

	return (
		<>
			<DashHeader
				searchBar={false}
				pageTitle="Accounts"
				tab="accounts"
				name={`${details?.senior?.fname || "Not"}  ${details?.senior?.lname || "found"
					}`}
				userImg={userIm}
			/>
			{!noData && (
				<Stack
					spacing={2}
					className="account-details-stack"
					sx={{
						display: "flex",
						flexDirection: {
							xs: "column !important",
							sm: "column !important",
							md: "column !important",
							lg: "column !important",
							xl: "column !important",
							xxl: "row !important",
						},
						flexWrap: {
							xs: 'nowrap',
							sm: 'nowrap',
							md: 'nowrap',
							lg: 'nowrap',
							xl: 'nowrap',
							xxl: 'wrap'
						},
						height: {
							xs: '92%',
							sm: '92%',
							md: '92%',
							lg: '92%',
							xl: '92%',
							xxl: '86%'
						}
					}}
				>
					<Paper
						className="account-details-stack-paper1"
						flex={2}
						sx={{
							borderRadius: "10px",
							padding: "1.5rem",
							display: "flex",
							marginBottom: {
								xs: "1rem",
								sm: "1rem",
								md: "1rem",
								lg: "1rem",
								xl: "1rem",
								xxl: 0,
							},
							marginRight: {
								xs: "2rem",
								sm: "2rem",
								md: "2rem",
								lg: "2rem",
								xl: "2rem",
								xxl: 0,
							},
							width: {
								xs: "100%",
								sm: "100%",
								md: "100%",
								lg: "100%",
								xl: "100%",
								xxl: "67%",
							},
                         
						}}
					>
						<Stack
							className="inside-stack"
							direction="row"
							sx={{
								display: "flex",
								flexDirection: {
									xs: "column",
									sm: "column",
									md: "row",
									lg: "row",
									xl: "row",
									xxl: "row",
								},
								width: "100%",
							}}
							spacing={innerWidth <= 960 ? 0 : 3}
							divider={
								<Divider
									sx={{
										marginY: {
											xs: "2rem",
											sm: "2rem",
											md: "2rem",
											lg: 0,
											xl: 0,
											xxl: 0,
										},
									}}
									orientation={innerWidth <= 960 ? "horizontal" : "vertical"}
									flexItem
								/>
							}
						>
							<Box
								sx={{
									width: {
										xs: "100%",
										sm: "100%",
										md: "100%",
										lg: "40%",
										xl: "40%",
										xxl: "40%",
									},
								}}
								className="stack-box1"
							>
								<Box>
									<Stack
										direction="row"
										spacing={2}
										sx={{ marginBottom: "0.8rem" }}
									>
										<Box sx={{ alignSelf: "flex-end", width: "4rem" }}>
											<Avatar
												sx={{
													width: "4rem",
													height: "4rem",
													marginRight: "1rem",
													marginBottom: "1.1rem",
												}}
												src={details?.senior.profile ? `${details?.senior.profile}?timestamp=${new Date()}` : ""}
												alt="Profile Image"
											/>
										</Box>
										<Box flex={1}>
											<Stack
												direction="row"
												justifyContent="space-between"
												alignItems="center"
												spacing={4}
												sx={{ padding: "0", marginBottom: "0rem" }}
											>
												<Typography
													sx={{
														marginBottom: "0",
														fontSize: "1.2rem",
														color: "primary.main",
														width: "max-content",
													}}
												>
													{details?.senior?.fname} {details?.senior?.lname}
												</Typography>
												<Box className="align-icons">
													<Tooltip color="primary" title="Edit Senior">
														<IconButton
															onClick={() =>
																setHandleEditSeniorDialogOpen(true)
															}
														>
															<EditIcon className="edit-icon" />
														</IconButton>
													</Tooltip>
													<Tooltip color="primary" title="Delete Senior">
														<IconButton
															onClick={() =>
																setHandleDeleteSeniorDialogOpen(true)
															}
														>
															<MdDelete size="19px" />
														</IconButton>
													</Tooltip>
												</Box>
											</Stack>
											<Typography
												sx={{
													fontSize: "0.8rem",
													color: "#9c9c9c",
													marginBottom: "0.4rem",
												}}
											>{`Age ${details?.senior?.age}, ${details?.senior?.gender}`}</Typography>
											<Typography
												sx={{ fontSize: "0.8rem", color: "#9c9c9c" }}
											>{`ID: ${details?.senior.senior_id}`}</Typography>
											{details?.senior.room_no && <Typography
												sx={{
													fontSize: "0.8rem",
													color: "#9c9c9c",
													margin: "0.2rem 0",
												}}
											>
												{`Room No: ${details?.senior.room_no}`}
											</Typography>}
										</Box>
									</Stack>
									<Typography
										sx={{
											fontSize: "0.8rem",
											color: "#9c9c9c",
											margin: "0.2rem 0",
										}}
									>
										{`Master Account ID: ${details?.mas_acc_id}`}
									</Typography>

									<Stack
										direction="row"
										alignItems="center"
										spacing={1}
										sx={{ marginBottom: "0.8rem" }}
									>
										<Typography
											sx={{
												color: "#ffcc00",
												border: "1px solid",
												borderRadius: "5px",
												fontSize: ".9rem",
												paddingLeft: "0.5rem",
												paddingRight: "0.5rem",
											}}
										>
											{details?.contract_name === 'default_plan' ? `$${details['price_terms']}` : details?.contract_name}
										</Typography>
										<Typography
											sx={{ fontSize: "0.8rem", color: "#9c9c9c" }}
										>{`for ${details?.duration === null ? "-" : details?.duration
											} ${details?.contract_name === 'default_plan' ? "" : details?.duration === 1 || details?.duration === "1"
												? "year"
												: "years"
											}`}</Typography>
										<Typography>
											{details?.maintenance ? (
												<Typography
													sx={{
														color: "#ffcc00",
														border: "1px solid",
														borderRadius: "5px",
														fontSize: ".8rem",
														paddingLeft: "0.5rem",
														paddingRight: "0.5rem",
													}}
												>
													Maintenance Agreement
												</Typography>
											) : null}
										</Typography>
									</Stack>
									<Stack
										direction="row"
										alignItems="center"
										spacing={1}
										sx={{ marginBottom: "0.8rem" }}
									>
										<Location />
										<Typography
											sx={{
												fontSize: "0.7rem",
												color: "#9c9c9c",
												marginBottom: "1rem",
											}}
										>
											{details?.senior?.address || "-"}
										</Typography>

									</Stack>

									<Stack
										sx={{
											display: "flex",
											marginBottom: "1rem",
											flexDirection: {
												xs: "column",
												sm: "row",
												md: "row",
												lg: "row",
												xl: "row",
												xxl: "row",
											},
											justifyContent: "space-between",
										}}
									>
										<Stack
											sx={{
												marginBottom: {
													xs: "1rem",
													sm: 0,
													md: 0,
													lg: 0,
													xl: 0,
													xxl: 0,
												},
											}}
											direction="row"
											alignItems="center"
											spacing={1}
										>
											<EmailIcon />
											<Typography sx={{ fontSize: ".7rem", color: "#9c9c9c" }}>
												{details?.senior?.email || "-"}
											</Typography>
										</Stack>
										<Stack direction="row" alignItems="center" spacing={1}>
											<PhoneIcon />
											<Typography sx={{ fontSize: ".7rem", color: "#9c9c9c" }}>
												{details?.senior?.phone || "-"}
											</Typography>
										</Stack>
									</Stack>
									<Stack
										direction="row"
										alignItems="center"
										sx={{
											display: "flex",
											flexDirection: "row",
											marginBottom: "1.5rem",
											justifyContent: {
												xs: "space-between",
												sm: "flex-start",
												md: "flex-start",
												lg: "flex-start",
												xl: "flex-start",
												xxl: "flex-start",
											},
										}}
									>
										<Box
											sx={{
												display: "flex",
												flexDirection: {
													xs: "column",
													sm: "row",
													md: "row",
													lg: "row",
													xl: "row",
													xxl: "row",
												},
											}}
										>
											<Box sx={{ marginRight: "1rem" }}>
												<Typography
													sx={{ fontSize: "0.8rem", color: "#9c7175" }}
												>
													DOJ
												</Typography>
												<Typography
													sx={{ fontSize: "0.8rem", color: "#d8d8d8" }}
												>
													{formatDate(
														details?.senior?.registered_date.$date
													) !== "N/A"
														? formatDate(details?.senior?.registered_date.$date)
														: "-"}
												</Typography>
											</Box>
											<Box sx={{ marginRight: "1rem" }}>
												<Typography
													sx={{ fontSize: "0.8rem", color: "#9c7175" }}
												>
													DOB
												</Typography>
												<Typography
													sx={{ fontSize: "0.8rem", color: "#d8d8d8" }}
												>
													{formatDate(details?.senior_dob) !== "N/A"
														? formatDate(
															new Date(`${details?.senior_dob} 00:00`)
														)
														: "-"}
												</Typography>
											</Box>
										</Box>
										<Box
											sx={{
												display: "flex",
												flexDirection: {
													xs: "column",
													sm: "row",
													md: "row",
													lg: "row",
													xl: "row",
													xxl: "row",
												},
											}}
										>
											<Box sx={{ marginRight: "1rem" }}>
												<Typography
													sx={{ fontSize: "0.8rem", color: "#9c7175" }}
												>
													Height
												</Typography>
												<Typography
													sx={{ fontSize: "0.8rem", color: "#d8d8d8" }}
												>
													{details?.senior?.height_unit === "ft"
														? `${details?.senior?.height
															? `${details?.senior?.height}'`
															: details?.senior?.inchs
																? ""
																: "-"
														} ${details?.senior?.inchs
															? `${details?.senior?.inchs}''`
															: ""
														}`
														: `${details?.senior?.height || "-"} ${details?.senior?.height_unit || ""
														}`}
												</Typography>
											</Box>
											<Box>
												<Typography
													sx={{ fontSize: "0.8rem", color: "#9c7175" }}
												>
													Weight
												</Typography>
												<Typography
													sx={{ fontSize: "0.8rem", color: "#d8d8d8" }}
												>
													{details?.senior?.weight_unit === "lbs"
														? `${details?.senior?.weight
															? `${details?.senior?.weight} lbs `
															: details?.senior?.oz
																? ""
																: "-"
														} ${details?.senior?.oz
															? `${details?.senior?.oz} oz`
															: ""
														}`
														: `${details?.senior?.weight || "-"} ${details?.senior?.weight
															? details?.senior?.weight_unit
															: ""
														}`}
												</Typography>
											</Box>
										</Box>
									</Stack>
									<Box sx={{ marginBottom: "2rem" }}>
										<Typography sx={{ fontSize: "1rem", color: "#d8d8d8" }}>
											IOT Devices
										</Typography>
										<Stack
											// justifyContent="flex-start"
											direction="row"
											flexWrap="wrap"
											gap={1}
											marginTop="0.5rem"
										>
											{details?.plan?.sensors
												? details?.plan?.sensors?.map((sensor, i) => {
													const sensorCount = sensors.reduce((count, val) => {
														count[val] = (count[val] || 0) + 1;
														return count;
													}, {});
													const updatedSensorCount = {};
													for (const [key, count] of Object.entries(
														sensorCount
													)) {
														if (count > 1) updatedSensorCount[key] = count;
													}
													if (
														(sensor.name === "SOS" &&
															!details?.senior?.sos) ||
														(sensor.name === "watch" &&
															!details?.senior?.watch) ||
														(vNhub() && sensor.name !== "FallDetection")
													) {
														return;
													}
													return (
														<Badge
															badgeContent={updatedSensorCount[sensor.name]}
															color="primary"
															sx={{ fontSize: "2px", padding: "2px" }}
														>
															<Paper
																style={{
																	border: sensors.includes(sensor.name)
																		? "2px solid #00B89F"
																		: "",
																}}
																sx={{
																	backgroundColor: "#2f363e",
																	borderRadius: "0.5rem",
																	width: "3rem",
																	height: "2.5rem",
																	display: "flex",
																	justifyContent: "center",
																	alignItems: "center",
																}}
															>
																<Box sx={{ width: "1.5rem", height: "1rem" }}>
																	{sensor.name === "watch" && (
																		<WatchSmallIcon
																			className={`${sensors.includes(sensor.name)
																				? "green"
																				: "gray"
																				} watch`}
																		/>
																	)}
																	{sensor.name === "Door" && (
																		<DoorIcon
																			className={`${sensors.includes(sensor.name)
																				? "green"
																				: "gray"
																				} `}
																		/>
																	)}
																	{sensor.name === "PillBox" && (
																		<PillIcon
																			className={`${sensors.includes(sensor.name)
																				? "green"
																				: "gray"
																				} watch`}
																		/>
																	)}
																	{sensor.name === "BathRoom" && (
																		<BathRoomIcon
																			className={`${sensors.includes(sensor.name)
																				? "green"
																				: "gray"
																				} watch`}
																		/>
																	)}
																	{sensor.name === "Shower" && (
																		<ShowerIcon
																			className={`${sensors.includes(sensor.name)
																				? "green"
																				: "gray"
																				} watch`}
																		/>
																	)}
																	{sensor.name === "Sleep" && (
																		<SleepIcon
																			className={`${sensors.includes(sensor.name)
																				? "green"
																				: "gray"
																				} watch`}
																		/>
																	)}
																	{sensor.name === "Camera" && (
																		<CamerasmallIcon
																			className={`${sensors.includes(sensor.name)
																				? "green"
																				: "gray"
																				} watch`}
																		/>
																	)}
																	{sensor.name === "GPSWatch" && (
																		<GpsIcon
																			className={`${sensors.includes(sensor.name)
																				? "green"
																				: "gray"
																				} watch`}
																		/>
																	)}
																	{sensor.name === "FluidConsumption" && (
																		<FluidIcon
																			className={`${sensors.includes(sensor.name)
																				? "green"
																				: "gray"
																				} watch`}
																		/>
																	)}
																	{sensor.name === "SleepBelt" && (
																		<BeltIcon
																			className={`${sensors.includes(sensor.name)
																				? "beltgreenFill"
																				: "beltgrey"
																				} watch`}
																		/>
																	)}
																	{sensor.name === "SleepBelt-Chair" && (
																		<CouchIcon
																			className={`${sensors.includes(sensor.name)
																				? "beltgreenFill"
																				: "beltgrey"
																				} watch`}
																		/>
																	)}
																	{sensor.name === "SOS" && (
																		<SOSIcon
																			className={`${sensors.includes(sensor.name)
																				? "green"
																				: "gray"
																				} watch`}
																		/>
																	)}
																	{sensor.name === "FallDetection" && (
																		<FallDetectionIcon
																			className={`${sensors.includes(sensor.name)
																				? "green"
																				: "gray"
																				} watch`}
																		/>
																	)}
																</Box>
															</Paper>
														</Badge>
													);
												})
												: null}
										</Stack>
									</Box>
									<Box>
										<Stack
											direction="row"
											justifyContent="space-between"
											alignItems="center"
										>
											<Typography sx={{ fontSize: "1rem", color: "#d8d8d8" }}>
												{vNhub() ? "VNHUB" : "NHUB"}
											</Typography>
											{details?.nhub && (
												<Switch
													onChange={handleNHUBDelete}
													checked={details?.nhub}
												/>
											)}


										</Stack>
										{details?.nhub ? (
											<Stack direction="row" spacing={1}>
												<Box
													sx={{
														backgroundColor: "#2f363e",
														borderRadius: "0.5rem",
														width: "4rem",
														height: "4rem",
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
													}}
												>
													<Box sx={{ width: "54px", height: "48px" }}>
														<NHUBIcon />
													</Box>
												</Box>
												<Box className="nhub-acc-details">
													<div className="d-flex flex-row justify-content-start">
														<Typography
															sx={{
																fontSize: "0.8rem",
																color: "#d8d8d8",
																marginBottom: "0.5rem",
															}}
														>
															{details?.nhub?.name || "Not found"}
														</Typography>
														{moment
															.utc()
															.diff(details?.nhub?.vpn_update_date, "minutes") <
															5 &&
															details?.nhub?.vpn &&
															details?.nhub?.vpn !== "None:4000" ? (
															<img
																src={VpnOnIcon}
																alt="wifiIcon"
																style={{
																	marginLeft: "8px",
																	marginBottom: "4px",
																	width: "20px",
																	height: "20px",
																}}
															/>
														) : (

															<Typography>
																{details?.nhub?.virtual ? (
																	<Typography
																		sx={{
																			color: "#ffcc00",
																			border: "1px solid",
																			borderRadius: "7px",
																			fontSize: ".6rem",
																			paddingLeft: "0.6rem",
																			paddingRight: "0.5rem",
																			marginLeft: "7px",
																			marginBottom: "7px",
																		}}
																	>
																		Virtual
																	</Typography>
																) : <img
																	src={VpnOffIcon}
																	alt="wifiIcon"
																	style={{
																		marginLeft: "8px",
																		marginBottom: "4px",
																		width: "20px",
																		height: "20px",
																	}}
																/>}
															</Typography>
														)}

														{details?.nhub &&
															details?.nhub?.network_type &&
															details?.nhub?.network_type === "LAN" &&
															(moment
																.utc()
																.diff(
																	details?.nhub?.vpn_update_date,
																	"minutes"
																) < 5 ? (
																<Tooltip title={""}>
																	<img
																		src={LAN_Connected}
																		alt="wifiIcon"
																		style={{
																			marginLeft: "8px",
																			marginBottom: "5px",
																			width: "20px",
																			height: "20px",
																			cursor: "pointer",
																		}}
																	/>
																</Tooltip>
															) : (
																<Tooltip title={""}>
																	<img
																		src={LAN_Not_Connected}
																		alt="wifiIcon"
																		style={{
																			marginLeft: "8px",
																			marginBottom: "5px",
																			width: "20px",
																			height: "20px",
																			cursor: "pointer",
																		}}
																	/>
																</Tooltip>
															))}

														{details?.nhub &&
															details?.nhub?.network_type &&
															details?.nhub?.network_type === "Wi-Fi" &&
															(moment
																.utc()
																.diff(
																	details?.nhub?.vpn_update_date,
																	"minutes"
																) < 5 && details?.nhub?.wifi_strength ? (
																<Tooltip
																	title={details?.nhub?.wifi_strength}
																	placement="top"
																>
																	<img
																		src={handleWifiStrength(
																			details?.nhub?.wifi_strength, details?.nhub?.external_wifi
																		)}
																		alt="wifiIcon"
																		style={{
																			marginLeft: "8px",
																			marginBottom: "5px",
																			width: "20px",
																			height: "20px",
																			cursor: "pointer",
																		}}
																	/>
																</Tooltip>
															) : (
																<Tooltip title={""}>
																	<img
																		src={WifiOff}
																		alt="wifiIcon"
																		style={{
																			marginLeft: "8px",
																			marginBottom: "5px",
																			width: "20px",
																			height: "20px",
																			cursor: "pointer",
																		}}
																	/>
																</Tooltip>
															))}
													</div>
													{details?.nhub?.virtual ?
														"" : (<Typography
															sx={{
																fontSize: "0.6rem",
																color: "#d8d8d8",
																marginBottom: "0.7rem",
															}}
														>

															{`VPN: ${vpnValidator(details?.nhub?.vpn)
																? "--:--"
																: ((moment.utc().diff(details?.nhub?.vpn_update_date, "minutes") < 5) ? (details?.nhub?.vpn) : "--:--")
																}`}
														</Typography>)}
													{details?.nhub?.virtual ?
														(<Button
															variant="outlined"
															target='_blank'
															href={`/vnhub/device?nhub_id=${encodeURIComponent(details.nhub.name)}&senior_id=${details?.senior?._id?.$oid}`}
															sx={{
																fontSize: "0.7rem",
																padding: "0.2rem, 0.5rem",
																marginBottom: "0.5rem",
															}}
															className="btn-connect-nhub"

														>
															Connect to VNhub
														</Button>) :
														(moment.utc().diff(details?.nhub?.vpn_update_date, "minutes") < 5 ? (
															<Button
																variant="outlined"
																sx={{
																	fontSize: "0.7rem",
																	padding: "0.2rem, 0.5rem",
																	marginBottom: "0.5rem",
																}}
																className="btn-connect-nhub"
																disabled={
																	details?.nhub &&
																	vpnValidator(details?.nhub?.vpn)
																}
																onClick={handleConnect}
															>
																Connect to Nhub
															</Button>) : null)
													}

													{details?.nhub?.virtual ? "" :
														(<Stack
															direction="row"
															spacing={1}
															alignItems="center"
														>
															<Typography
																sx={{ fontSize: "0.7rem", color: "#d8d8d8" }}
															>
																Status{" "}
															</Typography>
															<Typography
																sx={{
																	fontSize: "0.8rem",
																	color: "#fff",
																	backgroundColor: details?.nhub?.active
																		? "primary.main"
																		: "#404040",
																	borderRadius: "1rem",
																	padding: "0.1rem 0.5rem",
																}}
															>
																{moment
																	.utc()
																	.diff(
																		details?.nhub?.vpn_update_date,
																		"minutes"
																	) < 5
																	? "Active"
																	: "Inactive"}
															</Typography>
														</Stack>)}
												</Box>
											</Stack>
										) : (
											<Box className="nhub-box" sx={{ display: "grid" }}>
												<Typography
													sx={{
														fontSize: "1rem",
														color: "#9c9c9c",
														margin: "0.2rem 0",
													}}
												>
													No Nhub is Mapped
												</Typography>
												<Button
													variant="outlined"

													className="btn-connect-nhub"

													onClick={() =>
														setHandleCreateVnhubDialogOpen(true)
													}
												>
													<Typography sx={{
														fontSize: "0.7rem",
														color: "white"
													}}>
														Create VNhub
													</Typography>
												</Button>
											</Box>

										)}
									</Box>
								</Box>
							</Box>
							<Box className="stack-box2">
								<div className="div-box2">
									<div style={{ visibility: vNhub() ? 'hidden' : 'visible' }}>
										<Stack
											direction="row"
											justifyContent="space-between"
											alignItems="center"
											sx={{ marginBottom: "0.8rem" }}
										>
											<Typography
												sx={{
													fontSize: "0.9rem",
													color: "#d8d8d8",
												}}
											>
												Camera
											</Typography>
											{details?.camera?.url !== null && (
												<Stack direction="row" alignItems="center" spacing={2}>
													<Switch
														checked={details?.camera?.url === null ? false : true}
													/>
												</Stack>
											)}
										</Stack>
										{details?.camera?.url ? (
											<Stack
												divider={<Divider orientation="vertical" flexItem />}
												direction="row"
												alignItems="center"
												spacing={2}
												sx={{ marginBottom: "2rem" }}
											>
												<Stack direction="row" alignItems="center" spacing={1}>
													<Paper
														sx={{
															padding: "0.8rem",
															backgroundColor: "#2F363E",
															borderRadius: "10px",
														}}
													>
														<CameraIcon />
													</Paper>
													<Stack direction="row" spacing={1} alignItems="center">
														<Typography
															sx={{
																fontSize: "0.7rem",
																color: "#d8d8d8",
																marginLeft: "0.5rem",
															}}
														>
															Status{" "}
														</Typography>
														<Typography
															sx={{
																fontSize: "0.8rem",
																color: "#fff",
																backgroundColor: details?.nhub?.active
																	? "primary.main"
																	: "#404040",
																borderRadius: "1rem",
																padding: "0.1rem 0.5rem",
															}}
														>
															Active
														</Typography>
													</Stack>
												</Stack>
											</Stack>
										) : (
											<Stack minHeight="5rem" className="camera-stack">
												<Typography className="text-green">
													Not Available
												</Typography>
											</Stack>
										)}
									</div>
									<Stack
										direction="row"
										justifyContent="space-between"
										alignItems="center"
										sx={{ marginBottom: "0.8rem" }}
									>
										<Typography
											sx={{
												fontSize: "0.9rem",
												color: "#d8d8d8",
											}}
										>
											Fall Detection
										</Typography>
									</Stack>
									<Grid item xs={12} sm={6} md={4} lg={4}>
										{details?.sensors.includes("FallDetection") ? (
											<>
												{details?.nhub_sen?.map((el, ind) => {
													if (el?.sensor_name === "FallDetection") {
														let isMacId = false;
														isMacId = details?.fall_data.some(
															(dataItem) => dataItem?.macid === el?.mac_id
														);
														if (!isMacId) {
															return (
																<Stack
																	direction="row"
																	spacing={1}
																	sx={{
																		// display: "inline-flex",
																		height: "auto",
																		width: "230px",
																		borderRadius: "10px",
																		border: "1.7px solid #00B89F",
																		padding: "5px",
																		gap: "10.12px",
																		marginTop: "1rem",
																		marginRight: "1rem",
																	}}
																	justifyContent="left"
																	alignItems="center"
																>
																	<div>
																		<Stack
																			direction="row"
																			alignItems="center"
																			spacing={1}
																		>
																			<Stack
																				direction="row"
																				alignItems="center"
																				spacing={1}
																			>
																				<Box>
																					<NewFallIcon />
																				</Box>
																				<Box
																					display={"flex"}
																					flexDirection={"column"}
																				>
																					<Box
																						display="flex"
																						flexDirection={"row"}
																						marginTop="0.3rem"
																					>
																						<Typography
																							sx={{
																								color: "#d8d8d8",
																								fontSize: "0.8rem",
																								textTransform: "capitalize",
																								marginBottom: "0.5rem",
																								overflowText: "wrap",
																							}}
																						>
																							{`${el?.name}`}
																						</Typography>
																					</Box>
																					<Typography
																						sx={{
																							marginTop: "0.3rem",
																							marginBottom: "0.5rem",
																							color: "#d8d8d8",
																							fontSize: "0.6rem",
																						}}
																					>
																						No Fall Detected
																					</Typography>
																				</Box>
																			</Stack>
																		</Stack>
																	</div>
																</Stack>
															);
														}
													}
												})}

												{Object.keys(details?.fall_data).length > 0 &&
													details?.fall_data?.map((el, ind) => {
														if (el?.fall_detected) {
															return (
																<Stack
																	direction="row"
																	spacing={1}
																	sx={{
																		display: "inline-flex",
																		height: "auto",
																		width: "min-content",
																		borderRadius: "10px",
																		border: "1.7px solid #00B89F",
																		padding: "5px",
																		gap: "10.12px",
																		marginTop: "1rem",
																		marginRight: "1rem",
																	}}
																	justifyContent="space-evenly"
																	alignItems="center"
																>
																	<div>
																		<Stack
																			direction="row"
																			alignItems="center"
																			spacing={1}
																		>
																			<Stack
																				direction="row"
																				alignItems="center"
																				spacing={1}
																			>
																				<Box sx={{ marginBottom: "2rem" }}>
																					<NewFallIcon />
																				</Box>
																				<Box
																					display={"flex"}
																					flexDirection={"column"}
																				>
																					<Box
																						display="flex"
																						flexDirection={"row"}
																					>
																						<Typography
																							sx={{
																								color: "#d8d8d8",
																								fontSize: "0.8rem",
																								textTransform: "capitalize",
																								marginBottom: "0.5rem",
																							}}
																						>
																							{`${el?.device_name}`}
																						</Typography>
																						<Tooltip
																							enterTouchDelay={0}
																							disableInteractive
																							disableFocusListener
																							title={
																								<div>
																									<span>Device id</span>
																									<br />
																									<span>
																										{el?.deviceId || "-"}
																									</span>{" "}
																									<br />
																									<br />
																									<span>
																										Serial Product
																									</span>{" "}
																									<br />
																									<span>
																										{el?.serialProduct || "-"}
																									</span>{" "}
																									<br />
																									<br />
																									<span>{"Status : "}</span>
																									<span
																										style={{
																											color: "#00b89f",
																											fontSize: "12px",
																										}}
																									>
																										{el?.calling
																											? "Calling"
																											: el?.fall_confirmed
																												? "Confirmed"
																												: "Detected"}
																									</span>{" "}
																									<br />
																									<br />
																									<span>{"Date & Time"}</span>
																									<br />
																									<span>
																										{new Date(
																											el?.calling
																												? el?.calling + "z"
																												: el?.fall_confirmed
																													? el?.fall_confirmed +
																													"z"
																													: el?.fall_detected +
																													"z"
																										).toLocaleString(
																											undefined,
																											{
																												year: "numeric",
																												month: "short",
																												day: "numeric",
																												hour: "2-digit",
																												minute: "2-digit",
																												second: "2-digit",
																												hour12: true,
																												timeZone:
																													Intl.DateTimeFormat().resolvedOptions()
																														.timeZone,
																											}
																										)}
																									</span>{" "}
																									<br />
																									<br />
																									<span>SSID</span>
																									<br />
																									<span>{el?.ssid || "-"}</span>
																								</div>
																							}
																						>
																							<img
																								style={{
																									height: "15px",
																									width: "15px",
																									marginLeft: "5px",
																									marginTop: "2px",
																									cursor: "pointer",
																								}}
																								src={InfoIcon}
																								alt=""
																							/>
																						</Tooltip>
																					</Box>
																					<Typography
																						sx={{
																							marginBottom: "0.8rem",
																							color: "#d8d8d8",
																							fontSize: "0.6rem",
																						}}
																					>
																						{el?.calling
																							? new Date(
																								el?.calling + "z"
																							).toLocaleString(undefined, {
																								year: "numeric",
																								month: "short",
																								day: "numeric",
																								hour: "2-digit",
																								minute: "2-digit",
																								second: "2-digit",
																								hour12: true,
																								timeZone:
																									Intl.DateTimeFormat().resolvedOptions()
																										.timeZone,
																							})
																							: el?.fall_confirmed
																								? new Date(
																									el?.fall_confirmed + "z"
																								).toLocaleString(undefined, {
																									year: "numeric",
																									month: "short",
																									day: "numeric",
																									hour: "2-digit",
																									minute: "2-digit",
																									second: "2-digit",
																									hour12: true,
																									timeZone:
																										Intl.DateTimeFormat().resolvedOptions()
																											.timeZone,
																								})
																								: new Date(
																									el?.fall_detected + "z"
																								).toLocaleString(undefined, {
																									year: "numeric",
																									month: "short",
																									day: "numeric",
																									hour: "2-digit",
																									minute: "2-digit",
																									second: "2-digit",
																									hour12: true,
																									timeZone:
																										Intl.DateTimeFormat().resolvedOptions()
																											.timeZone,
																								})}
																					</Typography>
																					<FallDetectionStepper
																						detectedData={el?.fall_detected}
																						confirmedData={el?.fall_confirmed}
																						callingData={el?.calling}
																					/>
																				</Box>
																			</Stack>
																		</Stack>
																	</div>
																</Stack>
															);
														} else {
															return (
																<Stack
																	direction="row"
																	spacing={1}
																	width="296px"
																	sx={{
																		// display: "inline-flex",
																		height: "auto",
																		borderRadius: "10px",
																		border: "1.7px solid #00B89F",
																		padding: "5px",
																		gap: "10.12px",
																		marginTop: "1rem",
																		marginRight: "1rem",
																	}}
																	justifyContent="left"
																>
																	<div>
																		<Stack
																			direction="row"
																			alignItems="center"
																			spacing={1}
																		>
																			<Stack
																				direction="row"
																				alignItems="center"
																				spacing={1}
																			>
																				<Box>
																					<NewFallIcon />
																				</Box>
																				<Box
																					display={"flex"}
																					flexDirection={"column"}
																				>
																					<Box
																						display="flex"
																						flexDirection={"row"}
																						marginTop="0.3rem"
																					>
																						<Typography
																							sx={{
																								color: "#d8d8d8",
																								fontSize: "0.8rem",
																								textTransform: "capitalize",
																								marginBottom: "0.5rem",
																								overflowText: "wrap",
																							}}
																						>
																							{`${el?.device_name}`}
																						</Typography>
																						<Tooltip
																							enterTouchDelay={0}
																							disableInteractive
																							disableFocusListener
																							title={
																								<div>
																									<span>Device id</span>
																									<br />
																									<span>
																										{el?.deviceId || "-"}
																									</span>{" "}
																									<br />
																									<br />
																									<span>
																										Serial Product
																									</span>{" "}
																									<br />
																									<span>
																										{el?.serialProduct || "-"}
																									</span>{" "}
																									<br />
																									<br />
																									<span>{"Status : "}</span>
																									<span
																										style={{
																											color: "#00b89f",
																											fontSize: "12px",
																										}}
																									>
																										{"No Fall Detected"}
																									</span>{" "}
																									<br />
																									<br />
																									<span>{"Date & Time"}</span>
																									<br />
																									<span>{"-"}</span> <br />
																									<br />
																									<span>SSID</span>
																									<br />
																									<span>{el?.ssid || "-"}</span>
																								</div>
																							}
																						>
																							<img
																								style={{
																									height: "15px",
																									width: "15px",
																									marginLeft: "5px",
																									marginTop: "2px",
																									cursor: "pointer",
																								}}
																								src={InfoIcon}
																								alt=""
																							/>
																						</Tooltip>
																					</Box>
																					<Typography
																						sx={{
																							marginTop: "0.3rem",
																							marginBottom: "0.5rem",
																							color: "#d8d8d8",
																							fontSize: "0.6rem",
																						}}
																					>
																						No Fall Detected
																					</Typography>
																				</Box>
																			</Stack>
																		</Stack>
																	</div>
																</Stack>
															);
														}
													})}
											</>
										) : (
											// )
											<Stack minHeight="5rem" className="camera-stack">
												<Typography className="text-green">
													Not Available
												</Typography>
											</Stack>
										)}
									</Grid>
									<div style={{ visibility: vNhub() ? 'hidden' : 'visible' }}>
										<Stack
											direction="row"
											justifyContent="space-between"
											alignItems="center"
											sx={{ marginBottom: "0.8rem" }}
											marginTop="2rem"
										>
											<Typography
												sx={{
													fontSize: "0.9rem",
													color: "#d8d8d8",
												}}
											>
												Health Watch
											</Typography>
											{details?.sensors.includes("watch") && (
												<Stack direction="row" alignItems="center" spacing={2}>
													<Switch
														checked={
															details?.watch_status?.heart_rate === null
																? false
																: true
														}
													/>
												</Stack>
											)}
										</Stack>
										{details?.sensors.includes("watch") ? (
											<div>
												<Stack direction="row" alignItems="center" spacing={1}>
													<Stack direction="row" alignItems="center" spacing={1}>
														<Paper
															sx={{
																padding: "1rem",
																backgroundColor: "#2F363E",
																borderRadius: "10px",
															}}
														>
															<WatchIcon />
														</Paper>
														<Box>
															<Typography
																sx={{
																	marginBottom: "0.5rem",
																	color: "#d8d8d8",
																	fontSize: "0.9rem",
																	textTransform: "capitalize",
																}}
															>
																{`${details?.watch_name || "Not Available"}`}
															</Typography>
															<Typography
																sx={{
																	marginBottom: "0.4rem",
																	color: "#d8d8d8",
																	fontSize: "0.6rem",
																}}
															>
																{`Model: ${details?.watch_model || "Not Available"
																	}`}
															</Typography>
															<Typography
																sx={{
																	marginBottom: "0.4rem",
																	color: "#d8d8d8",
																	fontSize: "0.6rem",
																}}
															>
																{WatchUpdateDate &&
																	moment(new Date(WatchUpdateDate))
																		.local()
																		.format("ll hh:mm A")}
															</Typography>
															{details?.watch_status?.battery_level ===
																"101%" && (
																	<Typography
																		sx={{
																			color: "#00b89f",
																			fontSize: "0.8rem",
																			fontWeight: "bold",
																		}}
																	>
																		Charging
																	</Typography>
																)}
														</Box>
													</Stack>
												</Stack>
												<Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
													<Stack
														sx={{
															display: "flex",
															flexDirection: {
																xs: "column",
																sm: "column",
																md: "row",
																lg: "row",
																xl: "row",
																xxl: "row",
															},
															width: { xs: "100%", sm: "100%", md: "26.25rem" },
															gap: "8px",
														}}
													>
														<Stack
															direction="row"
															sx={{
																width: { xs: "100%", sm: "100%", md: "6.3rem" },
																height: "5.1rem",
																borderRadius: "10px",
																border: "2px solid #00B89F",
																fontSize: "0.5rem",
																gap: "15.12px",
																padding: "0.25rem",
															}}
															justifyContent="space-evenly"
															alignItems="center"
														>
															<HeartRateIcon />
															<Box>
																<Typography
																	sx={{
																		fontSize: "0.5rem",
																		color: "#00B89F",
																		whiteSpace: "noWrap",
																	}}
																>
																	Heart Rate
																</Typography>
																<Typography
																	sx={{
																		fontSize: "0.6rem",
																		color: "#fff",
																		fontWeight: "bold",
																	}}
																>
																	{details?.watch_status?.battery_level === "101%"
																		? "-"
																		: details?.watch_status?.heart_rate || "-"}
																</Typography>
															</Box>
														</Stack>
														<Grid
															flex={1}
															container
															alignItems="center"
															sx={{ marginBottom: "1.25rem" }}
															rowSpacing="0.65rem"
														>
															<Grid item xs={12} sm={6} md={4} lg={4}>
																<Stack
																	direction="row"
																	sx={{
																		width: {
																			xs: "100%",
																			sm: "100%",
																			md: "100%",
																			lg: "5.3rem",
																		},
																		height: "2.2rem",
																		borderRadius: "10px",
																		border: "2px solid #00B89F",
																		padding: "5px",
																		gap: "15.12px",
																	}}
																	justifyContent="space-evenly"
																	alignItems="center"
																>
																	<BPMIcon />
																	<Box>
																		<Typography
																			sx={{
																				fontSize: "0.5rem",
																				color: "#00B89F",
																				whiteSpace: "noWrap",
																			}}
																		>
																			Blood Pressure
																		</Typography>
																		<Typography
																			sx={{
																				fontSize: "0.6rem",
																				color: "#fff",
																				fontWeight: "bold",
																			}}
																		>
																			{`${details?.watch_status?.battery_level ===
																				"101%"
																				? "-"
																				: details?.watch_status?.blood_pressure
																					?.sys || "-"
																				} / ${details?.watch_status?.battery_level ===
																					"101%"
																					? "-"
																					: details?.watch_status?.blood_pressure
																						?.dia || "-"
																				} `}
																		</Typography>
																	</Box>
																</Stack>
															</Grid>
															<Grid item xs={12} sm={6} md={4} lg={4}>
																<Stack
																	direction="row"
																	sx={{
																		width: {
																			xs: "100%",
																			sm: "100%",
																			md: "100%",
																			lg: "5.3rem",
																		},
																		height: "2.2rem",
																		borderRadius: "10px",
																		border: "2px solid #00B89F",
																		padding: "5px",
																		gap: "15.12px",
																	}}
																	justifyContent="space-evenly"
																	alignItems="center"
																>
																	<SleepIcon />
																	<Box>
																		<Typography
																			sx={{
																				fontSize: "0.5rem",
																				color: "#00B89F",
																			}}
																		>
																			Sleep
																		</Typography>
																		<Typography
																			sx={{
																				fontSize: "0.46rem",
																				color: "#fff",
																				fontWeight: "bold",
																			}}
																		>
																			{details?.watch_status?.battery_level ===
																				"101%"
																				? "-"
																				: details?.watch_status?.sleep
																					? strVal(details?.watch_status?.sleep)
																					: "-"}
																		</Typography>
																	</Box>
																</Stack>
															</Grid>
															<Grid item xs={12} sm={6} md={4} lg={4}>
																<Stack
																	direction="row"
																	sx={{
																		width: {
																			xs: "100%",
																			sm: "100%",
																			md: "100%",
																			lg: "5.3rem",
																		},
																		height: "2.2rem",
																		borderRadius: "10px",
																		border: "2px solid #00B89F",
																		padding: "5px",
																		gap: "15.12px",
																	}}
																	justifyContent="space-evenly"
																	alignItems="center"
																>
																	<Box
																		component="img"
																		sx={{
																			display: "flex",
																			justifyContent: "center",
																			alignItems: "center",
																			width: "1.5rem",
																			height: "1.5rem",
																			marginRight: "0.5rem",
																		}}
																		src={spO2}
																	/>
																	<Box>
																		<Typography
																			sx={{
																				fontSize: "0.5rem",
																				color: "#00B89F",
																			}}
																		>
																			SpO2
																		</Typography>
																		<Typography
																			sx={{
																				fontSize: "0.6rem",
																				color: "#fff",
																				fontWeight: "bold",
																			}}
																		>
																			{details?.watch_status?.battery_level ===
																				"101%"
																				? "-"
																				: details?.watch_status?.blood_oxygen ||
																				"-"}
																		</Typography>
																	</Box>
																</Stack>
															</Grid>
															<Grid item xs={12} sm={6} md={4} lg={4}>
																<Stack
																	direction="row"
																	sx={{
																		width: {
																			xs: "100%",
																			sm: "100%",
																			md: "100%",
																			lg: "5.3rem",
																		},
																		height: "2.2rem",
																		borderRadius: "10px",
																		border: "2px solid #00B89F",
																		padding: "5px",
																		gap: "15.12px",
																	}}
																	justifyContent="space-evenly"
																	alignItems="center"
																>
																	<StepsIcon />
																	<Box>
																		<Typography
																			sx={{
																				fontSize: "0.5rem",
																				color: "#00B89F",
																			}}
																		>
																			Steps
																		</Typography>
																		<Typography
																			sx={{
																				fontSize: "0.6rem",
																				color: "#fff",
																				fontWeight: "bold",
																			}}
																		>
																			{details?.watch_status?.battery_level ===
																				"101%"
																				? "-"
																				: details?.watch_status?.step || "-"}
																		</Typography>
																	</Box>
																</Stack>
															</Grid>
															<Grid item xs={12} sm={6} md={4} lg={4}>
																<Stack
																	direction="row"
																	sx={{
																		width: {
																			xs: "100%",
																			sm: "100%",
																			md: "100%",
																			lg: "5.3rem",
																		},
																		height: "2.2rem",
																		borderRadius: "10px",
																		border: "2px solid #00B89F",
																		padding: "5px",
																		gap: "15.12px",
																	}}
																	justifyContent="space-evenly"
																	alignItems="center"
																>
																	<CaloriesIcon />
																	<Box>
																		<Typography
																			sx={{
																				fontSize: "0.5rem",
																				color: "#00B89F",
																			}}
																		>
																			Calories
																		</Typography>
																		<Typography
																			sx={{
																				fontSize: "0.6rem",
																				color: "#fff",
																				fontWeight: "bold",
																			}}
																		>
																			{details?.watch_status?.battery_level ===
																				"101%"
																				? "-"
																				: details?.watch_status?.calories || "-"}
																		</Typography>
																	</Box>
																</Stack>
															</Grid>
															<Grid item xs={12} sm={6} md={4} lg={4}>
																<Stack
																	direction="row"
																	sx={{
																		width: {
																			xs: "100%",
																			sm: "100%",
																			md: "100%",
																			lg: "5.3rem",
																		},
																		height: "2.2rem",
																		borderRadius: "10px",
																		border: "2px solid #00B89F",
																		padding: "5px",
																		gap: "15.12px",
																	}}
																	justifyContent="space-evenly"
																	alignItems="center"
																	spacing={1}
																>
																	<BatteryIcon />
																	<Box>
																		<Typography
																			sx={{
																				fontSize: "0.5rem",
																				color: "#00B89F",
																			}}
																		>
																			Battery
																		</Typography>
																		<Typography
																			sx={{
																				fontSize: "0.6rem",
																				color: "#fff",
																				fontWeight: "bold",
																			}}
																		>
																			{details?.watch_status?.battery_level ===
																				"101%"
																				? "-"
																				: details?.watch_status?.battery_level ||
																				"-"}
																		</Typography>
																	</Box>
																</Stack>
															</Grid>
														</Grid>
													</Stack>
												</Box>
											</div>
										) : (
											<Stack minHeight="5rem" className="camera-stack">
												<Typography className="text-green">
													Not Available
												</Typography>
											</Stack>
										)}
									</div>
								</div>
							</Box>
						</Stack>
					</Paper>
					<Paper
						className="account-details-stack-paper2"
						sx={{
							borderRadius: "10px",
							padding: "1.5rem",
							margin: "0",
							height: "100%",
							width: {
								xs: "100%",
								sm: "100%",
								md: "100%",
								lg: "100%",
								xl: "100%",
								xxl: "31%",
							},
						}}
						flex={1}
					>
						<Box sx={{ minHeight: "10rem" }}>
							<Box className="box-executive-section">
								<Typography sx={{ color: "#d8d8d8", fontWeight: "light" }}>
									Executive Members
								</Typography>
								<Tooltip color="primary" title="Add Executive Member">
									<IconButton
										onClick={() => {
											totalCount >= memberLimit &&
												snackbarNotification(Notification.memberLimitReached);
											setHandleAddAccountDialogOpen(
												totalCount < memberLimit ? true : false
											);
										}}
									>
										<AddAccountIcon />
									</IconButton>
								</Tooltip>
							</Box>
							{activeExecutiveMember ? (
								<Stack
									className="second-box-stack"
									direction="row"
									spacing={1}
									sx={{ marginBottom: "1.5rem" }}
								>
									<Box
										sx={{
											position: "relative",
											width: "4rem",
											height: innerWidth < 600 ? "6rem" : "4rem",
										}}
									>
										<Avatar
											sx={{
												width: "4rem",
												height: "4rem",
												marginRight: "1rem",
												marginBottom: "0.2rem",
											}}
											src={activeExecutiveMember?.profile ? `${activeExecutiveMember?.profile}?timestamp=${new Date()}` : ""}
											alt="Profile Image"
										/>
										<Executive
											style={{
												position: "absolute",
												top: "2.7rem",
												left: "2.7rem",
												width: "1.4rem",
												height: "1.4rem",
											}}
										/>
									</Box>
									<Box flex={1}>
										<Stack
											direction="row"
											justifyContent="space-between"
											alignItems={innerWidth < 600 ? "flex-start" : "center"}
											sx={{ padding: "0", marginBottom: "0" }}
										>
											<Stack
												sx={{
													display: "flex",
													flexDirection: {
														xs: "column",
														sm: "row",
														md: "row",
														lg: "row",
														xl: "row",
														xxl: "row",
													},
												}}
												spacing={innerWidth < 600 ? 0 : 1}
											>
												<Typography
													sx={{
														marginRight: "0.25rem",
														fontSize: "1rem",
														color: "primary.main",
														width: "max-content",
													}}
												>
													{activeExecutiveMember?.name || "-"}
												</Typography>
												<Typography
													sx={{
														color: "#6c7175",
														fontWeight: "300",
														fontSize: "0.8rem",
														marginTop: "0 !important",
														paddingTop: "0.115rem",
													}}
												>
													({activeExecutiveMember?.relation})
												</Typography>
											</Stack>
											<Box className="align-icons">
												<Tooltip color="primary" title="Edit Executive Member">
													<IconButton
														onClick={() =>
															setHandleEditExecutiveMemberDialogOpen(true)
														}
													>
														<EditIcon className="edit-icon" />
													</IconButton>
												</Tooltip>
												<Tooltip
													color="primary"
													title="Delete Executive Member"
												>
													<IconButton
														onClick={() =>
															setHandleDeleteExecutiveMemberDialogOpen(true)
														}
													>
														<MdDelete size="19px" />
													</IconButton>
												</Tooltip>
											</Box>
										</Stack>
										<Typography
											sx={{
												color: "#9c9c9c",
												fontSize: "0.8rem",
											}}
										>
											{`ID: ${activeExecutiveMember?.member_id}`}
										</Typography>
										<Stack
											spacing={1}
											direction="row"
											alignItems="center"
											marginBottom={0.5}
										>
											<Location />
											<Typography
												sx={{
													width: "max-content",
													color: "#9c9c9c",
													fontSize: "0.8rem",
												}}
											>
												{activeExecutiveMember?.address || "Not Provided"}
											</Typography>
										</Stack>
										<Stack
											sx={{
												display: "flex",
												flexDirection: {
													xs: "column",
													sm: "row",
													md: "row",
													lg: "row",
													xl: "row",
													xxl: "row",
												},
												alignItems: {
													xs: "flex-start",
													sm: "center",
													md: "center",
													lg: "center",
													xl: "center",
													xxl: "center",
												},
											}}
											spacing={2}
										>
											<Stack
												direction="row"
												alignItems="center"
												sx={{
													marginRight: {
														xs: "0 !important",
														sm: "1rem !important",
														md: "1rem !important",
														lg: "1rem !important",
														xl: "1rem !important",
														xxl: "1rem !important",
													},
												}}
												spacing={1}
											>
												<EmailIcon />
												<Typography
													sx={{
														fontSize: ".7rem",
														color: "#9c9c9c",
														wordBreak: "break-word",
														whiteSpace: "nowrap",
														textOverflow: "ellipsis",
														overflow: "hidden",
														maxWidth: "200px",
													}}
												>
													{activeExecutiveMember?.email || "Not Provided"}
												</Typography>
											</Stack>
											<Stack
												direction="row"
												alignItems="center"
												sx={{ marginTop: "0 !important" }}
												spacing={1}
											>
												<PhoneIcon />
												<Typography
													sx={{
														width: "max-content",
														fontSize: ".7rem",
														color: "#9c9c9c",
													}}
												>
													{activeExecutiveMember?.phone || "Not Provided"}
												</Typography>
											</Stack>
										</Stack>
									</Box>
								</Stack>
							) : (
								<Stack className="stack-no-member">
									<Typography className="text-green">
										No executive members found
									</Typography>
								</Stack>
							)}
							<Stack
								direction="row"
								sx={{ gap: "0.4rem", marginBottom: "1.5rem" }}
								flexWrap="wrap"
								alignItems="center"
								maxWidth="25rem"
							>
								{executiveMembers
									.filter((member) => activeExecutiveMember?.id !== member.id)
									.map((member) => (
										<ExecutiveMemberLink
											onClick={() => setActiveExecutiveMember(member)}
											key={member.id}
											name={member?.name}
											im={member?.profile}
										/>
									))}
							</Stack>
						</Box>
						<div>
							<Divider />
							<div className="members-section">
								<Box className="box-member-section">
									<Typography
										sx={{
											color: "#d8d8d8",
											fontWeight: "300",
										}}
									>
										Members
									</Typography>
									<Tooltip color="primary" title="Add Member">
										<IconButton
											onClick={() => {
												totalCount >= memberLimit &&
													snackbarNotification(Notification.memberLimitReached);
												setHandleAddMemberDialogOpen(
													totalCount < memberLimit
														? totalCount + 1 === memberLimit
															? executiveCount < 1
																? snackbarNotification(
																	Notification.AtleastOneExecutive
																)
																: true
															: true
														: false
												);
											}}
										>
											<AddAccountIcon />
										</IconButton>
									</Tooltip>
								</Box>
								{activeMember ? (
									<Stack
										direction="row"
										spacing={1}
										sx={{
											display: "flex",
											flexDirection: "row",
											alignItems: {
												xs: "flex-start",
												sm: "center",
												md: "center",
												lg: "center",
												xl: "center",
												xxl: "center",
											},
											marginBottom: "1.5rem",
										}}
									>
										<Box
											className="box-member-image"
											sx={{
												width: "4rem",
												height: innerWidth < 600 ? "6rem" : "4rem",
											}}
										>
											<Avatar
												sx={{
													width: "4rem",
													height: "4rem",
													marginRight: "1rem",
													marginBottom: "0.2rem",
												}}
												src={activeMember?.profile ? `${activeMember?.profile}?timestamp=${new Date()}` : ""}
												alt="Profile Image"
											/>
										</Box>
										<Box flex={1}>
											<Stack
												className="second-box-stack"
												direction="row"
												justifyContent="space-between"
												alignItems={innerWidth < 600 ? "flex-start" : "center"}
												sx={{ padding: "0", marginBottom: "0" }}
											>
												<Stack
													sx={{
														display: "flex",
														alignItems: {
															xs: "flex-start",
															sm: "flex-start",
															md: "center",
															lg: "center",
															xl: "center",
															xxl: "center",
														},
														flexDirection: {
															xs: "column",
															sm: "row",
															md: "row",
															lg: "row",
															xl: "row",
															xxl: "row",
														},
													}}
													spacing={0}
												>
													<Typography
														sx={{
															marginRight: "0.25rem",
															fontSize: "1rem",
															color: "primary.main",
															width: "max-content",
														}}
													>
														{activeMember?.name}
													</Typography>
													<Typography
														sx={{
															color: "#6c7175",
															fontWeight: "300",
															fontSize: "0.8rem",
															marginTop: "0 !important",
															paddingTop: "0.115rem",
														}}
													>
														({activeMember?.relation})
													</Typography>
												</Stack>
												<Box className="align-icons">
													<Tooltip color="primary" title="Edit Member">
														<IconButton
															onClick={() =>
																setHandleEditMemberDialogOpen(true)
															}
														>
															<EditIcon className="edit-icon" />
														</IconButton>
													</Tooltip>
													<Tooltip color="primary" title="Delete Member">
														<IconButton
															onClick={() =>
																setHandleDeleteMemberDialogOpen(true)
															}
														>
															<MdDelete size="19px" />
														</IconButton>
													</Tooltip>
												</Box>
											</Stack>
											<Typography
												sx={{
													color: "#9c9c9c",
													fontSize: "0.8rem",
												}}
											>
												{`ID: ${activeMember?.member_id}`}
											</Typography>
											<Stack
												spacing={1}
												direction="row"
												alignItems="center"
												marginBottom={0.7}
											>
												<Location />
												<Typography
													sx={{
														width: "max-content",
														color: "#9c9c9c",
														fontSize: "0.8rem",
													}}
												>
													{activeMember?.address || "Not Provided"}
												</Typography>
											</Stack>
											<Stack
												sx={{
													display: "flex",
													flexDirection: {
														xs: "column",
														sm: "row",
														md: "row",
														lg: "row",
														xl: "row",
														xxl: "row",
													},
													alignItems: {
														xs: "flex-start",
														sm: "center",
														md: "center",
														lg: "center",
														xl: "center",
														xxl: "center",
													},
												}}
												spacing={2}
											>
												<Stack
													direction="row"
													alignItems="center"
													sx={{
														marginRight: {
															xs: "0 !important",
															sm: "1rem !important",
															md: "1rem !important",
															lg: "1rem !important",
															xl: "1rem !important",
															xxl: "1rem !important",
														},
													}}
													spacing={1}
												>
													<EmailIcon />
													<Typography
														sx={{
															fontSize: ".7rem",
															color: "#9c9c9c",
															wordBreak: "break-word",
														}}
													>
														{activeMember?.email || "Not Provided"}
													</Typography>
												</Stack>
												<Stack
													direction="row"
													alignItems="center"
													sx={{ marginTop: "0 !important" }}
													spacing={1}
												>
													<PhoneIcon />
													<Typography
														sx={{
															width: "max-content",
															fontSize: ".7rem",
															color: "#9c9c9c",
														}}
													>
														{activeMember?.phone || "Not Provided"}
													</Typography>
												</Stack>
											</Stack>
										</Box>
									</Stack>
								) : (
									<Stack className="stack-no-member">
										<Typography className="text-green">
											No members found
										</Typography>
									</Stack>
								)}
								<Stack
									direction="row"
									sx={{ gap: "0.4rem", marginBottom: "0.5rem" }}
									flexWrap="wrap"
									alignItems="center"
									maxWidth="25rem"
								>
									{members
										.filter(
											(member) => activeMember?.member_id !== member.member_id
										)
										.map((member) => (
											<ExecutiveMemberLink
												onClick={() => setActiveMember(member)}
												key={member.member_id}
												name={member?.name}
												im={member?.profile}
											/>
										))}
								</Stack>
							</div>
						</div>
					</Paper>
					<Snackbar
						open={showSnackBar}
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						message={snackbarContent?.message || ""}
						autoHideDuration={4000}
						onClose={() => setShowSnackBar(false)}
						sx={{
							".MuiSnackbarContent-root": {
								color: "#fff",
								fontWeight: "400",
								backgroundColor:
									snackbarContent.class === "error"
										? "red"
										: snackbarContent.class === "warning"
											? "#ffcc00"
											: "#00b89f",
								display: "flex",
								justifyContent: "center",
								borderRadius: "10rem",
							},
						}}
					/>
					<ConfirmationDialog
						text="Do you really want to delete this senior?"
						open={handleDeleteSeniorDialogOpen}
						handleClose={(val) => {
							if (val === true) {
								AuthServices.deleteSenior(details?.senior?._id?.$oid)
									.then(({ message }) => {
										snackbarNotification({
											message,
											class: "success",
										});
										setTimeout(() => {
											history.push("/accounts");
										}, 1000);
									})
									.catch((err) => {
										const { data } = err || {};
										const { message } = data || {};
										if (message === "Nhub is still active") {
											setHandleNhubDialog(true);
										}
										storeHandler(actions.SHOW_LOADER, false);
									});
							}
							setHandleDeleteSeniorDialogOpen(false);
						}}
					/>
					<ConfirmationExecutiveDialog
						text="Do you really want to delete this executive member?"
						eid={activeExecutiveMember?.id}
						open={handleDeleteExecutiveMemberDialogOpen}
						handleClose={(val, exec_member_id) => {
							if (val === true) {
								AuthServices.deleteMember(exec_member_id, details?.mas_obj_id)
									.then(({ message }) => {
										setActiveMember(null);
										if (id) getAccountDetails(id);
										else {
											setNoData(true);
										}
										snackbarNotification({
											message,
											class: "success",
										});
									})
									.catch((err) => {
										const { data } = err || {};
										const { message } = data || {};
										snackbarNotification({
											message,
											class: "error",
										});
										storeHandler(actions.SHOW_LOADER, false);
									});
							}
							setHandleDeleteExecutiveMemberDialogOpen(false);
						}}
					/>
					<ConfirmationDialog
						text="Do you really want to delete this member?"
						id={activeMember?.id}
						open={handleDeleteMemberDialogOpen}
						handleClose={(val, member_id) => {
							if (val === true) {
								AuthServices.deleteMember(member_id, details?.mas_obj_id)
									.then(({ message }) => {
										if (id) getAccountDetails(id);
										else {
											setNoData(true);
										}
										snackbarNotification({
											message,
											class: "success",
										});
									})
									.catch((err) => {
										const { data } = err || {};
										const { message } = data || {};
										snackbarNotification({
											message,
											class: "error",
										});
										storeHandler(actions.SHOW_LOADER, false);
									});
							}
							setHandleDeleteMemberDialogOpen(false);
						}}
					/>
					<ConfirmationDialog
						text="Do you really want to create VNhub?"

						open={handleCreateVnhubDialogOpen}
						handleClose={(val) => {
							const vdata = {
								"mas_acc_id": details?.mas_acc_id,
								"seniors": [
									details?.senior._id.$oid
								]
							}
							if (val === true) {
								AuthServices.vnhubCreate(vdata)
									.then(({ message }) => {

										snackbarNotification({
											message,
											class: "success",
										});
										if (id) getAccountDetails(id);
										else {
											setNoData(true);
										}
									})
									.catch((err) => {
										const { data } = err || {};
										const { message } = data || {};
										snackbarNotification({
											message,
											class: "error",
										});
										storeHandler(actions.SHOW_LOADER, false);
									});
							}
							setHandleCreateVnhubDialogOpen(false);
						}}
					/>
					{handleAddAccountDialogOpen && (
						<AddExecutiveDialog
							countries={countries}
							relations={relations}
							masterAccountId={details?.mas_obj_id}
							contract_name={details?.contract_name}
							membersAdded={members.length}
							snackbarNotification={snackbarNotification}
							open={handleAddAccountDialogOpen}
							handleClose={(val) => {
								setHandleAddAccountDialogOpen(false);
								if (val === true)
									if (id) getAccountDetails(id);
									else {
										setNoData(true);
									}
							}}
						/>
					)}
					{handleAddMemberDialogOpen && (
						<AddMemberDialog
							countries={countries}
							relations={relations}
							masterAccountId={details?.mas_obj_id}
							contract_name={details?.contract_name}
							membersAdded={members.length}
							open={handleAddMemberDialogOpen}
							snackbarNotification={snackbarNotification}
							handleClose={(val) => {
								setHandleAddMemberDialogOpen(false);
								if (val === true)
									if (id) getAccountDetails(id);
									else {
										setNoData(true);
									}
							}}
						/>
					)}
					{handleEditSeniorDialogOpen && (
						<EditSeniorDialog
							countries={countries}
							relations={relations}
							masterAccountId={details?.mas_obj_id}
							sensors={sensors}
							open={handleEditSeniorDialogOpen}
							seniorInfo={details}
							snackbarNotification={snackbarNotification}
							handleClose={(val) => {
								setHandleEditSeniorDialogOpen(false);
								if (val === true)
									if (id) {
										getAccountDetails(id);
									} else {
										setNoData(true);
									}
							}}
						/>
					)}
					{handleEditExecutiveMemberDialogOpen && (
						<EditExecutiveMemberDialog
							countries={countries}
							relations={relations}
							id={activeExecutiveMember?.id}
							currentExecutiveDetails={activeExecutiveMember}
							executiveCount={executiveCount}
							snackbarNotification={snackbarNotification}
							masterAccountId={details?.mas_obj_id}
							membersData={members}
							open={handleEditExecutiveMemberDialogOpen}
							handleClose={(val) => {
								setHandleEditExecutiveMemberDialogOpen(false);
								if (val === true)
									if (id) getAccountDetails(id);
									else {
										setNoData(true);
									}
							}}
						/>
					)}
					{handleEditMemberDialogOpen && (
						<EditMemberDialog
							countries={countries}
							relations={relations}
							id={activeMember?.id}
							currentDetails={activeMember}
							snackbarNotification={snackbarNotification}
							masterAccountId={details?.mas_obj_id}
							membersData={members}
							open={handleEditMemberDialogOpen}
							handleClose={(val) => {
								setHandleEditMemberDialogOpen(false);
								if (val === true)
									if (id) getAccountDetails(id);
									else {
										setNoData(true);
									}
							}}
						/>
					)}
					{handleNhubDialog && (
						<ConnectToNHUBDialog
							open={handleNhubDialog}
							handleClose={(val) => {
								if (val === true) {
									handleConnect();
									setHandleNhubDialog(false);
								}
								setHandleNhubDialog(false);
							}}
						/>
					)}
				</Stack>
			)}
		</>
	);
};

export default AccountDetails;
