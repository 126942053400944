import { Button, Dialog, Stack, Typography, MenuItem } from "@mui/material";
import { Box } from '@mui/material'
import { ReactComponent as TickIcon } from 'assets/img/tick_icon.svg';
import SimpleInputField from "components/simple-inputfield/SimpleInputField";
import { TransparentBtn } from "app/notification/components/styled-componets/style";
// import SelectInputMod from 'app/create-account/Components/select-input-mod/SelectInputMod';
// import Timepicker from "components/time-picker/Timepicker";
import React, { useEffect, useState } from 'react'
// import { getTimein24HrFormat } from "utils/datetime-formatter";
import SelectInputMod from 'app/create-account/Components/select-input-mod/SelectInputMod';
import { CustomDialogTitle } from "components/dialogs/custom-dialog-title/CustomDialogTitle";

const intervalArr = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
const beltTypeArr = ['bed', 'couch/chair']
const currentDate = new Date().toDateString()

const SleepbeltNotification = ({ open, handleClose, saveConfig, sleepbeltBed }) => {
  const initialState = {
    device: sleepbeltBed ? "SleepBelt": "SleepBelt-Chair",
    day_interval: "",
    day_message: "",
    night_interval: "",
    night_message: "",
    type: sleepbeltBed ? "SleepBelt": "SleepBelt-Chair",
    day: {
      start_time: new Date(`${currentDate} 6:00`).toUTCString(),
      end_time: new Date(`${currentDate} 17:59:59`).toUTCString()
    },
    night: {
      start_time: new Date(`${currentDate} 18:00`).toUTCString(),
      end_time: new Date(`${currentDate} 5:59:59`).toUTCString()
    }
  };
  const [settings, setSettings] = useState({ ...initialState });
  const [invalid, setInvalid] = useState({});
  const [invalidModes, setInvalidModes] = useState(false);

  useEffect(() => {
    setSettings({...initialState})
  },[])
  const [notificatonModes, setNotificationModes] = useState({ email: false, sms: false, push: false });
  const handleModeClick = (e) => {
    const mode = e.target.name;
    const prev = notificatonModes[mode];
    setNotificationModes({ ...notificatonModes, [mode]: !prev });
    invalid.modes = false;
    setInvalidModes(false)

  }

  const handleChange = (value, key) => {
    let data = settings;
    data[key] = value;
    setInvalid({ ...invalid, [key]: false });
    setSettings(data);
  };

  const validate = () => {
    let data = settings;
    let invalidData = {};
    let isValid = true;
    Object.keys(data).forEach((key) => {
      if (data[key] === "") {
        invalidData[key] = true;
        isValid = false;
      }
    });
    setInvalid(invalidData);
    const isValidModes = Object.values(notificatonModes).some(item => item)
    setInvalidModes(!isValidModes)
    if (isValid && isValidModes) {
      saveConfig({ ...data, noti_type: notificatonModes });
      handleClose();
    }
  };


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      BackdropProps={{ style: { backgroundColor: 'black', opacity: "0.9" } }}
      className="dialog-pill"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box width="40rem">
        <div style={{position:"relative" , display: 'flex', direction: 'row', justifyContent: 'center'}}>
          <Typography sx={{mt:".3rem", mb: '2rem', color: 'primary.main', fontWeight: 'bold', fontSize: '1rem' }}>
          Critical Event Notifications
            </Typography>
            <CustomDialogTitle onClose={() => {handleClose()}} style={{padding:0,margin:0 , position:"absolute" , right:"0" , top:"0"}} ></CustomDialogTitle>
        </div>
            <Box width="100%" sx={{ display: "flex", justifyContent: "space-between", flexWrap: 'wrap' }}>
              <Box className="w-xs-100" width="45%">
                <Typography sx={{ fontSize: "0.8rem", paddingBottom: "0.5rem" }}> Day Time Interval (hrs) <span  style={{color: '#9c9c9c'}}>6 Am to 6 Pm</span> </Typography>
                <SelectInputMod value={settings?.day_interval || ''}  helperText={invalid?.day_interval && "Required"}  sx={{ "& .MuiSelect-select": { padding: "0.5rem", paddingRight: "32px" } }} onChange={(event) => handleChange(event.target.value, "day_interval")}>
                  {intervalArr.map((option, index) => <MenuItem key={index} value={option.toString()} > {option} hrs</MenuItem>)}
                </SelectInputMod>
              </Box>
              <Box className="w-xs-100" width="45%">
                <Typography sx={{ fontSize: "0.8rem", paddingBottom: "0.5rem" }}> Night Time Interval (hrs)  <span  style={{color: '#9c9c9c'}}> 6 Pm to 6 Am</span></Typography>
                <SelectInputMod value={settings?.night_interval || ''}  helperText={invalid?.night_interval && "Required"} sx={{ "& .MuiSelect-select": { padding: "0.5rem", paddingRight: "32px" } }} onChange={(event) => handleChange(event.target.value, "night_interval")}>
                  {intervalArr.map((option, index) => <MenuItem key={index} value={option.toString()} > {option} hrs </MenuItem>)}
                </SelectInputMod>
              </Box>
            </Box>
            <Box width="100%" sx={{ display: "flex", justifyContent: "space-between", flexWrap: 'wrap' }}>
              <Box className="w-xs-100" width="45%">
                <SimpleInputField
                  type="Other"
                  value={settings?.day_message}
                  helperText={invalid?.day_message && "Message is required"}
                  labelText={"CEN for Day Time"}
                  onChange={(event) =>
                    handleChange(event.target.value, "day_message")
                  }
                  multiline={8}
                  placeholder="Enter Critical Event Notification"
                  maxRows={8}
                />
              </Box>
              <Box className="w-xs-100" width="45%">
                <SimpleInputField
                  type="Other"
                  value={settings?.night_message}
                  helperText={invalid?.night_message && "Message is required"}
                  labelText="CEN for Night Time"
                  onChange={(event) =>
                    handleChange(event.target.value, "night_message")
                  }
                  multiline={8}
                  placeholder="Enter Critical Event Notification"
                  maxRows={8}
                />
              </Box>
            </Box>
          {invalidModes && <Box sx={{color: 'red', fontSize: '0.85rem'}}>Please select at least one medium</Box>}

        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.8rem' }}>
          <TransparentBtn sx={{ color: notificatonModes?.email ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='email' variant="outlined">Email</TransparentBtn>
          <TransparentBtn sx={{ color: notificatonModes?.sms ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='sms' variant="outlined">SMS</TransparentBtn>
          <TransparentBtn sx={{ color: notificatonModes?.push ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='push' variant="outlined">Push</TransparentBtn>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{ color: "#fff", width: "15rem" }}
            startIcon={<TickIcon />}
            onClick={validate}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default SleepbeltNotification

