export const isURLValid = (input) => {
  const urlRegex =
    /^[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?/;

  return urlRegex.test(input);
};

export const isEmailValid = (input) => {
  const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  // const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/;
  // const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return emailRegex.test(input);
};

export const isEmailValidOtp = (input) => {
  // const emailRegex = /[a-z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,15}/; // Regex for email Id don't accept +
  const emailRegex = /[a-z0-9._%+-]+@[a-z0-9.\+]+\.[a-z]{2,15}/; // Regex for email Id accept +

  return emailRegex.test(input);
}

export const isMobileValid = (input) => {
  // const phonenoRegex = /^[0-9]{3}[- ]?[0-9]{3}[- ]?[0-9]{4}$/;
  // return phonenoRegex.test(input);
  return /^[0-9]{0,10}$/.test(input);
};

export const isPhonenoValid = (input) => {
  // const phonenoRegex = /^[0-9]{3}[- ]?[0-9]{3}[- ]?[0-9]{4}$/;
  // return phonenoRegex.test(input);
  return /^[0-9]{10}$/.test(input);
};

export const isPersonalInfoValid = (input) => {
  // const phonenoRegex = /^[0-9]{3}[- ]?[0-9]{3}[- ]?[0-9]{4}$/;
  // return phonenoRegex.test(input);
  return /^[0-9]$/.test(input);
};

export const isTime = (input) => {
  return /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(input);
}

export const isName = (input) => {
  return /^[a-zA-Z]{0,30}$/.test(input);
}

export const isValidName = (value) => {
  return /^[a-zA-Z ]*$/.test(value);
}

export const isHeight = (input) => {
  return /^[0-9]{0,3}$/.test(input);
}

export const isWeight = (input) => {
  return /^[0-9]{0,3}$/.test(input);
}

export const isPrice = (input) => {
  return /^(\$|)([1-9]\d{0,2}(\,\d{3})*|([1-9]\d*))(\.\d{2})?$/.test(input);
}

export const mobileFormatter = (value) => {
  let newValue;
  if (value?.length >= 7)
    newValue = value?.slice(0, 3) + '.' + value?.slice(3, 6) + '.' + value?.slice(6, 10);
  else if (value?.length >= 4)
    newValue = value?.slice(0, 3) + '.' + value?.slice(3, 6);
  else
    newValue = value?.slice(0, 3);
  return newValue
}


export const vpnValidator = (value) => {
  return ["", null, undefined, "None:4000"].includes(value)
}

export const isRoom = (input) => {
  if (input?.length > 5) return false
  return /^[a-zA-Z0-9 ]*$/.test(input);
}

export const isNumber = (val) => {

  //it will return true when the val is number and greate then 0
  const regex = new RegExp(/^[1-9]\d*$/);
  return regex.test(val) || !val;
}