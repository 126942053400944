import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from '@mui/material';
import React, { useState } from 'react';
import showIcon from 'assets/img/view.svg';
import hideIcon from 'assets/img/eye-closed.svg';
const ModInput = styled(InputBase)({
  'label + &': {
    marginTop: '1.5rem',
    color: '#9c9c9c',
    backgroundColor: '#fff',
    borderRadius: '0.5rem',
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    width: '100%',
    fontSize: '1rem',
    padding: '0.5rem 1rem',
    color: 'black',
    fontWeight: '400',
    '&::placeholder': {
      color: 'grey',
      fontWeight: '400',
      opacity: '1'
    },
  },
  '&:focus': {
    color: 'black',
  },
});
const AddUserInput = ({ fieldType, labelText, options, multiline, onChange, value, handleChange, handleFocus, handleBlur, disabled, backgroundColor, placeholder, helperText, required }) => {
  const [showPass, setShowPass] = useState(false);
  return (
    <FormControl
      fullWidth
      sx={{ backgroundColor: 'transparent', borderRadius: '0', mb: '1.4rem' }}
      variant="filled"
      onChange={onChange}
    >
      <InputLabel
        sx={{
          color: '#9c9c9c',
          fontSize: '1rem',
          '&.Mui-focused': {
            color: '#9c9c9c',
          },
        }}
        shrink
      >
        {labelText}<span style={{ color: 'red',  }}>{required && ' *'}</span>
      </InputLabel>
      {fieldType === 'password' ? (
        <ModInput
          // placeholder={labelText}
          inputProps={{
            type: showPass ? 'text' : 'password',
            autoComplete: 'new-password',
          }}
          placeholder={placeholder}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                disableRipple={true}
                onClick={() => setShowPass(!showPass)}
              >
                <img src={showPass ? hideIcon : showIcon} alt="" />
              </IconButton>
            </InputAdornment>
          }
          value={value}
        />
      ) : fieldType === 'select' ? (
        <Select
          label={labelText}
          value={value}
          onChange={onChange}
          input={<ModInput placeholder={labelText} />}
        >
          {options.map((option, index) => {
            return (
              <MenuItem key={index} value={option} disabled={index===disabled}>
                {option[0].toUpperCase() + option.slice(1)}
              </MenuItem>
            );
          })}
        </Select>
      ) : (
        <ModInput sx={{ color: "black", backgroundColor: backgroundColor === "#d0d0d0" && "#d0d0d0" }} onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange} value={value}  multiline={multiline} minRows={4} placeholder={placeholder} />
      )}
      <Box sx={{height: '0rem'}}>
        <FormHelperText style={{ fontSize: '0.65rem', fontWeight: '500', color: 'red', margin: '0px 0 0 10px'}} id="my-helper-text">{helperText}</FormHelperText>
      </Box>
    </FormControl>
  );
};

export default AddUserInput;
