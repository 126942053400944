import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import LoginBg from 'assets/img/loginbg.png';
import logo from 'assets/img/logo.png';
import styles from './prelogin.module.scss';
const PreLoginLayout = ({ children }) => {
  return (
    <Stack direction="row">
      <Box
        className={styles.loginBg}
        sx={{
          height: '100vh',
          flex: '1 4 600px',
          display: { xs: 'none', sm: 'block' },
        }}
      ></Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: { xs: '1 1 0', sm: '1 1 600px' },
          height: '100vh',
          background: { xs: `url(${LoginBg})`, sm: 'none' },
        }}
      >
        <div style={{ height: '1rem', width: '1rem' }}></div>
        {children}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem', height: '3.5rem' }}>
          <Typography sx={{ fontSize: '16px', color: '#aaa' }}>
            Powered by{' '}
          </Typography>
          <img className={styles.logo} src={logo} alt="" />
        </Box>
      </Box>
    </Stack>
  );
};

export default PreLoginLayout;
