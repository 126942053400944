import { Box, Paper, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import cameraIcon from 'assets/img/camera.svg';
import watchIcon from 'assets/img/watch.svg';
import door from 'assets/img/door.svg';
import pill from 'assets/img/pill.svg';
import bathroomIcon from 'assets/img/32px_Bathroom_Sensor_IconP_White.svg';
import WatchIcon from 'assets/img/watch.svg';
import nhubIcon from 'assets/img/nhub.svg';
import ActiveDevice from 'app/dashboard/Components/active-devices/ActiveDevice';
import FallIcon from 'assets/img/28x28 px Fall_detection outlined_White.svg';
import SleepBeltIcon from 'assets/img/belt-white.svg';
import CouchIcon from 'assets/img/couch-white.svg';

import SOSIcon from 'assets/img/sos-white.svg';
import ShowerIcon from "assets/img/shower-white.svg";
import { GlobalContext } from 'context';

const ActiveDevices = () => {

  const { dispatch, state } = useContext(GlobalContext);
  const { dashboardData: { active_devices } } = state || {}

  const { AdultSensors, BathRoom,Shower, Bed, CarKeys, Couch, DiabeticPump, Door, PillBox, camera, nhub,vnhub, watch, SleepBelt, FallDetection, SOS, "SleepBelt-Chair": couch } = active_devices || {}

  return (
    <>
      <Typography sx={{ marginBottom: '2rem'}} textAlign="center">
        Active devices
      </Typography>
      <Box sx={{padding: '0 2rem'}}>
        <Box sx={{display: 'flex', justifyContent:'center', flexWrap:'wrap'}}>
          {/* <ActiveDevice count={AdultSensors} deviceName={'Adult Sensors'} icon={cameraIcon} /> */}
          {/* <ActiveDevice count={Bed} deviceName={'Bed'} icon={cameraIcon} /> */}
          {/* <ActiveDevice count={CarKeys} deviceName={'Car Keys'} icon={cameraIcon} /> */}
          {/* <ActiveDevice count={Couch} deviceName={'Couch'} icon={cameraIcon} /> */}
          {/* <ActiveDevice count={DiabeticPump} deviceName={'Diabetic Pump'} icon={cameraIcon} /> */}
          <ActiveDevice count={Door} deviceName={'Door'} icon={door} />
          <ActiveDevice count={PillBox} deviceName={'PillBox'} icon={pill} />
          <ActiveDevice count={camera} deviceName={'Camera'} icon={cameraIcon} />
          <ActiveDevice count={nhub} deviceName={'NHub'} icon={nhubIcon} />
          <ActiveDevice count={vnhub} deviceName={'VNHub'} icon={nhubIcon} />
          <ActiveDevice count={watch} deviceName={'Healthcare Watch'} icon={watchIcon} />
          <ActiveDevice count={FallDetection} deviceName={'Fall Detection'} icon={FallIcon} />
          <ActiveDevice count={SOS} deviceName={'Urgent Band'} icon={SOSIcon} />
          <ActiveDevice count={SleepBelt} deviceName={'Sleep Belt (Bed)'} icon={SleepBeltIcon} />
          <ActiveDevice count={couch} deviceName={'Sleep Belt (Couch / Chair)'} icon={CouchIcon} />
          <ActiveDevice count={Shower} deviceName={'Shower'} icon={ShowerIcon} />
          <ActiveDevice count={BathRoom} deviceName={'BathRoom'} icon={bathroomIcon} />
        </Box>
      </Box>
    </>
  );
};

export default ActiveDevices;
