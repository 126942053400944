import React, { useContext, useState, useEffect } from "react";
import { Typography, Button, Box, Stack } from "@mui/material";
import SimpleInputField from "components/simple-inputfield/SimpleInputField";
import AuthServices from "api/services/auth-services";
import { actions, GlobalContext } from "context";
import { TransparentBtn } from "../../../components/styled-componets/style";
import { ReactComponent as TickIcon } from "assets/img/tick_icon.svg";
import { ReactComponent as SleepIcon } from "assets/img/sleep_icon.svg";
import { ReactComponent as HeartRateIcon } from "assets/img/heart_rate_gray.svg";
import { ReactComponent as BPMIcon } from "assets/img/bpm_icon.svg";
import { ReactComponent as StepsIcon } from "assets/img/steps_icon.svg";
import { ReactComponent as BatteryIcon } from "assets/img/battery_icon.svg";
import { ReactComponent as spo2 } from "assets/img/act.svg";
import FeatureInfo from "../../../components/styled-componets/FeatureInfo";
import { getTimeZoneOffset, getUserTimeZome } from "utils/datetime-formatter";

const SubTabThreeWatch = ({
	currentSelected,
	selectedSenior,
	snackbarNotification,
	goBack,
	selectedSensorNotification,
}) => {
	const { dispatch } = useContext(GlobalContext);
	const storeHandler = (type, payload) => dispatch({ type, payload });

	const [selected, setSelected] = useState("");
	const [invalid, setInvalid] = useState({
		heart_rate: {
			device: false,
			sensor: false,
			message: false,
			lesser_message: false,
			max: false,
			min: false,
		},
		blood_pressure: {
			device: false,
			sensor: false,
			message: false,
			lesser_message: false,
			max_sys: false,
			min_sys: false,
			max_dia: false,
			min_dia: false,
		},
		sleep: {
			device: false,
			sensor: false,
			message: false,
			lesser_message: false,
			max: false,
			min: false,
		},
		steps: {
			device: false,
			sensor: false,
			message: false,
			lesser_message: false,
			max: false,
			min: false,
		},
		blood_oxygen: {
			device: false,
			sensor: false,
			message: false,
			lesser_message: false,
			max: false,
			min: false,
		},
		battery: {
			device: false,
			sensor: false,
			message: false,
			// lesser_message:"",
			max: false,
			min: false,
		},
	});
	const [invalidModes, setInvalidModes] = useState(false);
	const [addedSettings, setAddedSettings] = useState();
	const [showWarning, setShowWarning] = useState(false);
  const initialSettings = {
    heart_rate: {
			device: "watch",
			sensor: "heart_rate",
			message: "",
			lesser_message: "",
			max: "",
			min: "",
		},
		blood_pressure: {
			device: "watch",
			sensor: "blood_pressure",
			message: "",
			lesser_message: "",
			max_sys: "",
			min_sys: "",
			max_dia: "",
			min_dia: "",
		},
		sleep: {
			device: "watch",
			sensor: "sleep",
			message: "",
			lesser_message: "",
			max: "",
			min: "",
		},
		steps: {
			device: "watch",
			sensor: "steps",
			message: "",
			lesser_message: "",
			max: "",
			min: "",
		},
		blood_oxygen: {
			device: "watch",
			sensor: "blood_oxygen",
			message: "",
			lesser_message: "",
			max: "",
			min: "",
		},
		battery: {
			device: "watch",
			sensor: "battery",
			message: "",
			// lesser_message:"",
			max: "100",
			min: "",
		},
  }
	const [settings, setSettings] = useState({...initialSettings});
	const [bool, setBool] = useState(false);

	const [notificatonModes, setNotificationModes] = useState({
		email: false,
		sms: false,
		push: false,
	});
	const handleModeClick = (e) => {
		const mode = e.target.name;
		const prev = notificatonModes[mode];
		setNotificationModes({ ...notificatonModes, [mode]: !prev });
		invalid.modes = false;
		setInvalidModes(false);
	};

	const handleChange = (value, key, sensor) => {
		let data = settings;
		data[sensor][key] = value;
		setSettings(data);
		setBool((prev) => !prev);
		const newInvalid = { ...invalid };
		if (key) newInvalid[selected][key] = false;
		setInvalid(newInvalid);
	};

	const validate = () => {
		let invalidData = {};
		let valid = true;
		Object.keys(settings[selected]).forEach((key) => {
			if (settings[selected][key] === "") {
				invalidData[key] = true;
				valid = false;
			}
		});
		setInvalid({ ...invalid, [selected]: invalidData });
		const isValidModes = Object.values(notificatonModes).some((item) => item);
		setInvalidModes(!isValidModes);
		if (valid && (currentSelected == "generic" || isValidModes)) handleAdd();
	};

	const handleAdd = async () => {
		storeHandler(actions.SHOW_LOADER, true);
		try {
			if (currentSelected === "generic") {
				const { message } = await AuthServices.addGenericNotifications({
					...settings[selected],
					timezone: getUserTimeZome(),
					origin: getTimeZoneOffset(),
				});
				snackbarNotification({
					message,
					class: "success",
				});
			} else {
				const { message } = await AuthServices.addCentricNotifications(
					selectedSenior?.id,
					{
						...settings[selected],
						noti_type: notificatonModes,
						timezone: getUserTimeZome(),
						origin: getTimeZoneOffset(),
					}
				);
        setSelected('')
				snackbarNotification({
					message,
					class: "success",
				});
			}
			goBack();
			storeHandler(actions.SHOW_LOADER, false);
		} catch (err) {
			const { data } = err || {};
			const { message } = data || {};
			snackbarNotification({
				message,
				class: "error",
			});
			storeHandler(actions.SHOW_LOADER, false);
		}
	};

	useEffect(() => {
		setInvalidModes(false);
		setNotificationModes({ email: false, sms: false, push: false });
	}, [selected]);
	const isNumber = (val) => {
		const regex = new RegExp(/^\d+$/);
		return regex.test(val) || !val;
	};

	useEffect(() => {
		if (currentSelected === "seniorSpecific") {
			const addedSettings = selectedSensorNotification.map(
				(setting) => setting?.setting
			);
			setAddedSettings(addedSettings);
      setSettings({...initialSettings})
      setSelected("")
      setShowWarning(false);
		} else {
			setSelected("heart_rate");
		}
	}, [selectedSensorNotification]);
	return (
		<>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						margin: "2rem 0 2rem 0",
						gap: "0.5rem",
						flexWrap: "wrap",
					}}
				>
					<FeatureInfo
						className={`default-icon ${
							selected === "heart_rate" && "active-watch-icon"
						}`}
						primary={selected === "heart_rate"}
						added={addedSettings?.includes("heart_rate")}
						onClick={() => {
							if (addedSettings?.includes("heart_rate")) {
								setShowWarning(true);
								setSelected("");
							} else {
								setSelected("heart_rate");
                setShowWarning(false);
							}
						}}
						icon={HeartRateIcon}
					>
						<Typography>Heart Rate</Typography>
					</FeatureInfo>
					<FeatureInfo
						className={`default-icon ${
							selected === "blood_pressure" && "active-watch-icon"
						}`}
						primary={selected === "blood_pressure"}
						added={addedSettings?.includes("blood_pressure")}
						onClick={() => {
							if (addedSettings?.includes("blood_pressure")) {
								setShowWarning(true);
								setSelected("");
							} else {
								setSelected("blood_pressure");
                setShowWarning(false);
							}
						}}
						icon={BPMIcon}
					>
						<Typography>BP</Typography>
					</FeatureInfo>
					<FeatureInfo
						className={`default-icon ${
							selected === "sleep" && "active-watch-icon"
						}`}
						primary={selected === "sleep"}
						added={addedSettings?.includes("sleep")}
						onClick={() => {
							if (addedSettings?.includes("sleep")) {
								setShowWarning(true);
								setSelected("");
							} else {
								setSelected("sleep");
                setShowWarning(false);
							}
						}}
						icon={SleepIcon}
					>
						<Typography>Sleep</Typography>
					</FeatureInfo>
					<FeatureInfo
						special={true}
						className={`default-icon ${
							selected === "steps" && "active-watch-icon"
						}`}
						primary={selected === "steps"}
						added={addedSettings?.includes("steps")}
						onClick={() => {
							if (addedSettings?.includes("steps")) {
								setShowWarning(true);
								setSelected("");
							} else {
								setSelected("steps");
                setShowWarning(false);
							}
						}}
						icon={StepsIcon}
					>
						<Typography>Steps</Typography>
					</FeatureInfo>
					<FeatureInfo
						stroke={true}
						className={`default-icon ${
							selected === "blood_oxygen" && "active-watch-icon"
						}`}
						primary={selected === "blood_oxygen"}
						added={addedSettings?.includes("blood_oxygen")}
						onClick={() => {
							if (addedSettings?.includes("blood_oxygen")) {
								setShowWarning(true);
								setSelected("");
							} else {
								setSelected("blood_oxygen");
                setShowWarning(false);
							}
						}}
						icon={spo2}
					>
						<Typography>SPO2</Typography>
					</FeatureInfo>
					<FeatureInfo
						stroke={true}
						className={`default-icon ${
							selected === "battery" && "active-watch-icon"
						}`}
						primary={selected === "battery"}
						added={addedSettings?.includes("battery")}
						onClick={() => {
							if (addedSettings?.includes("battery")) {
								setShowWarning(true);
								setSelected("");
							} else {
								setSelected("battery");
                setShowWarning(false);
							}
						}}
						icon={BatteryIcon}
					>
						<Typography>Battery</Typography>
					</FeatureInfo>
				</Box>
				{!showWarning ? (
        <>
        <Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Box className="notifications-box-second">
						<Box className="notification-content">
							{selected === "blood_pressure" ? (
								<Box style={{ display: "grid" }}>
									<Stack className="stack-row">
										<Box className="box-row">
											<SimpleInputField
												type="Other"
												helperText={
													invalid[selected]?.min_sys && "Please enter the value"
												}
												value={settings[selected]?.max_sys}
												labelText="Sys Max"
												onChange={(event) => {
													if (!isNumber(event.target.value)) return;
													if (event.target.value.length <= 3) {
														handleChange(
															event.target.value,
															"max_sys",
															selected
														);
													}
												}}
											/>
										</Box>
										<Box>
											<SimpleInputField
												type="Other"
												helperText={
													invalid[selected]?.max_sys && "Please enter the value"
												}
												value={settings[selected]?.min_sys}
												labelText="Sys Min"
												onChange={(event) => {
													if (!isNumber(event.target.value)) return;
													if (event.target.value.length <= 3) {
														handleChange(
															event.target.value,
															"min_sys",
															selected
														);
													}
												}}
											/>
										</Box>
									</Stack>
									<Stack className="stack-row">
										<Box className="box-row">
											<SimpleInputField
												type="Other"
												helperText={
													invalid[selected]?.max_dia && "Please enter the value"
												}
												value={settings[selected]?.max_dia}
												labelText="Dia Max"
												onChange={(event) => {
													if (!isNumber(event.target.value)) return;
													if (event.target.value.length <= 3) {
														handleChange(
															event.target.value,
															"max_dia",
															selected
														);
													}
												}}
											/>
										</Box>
										<Box>
											<SimpleInputField
												type="Other"
												helperText={
													invalid[selected]?.min_dia && "Please enter the value"
												}
												value={settings[selected]?.min_dia}
												labelText="Dia Min"
												onChange={(event) => {
													if (!isNumber(event.target.value)) return;
													if (event.target.value.length <= 3) {
														handleChange(
															event.target.value,
															"min_dia",
															selected
														);
													}
												}}
											/>
										</Box>
									</Stack>
								</Box>
							) : (
								selected && (
									<Box
										className="notification-content-first"
										style={{ display: "grid", width: "35%" }}
									>
										{selected !== "battery" && (
											<Box>
												<SimpleInputField
													type="Other"
													helperText={
														invalid[selected]?.max && "Please enter the value"
													}
													value={settings[selected]?.max}
													labelText="Greater than"
													placeholder={selected === "sleep" ? "Hours" : ""}
													onChange={(event) => {
														if (!isNumber(event.target.value)) return;
														let maxlen = selected === "sleep" ? 2 : 3;
														if (
															event.target.value.length <= maxlen ||
															selected === "steps"
														) {
															handleChange(event.target.value, "max", selected);
														}
													}}
												/>
											</Box>
										)}
										{selected && (
											<Box>
												<SimpleInputField
													type="Other"
													helperText={
														invalid[selected]?.min && "Please enter a value"
													}
													value={settings[selected]?.min}
													placeholder={selected === "sleep" ? "Hours" : ""}
													labelText="Lesser than"
													onChange={(event) => {
														if (
															!isNumber(event.target.value) ||
															(selected === "battery" &&
																event.target.value > 100)
														)
															return;
														let maxlen = selected === "sleep" ? 2 : 3;
														if (
															event.target.value.length <= maxlen ||
															selected === "steps"
														) {
															handleChange(event.target.value, "min", selected);
														}
													}}
												/>
											</Box>
										)}
									</Box>
								)
							)}
							{selected && (
								<Box
									className="notification-content-second"
									style={{ width: "60%" }}
								>
									<SimpleInputField
										type="Other"
										value={settings[selected]?.message}
										helperText={
											invalid[selected]?.message && "Message is required"
										}
										labelText={`${
											selected === "battery"
												? "CEN for Lesser than"
												: "CEN for Greater than"
										} `}
										onChange={(event) =>
											handleChange(event.target.value, "message", selected)
										}
										multiline={8}
										placeholder="Enter Critical Event Notification"
										maxRows={8}
									/>
									{selected !== "battery" && (
										<SimpleInputField
											type="Other"
											value={settings[selected]?.lesser_message}
											helperText={
												invalid[selected]?.lesser_message &&
												"Message is required"
											}
											labelText="CEN for Lesser than"
											onChange={(event) =>
												handleChange(
													event.target.value,
													"lesser_message",
													selected
												)
											}
											multiline={8}
											placeholder="Enter Critical Event Notification"
											maxRows={8}
										/>
									)}
								</Box>
							)}
						</Box>
					</Box>
				</Box>
				{currentSelected !== "generic" && selected && (
					<>
						{invalidModes && (
							<Box sx={{ color: "red", fontSize: "0.85rem" }}>
								Please select at least one medium
							</Box>
						)}

						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								marginBottom: "0.8rem",
							}}
						>
							<TransparentBtn
								sx={{ color: notificatonModes?.email ? "#00b89f" : "#4d4c4c" }}
								onClick={handleModeClick}
								name="email"
								variant="outlined"
							>
								Email
							</TransparentBtn>
							<TransparentBtn
								sx={{ color: notificatonModes?.sms ? "#00b89f" : "#4d4c4c" }}
								onClick={handleModeClick}
								name="sms"
								variant="outlined"
							>
								SMS
							</TransparentBtn>
							<TransparentBtn
								sx={{ color: notificatonModes?.push ? "#00b89f" : "#4d4c4c" }}
								onClick={handleModeClick}
								name="push"
								variant="outlined"
							>
								Push
							</TransparentBtn>
						</Box>
					</>
				)}
				{selected && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Button
							variant="contained"
							sx={{ color: "#fff", width: "15rem", mb: "1rem" }}
							startIcon={<TickIcon />}
							onClick={validate}
						>
							Add
						</Button>
					</Box>
				)}
        </>)
				  : (
					<Typography color="primary" sx={{ textAlign: "center",mb:'2rem' }}>
						CEN Configurations already exist
					</Typography>
				)}
		</>
	);
};

export default SubTabThreeWatch;
