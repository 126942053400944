import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton, Box } from '@mui/material';
import { BsSearch } from 'react-icons/bs';

const LightSearchBar = ({ setSearchValue, value, helperText, ...rest }) => {
  const [searchString, setSearchString] = useState('');

  const search = (textString) => {
    console.log(textString);
  };
  return (
    <>
      <TextField
        size="small"
        hiddenLabel
        value={value}
        onChange={(e) => {
          setSearchValue(e.target.value)
        }}
        sx={{
          backgroundColor: '#fff',
          margin: 0,
          borderRadius: '10px',
        }}
        {...rest}
        InputProps={{
          placeholder: 'Search by name or id',
          disableUnderline: true,
          sx: { borderRadius: '10px', color: '#6C7175' },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                sx={{ color: 'primary.main' }}
                disableRipple={true}
                onClick={() => search(searchString)}
              >
                <BsSearch />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box>
        <label style={{ fontSize: '0.9rem', fontWeight: '500', color: 'red', padding: '0 0 0 10px' }}>{helperText}</label>
      </Box>
    </>
  );
};

export default LightSearchBar;
