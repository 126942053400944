import { FormControl, InputLabel, Select } from '@mui/material';
import { Box } from '@mui/material';
import React from 'react'

const SelectInputMod = (props) => {
  const { label, required, where, helperText, ...rest } = props;
  return (
    <>
      <FormControl variant="filled" fullWidth size="small" required={required} >
        <InputLabel id="demo-simple-select-filled-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          {...rest}
          disableUnderline
        />
      </FormControl>
      <Box sx={{marginTop: '-1rem'}}>
        <label style={{ fontSize: '0.65rem', fontWeight: '500', color: 'red', padding: '2px 0 0', }}>{helperText}</label>
      </Box>
    </>
  )
}

export default SelectInputMod