import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

const ActiveDevice = ({ count, deviceName, icon }) => {
  return (
    <Box
      sx={{
        border: '4px solid #14B498',
        borderRadius: '1rem',
        margin: '0rem 0.2rem 2rem'
      }}
    >
      <Box
        sx={{
          minWidth: '8.5rem',
          padding: '1.5rem',
          borderTopLeftRadius: '1rem',
          borderTopRightRadius: '1rem',
        }}
      >
        <Typography color="primary" variant="h3" textAlign="center">
          {count ? count : "-"}
        </Typography>
      </Box>
      <Box
        sx={{
          padding: '0.5rem',
          backgroundColor: '#14B498',
          borderBottomLeftRadius: '.5rem',
          borderBottomRightRadius: '.5rem',
          height: '3rem'
        }}
      >
        <Box
          sx={{ display: 'flex', justifyContent: 'center',gap: '0.5rem', alignItems: 'center', backgroundColor: '#14B498' }}
        >
          <img sx={{ height: '2rem' }} src={icon} alt="" />
          <Typography fontSize="0.8rem">{deviceName}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ActiveDevice;
