import { Button, Dialog, Stack, Typography } from "@mui/material";
import { Box } from '@mui/material'
import { ReactComponent as TickIcon } from 'assets/img/tick_icon.svg';
import SimpleInputField from "components/simple-inputfield/SimpleInputField";
import { TransparentBtn } from "app/notification/components/styled-componets/style";
// import SelectInputMod from 'app/create-account/Components/select-input-mod/SelectInputMod';
// import Timepicker from "components/time-picker/Timepicker";
import React, { useState } from 'react'
import { CustomDialogTitle } from "components/dialogs/custom-dialog-title/CustomDialogTitle";
// import { getTimein24HrFormat } from "utils/datetime-formatter";

const SosNotification = ({ open, fallDetection, handleClose, saveConfig }) => {
  const initialState = {
    "device": fallDetection ? "FallDetection" : "SOS",
    "message": ""
  };
  const [settings, setSettings] = useState({ ...initialState });
  const [invalid, setInvalid] = useState({});
  const [invalidModes, setInvalidModes] = useState(false);

  const [notificatonModes, setNotificationModes] = useState({ email: false, sms: false, push: false });
  const handleModeClick = (e) => {
    const mode = e.target.name;
    const prev = notificatonModes[mode];
    setNotificationModes({ ...notificatonModes, [mode]: !prev });
    invalid.modes = false;
    setInvalidModes(false)

  }

  const handleChange = (value, key) => {
    let data = settings;
    data[key] = value;
    setInvalid({ ...invalid, [key]: false });
    setSettings(data);
  };

  const validate = () => {
    let data = settings;
    let invalidData = {};
    let isValid = true;
    Object.keys(data).forEach((key) => {
      if (data[key] === "") {
        invalidData[key] = true;
        isValid = false;
      }
    });
    setInvalid(invalidData);
    const isValidModes = Object.values(notificatonModes).some(item => item)
    setInvalidModes(!isValidModes)

    if (isValid && isValidModes) {
      saveConfig({ ...data, noti_type: notificatonModes });
      handleClose();
    } else {
      return false
    }

  };


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      BackdropProps={{ style: { backgroundColor: 'black', opacity: "0.9" } }}
      className="dialog-pill"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box width="40rem">
        <div style={{ position: "relative", display: 'flex', direction: 'row', justifyContent: 'center' }}>
          <Typography sx={{ mt: ".3rem", mb: '2rem', color: 'primary.main', fontWeight: 'bold', fontSize: '1rem' }}>
            Critical Event Notifications
          </Typography>
          <CustomDialogTitle onClose={() => { handleClose() }} style={{ padding: 0, margin: 0, position: "absolute", right: "0", top: "0" }} ></CustomDialogTitle>
        </div>
        <Stack>
          <Box sx={{ paddingTop: '1.5rem' }}>
            <SimpleInputField
              labelText="CEN"
              multiline={8}
              placeholder="Enter Critical Event Notification"
              type="Other"
              helperText={invalid?.message ? "Please enter a valid message" : ""}
              value={settings?.message}
              onChange={(event) => handleChange(event.target.value, "message")}
            />
          </Box>
        </Stack>
        {invalidModes && <Box sx={{ color: 'red', fontSize: '0.85rem' }}>Please select at least one medium</Box>}

        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.8rem' }}>
          <TransparentBtn sx={{ color: notificatonModes?.email ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='email' variant="outlined">Email</TransparentBtn>
          <TransparentBtn sx={{ color: notificatonModes?.sms ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='sms' variant="outlined">SMS</TransparentBtn>
          <TransparentBtn sx={{ color: notificatonModes?.push ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='push' variant="outlined">Push</TransparentBtn>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{ color: "#fff", width: "15rem" }}
            startIcon={<TickIcon />}
            onClick={validate}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default SosNotification

