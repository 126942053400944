import { Button } from '@mui/material'
import React from 'react'

const SensorButton = ({ children, onClick }) => {
    return (
        <Button
            sx={{
                backgroundColor: '#2F363E',
                padding: '0.75rem',
                textAlign: 'center',
                fontSize: '1rem',
            }}
            onClick={onClick}
        >
            {children}
        </Button>
    )
}

export default SensorButton