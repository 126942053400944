import httpClient from '../http-client';
import {
    SAVE_VPN_CRED,
    TEST_EMAIL_CONNECTION,
    TEST_SMS_CONNECTION,
    TEST_PUSH_CONNECTION,
    SAVE_EMAIL_CONNECTION,
    SAVE_SMS_CONNECTION,
    SAVE_PUSH_CONNECTION,
} from '../config';


const UtilityServices = {
    saveVpnCred: (data) => httpClient.post(SAVE_VPN_CRED, data).then((response => response.data)),
    testEmailConnection: (data) => httpClient.post(TEST_EMAIL_CONNECTION, data).then((response => response.data)),
    testSmsConnection: (data) => httpClient.post(TEST_SMS_CONNECTION, data).then((response => response.data)),
    testPushConnection: (data) => httpClient.post(TEST_PUSH_CONNECTION, data).then((response => response.data)),
    saveEmailConnection: (data) => httpClient.post(SAVE_EMAIL_CONNECTION, data).then((response => response.data)),
    saveSmsConnection: (data) => httpClient.post(SAVE_SMS_CONNECTION, data).then((response => response.data)),
    savePushConnection: (data) => httpClient.post(SAVE_PUSH_CONNECTION, data).then((response => response.data)),
}

export default UtilityServices;