import React from 'react';
import Button from '@mui/material/Button';
import { ReactComponent as NextIcon } from 'assets/img/next_icon.svg';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import "./EmailDialog.scss";

export default function EmailDialog({ open, handleClose, func }) {
    return (
        <div className='dialog-div'>
            <Dialog
                open={open}
                onClose={() => handleClose(false)}
                BackdropProps={{ style: { backgroundColor: 'black', opacity: "0.9" } }}
                className="email-dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box
                    sx={{
                        color: '#D8D8D8',
                        margin: '0 2rem',
                        marginTop: '0.5rem',
                        justifyContent: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography>
                        Emails are not the recommended way of pushing notifications. Alternatively, try using SMS or Push notifications to significantly optimize costs.
                    </Typography>
                    <Box className="flex-row">
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                color: '#D8D8D8',
                                margin: '1rem 1rem 0 0',
                                width: '100%',
                                whiteSpace: 'noWrap',
                                fontWeight: '300',
                                fontSize: '1.1rem',
                            }}
                            // endIcon={<NextIcon />}
                            onClick={() => func()}
                        >
                            Continue Anyway?
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                color: '#D8D8D8',
                                margin: '1rem 0 0 0',
                                width: '100%',
                                whiteSpace: 'noWrap',
                                fontWeight: '300',
                                fontSize: '1.1rem',
                            }}
                            // endIcon={<NextIcon />}
                            onClick={() => handleClose(false)}
                        >
                            Go Back
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </div>
    );
}