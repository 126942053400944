import { Box, Button, Grid, MenuItem, Paper, Snackbar, Stack, styled, Typography } from '@mui/material';
import AddSeniorInput from 'components/add-senior-input/AddSeniorInput';
import SeniorInfo from 'components/senior-info/SeniorInfo';
import React, { useContext, useState } from 'react';
import { ReactComponent as TickIcon } from 'assets/img/tick_icon.svg';
import { ReactComponent as PillIcon } from 'assets/img/pill_small.svg';
// import { ReactComponent as BedIcon } from 'assets/img/bed_small.svg';
import { ReactComponent as BathIcon } from 'assets/img/24px_Bathroom_Sensor_Icon_Active.svg';
import { ReactComponent as DoorIcon } from 'assets/img/door_small.svg';
import { ReactComponent as CameraIcon } from 'assets/img/camera_small.svg';
import { ReactComponent as SosIcon } from 'assets/img/sos.svg';
import { ReactComponent as GpsIcon } from 'assets/img/gps.svg';
import { ReactComponent as ShowerIcon } from 'assets/img/shower.svg';
import { ReactComponent as FallDetectionIcon } from 'assets/img/falldetection13x13green.svg';
import { ReactComponent as FluidIcon } from 'assets/img/fluid.svg';
import { ReactComponent as SleepIcon } from 'assets/img/sleep_icon.svg';
import { ReactComponent as BeltIcon } from 'assets/img/belt.svg';
import { ReactComponent as CouchBeltIcon } from "assets/img/couch.svg";

// import { ReactComponent as CouchIcon } from 'assets/img/couch_small.svg';
// import { ReactComponent as DiabeticIcon } from 'assets/img/diabetic_pump_small.svg';
import { ReactComponent as WatchIcon } from 'assets/img/watch.svg';
// import AdultIcon from 'assets/img/adult_sensors_small.png';
import { ReactComponent as UploadIcon } from 'assets/img/upload_icon.svg';
// import { ReactComponent as WatchIcon } from 'assets/img/watch_gray.svg';
import { ReactComponent as Avatar } from 'assets/avatar.svg';
import { actions, GlobalContext } from 'context';
import AuthServices from 'api/services/auth-services';
import { useEffect } from 'react';
import AlertDialog from './components/Next/dialog';
import BasicDatePicker from 'components/datepicker/Datepicker';
import "./scss/step3.scss";
import SensorButton from 'app/create-account/Components/sensor-button/SensorButton';
import PillboxNotifications from './components/CEN/pillbox/PillboxNotifications';
import DoorNotifications from './components/CEN/door/DoorNotifications';
import WatchNotifications from './components/CEN/watch/WatchNotifications';
import { isEmailValid, isHeight, isHeightPoint, isMobileValid, isName, isPhonenoValid, isPersonalInfoValid, isWeight, mobileFormatter, isValidName, isRoom } from 'utils/validator';
import "./CreAccStep3.scss";
import SelectInputMod from 'app/create-account/Components/select-input-mod/SelectInputMod';
import ConfiguredNotifications from './components/CEN/configured/Configured';
import ShowerNotification from './components/CEN/shower/ShowerNotification';
import SosNotification from './components/CEN/sos/SosNotification';
import SleepbeltNotification from './components/CEN/sleepbelt/SleepbeltNotification';
import BathRoomNotification from './components/CEN/bathroom/BathRoomNotifications';
import { getTimeZoneOffset, getUserTimeZome } from 'utils/datetime-formatter';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  boxShadow: "0 0 0 0",
  color: theme.palette.text.secondary,
}));

const Notification = {
  profile: { message: 'Please upload a profile image', class: 'error' },
  invalidProfile: { message: 'Please upload a valid profile image', class: 'error' },
  phone: { message: 'Please enter a valid phone number', class: 'error' },
  email: { message: 'Please enter a valid email address', class: 'error' },
  emailRegistered: { message: 'Email is already registered', class: 'error' },
  incomplete: { message: 'Please fill all the fields', class: 'error' },
  addSeniorSuccess: { message: 'Senior added successfully', class: 'success' },
  date: { message: 'Please select a valid date', class: 'error' },
  error: { message: 'We are facing some issues, Please try later!!', class: 'error' },
  invalidDate: { message: 'Please select a valid date', class: 'error' },
  invalid: { message: 'Invalid Fields found, please check the details', class: 'error' },
  mobileNoInUse: { message: 'Mobile number already registered', class: 'error' },
  emailInUse: { message: 'Email already registered', class: 'error' },
}


const bloodGroupOptions = [
  { id: 0, name: "A+" },
  { id: 1, name: "A-" },
  { id: 2, name: "B+" },
  { id: 3, name: "B-" },
  { id: 4, name: "AB+" },
  { id: 5, name: "AB-" },
  { id: 6, name: "O+" },
  { id: 7, name: "O-" },
];

const genderOptions = [
  { id: 0, value: 'Male' },
  { id: 1, value: 'Female' },
  { id: 2, value: 'Other' }
];

const weightOptions = [
  { id: 0, value: "lbs" },
  { id: 1, value: "kg" }
]

const heightOptions = [
  { id: 0, value: "ft" },
  { id: 1, value: "cm" }
]

const initialDetails = { zipcode: "", country: '', dob: '' };


const CreAccStep2 = ({ changeTab, setseniorList }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { masterAccountId, contractData: { plans }, planId } = state;
  const sensorsList = []
  plans?.map(item => {
    if (item['contract_name'] === 'default_plan') {
      sensorsList.push(...item['sensors'])
    }
  })

  const [seniors, setSeniors] = useState([]);
  const [seniorDetails, setSeniorDetails] = useState({ ...initialDetails });
  const [profileImgFile, setFile] = useState({})
  const [profileImgName, setFileName] = useState({})
  const [profileImgUrl, setProfileImgUrl] = useState({})
  const [uploaded, setUploaded] = useState(false)
  const [inValid, setInValid] = useState({});
  const [openNextDialog, setOpenNextDialog] = useState(false)
  const [pillCENDialogOpen, setPillCENDialogOpen] = useState(false)
  const [doorCENDialogOpen, setDoorCENDialogOpen] = useState(false)
  const [watchCENDialogOpen, setWatchCENDialogOpen] = useState(false)
  const [showerCENDialogOpen, setShowerCENDialogOpen] = useState(false)
  const [sosCENDialogOpen, setSosCENDialogOpen] = useState(false)
  const [sleepbeltCENDialogOpen, setSleepbeltCENDialogOpen] = useState(false)
  const [bathRoomCENDialogOpen, setBathRoomCENDialogueOpen] = useState(false);
  const [sleepbeltBed, setSleepbeltBed] = useState(false)
  const [fallDetection, setFallDetection] = useState(false)

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });
  const [country, setCountry] = useState('');
  const [disabled, setDisabled] = useState(true)
  const [countries, setCountries] = useState(null)
  const [city, setCity] = useState(null)
  const [currentState, setCurrentState] = useState(null);
  const [productNotifications, setProductNotifications] = useState([]);
  const [mobile, setMobile] = useState();
  const [height, setHeight] = useState(0)
  const [weight, setWeight] = useState(0)
  const [showConfiguredNotifications, setShowConfiguredNotifications] = useState(false);
  const mandatoryInputFields = [
    'fname',
    'lname',
    'age',
    'dob',
    'gender',
    'address',
    'zipcode',
    'city',
    'state',
    'country'
  ]

  useEffect(() => {
    if (seniors.length <= 0) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }

    setseniorList(seniors)
  }, [seniors])

  const storeHandler = (type, payload) => dispatch({ type, payload })

  const addDetail = (key, value) => {
    if (key === 'fname' || key === 'lname') {
      if (isValidName(value)) {
        const data = { ...seniorDetails, [key]: value };
        setInValid({ ...inValid, [key]: false })
        setSeniorDetails({ ...data });
      }
    } else if (key === 'height') {
      switch (height) {
        case 0: if (isHeight(value)) {
          const data = { ...seniorDetails, [key]: value };
          setSeniorDetails({ ...data });
        }
          break;
        case 1: if (isHeight(value)) {
          const data = { ...seniorDetails, [key]: value };
          setSeniorDetails({ ...data });
        }
          break;
        default: break;
      }
      setInValid({ ...inValid, [key]: false })
    } else if (key === 'inchs') {
      if (isHeight(value)) {
        const data = { ...seniorDetails, [key]: value };
        setSeniorDetails({ ...data });
        setInValid({ ...inValid, [key]: false })
      }
    }

    else if (key === 'weight') {
      if (isWeight(value)) {
        const data = { ...seniorDetails, [key]: value };
        setSeniorDetails({ ...data });
        setInValid({ ...inValid, [key]: false })
      }
    } else if (key === 'mobile') {
      if (isMobileValid(value)) {
        const data = { ...seniorDetails, [key]: value };
        setSeniorDetails({ ...data });
        mobileValidator(value)
      } else {
        mobileValidator(value)
      }
    } else if (key === 'dob') {
      const date = new Date(value)
      const year = date.getFullYear().toString().length === 4 ? date.getFullYear().toString() : date.getFullYear().toString().length === 3 ? ('0' + date.getFullYear().toString()) : date.getFullYear().toString().length === 2 ? ('00' + date.getFullYear().toString()) : date.getFullYear().toString().length === 1 ? ('000' + date.getFullYear().toString()) : '0000';
      const month = (date.getMonth() + 1).toString().length === 2 ? (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString().length === 1 ? ('0' + (date.getMonth() + 1).toString()) : '00'
      const dateString = date.getDate().toString().length === 2 ? date.getDate().toString() : date.getDate().toString().length === 1 ? ('0' + date.getDate().toString()) : '00'
      let dateStr = year + '-' + month + '-' + dateString;
      const age = calculateAge(value);
      if (age <= 0) {
        setInValid({ ...inValid, dob: true, age: true });
        const data = { ...seniorDetails, dob: '' }
        setSeniorDetails(data)
        return;
      }
      setInValid({ ...inValid, dob: false, age: false })
      const data = { ...seniorDetails, [key]: dateStr, age };
      setSeniorDetails({ ...data });
    } else if (key === 'zipcode') {
      setInValid({ ...inValid, zipcode: false })
      const data = { ...seniorDetails, [key]: value };
      setSeniorDetails({ ...data });
    } else if (key === 'room_no') {
      if (isRoom(value)) {
        const data = { ...seniorDetails, [key]: value };
        setSeniorDetails({ ...data });
        setInValid({ ...inValid, [key]: false })
      }
    } else {
      setInValid({ ...inValid, [key]: false })
      const data = { ...seniorDetails, [key]: value };
      setSeniorDetails({ ...data });
    }
  }

  const uploadFile = (e) => {
    const file = e.target.files[0];
    if (file.type.startsWith("image") && file.type !== "image/gif") {
      setFile(file);
      setFileName(e.target.value);
      setProfileImgUrl(URL.createObjectURL(file));
      setUploaded(true);
    } else {
      snackbarNotification(Notification.invalidProfile)
    }
  }

  const mobileValidator = (value) => {
    if (value.length > 0 && value.length <= 10)
      setInValid({ ...inValid, mobile: !isPhonenoValid(value) });
    else setInValid({ ...inValid, mobile: false });
  }


  const emailValidator = (e) => {
    const value = e.target.value;
    if (value.length > 0)
      setInValid({ ...inValid, email: !isEmailValid(value) });
    else setInValid({ ...inValid, email: false });
  }

  const validate = () => {
    let invalidFields = [];
    const newInValid = {};
    mandatoryInputFields.forEach(item => {
      if (seniorDetails[item] === '' || seniorDetails[item] === null || seniorDetails[item] === undefined) {
        invalidFields.push(item)
        newInValid[item] = true;
      }
    })

    if (invalidFields?.length) {
      setInValid({ ...inValid, ...newInValid });
    } else {
      for (let key in inValid) {
        if (inValid[key]) {
          return;
        }
      }
      addSenior();
    }

  }

  const snackbarNotification = (data) => {
    setSnackBarContent(data)
    setShowSnackBar(true)
  }

  const calculateAge = (dob) => {
    const today = new Date();
    const year = parseInt(today.getFullYear().toString().length === 4 ? today.getFullYear().toString() : today.getFullYear().toString().length === 3 ? ('0' + today.getFullYear().toString()) : today.getFullYear().toString().length === 2 ? ('00' + today.getFullYear().toString()) : today.getFullYear().toString().length === 1 ? ('000' + today.getFullYear().toString()) : '0000');
    const month = parseInt((today.getMonth() + 1).toString().length === 2 ? (today.getMonth() + 1).toString() : (today.getMonth() + 1).toString().length === 1 ? ('0' + (today.getMonth() + 1).toString()) : '00');
    const date = parseInt(today.getDate().toString().length === 2 ? today.getDate().toString() : today.getDate().toString().length === 1 ? ('0' + today.getDate().toString()) : '00');
    const birthDate = new Date(dob);
    let age = year - birthDate.getFullYear();
    const m = month - birthDate.getMonth();
    if (m < 0 || (m === 0 && date < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const addSenior = async () => {
    // return // need masterAccountid after that you can remove the avove code this is for testing perpous

    const formData = new FormData();
    if (uploaded)
      formData.append('profile', profileImgFile, profileImgName);
    formData.append('m_acc_id', masterAccountId);
    formData.append('height_unit', heightOptions[height].value)
    formData.append('weight_unit', weightOptions[weight].value)
    formData.append('notifications', JSON.stringify(productNotifications?.map(item => ({ ...item, timezone: getUserTimeZome(), origin: getTimeZoneOffset() }))));
    Object.keys(seniorDetails).forEach((key) => {
      formData.append(key, seniorDetails[key]);
    })
    storeHandler(actions.SHOW_LOADER, true)
    try {
      const data = await AuthServices.addSenior(formData);
      setSeniors([...seniors, data]);
      storeHandler(actions.SHOW_LOADER, false)
      addNextSenior();
    } catch (err) {
      const { data } = err;
      const { message } = data || '';
      snackbarNotification({
        message,
        class: 'error'
      })
      storeHandler(actions.SHOW_LOADER, false)
    }
  }

  const resetForm = () => {
    setSeniorDetails({ ...initialDetails });
    setMobile('');
    setCountry('');
    setFile({});
    setFileName({});
    setProfileImgUrl({});
    setProductNotifications([]);
    setUploaded(false);
  }

  const addNextSenior = () => {
    const data = {}
    data.name = seniorDetails.fname + ' ' + seniorDetails.lname;
    data.age = seniorDetails.age;
    data.sex = seniorDetails.gender;
    data.profileImg = profileImgUrl;
    snackbarNotification(Notification.addSeniorSuccess)
    resetForm();
    setSeniors([...seniors, data]);
  }

  const handleBlur = (event) => {

    if ((seniorDetails?.['country'] && seniorDetails?.['zipcode'])) {
      storeHandler(actions.SHOW_LOADER, true)
      const filteredCountry = countries.find(item => item.name === seniorDetails['country'])
      AuthServices.zipcodeGetCityState(filteredCountry?.code, seniorDetails.zipcode)
        .then(({ data }) => {
          setCity(data?.place_name !== null ? data?.place_name : "")
          setCurrentState(data?.state_name !== null ? data?.state_name : "")
          storeHandler(actions.SHOW_LOADER, false)
        }).catch((err) => {
          const { data } = err || {};
          const { message } = data || {};
          snackbarNotification({
            message,
            class: "error",
          });
          storeHandler(actions.SHOW_LOADER, false);
        })
    }

  }


  const finishAccountCreation = () => {
    setOpenNextDialog(true)
    storeHandler(actions.SHOW_LOADER, false)
  }

  const getDashboard = () => {
    storeHandler(actions.SHOW_LOADER, true)
    AuthServices.getDashboard().then(({ data }) => {
      setCountries(data.countries)
      storeHandler(actions.SHOW_LOADER, false)
    }).catch((err) => {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    })
  }

  useEffect(() => {
    getDashboard()
  }, [])

  useEffect(() => {
    const data = { ...seniorDetails, city: city, state: currentState }
    const newInValid = { ...inValid }
    if (city)
      newInValid.city = false
    if (currentState)
      newInValid.state = false
    setInValid({ ...inValid, ...newInValid })
    setSeniorDetails(data)
  }, [countries, city, currentState])


  useEffect(() => {
    handleBlur()
  }, [country, seniorDetails.zipcode]);

  const handleHeightChange = (e) => {
    setHeight(e.target.value)
  }

  const handleWeightChange = (e) => {
    setWeight(e.target.value)
  }

  const handleChangeMobile = (event) => {
    const value = event?.target?.value?.split('.')?.join('')
    if (isMobileValid(value)) {
      setMobile(mobileFormatter(value))
      addDetail('mobile', value);
    }
  }

  const removeProductNotification = (index) => {
    const notifications = productNotifications;
    notifications.splice(index, 1);
    setProductNotifications([...notifications]);
  }

  const addProductNotification = (configuration) => {
    const notifications = productNotifications;
    if (configuration?.device === 'watch') {
      const index = notifications.findIndex((item) => item.sensor === configuration.sensor)
      if (index !== -1) {
        notifications[index] = configuration;
      }
      else
        notifications.push(configuration);
    }
    else {
      const index = notifications.findIndex(item => item.device === configuration.device);
      if (index !== -1) {
        notifications[index] = configuration;
      }
      else
        notifications.push(configuration);
    }
    setProductNotifications(notifications);
  }


  return (
    <>
      <Paper sx={{ minWidth: '95%', marginBottom: '2rem' }}>
        <Typography sx={{ color: '#d8d8d8', marginBottom: '0.25rem' }}>
          {seniors?.length ? seniors?.length === 1 ? "1 Senior Added" : `${seniors?.length} Seniors Added` : "No Senior Added"}
        </Typography>
        {seniors && <Stack className="stack" direction="row" spacing={1} sx={{ width: "100%" }}>
          {seniors.map((senior, index) => {
            return (
              <SeniorInfo
                key={index}
                name={senior.name}
                age={senior.age}
                sex={senior.sex}
                image={senior.profileImg}
              />
            );
          })
          }
        </Stack>}
      </Paper>
      <Paper>
        <Typography sx={{ fontSize: '1rem', color: '#d8d8d8', marginLeft: '0.6rem', marginTop: "0.5rem" }}>
          Senior #{seniors?.length + 1} {/*of {seniorLimit}*/}
        </Typography>
        <Box
          className="allDetails"
        >
          <Grid className="grid-senior" sx={{ width: { xs: '100%', sm: '100%', md: '75%' } }} container spacing={0}>
            <Grid className="grid-senior-item" item xs={12} sm={6} md={4}>
              <Item>
                <AddSeniorInput helperText={inValid?.fname && 'Enter the first name'} id="fname" value={seniorDetails?.fname || ''} onChange={(e) => { addDetail('fname', e.target.value) }} label="First name" required />
              </Item>
            </Grid>
            <Grid className="grid-senior-item" item xs={12} sm={6} md={4}>
              <Item>
                <AddSeniorInput helperText={inValid?.lname && 'Enter the last name'} id="lname" value={seniorDetails?.lname || ''} onChange={(e) => addDetail('lname', e.target.value)} label="Last name" required />
              </Item>
            </Grid>
            <Grid className="grid-senior-item" item xs={12} sm={6} md={4}>
              <Item>
                <AddSeniorInput id="email" helperText={inValid?.email && 'Enter a valid email address'} value={seniorDetails?.email || ''} onChange={(e) => { addDetail('email', e.target.value); emailValidator(e); }} label="Email" />
              </Item>
            </Grid>
            <Grid className="grid-senior-item spcl" item xs={12} sm={6} md={4}>
              <Item>
                <SelectInputMod helperText={inValid?.gender && 'Select your gender'} value={seniorDetails?.gender || ''} onChange={(e) => addDetail('gender', e.target.value)} label="Gender" required>
                  {genderOptions?.map((option, index) => {
                    return (
                      <MenuItem key={index} value={option?.value} >
                        {option.value || ''}
                      </MenuItem>
                    );
                  })}
                </SelectInputMod>
              </Item>
            </Grid>
            <Grid className="grid-senior-item spcl" item xs={12} sm={6} md={4}>
              <Item>
                <AddSeniorInput value={mobile || ''} type="text" helperText={inValid?.mobile && 'Enter a 10-digit mobile number'} id="mobile" onChange={(e) => handleChangeMobile(e)} label="Phone Number" />
              </Item>
            </Grid>
            <Grid className="grid-senior-item spcl" item xs={12} sm={6} md={4}>
              <Item>
                <BasicDatePicker senior={true} helperText={inValid?.dob && 'Select a valid DOB'} id="dob" value={seniorDetails?.dob || ''} onChange={(newValue) => addDetail('dob', newValue)} required />
              </Item>
            </Grid>

            <Grid className="grid-senior-item spcl" item xs={12} sm={6} md={4}>
              <Item>
                <SelectInputMod helperText={inValid?.country && 'Select your country'} label="Country" required value={seniorDetails?.country || ''} onBlur={handleBlur} onChange={(e) => { addDetail('country', e.target.value) }}>
                  {
                    countries?.map((option, index) => {
                      return <MenuItem key={index} value={option?.name} >{option.name}</MenuItem>
                    })
                  }
                </SelectInputMod>
              </Item>
            </Grid>
            <Grid className="grid-senior-item" item xs={12} sm={6} md={4}>
              <Item>
                <AddSeniorInput helperText={inValid?.zipcode && 'Enter the zipcode'} id="zipcode" value={seniorDetails?.zipcode || ''} onBlur={handleBlur} onChange={(e) => { addDetail('zipcode', e.target.value) }} label="Zip Code" required />
              </Item>
            </Grid>
            <Grid className="grid-senior-item" item xs={12} sm={6} md={4}>
              <Item>
                <AddSeniorInput helperText={inValid?.address && 'Enter your address'} id="address" value={seniorDetails?.address || ''} onChange={(e) => addDetail('address', e.target.value)} label="Address" required />
              </Item>
            </Grid>
            <Grid className="grid-senior-item" item xs={12} sm={6} md={4}>
              <Item>
                <AddSeniorInput helperText={inValid?.city && 'Enter your city'} id="city" value={seniorDetails.city || ''} onChange={(e) => addDetail('city', e.target.value)} label="City" required />
              </Item>
            </Grid>
            <Grid className="grid-senior-item" item xs={12} sm={6} md={4}>
              <Item>
                <AddSeniorInput helperText={inValid?.state && 'Enter your state'} id="state" value={seniorDetails.state || ''} onChange={(e) => addDetail('state', e.target.value)} label="State" required />
              </Item>
            </Grid>
            {/* <Grid className="grid-senior-item" item xs={12} sm={6} md={4}>
              <Item>
                <SelectInputMod helperText={inValid?.watch && 'Select a watch'} label="Watch" value={watch} required onChange={handleWatchChange}>
                  {options.map((option, index) => {
                    return (
                      <MenuItem key={index} value={option?.id} >
                        {option.name || ''}
                      </MenuItem>
                    );
                  })}
                </SelectInputMod>
              </Item>
            </Grid> */}
            <Grid className="grid-senior-item" item xs={12} sm={6} md={4}>
              <Item>
                <AddSeniorInput id="height" helperText={inValid?.height && 'Enter a valid height'} height={height} handleHeightChange={handleHeightChange} field="height" endAdornment={true} value={seniorDetails?.height || ''} onChange={(e) => { addDetail('height', e.target.value) }} label="Height" />
              </Item>
            </Grid>
            {height === 0 && (
              <Grid className="grid-senior-item" item xs={12} sm={6} md={4}>
                <Item>
                  <AddSeniorInput id="inchs" helperText={inValid?.inchs && 'Enter valid inches'} field="inchs" value={seniorDetails?.inchs || ''} onChange={(e) => { addDetail('inchs', e.target.value) }} label="Inch" />
                </Item>
              </Grid>
            )}
            <Grid className="grid-senior-item" item xs={12} sm={6} md={4}>
              <Item>
                <AddSeniorInput id="weight" helperText={inValid?.weight && 'Enter a valid weight'} weight={weight} handleWeightChange={handleWeightChange} field="weight" endAdornment={true} value={seniorDetails?.weight || ''} onChange={(e) => { addDetail('weight', e.target.value) }} label="Weight" />
              </Item>
            </Grid>
            <Grid className="grid-senior-item" item xs={12} sm={6} md={4}>
              <Item>
                <SelectInputMod id="bloodgroup" value={seniorDetails?.blood_group || ''} onChange={(e) => addDetail('blood_group', e.target.value)} label="Blood Group">
                  {bloodGroupOptions?.map((option, index) => {
                    return (
                      <MenuItem key={index} value={option?.name} >
                        {option.name || ''}
                      </MenuItem>
                    );
                  })}
                </SelectInputMod>
              </Item>
            </Grid>
            <Grid className="grid-senior-item" item xs={12} sm={6} md={4}>
              <Item>
                <AddSeniorInput id="Room Number" helperText={inValid?.room_no} value={seniorDetails?.room_no || ''} onChange={(e) => addDetail('room_no', e.target.value)} label="Room Number" />
              </Item>
            </Grid>
            {/* {weight === 0 && (
              <Grid className="grid-senior-item" item xs={12} sm={6} md={3}>
                <Item>
                  <AddSeniorInput id="oz" helperText={inValid?.inchs && 'Enter a valid oz'} field="oz" value={seniorDetails?.oz || ''} onChange={(e) => { addDetail('oz', e.target.value) }} label="Oz" />
                </Item>
              </Grid>
            )
            } */}
          </Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
          >
            <Box className="parent-box">
              <Box className="parent-box-box1">
                <Box
                  sx={{
                    padding: '1rem 2rem',
                    textAlign: 'center',
                    borderRadius: '1rem',
                  }}
                >
                  {profileImgUrl?.length ?
                    <img
                      src={profileImgUrl}
                      style={{ height: '6.5rem', width: '6.5rem', borderRadius: '50%' }}
                      alt=""
                    />
                    :
                    <Avatar />
                  }
                </Box>
                <Box>
                  <Button
                    component='label'
                    sx={{
                      background: 'transparent',
                      padding: '0.5rem',
                      color: 'primary',
                      fontSize: '0.65rem',
                      borderRadius: '1rem',
                      borderTopLeftRadius: '0.5rem',
                      borderTopRightRadius: '0.5rem',
                      borderColor: 'primary.main',
                      border: '2px solid',
                      width: "101%",
                      margin: "0 -1px -3px -1px",
                      borderBottom: "0px"
                    }}

                    fullWidth
                    startIcon={<UploadIcon />}
                  >
                    Upload Image
                    <input type="file" value={seniorDetails?.profile || ''} onChange={uploadFile} hidden accept="image/png, image/jpeg, image/jpg" />
                  </Button>
                </Box>
              </Box>
              <Typography
                sx={{
                  fontSize: '.8rem',
                  color: '#d8d8d8',
                  marginBottom: '1rem',
                }}
              >
                Setup Products Notification
              </Typography>
              <Box className="box-spn" style={{ width: "min-content", minWidth: "22rem" }}>
                <Grid container spacing={0}>
                  {
                    sensorsList.map((sensor, index) => {
                      switch (sensor.name) {
                        case "PillBox": return (
                          <Grid item className='center-grid-item' xs={6} sm={6} md={2} lg={2} xl={2}>
                            <SensorButton onClick={() => setPillCENDialogOpen(true)}>
                              <PillIcon width="1rem" height="1rem" />
                            </SensorButton>
                          </Grid>
                        )
                        case "Door": return (
                          <Grid item className='center-grid-item' xs={6} sm={6} md={2} lg={2} xl={2}>
                            <SensorButton onClick={() => setDoorCENDialogOpen(true)}>
                              <DoorIcon width="1rem" height="1rem" />
                            </SensorButton>
                          </Grid>
                        )
                        case "Camera": return (
                          <Grid item className='center-grid-item' xs={6} sm={6} md={2} lg={2} xl={2}>
                            <SensorButton>
                              <CameraIcon width="1rem" height="1rem" />
                            </SensorButton>
                          </Grid>
                        )
                        case "SOS": return (
                          <Grid item className='center-grid-item' xs={6} sm={6} md={2} lg={2} xl={2}>
                            <SensorButton onClick={() => { setFallDetection(false); setSosCENDialogOpen(true) }}>
                              <SosIcon width="1rem" height="1rem" />
                            </SensorButton>
                          </Grid>
                        )
                        case "Shower": return (
                          <Grid item className='center-grid-item' xs={6} sm={6} md={2} lg={2} xl={2}>
                            <SensorButton onClick={() => setShowerCENDialogOpen(true)}>
                              <ShowerIcon width="1rem" height="1rem" />
                            </SensorButton>
                          </Grid>
                        )
                        case "FallDetection": return (
                          <Grid item className='center-grid-item' xs={6} sm={6} md={2} lg={2} xl={2}>
                            <SensorButton onClick={() => { setFallDetection(true); setSosCENDialogOpen(true) }}>
                              <FallDetectionIcon width="1rem" height="1rem" />
                            </SensorButton>
                          </Grid>
                        )
                        case "FluidConsumption": return (
                          <Grid item className='center-grid-item' xs={6} sm={6} md={2} lg={2} xl={2}>
                            <SensorButton>
                              <FluidIcon width="1rem" height="1rem" />
                            </SensorButton>
                          </Grid>
                        )
                        case "Sleep": return (
                          <Grid item className='center-grid-item' xs={6} sm={6} md={2} lg={2} xl={2}>
                            <SensorButton>
                              <SleepIcon width="1rem" height="1rem" />
                            </SensorButton>
                          </Grid>
                        )
                        case "SleepBelt": return (
                          <Grid item className='center-grid-item' xs={6} sm={6} md={2} lg={2} xl={2}>
                            <SensorButton onClick={() => { setSleepbeltBed(true); setSleepbeltCENDialogOpen(true) }}>
                              <BeltIcon width="1rem" height="1rem" />
                            </SensorButton>
                          </Grid>
                        )
                        case "SleepBelt-Chair": return (
                          <Grid item className='center-grid-item' xs={6} sm={6} md={2} lg={2} xl={2}>
                            <SensorButton onClick={() => { setSleepbeltBed(false); setSleepbeltCENDialogOpen(true) }}>
                              <CouchBeltIcon width="1rem" height="1rem" />
                            </SensorButton>
                          </Grid>
                        )
                        case "BathRoom": return (
                          <Grid item className='center-grid-item' xs={6} sm={6} md={2} lg={2} xl={2}>
                            <SensorButton onClick={() => { setBathRoomCENDialogueOpen(true) }}>
                              <BathIcon width="1rem" height="1rem" />
                            </SensorButton>
                          </Grid>
                        )
                        case "GPSWatch": return (
                          <Grid item className='center-grid-item' xs={6} sm={6} md={2} lg={2} xl={2}>
                            <SensorButton>
                              <GpsIcon width="1rem" height="1rem" />
                            </SensorButton>
                          </Grid>
                        )
                        case "watch": return (
                          <Grid item className='center-grid-item' xs={6} sm={6} md={2} lg={2} xl={2}>
                            <SensorButton onClick={() => setWatchCENDialogOpen(true)}>
                              <WatchIcon className='green' width="1rem" height="1rem" />
                            </SensorButton>
                          </Grid>
                        )
                        default: return <></>;
                      }
                    })
                  }
                </Grid>
              </Box>
              <Box>
                <Button sx={{ width: '17rem' }} onClick={() => setShowConfiguredNotifications(true)}>
                  <Typography
                    variant='outlined'
                    sx={{
                      fontSize: '.8rem',
                      color: '#d8d8d8',
                      fontWeight: '500',
                      outline: '2px solid #00B89F',
                      padding: '0.5rem 1rem',
                      borderRadius: '1rem',
                      '&:hover': {
                        color: '#00B89F',
                      }
                    }}
                  >
                    View Configured Notifications
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", sm: "75%", md: "50%" },
            margin: '0 auto',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'column', md: 'row' }
          }}
        >
          <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              color: '#fff',
              marginTop: '2.5rem',
              width: "100%"
            }}
            onClick={validate}
            startIcon={<TickIcon />}
          >
            Add Senior
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={disabled}
            className={disabled ? 'disabled' : 'enabled'}
            sx={{
              color: '#fff',
              marginTop: '2.5rem',
              marginLeft: { xs: '0', sm: '0', md: '2rem' }
            }}
            onClick={() => finishAccountCreation()}
            startIcon={<TickIcon />}
          >
            Next
          </Button>
        </Box>
        <AlertDialog
          open={openNextDialog}
          text="Done adding All Seniors, move to next step?"
          handleClose={() => setOpenNextDialog(false)}
          handleAccept={changeTab}
        />
        <ConfiguredNotifications open={showConfiguredNotifications} handleClose={setShowConfiguredNotifications} notifications={productNotifications} deleteConfig={removeProductNotification} />
        <PillboxNotifications saveConfig={addProductNotification} open={pillCENDialogOpen} handleClose={() => setPillCENDialogOpen(false)} />
        <DoorNotifications saveConfig={addProductNotification} open={doorCENDialogOpen} handleClose={() => setDoorCENDialogOpen(false)} />
        <WatchNotifications saveConfig={addProductNotification} open={watchCENDialogOpen} handleClose={() => setWatchCENDialogOpen(false)} />
        <ShowerNotification saveConfig={addProductNotification} open={showerCENDialogOpen} handleClose={() => setShowerCENDialogOpen(false)} />
        {sosCENDialogOpen && <SosNotification saveConfig={addProductNotification} fallDetection={fallDetection} open={sosCENDialogOpen} handleClose={() => setSosCENDialogOpen(false)} />}
        {sleepbeltCENDialogOpen && <SleepbeltNotification saveConfig={addProductNotification} sleepbeltBed={sleepbeltBed} open={sleepbeltCENDialogOpen} handleClose={() => setSleepbeltCENDialogOpen(false)} />}
        <BathRoomNotification saveConfig={addProductNotification} open={bathRoomCENDialogOpen} handleClose={() => { setBathRoomCENDialogueOpen(false) }} />
        <Snackbar open={showSnackBar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          message={snackbarContent?.message || ''}
          autoHideDuration={4000}
          onClose={() => setShowSnackBar(false)}
          sx={{
            '.MuiSnackbarContent-root': {
              color: '#fff',
              fontWeight: '400',
              backgroundColor: snackbarContent.class === 'error' ? 'red' : (snackbarContent.class === 'warning' ? '#ffcc00' : '#00b89f'),
              display: 'flex',
              justifyContent: 'center',
              borderRadius: '10rem'
            }
          }}
        />
      </Paper>
    </>
  );
};

export default CreAccStep2;
