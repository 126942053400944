import {
  FormControlLabel,
  styled,
  Typography,
  Button,
  Box,
} from "@mui/material";

export const ExtraInfo = styled(Typography)({
  color: "#6C7175",
  fontSize: "0.6rem",
  marginBottom: "3rem",
});
export const TransparentBtn = styled(Button)({
  borderRadius: "2rem",
  marginTop: "1.5rem",
  marginBottom: "0.5rem",
  fontSize: "1rem",
  borderColor: "inherit",
  fontWeight: "300",
  flexBasis: "22.5%",
  padding: "0.75rem 0",
  borderWidth: "3px",
  fontWeight: 'bolder',
  '&:hover': {
    borderWidth: "3px",
  }
});
export const RadioLabel = styled(FormControlLabel)({
  color: "#00B89F",
});
export const MemberTag = styled(Box)({
  border: "2px solid",
  borderColor: "#14B498",
  padding: "0rem 1rem 0rem 0",
  borderRadius: "1.5rem",
  display: "flex",
  alignItems: "center",
});
export const MemberName = styled(Typography)({
  fontSize: "0.7rem",
  flex: 1,
  marginLeft: "0.5rem",
  color: "#D8D8D8",
});
export const IconDisplay = styled(Box)({
  backgroundColor: "#2F363E",
  borderRadius: "0.5rem",
  boxShadow: "0 0 4px 3px rgba(0,0,0,0.29)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "1rem",
  marginBottom: '0.75rem !important',
  width: "3.5rem",
  cursor: "pointer",
});
export const TabBtn = styled(Button)({
  backgroundColor: "transparent",
  border: "0 solid",
  fontSize: "1rem",
  color: "#6C7175",
  fontWeight: "300",
  flexBasis: "30%",
  marginBottom: "0",
});
export const ActiveTabBtn = styled(Button)({
  backgroundColor: "#00B89F",
  border: "0 solid",
  fontSize: "1rem",
  color: "#D8D8D8",
  fontWeight: "300",
  flexBasis: "30%",
  marginBottom: "0",
});
