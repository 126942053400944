import { InputAdornment, MenuItem, TextField } from '@mui/material';
import { Box } from '@mui/material';
import SelectInputMod from 'app/create-account/Components/select-input-mod/SelectInputMod';
import React, { useState } from 'react';
import styles from './add-senior-input.module.scss';
import './AddSeniorInput.scss';

const AddSeniorInput = (props) => {
  const { helperText, endAdornment, startAdornment, field, handleWeightChange, handleHeightChange, height, weight, onKeyDown, ...rest } = props;
  const weightValues = [
    {
      id: 0,
      value: "lbs"
    },
    {
      id: 1,
      value: "kg"
    }
  ]
  const heightValues = [
    {
      id: 0,
      value: "ft"
    },
    {
      id: 1,
      value: "cm"
    },
  ]

  return (
    <>
      <TextField
        className="add-senior-input"
        size='small'
        fullWidth
        variant='filled'
        onKeyDown={onKeyDown}
        // error={typeof props.helperText === 'string' ? true : false}
        placeholder={props.placeholder}
        {...rest}
        type={props.type}
        InputProps={{
          disableUnderline: true,
          startAdornment: startAdornment && <InputAdornment position="start" ><Box>$</Box></InputAdornment>,
          endAdornment: endAdornment && <InputAdornment className="input-adornment" position="end">
            {field === "weight" ?
              <SelectInputMod value={weightValues[weight].id} onChange={handleWeightChange}>
                {
                  weightValues?.map((option, index) => {
                    return <MenuItem key={index} value={option?.id} >{option.value}</MenuItem>
                  })
                }
              </SelectInputMod> :
              <SelectInputMod value={heightValues[height]?.id} onChange={handleHeightChange}>
                {
                  heightValues?.map((option, index) => {
                    return <MenuItem key={index} value={option?.id} >{option.value}</MenuItem>
                  })
                }
              </SelectInputMod>
            }
          </InputAdornment>
        }}
        sx={{ marginBottom: '0rem' }}
      />
      <Box>
        <label style={{ fontSize: '0.65rem', fontWeight: '500', color: 'red', padding: '2px 0 0 0px',whiteSpace:'nowrap' }}>{props.helperText}</label>
      </Box>
    </>
  )
}

export default AddSeniorInput;
