import { Paper, Stack, Typography } from '@mui/material';
import { Box } from '@mui/material';
import SelectMenuFilled from 'components/select-menu-filled/SelectMenuFilled';
import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import "./ReportsBarChart.scss";

const types = ['Accounts', 'Users', 'Sensors'];
const ReportsChart = ({ type, setType, title, options, data, sensorOptions, categoryOptions, contractOptions,productOptions, activeOptions, platformOptions,contractType, productPlanType, setProductPlanType, activeType, setActiveType, platformType, setPlatformType, sensorType, setContractSelected,setSensorType, categoryType, setCategoryType, userRolesOptions, userRole, setUserRole }) => {
  const [maxValue, setMaxValue] = useState(0);
  const [threshold, setThreshold] = useState(0);
  const [sensorSelected, setSensorSelected] = useState("Door");

  const getThreshold = () => {
    Object.keys(data).forEach((key) => {
      let str = data[key]?.val?.toString().trim();
      let num = str === "" ? NaN : +str;
      if(num > maxValue) {
        setMaxValue(data[key].val);
      }
    })
  }

  const sensorNameApi = (value) => {
    let sensorName;
    switch (value) {
      case "PillBox":
        sensorName = "PillBox"
        break;
      case "BathRoom":
        sensorName = "BathRoom";
        break;
      case "Urgent Band":
        sensorName = "SOS";
        break;
      case "Health Watch":
        sensorName = "watch";
        break;
      case "Fall Detection":
        sensorName = "FallDetection";
        break;
      case "Sleep Belt-Bed":
        sensorName = "SleepBelt";
        break;
      case "GPS Watch":
        sensorName = "GPSWatch"; 
        break;       
      case "Sleep Belt-Chair/Couch":
        sensorName = "SleepBelt-Chair"; break;
      default: sensorName = value;
    }
    setSensorType(sensorName);
  }

  useEffect(() => {
    getThreshold();
    setThreshold(Math.max(200, ((Math.ceil(maxValue/100)) * 100)))
  }, [data, maxValue]);

  useEffect(() => {
    
  switch (sensorType) {
    case "PillBox":
      setSensorSelected("PillBox");
      break;
    case "BathRoom":
      setSensorSelected("BathRoom");
      break;
    case "SOS":
      setSensorSelected("Urgent Band");
      break;
    case "watch":
      setSensorSelected("Health Watch");
      break;
    case "FallDetection":
      setSensorSelected("Fall Detection");
      break;
    case "SleepBelt":
        setSensorSelected("Sleep Belt-Bed");
      break;
    case "GPSWatch":
      setSensorSelected("GPS Watch");
      break;
    case "SleepBelt-Chair":
      setSensorSelected("Sleep Belt-Chair/Couch");
      break;
    default: setSensorSelected(sensorType);
  }
  },[sensorType])

  return (
    <>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Stack className="stack-reports" direction="column" justifyContent="center" alignItems="flex-start">
          <Typography color="#D0D1D1">
            {title}
          </Typography>
          <Stack sx={{
            display: 'flex', 
            flexDirection: 'column',
            padding: '0',
            backgroundColor: '#303B47',
            borderRadius: '10px',
            marginBottom: '15px',
            width: '100%',
          }}>
            <Stack
              direction='row'
              spacing={2}
              sx={{
                '& .MuiFormControl-root': {
                  width: '100px',
                  height: '40px',
                  backgroundColor: 'white',
                },
                // position: 'absolute',
                zIndex: '1',
                padding: '10px 0 0 40px',
                margin: '0px 0 -80px 10px',
                width: '100%',
              }}
              className="stack-dropdown-container"
            >
              <SelectMenuFilled
                value={type}
                options={options}
                onChange={(e) => {
                  setType(e.target.value)
                }}
                where="Reports"
              />
              {
                productOptions && (
                  <SelectMenuFilled
                    value={productPlanType}
                    options={productOptions}
                    onChange={(e) => {
                      setProductPlanType(e.target.value)
                    }}
                    where="Reports"
                  />
                )
              }
              {
                userRolesOptions && (
                  <SelectMenuFilled
                    value={userRole}
                    options={userRolesOptions}
                    onChange={(e) => {
                      setUserRole(e.target.value)
                    }}
                    where="Reports"
                  />
                )
              }
              {
                activeOptions && (
                  <SelectMenuFilled
                    value={activeType}
                    options={activeOptions}
                    onChange={(e) => {
                      setActiveType(e.target.value)
                    }}
                    where="Reports"
                  />
                )
              }
              {
                platformOptions && (
                  <SelectMenuFilled
                    value={platformType}
                    options={platformOptions}
                    onChange={(e) => {
                      setPlatformType(e.target.value)
                    }}
                    where="Reports"
                  />
                )
              }
                            {
                contractOptions && (
                  <SelectMenuFilled
                    value={contractType}
                    options={contractOptions}
                    onChange={(e) => {
                      setContractSelected(e.target.value)
                    }}
                    where="Reports"
                  />
                )
              }
              {
                sensorOptions && ( 
                  <SelectMenuFilled
                    value={sensorSelected}
                    options={sensorOptions}
                    onChange={(e) => {
                      // setSensorType(e.target.value)
                      sensorNameApi(e.target.value)
                    }}
                    where="Reports"
                  />
                )
              }
              {
                categoryOptions && (
                  <SelectMenuFilled
                    value={categoryType}
                    options={categoryOptions}
                    onChange={(e) => {
                      setCategoryType(e.target.value)
                    }}
                    where="Reports"
                  />
                )
              }
            </Stack>
            <ResponsiveContainer height={300} width="100%">
              <BarChart
                data={data}
                width= '100px'
                margin={{ top: 75, right: 30, left: -16, bottom: 5 }}
                padding={{ left: 0 }}
              >
                <Bar dataKey="val" fill="#00B89F" maxBarSize={13} />
                <XAxis
                  dataKey="month"
                  tick={{ fill: '#00B89F' }}
                  tickLine={false}
                  tickCount={12}
                  interval={0}
                  fontSize={8}
                  margin={{ top: 100, bottom: 0, left: 0, right: 0 }}
                />
                <YAxis
                  fontSize={8}
                  tick={{ fill: '#00B89F' }}
                  padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
                  domain={[0, threshold]}
                  tickLine={false}
                  margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
                />
                    <Tooltip wrapperStyle={{ width: 120, backgroundColor: '#303B47', borderRadius: '0.8rem'}} />
              </BarChart>
            </ResponsiveContainer>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}

export default ReportsChart;
