import { Box, Button, Stack, Grid, Snackbar, Typography, Menu, MenuItem, Checkbox, ListItemText } from '@mui/material';
import AuthServices from 'api/services/auth-services';
import DashHeader from 'components/dash-header/DashHeader';
import DeviceInfoCard from 'app/nhub/components/nhub-device-info-card/DeviceInfoCard';
import { actions, GlobalContext } from 'context';
import React, { useContext, useEffect, useState } from 'react';
import "./Nhub.scss";
import { ReactComponent as FilterIcon } from 'assets/img/filter_icon.svg';


const Nhub = () => {
  const { dispatch, state } = useContext(GlobalContext);
  const [devices, setDevices] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('Show All');
  const [devicesDataCopy, setdevicesDataCopy] = useState([]);
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'success' })
  const [openFilterType, setOpenFilterType] = useState(false);


  const snackbarNotification = (data) => {
    setSnackBarContent(data)
    setShowSnackBar(true)
  }

  const storeHandler = (type, payload) => dispatch({ type, payload });

  useEffect(() => {
    getNhubData();
  }, [])

  const handleToggle = (filter) => {  
    setSelectedFilter(filter)
    if (filter === 'Virtual Nhub'){
      let newFilter = devices?.filter(el => {
        if (el?.device?.virtual === true) {
          return el
        }
      })
      setdevicesDataCopy(newFilter)
    }else if(filter === 'Physical Nhub'){
      let newFilter = devices?.filter(el => {
        if (el?.device?.virtual === false) {
          return el
        }
      })
      setdevicesDataCopy(newFilter)
    }else{
      setdevicesDataCopy(devices)
    }
    setOpenFilterType(false)
  }
  


  const handleFilterClick = (e) => {
    setOpenFilterType(e.currentTarget);
  };

  const handleFilterClose = () => {
    setOpenFilterType(false);
  };

  const getNhubData = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      let count = 0;
      const { data } = await AuthServices.getNhubDevices();
      data?.forEach((ele) => {
        ele?.device?.active === true && count++;
      })
      setDevices(data);
      setdevicesDataCopy(data)
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    }
  }

  const handleChange = (event, id) => {
    storeHandler(actions.SHOW_LOADER, true);
    AuthServices.deleteNhubDevice(event).then(({ message }) => {
      getNhubData()
      snackbarNotification({
        message,
        class: "success",
      })
      storeHandler(actions.SHOW_LOADER, false);
    }).catch((err) => {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    })
  }

  const checkMatch = (search, data) => {
    let start = 0;
    if (data.includes(search))
      return true;
    while (start < search.length) {
      let ind = data.indexOf(' ', start);
      if (ind === -1) {
        return false;
      }
      if (data.substring(ind + 1).includes(search)) {
        return true;
      }
      start = ind + 1;
    }
    return false;
  }

  const search = (searchString) => {

    let newFilter = devices?.filter(el => {

      if (el?.seniors?.length > 0) {

        let Filtereddata = el.seniors.filter(userData => {
          const name = userData.name.toLowerCase();
          const room_no = userData?.room_no?.toLowerCase();
          const searchStringLower = searchString.toLowerCase();
          return checkMatch(searchStringLower, name)  || checkMatch(searchStringLower, room_no || '')|| el.device.nhubs_id.toLowerCase().startsWith(searchStringLower);
        })
        if (Filtereddata.length > 0) {
          return el
        }

      }
    })
    setdevicesDataCopy(newFilter);

  }
  const physicalNhub = 'Physical Nhub';
  const VitualNhub = 'Virtual Nhub';
  const Showall = 'Show All';

  const accountsCategory = [physicalNhub, VitualNhub, Showall]

  return (
    <>
      <DashHeader
        tab="nhub"
        deviceName=""
        pageTitle="Nhub"
        searchBar={true}
        searchFn={search}
      />
      <Box sx={{ padding: '1rem', marginInline: 'auto' }}>
        <Stack direction="row-reverse">
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Button
              startIcon={<FilterIcon />}
              sx={{ color: '#d8d8d8', fontSize: '0.7rem' }}
              className='btn-accounts'
              onClick={handleFilterClick}
            >
              Filter
            </Button>
            <Menu
              anchorEl={openFilterType}
              keepMounted
              multiple
              open={Boolean(openFilterType)}
              disableRipple={true}
              onClose={handleFilterClose}
              anchorOrigin={{ vertical: 'bottom' }}
            >
              {accountsCategory.map((filter, index) => (
                <MenuItem
                  key={filter}
                  onClick={() => handleToggle(filter)}
                  selected={selectedFilter.includes(filter)}
                >
                  <ListItemText primary={filter} />
                </MenuItem>
              ))}
            </Menu>
          </Stack>
        </Stack>
      </Box>

      <div className='nhub-scroll'>
        {devicesDataCopy?.length > 0 ? <Grid container
          spacing={{ xs: 3, sm: 2, md: 7, lg: 3 }}
          direction="row"
          maxWidth="1800px"
          padding='1rem'
          margin="1rem auto"
        >

          {devicesDataCopy.map((ele, index) => {
                        return ele.device.active && (
              <Grid item xs={11} md={6} lg={3}>
                <DeviceInfoCard
                  // senior={ele?.seniors?.name}
                  key={index}
                  id={ele.device.id}
                  nhubs_id={ele.device.nhubs_id}
                  master_acc_id={ele.mas_acc_id}
                  status={ele.device.status}
                  active={ele.device.active}
                  vpn_update_date={ele.device.vpn_update_date}
                  handleChange={handleChange}
                  featureCounts={ele.sensors}
                  members={ele.seniors}
                  vpn_url={ele?.device?.vpn_url}
                  wifi_strength={ele?.device?.wifi_strength}
                  network_type={ele?.device?.network_type}
                  virtual={ele?.device?.virtual}
                  room_no={ele?.seniors[0]?.room_no}
                  external_wifi={ele?.device?.external_wifi}
                />
              </Grid>
            );
          }
          )}
        </Grid> :
          <Box className="box-nhub">
            <Typography className="text-green">No devices found</Typography>
          </Box>}
      </div>

      <Snackbar open={showSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={snackbarContent?.message || ''}
        autoHideDuration={4000}
        onClose={() => setShowSnackBar(false)}
        sx={{
          '.MuiSnackbarContent-root': {
            color: '#fff',
            fontWeight: '400',
            backgroundColor: snackbarContent.class === 'error' ? 'red' : (snackbarContent.class === 'warning' ? '#ffcc00' : '#00b89f'),
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '10rem'
          }
        }}

      />

    </>
  );
};

export default Nhub;
