import { Autocomplete, Box, Snackbar, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import AuthServices from 'api/services/auth-services';
import ActiveAccount from 'app/dashboard/Components/active-accounts/ActiveAccount';
import { TextFieldEl } from 'components/select-menu-outlined/SelectMenuOutlined';
import { actions, GlobalContext } from 'context';
import React, { useContext, useEffect, useState } from 'react';
import "./ActiveAccountsContainer.scss";

const ActiveAccounts = ({countries}) => {

  const { dispatch, state } = useContext(GlobalContext);
  const { dashboardData: { accounts, active } } = state || {}
  const [city, setCity] = useState(null);
  const [cities, setCities] = useState([]);
  const [statesDetailed, setStatesDetailed] = useState({})
  const [currentState, setCurrentState] = useState(null);
  const [states, setStates] = useState([]);
  const [country, setCountry] = useState(null);
  const [categoryValues, setCategoryValues] = useState();
  const [activeValue, setActiveValue] = useState();
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'success' })
  
  const [countryList, setCountryList] = useState([])
  const storeHandler = (type, payload) => dispatch({ type, payload })

  const snackbarNotification = (data) => {
    setSnackBarContent(data)
    setShowSnackBar(true)
  }

  const { Independent, assisted, ccrc, care_home, memory, nursing, personal, retirement,"55_housing" : housing, home_healthcare,snf,ccrc_assisted,ccrc_independent,ccrc_memory_care,ccrc_snf,home_care} = accounts || {};

  const filterActiveAccounts = () => {
    let countryName;
    for (let ctr in countries) {
      if (countries[ctr]?.country?.includes(country?.name)) {
        countryName = country?.name;
        break;
      }
    }
    storeHandler(actions.SHOW_LOADER, true);
    AuthServices.filterActiveAccounts(countryName, currentState, city).then(({ data }) => {
      setActiveValue(data?.active)
      setCategoryValues(data?.accounts)
      storeHandler(actions.SHOW_LOADER, false);
    }).catch((err) => {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    })
  }

  useEffect(() => {
    if (city && currentState && country) {
      filterActiveAccounts()
    }
    else {
      setActiveValue('');
      setCategoryValues({})
    }
  }, [city, currentState, country])

  // const getCityState = async (country) => {
  //   storeHandler(actions.SHOW_LOADER, true);
  //   try {
  //     const { data } = await AuthServices.getCityState(country.code);
  //     setStates(data.state);
  //     setStatesDetailed(data.city);
  //     storeHandler(actions.SHOW_LOADER, false);
  //   } catch (err) {
  //     const { data } = err || {};
  //     const { message } = data || {};
  //     snackbarNotification({
  //       message,
  //       class: "error",
  //     });
  //     storeHandler(actions.SHOW_LOADER, false);
  //   }
  // }

  const getCityState =  ({name: country}) => {

    const {countriesData} = countries || {}
    const {state, city} = countriesData || {}

    const statesList = state?.[country]?.length ? [...state?.[country]] : [];
    let citiesList = {};
    statesList.forEach(item => {
      if(city?.[item]) {
        citiesList[item] = city?.[item]
      }
    } )
    setStates([...statesList]);
      setStatesDetailed({...citiesList});
  }

  useEffect(() => {
    setStates([])
    setCurrentState("")
    setCities([])
    setCity("")
    if (country) {
      getCityState(country)
    }
  }, [country])

  useEffect(() => {
    setCities(statesDetailed[currentState])
  }, [currentState])

  useEffect(() => {
    const {countriesData} = countries || {}
    const {country: countriesList} = countriesData || {}
    if(countriesList?.length) {
      const data = countriesList.map(item => ({code: item, name: item}))
      setCountryList([...data])
    }
  }, [countries])

  return (
    <Box
      className="active-accounts"
      sx={{
        backgroundImage: 'linear-gradient(to right, #13b295,#06787B)',
        display: 'flex',
      }}
    >
      <Box className="active-accounts-box">
        <Stack
          className="active-accounts-stack acc"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box className="active-accounts-stack-box" flex={1}>
            <Typography m={0} sx={{ color: '#d8d8d8' }}>
              Active Accounts
            </Typography>
            <Typography m={0} sx={{ fontSize: '2.5rem', color: 'white' }}>
              {activeValue !== '' ? activeValue : (active || '0')}
            </Typography>
          </Box>
          <Stack
            className="active-accounts-stack-stack"
            sx={{
              '& .MuiTextField-root': {
                background: 'transparent',
              },
              display: { },
              width: { md: '28rem' },
            }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box className="active-accounts-stack-stack-box">
              <Autocomplete
                id="select2"
                size="small"
                options={countryList || []}
                autoHighlight
                value={country}
                onChange={(e, newValue) => {
                   setCountry(newValue)
                   }}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {/* <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    /> */}
                    {option.label} {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextFieldEl
                    {...params}
                    label="Country"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </Box>
            <Box className="active-accounts-stack-stack-box box2">
              <Autocomplete
                size='small'
                id="select1"
                options={states || []}
                autoHighlight
                getOptionLabel={(option) => option}
                value={currentState}
                onChange={(e, newValue) => {
                  setCities(statesDetailed[newValue])
                  setCurrentState(newValue)
                }}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextFieldEl
                    {...params}
                    label="State"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </Box>
            <Box className="active-accounts-stack-stack-box">
              <Autocomplete
                id="select"
                options={cities || []}
                autoHighlight
                size='small'
                value={city}
                getOptionLabel={(option) => option}
                onChange={(e, newValue) => {
                  setCity(newValue)
                }}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextFieldEl
                    {...params}
                    label="City"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'off', // disable autocomplete and autofill
                    }}
                  />
                )}
              /> 
            </Box>
          </Stack>
        </Stack>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >

          <Grid container rowSpacing={2} columnSpacing={4}>
          <Grid item xs={6} md={4} lg={3}>
              <ActiveAccount title="Personal Home" number={typeof categoryValues?.personal === 'number' ? categoryValues.personal : personal || 0} />
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <ActiveAccount title="55+ Housing" number={typeof categoryValues?.['55_housing'] === 'number' ? categoryValues?.['55_housing'] : housing || 0} />
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <ActiveAccount title="Independent Living" number={typeof categoryValues?.Independent === 'number' ? categoryValues.Independent : Independent || 0} />
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <ActiveAccount title="Assisted Living" number={typeof categoryValues?.assisted === 'number' ? categoryValues.assisted : (assisted || 0)} />
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <ActiveAccount title="Care Home" number={typeof categoryValues?.care_home === 'number' ? categoryValues.care_home :  care_home || 0} />
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <ActiveAccount title="Memory Care" number={typeof categoryValues?.memory === 'number' ? categoryValues.memory : memory || 0} />
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <ActiveAccount title="Nursing Home" number={typeof categoryValues?.nursing === 'number' ? categoryValues.nursing : nursing || 0} />
            </Grid>
            
             <Grid item xs={6} md={4} lg={3}>
              <ActiveAccount title="Home Healthcare" number={typeof categoryValues?.home_healthcare === 'number' ? categoryValues.home_healthcare : home_healthcare || 0} />
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <ActiveAccount title="SNF" number={typeof categoryValues?.snf === 'number' ? categoryValues.snf : snf || 0} />
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <ActiveAccount title="Hospice" number={typeof categoryValues?.retirement === 'number' ? categoryValues.retirement : retirement || 0} />
            </Grid>

            <Grid item xs={6} md={4} lg={3}>
              <ActiveAccount title="Home Care" number={typeof categoryValues?.home_care === 'number' ? categoryValues.home_care : home_care || 0} />
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <ActiveAccount title="CCRC - Assisted Living" number={typeof categoryValues?.ccrc_assisted === 'number' ? categoryValues.ccrc_assisted : ccrc_assisted || 0} />
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <ActiveAccount title="CCRC - Memory Care" number={typeof categoryValues?.ccrc_memory_care === 'number' ? categoryValues.ccrc_memory_care : ccrc_memory_care || 0} />
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <ActiveAccount title="CCRC - SNF" number={typeof categoryValues?.ccrc_snf === 'number' ? categoryValues.ccrc_snf : ccrc_snf || 0} />
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <ActiveAccount title="CCRC - Independent Living" number={typeof categoryValues?.ccrc_independent === 'number' ? categoryValues.ccrc_independent : ccrc_independent || 0} />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Snackbar open={showSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={snackbarContent?.message || ''}
        autoHideDuration={4000}
        onClose={() => setShowSnackBar(false)}
        sx={{
          '.MuiSnackbarContent-root': {
            color: '#fff',
            fontWeight: '400',
            backgroundColor: snackbarContent.class === 'error' ? 'red' : (snackbarContent.class === 'warning' ? '#ffcc00' : '#00b89f'),
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '10rem'
          }
        }}
      />
    </Box>
  );
};

export default ActiveAccounts;
