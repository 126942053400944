import {
  LOG_IN,
  SHOW_LOADER,
  ACCOUNTS_DATA,
  SHOW_NAV,
  DASHBOARD_DATA,
  CONTRACT_DATA,
  MASTER_ACCOUNT_ID,
  PLAN_ID,
  CATEGORY_ID,
  USER_PROFILE,
  USER_IMAGE,
  USERS_DATA,
  DURATION,
  MAINTENANCE
} from './actions';

const initialState = {
  isLoggedIn: null,
  showLoader: false,
  accountsData: [],
  dashboardData: {},
  userProfile: {},
  userImage: '',
  contractData: {},
  showNav: false,
  masterAccountId: {},
  planId: '',
  categoryId: '',
  duration: '',
  maintenance: false,
};
const reducer = (state, { type, payload }) => {
  switch (type) {
    case LOG_IN: {
      return { ...state, isLoggedIn: payload };
    }
    case SHOW_LOADER: {
      return { ...state, showLoader: payload };
    }
    case ACCOUNTS_DATA: {
      return { ...state, accountsData: [...payload] };
    }
    case USERS_DATA: {
      return { ...state, usersData: { ...payload } }
    }
    case USER_PROFILE: {
      return { ...state, userProfile: { ...payload } };
    }
    case USER_IMAGE: {
      return { ...state, userImage: payload };
    }
    case DASHBOARD_DATA: {
      return { ...state, dashboardData: { ...payload } };
    }
    case CONTRACT_DATA: {
      return { ...state, contractData: { ...payload } };
    }
    case MASTER_ACCOUNT_ID: {
      return { ...state, masterAccountId: payload };
    }
    case PLAN_ID: {
      return { ...state, planId: payload };
    }
    case CATEGORY_ID: {
      return { ...state, categoryId: payload };
    }
    case SHOW_NAV: {
      return { ...state, showNav: payload };
    }
    case DURATION: {
      return { ...state, duration: payload };
    }
    case MAINTENANCE: {
      return { ...state, maintenance: payload };
    }
    default:
      throw new Error();
  }
};
export { reducer, initialState };
