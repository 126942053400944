import { Box, Button, Dialog, Typography } from '@mui/material';
import { CustomDialogTitle } from 'components/dialogs/custom-dialog-title/CustomDialogTitle';
import './ConnectToNHUBDialog.scss'

const ConnectToNHUBDialog = ({ open, handleClose }) => {
    return (
        <div className='dialog-div-nhub'>
            <Dialog
                open={open}
                onClose={() => handleClose(false)}
                BackdropProps={{ style: { backgroundColor: 'black', opacity: "0.9" } }}
                className="nhub-dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box
                    sx={{
                        color: '#D8D8D8',
                        margin: '0 2rem',
                        marginTop: '0.5rem',
                        justifyContent: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <CustomDialogTitle onClose nhub={true}>Delete Senior</CustomDialogTitle>
                    <Typography className='dialog-text'>
                        Account cannot be deleted, this account is still active in the NHub and devices are connected to this account. To delete this account, please delete the account from the active NHub.
                    </Typography>
                    <Box className="flex-row box-btns-dialog">
                        <Button
                            variant="outlined"
                            sx={{
                                fontSize: "0.8rem",
                                padding: "0.2rem, 0.5rem",
                                margin: "1rem 1rem 1rem 0",
                                width: '50%'
                            }}
                            fullWidth
                            onClick={() => handleClose(true)}
                        >
                            Go to NHub
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                fontSize: "0.8rem",
                                padding: "0.2rem, 0.5rem",
                                margin: "1rem 0",
                                width: '50%'
                            }}
                            fullWidth
                            onClick={() => handleClose(false)}
                        >
                            OK
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </div>
    );
}

export default ConnectToNHUBDialog