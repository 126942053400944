import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Typography } from '@mui/material';
import {useState} from 'react';
import moment from "moment/moment";
const QontoConnector = styled(StepConnector)(({ theme }) => ({
// display:'flex',
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: '50%',
    left: 'calc(-50% - 1px)',
    right: 'calc(50% - 1px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#00b89f',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#00b89f',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:"#9c9c9c",
    borderTopWidth: 2,
    borderRadius: 1,
  },

}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
//   color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  zIndex: '1',
//   height: 23,
  alignItems: 'center',
  ...(ownerState.active && {
    width:10,
    height:10,
    borderRadius: '50%',
    color: '#00b89f',
    border: '.1rem solid #1E2730',
    outline: ".1rem solid #00b89f"
  }),
  '& .QontoStepIcon-completedIcon': {
    width: 10,
    height:10,
    borderRadius: '50%',
    backgroundColor: '#00b89f',
  },
  '& .QontoStepIcon-circle': {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: '#9c9c9c',

  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {active || completed ? (
        <div className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}



export default function CustomizedSteppers({detectedData,confirmedData,callingData}) {

  const fallDetectionSteps = ['Detected', 'Confirmed', 'Calling']
  let data={}
  if(detectedData) {
    data={...data,['detected']:
        new Date(
          detectedData + "z"                          
        )    
      .toLocaleString(undefined,{ 
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,})}
  }
  if(confirmedData){
    data={...data,['confirmed']:
        new Date(
            confirmedData +"z"                         
        )
      .toLocaleString(undefined,{ 
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,})}
  }
  if(callingData){
    data={...data,['calling']: 
        new Date(
            callingData + "Z"                        
        )
        .toLocaleString(undefined,{ 
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,})}
  } 

  let timeArr = []
  for (let index = 0; index < fallDetectionSteps.length; index++) {
      let stepName = fallDetectionSteps[index].toLocaleLowerCase()
      if (data[stepName]) {
          timeArr.push(data[stepName])
      } else {

          break;

      }

  }

  const [activeStep, setActiveStep] = useState(timeArr.length === 0 ? '' : timeArr.length - 1)


  const stepperStatus = (ind) => {

      if (isNaN(activeStep) || activeStep === '') {

          return { completed: false, active: false }

      }

      return { completed: ind < activeStep ? true : false, active: ind == activeStep ? true : false }

  }


// const CustomStack = styled(Stack)(({ theme }) => ({
//   width: '100%',
//   // Media queries for different screen sizes
//   [`@media (min-width: 1024px) and (min-height: 600px)`]: {
//     width: '190px',
//   },
//   [`@media (min-width: 1024px) and (min-height: 768px)`]: {
//     width: '190px',
//   },
//   [`@media (min-width: 1280px) and (min-height: 800px)`]: {
//     width: '200px',
//   },
//   [`@media (min-width: 1366px) and (min-height: 768px)`]: {
//     width: '200px',
//   },
//   [`@media (min-width: 1440px) and (min-height: 900px)`]: {
//     width: '220px',
//   },
//   [`@media (min-width: 1600px) and (min-height: 900px)`]: {
//     width: '245px',
//   },
//   [`@media (min-width: 1680px) and (min-height: 1050px)`]: {
//     width: '265px',
//   },
//   [`@media (min-width: 1920px) and (min-height: 1080px)`]: {
//     width: '265px',
//   },
//   [`@media (min-width: 1920px) and (min-height: 1200px)`]: {
//     width: '265px',
//   },
// }));




  return (
    <Stack sx={{width:{ sm:"230px", md:'230px',lg:"230px", xl:'270px'}}} spacing={4}>
      <Stepper style={{ paddingLeft: '0', paddingRight: '0' }} alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
        {fallDetectionSteps.map((label, index) => {
        const { active, completed } = stepperStatus(index) || {};
          return (
            <Step key={label}>
              <div className='d-flex justify-content-center flex-column align-items-center' style={{ gap: '6px' }}>
                <Typography style={{ color: '#00b89f', fontSize: '.6rem' }}>{label}</Typography>
                <StepLabel  StepIconComponent={() => QontoStepIcon(stepperStatus(index))}></StepLabel>
                <Typography style={{ color: active ? '#0FEFD1' : '#AFAFAF', fontSize: '.6rem' }}>{timeArr[index] || '--'}</Typography>
              </div>
            </Step>
          );
        })}
      </Stepper>
    </Stack>
  );


}

