import React, { useState } from 'react';
import { Paper, Button, Snackbar } from '@mui/material';
import sendIcon from 'assets/img/SendIcon.svg';
import { ReactComponent as BackIcon } from 'assets/img/back-icon.svg';
import InputField from 'components/inputfield/InputField';
import { Link } from 'react-router-dom';
import styles from './forgotpassword.module.scss';
import { isEmailValidOtp, isPhonenoValid, mobileFormatter } from 'utils/validator';
import SendOTP from 'app/forgot-password/components/send-otp/SendOTP';
import AuthServices from 'api/services/auth-services';
import { Box } from '@mui/material';

const OTP_SENT = 'otp is sent'
const INVALID_MOBILE = 'email is invalid'

const inputValidationMessage = {
  emptyMobile: 'Enter  Valid email Address',
  inValidMobileNumber: 'Enter Valid email Address'
}

const ForgotPassword = () => {

  // states
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);

  const [email, setEmail] = useState('');
  const [fromError, setFormError] = useState();

  const [invaildEmailId, setInvalidEmailId] = useState(false);

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });

  const generateOtp = async (event) => {
    event.preventDefault()

    const formData = new FormData();
    formData.append('email', email);
    setShowSnackBar(false)

    try {
      if(isEmailValidOtp(email)){
        const { message } = await AuthServices.forgetPassword(formData);
        setShowSnackBar(true)
        setSnackBarContent({
          message,
          class: 'success'
        })
        setInvalidEmailId(false);
        setShowVerifyOtp(true)
      }else{
        setInvalidEmailId(true);
        setFormError("Enter valid email address");
      }
    }
    catch (err) {
      const { data } = err || {}
      const { message } = data || {}
      setInvalidEmailId(true);
      setFormError(message);

      setShowSnackBar(true)
      setSnackBarContent({
        message,
        class: 'error'
      })
    }
  }

  return (
    <>
      {!showVerifyOtp &&
        <Paper elevation={0} style={{ width: '27.5rem' }}>
          {/* <Stack
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          > */}
          {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <Link to="/login">
                <BackIcon size="2.8rem" />
              </Link>
            <Typography className={styles.header} variant="h3" textAlign="center">
              Forgot password
            </Typography>
          </div> */}

          <Box textAlign="center" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: '0.6rem' }}><Link to="/login"><BackIcon /></Link><span style={{ width: '100%', textAlign: 'center', marginBottom: '0.4rem', color: '#00B89F', fontWeight: '800' }}>Forgot Password</span></Box>
          {/* </Stack> */}

          <form onSubmit={generateOtp}>
            <InputField className="inputfield"
              sx={{ '.MuiFormHelperText-root': { color: 'red', fontWeight: '600' } }} label="Email Address" variant="filled" fullWidth type="text"
               onChange={(event) => { setEmail(event.target.value)}}
              helperText={invaildEmailId && fromError}
            />
            <Button
              variant="contained" color="primary" fullWidth startIcon={<Box mr={1.5} pb={0} mb={-1}><img src={sendIcon} alt="" /></Box>}
              className={styles.sendOTPButton}
              onClick={generateOtp}
              disabled={!isEmailValidOtp(email)}
              sx={{
                textShadow: '1px 0 0 currentColor'
              }}
            >
              Send OTP
            </Button>
          </form>
        </Paper>
      }
      {showVerifyOtp && <SendOTP email={email} />}
      <Snackbar open={showSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={snackbarContent?.message || ''}
        autoHideDuration={4000}
        onClose={() => setShowSnackBar(false)}
        sx={{
          '.MuiSnackbarContent-root': {
            color: '#fff',
            fontWeight: '400',
            backgroundColor: snackbarContent.class === 'error' ? 'red' : (snackbarContent.class === 'warning' ? '#ffcc00' : '#00b89f'),
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '10rem'
          }
        }}
      />
    </>
  );
};

export default ForgotPassword;
