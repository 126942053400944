import { ReactComponent as PillIcon } from "assets/img/pill_icon.svg";
import { ReactComponent as DoorIcon } from "assets/img/door_icon.svg";
import { ReactComponent as HeartRateIcon } from "assets/img/heart_rate_gray.svg";
import { ReactComponent as BPMIcon } from "assets/img/bpm_icon.svg";
import { ReactComponent as StepsIcon } from "assets/img/steps_icon.svg";
import { ReactComponent as BatteryIcon } from "assets/img/battery_icon.svg";
import { ReactComponent as Spo2Icon } from "assets/img/normal.svg";
import { ReactComponent as ShowerIcon } from "assets/img/shower-icon.svg";
import { ReactComponent as SleepBeltIcon } from "assets/img/belt-white.svg";
import { ReactComponent as CouchIcon } from "assets/img/couch-white.svg";
import { ReactComponent as SOSIcon } from "assets/img/sos-big.svg";
import {ReactComponent as FallDetectionIcon} from "assets/img/falldetection28x28green.svg";
import { ReactComponent as BathRoomIcon } from "assets/img/24px_Bathroom_Sensor_Icon_Active.svg";
const SMS_TEMPLATE = [
    {
        "name": "Heart Rate",
        "message": "NTelCare High/Low <b>Heart Rate</b> Alert : <br>Dear Family Member Name,<br> &ensp;&ensp; Senior Name heart rate is higher/Lower than usual heart rate at time pm. Text STOP to opt-out. For more info login to family https://family.ntelcare.com.",
        "icon": <HeartRateIcon className="active-icons" />
    }, {
        "name": "Blood Pressure",
        "message": "NTelCare High/Low <b>Blood Pressure</b> Alert : <br>Dear Family Member Name,<br> &ensp;&ensp; Senior Name blood pressure is higher/Lower than usual blood pressure at time pm. Text STOP to opt-out. For more info login to family https://family.ntelcare.com.",
        "icon": <BPMIcon className="active-icons" />
    },{
        "name": "Blood Oxygen",
        "message": "NTelCare High/Low <b>Blood Oxygen</b> Alert : <br>Dear Family Member Name,<br> &ensp;&ensp; Senior Name blood oxygen is higher/Lower than usual blood oxygen at time pm. Text STOP to opt-out. For more info login to family https://family.ntelcare.com.",
        "icon": <Spo2Icon className="active-icons" />
    }, {
        "name": "Steps",
        "message": "NTelCare High/Low <b>Steps</b> Alert : <br>Dear Family Member Name,<br> &ensp;&ensp;  Senior Name  count is higher/Lower than usual steps count at time pm. Text STOP to opt-out. For more info login to family https://family.ntelcare.com.",
        "icon": <StepsIcon className="active-spcl" />
    }, {
        "name": "Battery",
        "message": "NTelCare Low <b>Battery</b> Alert : <br>Dear Family Member Name,<br> &ensp;&ensp; Senior Name watch battery is Lower than usual battery level at time pm. Text STOP to opt-out. For more info login to family https://family.ntelcare.com.",
        "icon": <BatteryIcon className="active-diff" />
    }, {
        "name": "Door",
        "message": "NTelCare Open/Close <b>Door</b> Alert : <br>Dear Family Member Name,<br> &ensp;&ensp; Senior Name opened/closed is after than usual time. Text STOP to opt-out. For more info login to family https://family.ntelcare.com.",
        "icon": <DoorIcon className="active-icons" />
    }, {
        "name": "PillBox",
        "message": "NTelCare <b>PillBox</b> Alert : <br>Dear Family Member Name,<br> &ensp;&ensp; Senior Name not take medication 1/2/3 at time pm. Text STOP to opt-out. For more info login to family https://family.ntelcare.com.",
        "icon": <PillIcon className="active-icons" />
    }, {
        "name": "Shower",
        "message": "NTelCare <b>Shower</b> Alert : <br>Dear Family Member Name,<br> &ensp;&ensp; Senior Name not take shower at time pm. Text STOP to opt-out. For more info login to family https://family.ntelcare.com.",
        "icon": <ShowerIcon className="active-icons" />
    }, {
        "name": "SOS watch",
        "message": "NTelCare high <b>urgent band</b> Alert : <br>Dear Family Member Name,<br> &ensp;&ensp; Senior Name presses the urgent band at time pm. Text STOP to opt-out. For more info login to family https://family.ntelcare.com.",
        "icon": <SOSIcon className="" />
    }, {
        "name": "Sleep",
        "message": "NTelCare High/Less <b>Sleep</b> Alert : <br>Dear Family Member Name,<br> &ensp;&ensp; Senior Name slept is higher/lesser than usual sleep time. Text STOP to opt-out. For more info login to family https://family.ntelcare.com.",
        "icon": <SleepBeltIcon className="active-icons" />
    }, {
        "name": "SleepBelt-Chair",
        "message": "NTelCare High/Low <b>Sleepbelt</b> Alert : <br>Dear Family Member Name,<br> &ensp;&ensp; Senior Name spend  is time higher/Lower than usual interval. Text STOP to opt-out. For more info login to family https://family.ntelcare.com.",
        "icon": <CouchIcon className="active-icons" />
    },{
        "name": "Fall detection",
        "message": "NTelCare <b>Fall Detection</b> Alert : <br>Dear Family Member Name,<br> &ensp;&ensp; Senior Name fell down at time am/pm. Text STOP to opt-out. For more info login to family https://family.ntelcare.com.",
        "icon": <FallDetectionIcon className="" />
    },{
        "name" : "BathRoom" ,
        "message" : "NTelCare <b>BathRoom</b> Alert :<br>Dear Family Member Name,<br> &ensp;&ensp; Senior Name visit number is max/max than usual visiting. Text STOP to opt-out. For more info login to family https://family.ntelcare.com.",
        "icon" : <BathRoomIcon className = " " />
    }
];

export default SMS_TEMPLATE;