import { Box, Typography } from '@mui/material';
import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import './navilink.scss';
const NaviLink = ({ icon: Icon, text, ...rest }) => {
  const history = useHistory();
  let parentUrlState;
  switch (true) {
    case rest.to === "/accounts" && (history.location.pathname.startsWith("/account-details") || history.location.pathname === "/create-account"):
      parentUrlState = true;
      break;
    case rest.to === "/nhub" && history.location.pathname === "/nhub-details":
      parentUrlState = true;
      break;
    case rest.to === "/staff-roles" && (history.location.pathname === "/add-staff-role" || history.location.pathname === "/edit-staff"):
      parentUrlState = true;
      break;
    default:
      parentUrlState = false;
      break;
  }
  return (
    <Box color="#9c9c9c" sx={{ marginBottom: '1rem' }} >
      <NavLink {...rest} className={parentUrlState && 'active'}>
        <Box fontSize="12px">
          <Icon />
        </Box>
        <Typography fontSize="12px" textAlign="center">
          {text}
        </Typography>
      </NavLink>
    </Box>
  );
};

export default NaviLink;
