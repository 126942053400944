import { Box, Snackbar, Stack, Typography } from '@mui/material';
import { PieChart } from 'react-minimal-pie-chart';
import React, { useContext, useState } from 'react';
import { actions, GlobalContext } from 'context';
import { useEffect } from 'react';
import AuthServices from 'api/services/auth-services';


const Legend = ({ lineColor, txt }) => {
  return (
    <Stack
      sx={{ width: '90%', margin: '1rem auto', marginBottom: "1rem" }}
      direction="row"
      alignItems="center"
      spacing={1}
    >
      <Box
        flex={1}
        sx={{
          borderRadius: '3px',
          height: '0.8rem',
          backgroundColor: lineColor,
        }}
      ></Box>
      <Typography fontSize="0.8rem" color="#9c9c9c" flex={8}>
        {txt}
      </Typography>
    </Stack>
  );
};
const Users = ({ count }) => {
  const { dispatch, state } = useContext(GlobalContext);
  const { usersData } = state || {}
  const [users, setUsers] = useState({});
  const [usersMessage, setUsersMessage] = useState("");
  const [flag, setFlag] = useState([]);
  const [data, setData] = useState([]);
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'success' })

  const snackbarNotification = (data) => {
    setSnackBarContent(data)
    setShowSnackBar(true)
  }

  const storeHandler = (type, payload) => dispatch({ type, payload })

  useEffect(() => {
    storeHandler(actions.SHOW_LOADER, true)
    AuthServices.getDashboard().then(({ data }) => {
      setUsers(data?.users)
      storeHandler(actions.SHOW_LOADER, false)
    }).catch((err) => {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    })
  }, [])

  useEffect(() => {
    setUsers(usersData)
  }, [usersData])

  // useEffect(() => {
  //   let flag = true;
  //   for(let x of users) {
  //     x > 0 && (flag = false)
  //   }
  //   setUsersMessage(flag === true ? "No data" : "")
  // }, [users])

  useEffect(() => {
    const { care_givers, excetive, members, seniors } = users || {
      care_givers: 0,
      excetive: 0,
      members: 0,
      seniors: 0
    }
    const data1 = [];
    data1.push({ title: 'Seniors', value: seniors, color: 'black' })
    data1.push({ title: 'Family members', value: members, color: '#1BD243' })
    data1.push({ title: 'Executives', value: excetive, color: '#FEF741' })
    data1.push({ title: 'Caregivers', value: care_givers, color: '#3B65F5' })
    setData(data1);
  }, [users])

  // useEffect(() => {
  //   let flag = true;
  //   for(let ele in data) {
  //     flag = ele.value !== 0 ? false : true
  //     return flag === false && flag;
  //   }
  //   if (flag === true) {
  //     setFlag(true)
  //   } else {
  //     setFlag(false)
  //   }
  // }, [data])

  // const data = []
  // data.push({ title: 'Seniors', value: seniors, color: 'black' })
  // data.push({ title: 'Family members', value: members, color: '#1BD243' })
  // data.push({ title: 'Executives', value: excetive, color: '#FEF741' })
  // data.push({ title: 'Caregivers', value: care_givers, color: '#3B65F5' })

  // const data1 = [
  //   { title: 'Seniors', value: 30, color: '#ff4f4f' },
  //   { title: 'Family members', value: 27, color: '#1BD243' },
  //   { title: 'Executives', value: 32, color: '#FEF741' },
  //   { title: 'Caregivers', value: 34, color: '#3B65F5' },
  // ]

  return (
    <div style={{ paddingTop: '1rem' }}>
      <Typography textAlign="center">Users</Typography>

      {data?.length && <PieChart
        style={{ height: '50%' }}
        radius={20}
        center={[50, 25]}
        viewBoxSize={[100, 50]}
        lineWidth={25}
        // segmentsShift={(segmentIndex) => {
        //   if (segmentIndex === 0) return 2;
        //   else return 0;
        // }}
        startAngle={270}
        paddingAngle={5}
        data={[
          { title: data[0] ? data[0].value : "Seniors", value: data[0].value, color: '#ff4f4f' },
          { title: data[1] ? data[1].value : "Family Members", value: data[1].value, color: '#1BD243' },
          { title: data[2] ? data[2].value : "Executives", value: data[2].value, color: '#FEF741' },
          { title: data[3] ? data[3].value : "Caregivers", value: data[3].value, color: '#3B65F5' },
        ]}
      />}
      <Legend lineColor="#ff4f4f" txt={data[0]?.value ? `Seniors: ${data[0]?.value}` : `Seniors: 0`} />
      <Legend lineColor="#1BD243" txt={data[1]?.value ? `Family Members: ${data[1]?.value}` : `Family Members: 0`} />
      <Legend lineColor="#FEF741" txt={data[2]?.value ? `Executives: ${data[2]?.value}` : `Executives: 0`} />
      <Legend lineColor="#3B65F5" txt={data[3]?.value ? `Caregivers: ${data[3]?.value}` : `Caregivers: 0`} />
      <Snackbar open={showSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={snackbarContent?.message || ''}
        autoHideDuration={4000}
        onClose={() => setShowSnackBar(false)}
        sx={{
          '.MuiSnackbarContent-root': {
            color: '#fff',
            fontWeight: '400',
            backgroundColor: snackbarContent.class === 'error' ? 'red' : (snackbarContent.class === 'warning' ? '#ffcc00' : '#00b89f'),
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '10rem'
          }
        }}
      />

    </div>
  );
};

export default Users;
