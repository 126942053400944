import { Route, Redirect } from 'react-router-dom';
import { useContext } from 'react';
import { GlobalContext } from 'context';

const PublicRoute = (props) => {
  const {
    state: { isLoggedIn },
  } = useContext(GlobalContext);
  const { component: Component, layout: Layout } = props;
  if (isLoggedIn) {
    return (
      <Route>
        <Redirect to="/dashboard" />
      </Route>
    );
  }
  return (
    <Route>
      <Layout>
        <Component />
      </Layout>
    </Route>
  );
};
export default PublicRoute;
