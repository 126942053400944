import { createTheme } from "@mui/material";
const appTheme = {
  background: "#24292d",
  backgroundPaper: "#272e36",
  primary: "#00b89f",
};

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
    },
  },
  palette: {
    primary: {
      main: appTheme.primary,
      contrastText: "#1a1a1a",
    },
    secondary: {
      main: "#6C7175",
    },
    background: {
      default: appTheme.background,
      paper: appTheme.backgroundPaper,
    },
    text: {
      primary: "#fff",
      secondary: appTheme.primary,
    },
  },
  typography: {
    fontFamily: ["Montserrat", "Open Sans"].join(","),
    h2: {
      fontSize: "2rem",
      color: "#6c7175",
    },
    h3: {
      fontSize: "1.25rem",
      fontWeight: 700,
      color: appTheme.primary,
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131",
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontSize: "14px",
          ["@media(min-width:1200px)"]: {
            fontSize: "16px",
          },
          ["@media(min-width:1600px)"]: {
            fontSize: "18px",
          },
          ["@media(min-width:1920px)"]: {
            fontSize: "20px",
          },
        },
        a: {
          textDecoration: "none",
          color: "inherit",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: "1rem",
          borderRadius: "1rem",
          boxShadow: "0 3px 10px rgba(0,0,0,0.29)",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          background: "white",
          marginBottom: "1rem",
          borderRadius: "10px",
          outline: "none",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: "700",
          borderRadius: "10px",
          fontSize: "1.5rem",
          textTransform: "capitalize",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          borderRadius: "10px",
          color: "#1a1a1a",
          "&.Mui-focused": {
            borderRadius: "10px",
            outline: `2px solid ${appTheme.primary}`,
          },
        },
        input: {
          borderTopLeftRadius: "inherit",
          borderTopLightRadius: "inherit",
          borderBottomLeftRadius: "inherit",
          borderBottomRightRadius: "inherit",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: "#1a1a1a",
          "&.Mui-focused": {
            borderColor: "#D8D8D8",
            borderRadius: "10px",
            outline: "none",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#1a1a1a",
          "&.Mui-focused": {
            color: "#1a1a1a",
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#9c9c9c",
        },
      },
    },
    '.MuiTableHead-root': {
      '& th:first-child': {
        borderRadius: '1em 0 0 1em'
      },
      '& th:last-child': {
        borderRadius: '0 1em 1em 0'
      }
    },
  },
});
export default theme;
