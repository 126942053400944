import {
  Box,
  Paper,
  Typography,
  Button,
  Snackbar,
  MenuItem,
  IconButton,
} from "@mui/material";
import AddUserInput from "components/add-user-input/AddUserInput";
import DashHeader from "components/dash-header/DashHeader";
import { ReactComponent as TickIcon } from "assets/img/tick_icon.svg";
import { ReactComponent as Avatar } from "assets/avatar.svg";
import { ReactComponent as UploadIcon } from "assets/img/upload_icon.svg";
import React, { useContext, useState } from "react";
import {
  isEmailValid,
  isMobileValid,
  isName,
  isPhonenoValid,
  mobileFormatter
} from "utils/validator";
import AuthServices from "api/services/auth-services";
import AddSeniorInput from "components/add-senior-input/AddSeniorInput";
import SelectInputMod from "app/create-account/Components/select-input-mod/SelectInputMod";
import { MdKeyboardBackspace as BackIcon } from 'react-icons/md'
import { useHistory } from "react-router-dom";
import { actions, GlobalContext } from "context";
import closeIcon from 'assets/img/close.svg';
const Notification = {
  profile: { message: "Please upload a profile image", class: "error" },
  invalidProfile: { message: 'Please upload a valid profile image', class: 'error' },
  phone: { message: "Please enter a valid phone number", class: "error" },
  email: { message: "Please enter a valid email address", class: "error" },
  mobile: { message: "Please enter a valid Mobile number", class: "error" },
  passwordMatch: {
    message: "Password and Confirm Password do not match",
    class: "error",
  },
  passwordLenErr: {
    message: "Password should be minimum 8 characters",
    class: "error",
  },
  incomplete: { message: "Please fill all the fields", class: "error" },
  emailRegisterd: { message: "Email already registered", class: "error" },
  phoneRegisterd: {
    message: "Phone number already registered",
    class: "error",
  },
  addSuccess: { message: "User added successfully", class: "success" },
  error: {
    message: "We are facing some issues, Please try later!!",
    class: "error",
  },
};

const response = {
  emailRegisterd: "email is alreaddy registered",
  phoneRegisterd: "mobile is alreaddy registered",
};

const EditProfile = () => {
  const history = useHistory();
  const [userDetails, setUserDetails] = useState({});
  const [validation, setValidation] = useState([]);
  const [passwordLenErr, setPasswordLenErr] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });
  const [mobile, setMobile] = useState();
  const [inValid, setInValid] = useState({});

  // Profile Image states
  const [profileImgFile, setFile] = useState();
  const [profileImgName, setFileName] = useState();
  const [profileImgUrl, setProfileImgUrl] = useState({});

  const snackbarNotification = (data) => {
    setSnackBarContent(data)
    setShowSnackBar(true)
  }

  const { dispatch } = useContext(GlobalContext);
  const storeHandler = (type, payload) => dispatch({ type, payload });

  const inputFields = [
    "fname",
    "lname",
    "email",
    "mobile",
    "password",
    "type",
    "confirm_password",
  ];

  const uploadFile = (e) => {
    const file = e.target.files[0];
    if (file?.type?.startsWith("image") && file.type !== "image/gif") {
    setFile(file);
    setFileName(e.target.value);
    setProfileImgUrl(URL.createObjectURL(file));
    }else {
      snackbarNotification(Notification?.invalidProfile)
  }
  };

  const userInfo = (key, value) => {
    // const invalidFields = [...validation];
    // const index = invalidFields.indexOf(key)
    // if (index > -1) {
    //   invalidFields.splice(index, 1);
    //   setValidation([...invalidFields])
    // }
    // const data = { ...userDetails, [key]: value }
    // setUserDetails({ ...data })
    if (key === "fname" || key === "lname") {
      if (isName(value)) {
        const data = { ...userDetails, [key]: value };
        setInValid({ ...inValid, [key]: false });
        setUserDetails({ ...data });
      }
    } else if (key === "mobile") {
      if (isMobileValid(value)) {
        const data = { ...userDetails, [key]: value };
        setUserDetails({ ...data });
        mobileValidator(value);
      } else {
        mobileValidator(value);
      }
    } else {
      setInValid({ ...inValid, [key]: false });
      const data = { ...userDetails, [key]: value };
      setUserDetails({ ...data });
    }
  };

  const passwordLenValidator = (value) => {
    if (value.length < 8) {
      setPasswordLenErr(true);
    } else {
      setPasswordLenErr(false);
    }
  };

  const passwordMatchValidator = (value) => {
    if (value !== userDetails.password) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
  };

  const emailValidator = (e) => {
    const value = e.target.value;
    if (value.length > 0)
      setInValid({ ...inValid, email: !isEmailValid(value) });
    else setInValid({ ...inValid, email: false });
  };

  const mobileValidator = (value) => {
    if (value?.length > 0 && value?.length <= 10)
      setInValid({ ...inValid, mobile: !isPhonenoValid(value) });
    else setInValid({ ...inValid, mobile: false });
  };

  const validate = () => {
    let invalidFields = [];
    const newInValid = {};
    inputFields.forEach((item) => {
      if (!userDetails[item] || inValid[item]) {
        invalidFields.push(item);
        newInValid[item] = true;
      }
    });
    if (invalidFields.length || passwordLenErr || passwordMatch) {
      setValidation([...invalidFields]);
      setInValid({ ...inValid, ...newInValid });
      // snackbarNotification(Notification.incomplete)
    }
    // else if (!uploaded) {
    //   snackbarNotification(Notification.profile);
    // }
    // else if (invalidEmail) {
    //   snackbarNotification(Notification.email);
    // }
    // if(invalidMobile) {
    //   snackbarNotification(Notification.mobile);
    // }
    else if (userDetails.password.length < 8) {
      snackbarNotification(Notification.passwordLenErr);
    } else if (userDetails.password !== userDetails.confirm_password) {
      snackbarNotification(Notification.passwordMatch);
    } else {
      setValidation([]);
      add();
    }
  };

  const add = async () => {
    const formData = new FormData();

    if (
      profileImgFile !== {} &&
      profileImgFile !== null &&
      profileImgFile !== undefined
    )
      formData.append("profile", profileImgFile, profileImgName);
    else formData.append("profile", null);
    for (let key in userDetails) {
      formData.append(key, userDetails[key]);
    }

    try {
      const response = await AuthServices.addUser(formData);
      const { message } = response;
      addSuccess();
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      })
      storeHandler(actions.SHOW_LOADER, false)
    }
  };

  const addSuccess = () => {
    snackbarNotification(Notification.addSuccess);
    resetForm();
    setTimeout(() => {
      history.push("/staff-roles");
    }, 2000);
  };

  const resetForm = () => {
    setFile({});
    setMobile('');
    setFileName({});
    setProfileImgUrl({});
    setTimeout(() => {
      setUserDetails({});
    }, 1000);
  };

  const handleChangeMobile = (event) => {
    const value = event?.target?.value?.split(".")?.join("");
    if (isMobileValid(value)) {
      setMobile(mobileFormatter(value));
      userInfo("mobile", value);
    }
  };

  return (
    <>
      <DashHeader pageTitle="NtelCare Staff Roles" />

      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: '80%' }}
      >
        <Paper
          sx={{
            padding: "1.5rem 1rem",
            width: { xs: "100%", md: "auto" },
            height: "100%",
            overflowY: "scroll",
          }}
        >
          <Box>
            <Box mb="2.25rem" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <IconButton onClick={() => history.push('/staff-roles')}>
                <BackIcon color='#00B89F' />
              </IconButton>
              <Typography color="primary" textAlign="center">
                Add Staff
              </Typography>
              <Box sx={{ width: '1.5rem',cursor:'pointer' }}>
            <img src={closeIcon} alt="close"  onClick={() => history.push('/staff-roles')}/>
            </Box>
            </Box>
          </Box>
          <Box sx={{ display: { md: "flex" }, gap: { md: "1rem" } }}>
            <Box>
              <Box>
                {/* <AddSeniorInput id="fname" helperText={inValid?.fname && 'Enter the first name'} onChange={(e) => userInfo('fname', e.target.value)} value={userDetails?.fname || ''} label="First Name" required /> */}

                <AddUserInput
                  labelText="First name"
                  helperText={inValid?.fname && "Enter the first name"}
                  placeholder="Enter the first name"
                  value={userDetails?.fname || ""}
                  onChange={(e) => userInfo("fname", e.target.value)}
                  required
                />
              </Box>
              <Box>
                {/* <AddSeniorInput id="lname" helperText={inValid?.lname && 'Enter a valid last name'} onChange={(e) => userInfo('lname', e.target.value)} value={userDetails?.lname || ''} label="Last Name" required /> */}
                <AddUserInput
                  labelText="Last name"
                  value={userDetails?.lname || ""}
                  placeholder="Enter the last name"
                  onChange={(e) => userInfo("lname", e.target.value)}
                  helperText={inValid?.lname && "Enter a valid last name"}
                  required
                />
              </Box>
              <Box>
                {/* <SelectInputMod where="staff-roles" value={userDetails?.type} onChange={(e) => userInfo('type', e.target.value)} label="Account Type" required>
                  {['admin', 'engineer']?.map((option, index) => {
                    return (
                      <MenuItem key={index} value={option} >
                        {option.charAt(0).toUpperCase() + option.slice(1) || ''}
                      </MenuItem>
                    );
                  })}
                </SelectInputMod> */}
                <AddUserInput
                  fieldType="select"
                  options={["select", "admin", "engineer"]}
                  disabled={0}
                  onChange={(e) => userInfo("type", e.target.value)}
                  value={userDetails?.type || "select"}
                  helperText={inValid?.type && "Select an account type"}
                  labelText="Account type"
                  required
                />
              </Box>
              <Box>
                {/* <AddSeniorInput
                  id="email"
                  helperText={inValid?.email && 'Enter a valid email address'}
                  onChange={(e) => {
                    userInfo('email', e.target.value)
                    emailValidator()
                  }}
                  value={userDetails?.email || ''}
                  label="Email"
                  required
                /> */}
                <AddUserInput
                  labelText="Email"
                  helperText={inValid?.email && "Enter a valid email address"}
                  value={userDetails?.email || ""}
                  onChange={(e) => {
                    userInfo("email", e.target.value);
                    emailValidator(e);
                  }}
                  placeholder="Enter the email address"
                  required
                />
              </Box>
              <Box sx={{ marginBottom: "1rem" }}>
                {/* <AddSeniorInput
                  id="password"
                  type="password"
                  passwordfield={true}
                  helperText={inValid?.password && 'Enter a valid password'}
                  onChange={(e) => {
                    userInfo('password', e.target.value)
                  }}
                  value={userDetails?.password || ''}
                  label="Password"
                  required
                /> */}
                <AddUserInput
                  fieldType="password"
                  labelText="Enter password"
                  value={userDetails?.password || ""}
                  placeholder="Enter the password"
                  onChange={(e) => {
                    userInfo("password", e.target.value);
                    passwordLenValidator(e.target.value);
                  }}
                  helperText={
                    inValid?.password
                      ? "Enter a valid password"
                      : passwordLenErr
                        ? "Enter min 8 characters"
                        : ""
                  }
                  required
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    border: "2px solid",
                    borderRadius: "0.5rem",
                    borderColor: "primary.main",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    marginBottom: "1rem",
                    minWidth: "11rem",
                    minHeight: "11rem",
                    maxWidth: "11rem",
                    maxHeight: "11rem",
                  }}
                >
                  <Box
                    sx={{
                      padding: "1rem 2rem",
                      textAlign: "center",
                      borderRadius: "1rem",
                    }}
                  >
                    {profileImgUrl.length ? (
                      <img
                        src={profileImgUrl}
                        style={{
                          height: "6.5rem",
                          width: "6.5rem",
                          borderRadius: "50%",
                        }}
                        alt=""
                      />
                    ) : (
                      <Avatar />
                    )}
                  </Box>
                  <Box>
                    <Button
                      component="label"
                      sx={{
                        background: "transparent",
                        padding: "0.25rem",
                        color: "primary",
                        fontSize: "0.65rem",
                        borderRadius: "0.5rem",
                        borderColor: "primary.main",
                        border: "1px solid",
                        margin: 0,
                      }}
                      fullWidth
                      startIcon={<UploadIcon />}
                    >
                      Upload Image
                      <input
                        type="file"
                        value={userDetails?.profile || ""}
                        onChange={uploadFile}
                        hidden
                        accept="image/png, image/jpeg, image/jpg"
                      />
                    </Button>
                  </Box>
                </Box>
              </Box>

              <Box>
                <Box>
                  {/* <AddSeniorInput
                    id="phone"
                    helperText={inValid?.phone && 'Enter a valid phone number'}
                    onChange={(e) => {
                      userInfo('phone', e.target.value)
                      mobileValidator()
                    }
                    }
                    value={userDetails?.phone || ''}
                    label="Phone"
                    required
                  /> */}
                  <AddUserInput
                    labelText="Mobile"
                    value={mobile || ""}
                    placeholder="Enter the mobile number"
                    helperText={inValid?.mobile && "Enter a valid phone number"}
                    onChange={(e) => handleChangeMobile(e)}
                    required
                  />
                </Box>
                <Box sx={{ marginBottom: "1rem" }}>
                  {/* <AddSeniorInput
                    label="Confirm password"
                    value={userDetails?.confirm_password || ''}
                    onChange={(e) => userInfo('confirm_password', e.target.value)}
                    required
                  /> */}

                  <AddUserInput
                    fieldType="password"
                    labelText="Confirm password"
                    placeholder="Re-enter your password"
                    value={userDetails?.confirm_password || ""}
                    helperText={
                      inValid?.confirm_password
                        ? "Enter a valid password"
                        : passwordMatch
                          ? "Passwords do not match"
                          : ""
                    }
                    onChange={(e) => {
                      userInfo("confirm_password", e.target.value);
                      passwordMatchValidator(e.target.value);
                    }}
                    required
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                color: "#fff",
                width: { xs: "50%", md: "25%" },
              }}
              startIcon={<TickIcon />}
              onClick={validate}
            >
              Save
            </Button>
          </Box>
        </Paper>
        <Snackbar
          open={showSnackBar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          message={snackbarContent?.message || ""}
          autoHideDuration={4000}
          onClose={() => setShowSnackBar(false)}
          sx={{
            ".MuiSnackbarContent-root": {
              color: "#fff",
              fontWeight: "400",
              backgroundColor:
                snackbarContent.class === "error"
                  ? "red"
                  : snackbarContent.class === "warning"
                    ? "#ffcc00"
                    : "#00b89f",
              display: "flex",
              justifyContent: "center",
              borderRadius: "10rem",
            },
          }}
        />
      </Box>
    </>
  );
};

export default EditProfile;
