import { Button, Dialog, MenuItem, Stack, Typography } from "@mui/material";
import { Box } from '@mui/material'
import { ReactComponent as TickIcon } from 'assets/img/tick_icon.svg';
import SimpleInputField from "components/simple-inputfield/SimpleInputField";
import { TransparentBtn } from "app/notification/components/styled-componets/style";
import Timepicker from "components/time-picker/Timepicker";
import React, { useState } from 'react'
import { CustomDialogTitle } from "components/dialogs/custom-dialog-title/CustomDialogTitle";
// import { isNumber } from "utils/validator";
// import SelectInputMod from "app/create-account/Components/select-input-mod/SelectInputMod";
// import { weekdaysArray } from "app/notification/components/edit-inputs/ShowerInput";

const ShowerNotification = ({ open, handleClose, saveConfig }) => {
  const initialState = {
    device: "Shower",
    message: "",
    time: "",
    // week_msg: '',
    // week_count: '',
    // week_day: ''
  };
  const [settings, setSettings] = useState({ ...initialState });
  const [invalid, setInvalid] = useState({});
  const [invalidModes, setInvalidModes] = useState(false);

  const [notificatonModes, setNotificationModes] = useState({ email: false, sms: false, push: false });
  const handleModeClick = (e) => {
    const mode = e.target.name;
    const prev = notificatonModes[mode];
    setNotificationModes({ ...notificatonModes, [mode]: !prev });
    invalid.modes = false;
    setInvalidModes(false)

  }

  const handleChange = (value, key) => {
    // console.log(key, value);
    let data = settings;
    data[key] = value;
    setInvalid({ ...invalid, [key]: false });
    setSettings(data);
  };

  const validate = () => {
    let data = settings;
    let invalidData = {};
    let isValid = true;
    Object.keys(data).forEach((key) => {
      if (data[key] === "") {
        invalidData[key] = true;
        isValid = false;
      }
    });
    setInvalid(invalidData);
    const isValidModes = Object.values(notificatonModes).some(item => item)
    setInvalidModes(!isValidModes)
    if (isValid && isValidModes) {
      saveConfig({ ...data, noti_type: notificatonModes });
      handleClose();
    }
  };

  // const handleCloseBtn = () => {
  //   handleClose()
  // }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      // onClose={handleCloseBtn}
      BackdropProps={{ style: { backgroundColor: 'black', opacity: "0.9" } }}
      className="dialog-pill"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box width="40rem">
        <div style={{ position: "relative", display: 'flex', direction: 'row', justifyContent: 'center' }}>
          <Typography sx={{ mt: ".3rem", mb: '2rem', color: 'primary.main', fontWeight: 'bold', fontSize: '1rem' }}>
            Critical Event Notification
          </Typography>
          <CustomDialogTitle onClose={() => { handleClose() }} style={{ padding: 0, margin: 0, position: "absolute", right: "0", top: "0" }} ></CustomDialogTitle>
        </div>
        <Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0 1rem 0",
            }}
          >
            <Box
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Box width="100%" sx={{ display: 'flex', alignItems: 'center' }}>
                <Box>
                  <Timepicker
                    labelText="Shower Time"
                    placeholder="00:00"
                    helperText={invalid?.time ? "Please enter a valid time" : ""}
                    value={settings?.time}
                    onChange={(newValue) => handleChange(newValue, "time")}
                  />
                </Box>
                <Box>
                  <Typography sx={{ marginLeft: '0.8rem' }}>Notification will be pushed if shower is not taken before this time everyday.</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <SimpleInputField
              labelText="CEN"
              multiline={8}
              placeholder="Enter Critical Event Notification"
              type="Other"
              helperText={invalid?.message ? "Please enter a valid message" : ""}
              value={settings?.message}
              onChange={(event) => handleChange(event.target.value, "message")}
            />
          </Box>
        </Stack>

        {/* <Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0 1rem 0",
            }}
          >
            <Box
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Box width="100%" sx={{ display: 'flex', gap: '1rem', alignItems: 'start', }}>
                <Box>
                  <SimpleInputField
                    value={settings?.week_count}
                    helperText={invalid?.week_count && <span style={{ fontSize: '.7rem' }}>Please enter valid input</span>}
                    labelText="Shower Count"
                    type="Other"
                    onChange={(event) => {
                      if (!isNumber(event.target.value)) return
                      if (event.target.value.length <= 5) { handleChange(event.target.value, "week_count") }
                    }
                    }
                  />
                </Box>

                <Box sx={{ "& .MuiFormControl-root": { minWidth: "165px", marginRight: '1rem' } }}>
                  <Typography sx={{ fontSize: '.8rem', paddingBottom: "0.3rem", color: '#D8D8D8', position: 'relative', top: '-2px' }}>Alert Day Starts</Typography>
                  <SelectInputMod key={settings?.week_day} sx={{ "& .MuiSelect-select": { padding: "0.5rem", paddingRight: "32px" } }} value={settings?.week_day} helperText={invalid?.week_day && 'Please enter valid input'} onChange={(event) => handleChange(event.target.value, "week_day")}>
                    {weekdaysArray.map((option, index) => <MenuItem key={index} value={option.key} > {option.value || ''} </MenuItem>)}
                  </SelectInputMod>
                </Box>

              </Box>

              <Box sx={{ mt: '-.5rem' }}>
                <Typography>If the senior did not surpass the total number of shower required within a 7-day period, a notification will be sent.</Typography>
              </Box>

            </Box>
          </Box>
          <Box>
            <SimpleInputField
              labelText="Shower Count CEN"
              multiline={8}
              placeholder="Enter Critical Event Notification"
              type="Other"
              helperText={invalid?.week_msg ? "Please enter a valid message" : ""}
              value={settings?.week_msg}
              onChange={(event) => handleChange(event.target.value, "week_msg")}
            />
          </Box>
        </Stack> */}
        {invalidModes && <Box sx={{ color: 'red', fontSize: '0.85rem' }}>Please select at least one medium</Box>}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.8rem' }}>
          <TransparentBtn sx={{ color: notificatonModes?.email ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='email' variant="outlined">Email</TransparentBtn>
          <TransparentBtn sx={{ color: notificatonModes?.sms ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='sms' variant="outlined">SMS</TransparentBtn>
          <TransparentBtn sx={{ color: notificatonModes?.push ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='push' variant="outlined">Push</TransparentBtn>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{ color: "#fff", width: "15rem" }}
            startIcon={<TickIcon />}
            onClick={validate}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default ShowerNotification

