//Login APIs
export const LOGIN = '/login/admin';
export const REFRESH_TOKEN = '/refresh/token';
export const FORGOT_PASSWORD = '/forgot/admin';
export const RESET_PASSWORD = '/reset/admin';
export const VERIFY_OTP = '/verify/admin';

//Admin APIs
export const DASHBOARD = '/admin/dashboard';
export const DASHBOARD_CHART = '/admin/dashboard/notification';
export const DASHBOARD_COUNTRY = '/dashbord/country';
export const CITY_STATE = '/zipcode';
export const GET_USER_PROFILE = '/get/user/profile';
export const EDIT_USER_PROFILE = '/edit/user/profile';
export const EDIT_USER_ROLES_PROFILE = '/edit/user';
export const GET_ALL_USERS = '/get/users';
export const GET_SPECIFIC_USER = '/get/user';
export const ADD_USER = '/add/user';
export const EDIT_SPECIFIC_USER = '/edit/user';
export const GET_LATEST_UPDATES = '/admin/latest/updates';

//Accounts APIs
export const ADD_MEMBER = '/add/member';
export const GET_RELATIONS = '/admin/relations';
export const EDIT_MEMBER = '/edit/member';
export const DELETE_MEMBER = '/delete/member';
export const ADD_SENIOR = '/add/senior';
export const DELETE_SENIOR = '/delete/senior';
export const ZIPCODE_CITY_STATE = '/zipcode';
export const MEMBER_EMAIL_EXIST = '/get/member';
export const EDIT_SENIOR = '/edit/senior';
export const CREATE_MASTER_ACCOUNT = '/add/master';
export const GET_MEMBER = '/get/member'
export const GET_MEMBERS = '/get/members'
export const FINISH_ACCOUNT_CREATION = '/account/finish';
export const DELETE_ENGINEER = '/delete/engineer';
export const DELETE_PROFILE_IMG = '/profile_delete/'

//Senior APIs
export const GET_SENIOR_LIST = '/admin/seniors';
export const GET_MEMBER_SENIOR_LIST = '/get/member/seniors';
export const GET_SENIOR_DETAILS = '/admin/senior';
export const DELETE_VNHUB_SENIOR = '/vnhub/remove/senior'

//Contract Details APIs
export const CONTRACT_DETAILS = '/contract/detail';
export const SENSOR_LIST = '/contract/sensor';
export const CONTRACT_TERM = '/add/terms'

// utility APIs
export const SAVE_VPN_CRED = '/utility/vpn';
export const TEST_EMAIL_CONNECTION = '/utility/connection/test/email';
export const TEST_SMS_CONNECTION = '/utility/connection/test/sms';
export const TEST_PUSH_CONNECTION = '/utility/connection/test/push';
export const SAVE_EMAIL_CONNECTION = '/utility/connection/save/email';
export const SAVE_SMS_CONNECTION = '/utility/connection/save/sms';
export const SAVE_PUSH_CONNECTION = '/utility/connection/save/push';
export const RASPBERRY_PI_VPN = '/nhubs/get/vpn';
export const NHUBS_LIST = '/nhubs/rpi/list';
export const GET_NHUB_STATUS = '/nhub/get_status';
export const NHUBS_UPLOAD = '/nhubs/rpi/upload';
export const APP_STATS = '/get/apps/details';

export const GET_NHUB_DEVICES = '/nhubs/devices';

export const GET_NHUB_DEVICE_DETAILS = '/nhubs/device';

export const DELETE_NHUB_DEVICE = '/nhubs/deactivate';

export const BROADCAST_MANAGEMENT_MESSAGE = '/nhubs/management';

export const ACCOUNTS = '/admin/seniors';

//Notification APIs
export const GET_BULK_NOTIFICATION_STATUS = '/get/bulk/notification/status';
export const SEND_NOTIFICATION_BULK = '/send/notification/bulk';
export const SEND_INDIVIDUAL_NOTIFICATION = '/send/notification/individual';
export const ADD_GENERIC_NOTIFICATIONS = '/add/notification/generic';
export const GET_GENERIC_NOTIFICATIONS = '/get/notification/generic';
export const EDIT_GENERIC_NOTIFICATIONS = '/edit/notification/generic';
export const DELETE_GENERIC_NOTIFICATIONS = '/delete/notification/generic';
export const ADD_CENTRIC_NOTIFICATIONS = '/add/notification/centric';
export const GET_CENTRIC_NOTIFICATIONS = '/get/notification/centric';
export const EDIT_CENTRIC_NOTIFICATIONS = '/edit/notification/centric';
export const DELETE_CENTRIC_NOTIFICATIONS = '/delete/notification/centric';
export const GET_SENIOR_DETAILS_NOTIFICATIONS = '/get/senior_cen';

//CEN APIs
export const CONFIG_ALL_CEN = '/config/cen/all'
export const GET_All_CEN = '/get/cen/all'

//Report APIs
export const ACCOUNT_REPORTS = '/reports/accounts';
export const CRITICAL_REPORTS = '/reports/critical';
export const USER_REPORTS = '/reports/users';
export const SENSOR_REPORTS = '/reports/sensors';
export const PRODUCT_REPORTS = '/reports/products';
export const MOBILE_DOWNLOADS_REPORTS = '/reports/mobile/download';
export const NHUB_REPORTS = '/reports/nhub';
export const SOS_REPORTS = '/reports/sos';
export const ACCOUNT_REPORTS_EXCEL = `/reports/download/url`;
export const CONTRACT_REPORTS = '/reports/terms';
export const VIRTUALNHUB_REPORTS = '/reports/vnhub';


//Virtual Nhub APIs
export const VNHUB_ADDSENSOR = '/vnhub/add/sensor'
export const VNHUB_DELETE_SENSOR = '/vnhub/delete/sensor'
export const VNHUBCREATE = '/vnhub/add';
export const VNHUBFILE = '/vnhub/vayyar/get'
