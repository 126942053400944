import { Box, Paper, Snackbar } from '@mui/material';
import AuthServices from 'api/services/auth-services';
import ActiveAccounts from 'app/dashboard/Components/active-accounts/ActiveAccountsContainer';
import ActiveDevices from 'app/dashboard/Components/active-devices/ActiveDevicesContainer';
import DashHeader from 'components/dash-header/DashHeader';
import LatestUpdates from 'app/dashboard/Components/latest-updates/LatestUpdatesContainer';
import NotificationTrend from 'app/dashboard/Components/notification-trend/NotificationTrend';
import Users from 'app/dashboard/Components/users/Users';
import { actions, GlobalContext } from 'context';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import "./Dashboard.scss";

const Dashboard = () => {

  const { state, dispatch } = useContext(GlobalContext);

  const storeHandler = (type, payload) => dispatch({ type, payload })
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'success' })

  const [countries, setCountries] = useState([]);

  const snackbarNotification = (data) => {
    setSnackBarContent(data)
    setShowSnackBar(true)
  }

  const getDashboard = async () => {
    storeHandler(actions.SHOW_LOADER, true)
    try {
      const { data } = await AuthServices.getDashboard();
      storeHandler(actions.DASHBOARD_DATA, { ...data });
      storeHandler(actions.SHOW_LOADER, false)
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    }
  }

  const getDashboardCountry = async () => {
    storeHandler(actions.SHOW_LOADER, true)
    try {
      const { data } = await AuthServices.getDashboardCountry();
      const countriesData = {
        countriesData: data
      }
      setCountries({ ...countriesData })
      // storeHandler(actions.DASHBOARD_DATA, countriesData);
      storeHandler(actions.SHOW_LOADER, false)
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    }
  }

  const handleChangeUsers = (usersData) => {
    storeHandler(actions.USERS_DATA, { ...usersData })
  }

  useEffect(() => {
    getDashboard();
    getDashboardCountry();
  }, []);

  return (
    <>
      <DashHeader pageTitle="Dashboard" />
      {/* The following container renders main content and latest updates side by side */}
      <Box sx={{ display: { md: 'flex' }, flexDirection: 'column', justifyContent: { md: 'space-between' }, height: "90%", overflowY: "scroll", width: "100%", padding: "0 0.5rem" }}>
        <Box sx={{ height: "100%", width: "100%", flexBasis: { md: '79.5%' } }}>
          <Box
            sx={{
              display: { xs: 'block', md: 'flex' },
              alignItems: { md: 'stretch' },
              justifyContent: { md: 'space-between' },
              marginBottom: '1rem',
            }}
            className="box"
          >
            <Box className="box-active" sx={{ flexBasis: { md: '82%' } }}>
              <ActiveAccounts handleChangeUsers={handleChangeUsers} countries={countries} />
            </Box>
            <Paper className="paper-users" sx={{ flexBasis: { md: '16%' } }}>
              <Users />
            </Paper>
          </Box>
          <Paper>
            <ActiveDevices />
          </Paper>
          <Box sx={{ margin: '1rem 0' }}>
            <NotificationTrend />
          </Box>
        </Box>
        {/* <Box
          sx={{
            flexBasis: { md: '19%' },
            display: { xs: 'none', md: 'block' },
            height: "100%"
          }}
        >
          <LatestUpdates />
        </Box> */}
      </Box>
      <Snackbar open={showSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={snackbarContent?.message || ''}
        autoHideDuration={4000}
        onClose={() => setShowSnackBar(false)}
        sx={{
          '.MuiSnackbarContent-root': {
            color: '#fff',
            fontWeight: '400',
            backgroundColor: snackbarContent.class === 'error' ? 'red' : (snackbarContent.class === 'warning' ? '#ffcc00' : '#00b89f'),
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '10rem'
          }
        }}
      />
    </>
  );
};

export default Dashboard;
