import { Box, Button, Dialog, Grid, MenuItem, Paper, Snackbar, styled, Typography } from '@mui/material';
import AddSeniorInput from 'components/add-senior-input/AddSeniorInput';
import SeniorInfo from 'components/senior-info/SeniorInfo';
import React, { useContext, useState } from 'react';
import { ReactComponent as TickIcon } from 'assets/img/tick_icon.svg';
import { ReactComponent as PillIcon } from 'assets/img/pill_small.svg';
// import { ReactComponent as BedIcon } from 'assets/img/bed_small.svg';
import { ReactComponent as BathIcon } from 'assets/img/bathroom_small.svg';
import { ReactComponent as DoorIcon } from 'assets/img/door_small.svg';
// import { ReactComponent as CouchIcon } from 'assets/img/couch_small.svg';
// import { ReactComponent as DiabeticIcon } from 'assets/img/diabetic_pump_small.svg';
import { ReactComponent as WatchIcon } from 'assets/img/watch.svg';
import AdultIcon from 'assets/img/adult_sensors_small.png';
import { ReactComponent as UploadIcon } from 'assets/img/upload_icon.svg';
// import { ReactComponent as WatchIcon } from 'assets/img/watch_gray.svg';
import { ReactComponent as Avatar } from 'assets/avatar.svg';
import { actions, GlobalContext } from 'context';
import AuthServices from 'api/services/auth-services';
import { useEffect } from 'react';
import AlertDialog from 'app/create-account/pages/create-account-step3/components/Next/dialog';
import BasicDatePicker from 'components/datepicker/Datepicker';
import InputField from 'components/inputfield/InputField';
import SensorButton from 'app/create-account/Components/sensor-button/SensorButton';
import PillboxNotifications from 'app/create-account/pages/create-account-step3/components/CEN/pillbox/PillboxNotifications';
import DoorNotifications from 'app/create-account/pages/create-account-step3/components/CEN/door/DoorNotifications';
import WatchNotifications from 'app/create-account/pages/create-account-step3/components/CEN/watch/WatchNotifications';
import { isEmailValid, isHeight, isMobileValid, isName, isPhonenoValid, isPersonalInfoValid, isWeight, mobileFormatter, vpnValidator, isValidName, isRoom } from 'utils/validator';
import SelectInputMod from 'app/create-account/Components/select-input-mod/SelectInputMod';
import { CustomDialogTitle } from 'components/dialogs/custom-dialog-title/CustomDialogTitle';
import "./EditSenior.scss";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    boxShadow: "0 0 0 0",
    color: theme.palette.text.secondary,
}));

const inputValidationMessage = {
    inValidMobileNumber: 'Enter a 10-digit mobile number'
}

const Notification = {
    profile: { message: 'Please upload a profile image', class: 'error' },
    invalidProfile: { message: 'Please upload a valid profile image', class: 'error' },
    phone: { message: 'Please enter a valid phone number', class: 'error' },
    email: { message: 'Please enter a valid email address', class: 'error' },
    emailRegistered: { message: 'Email is already registered', class: 'error' },
    incomplete: { message: 'Please fill all the fields', class: 'error' },
    date: { message: 'Please select a valid date', class: 'error' },
    error: { message: 'We are facing some issues, Please try later!!', class: 'error' },
    invalidDate: { message: 'Please select a valid date', class: 'error' },
    invalid: { message: 'Invalid Fields found, please check the details', class: 'error' },
    mobileNoInUse: { message: 'Mobile number already registered', class: 'error' },
    emailInUse: { message: 'Email already registered', class: 'error' },
}

const options = [
    { id: 0, name: "Health Watch" },
    { id: 1, name: "Urgent Band" },
    { id: 2, name: "Both" },
];

const bloodGroupOptions = [
    { id: 0, name: "A+" },
    { id: 1, name: "A-" },
    { id: 2, name: "B+" },
    { id: 3, name: "B-" },
    { id: 4, name: "AB+" },
    { id: 5, name: "AB-" },
    { id: 6, name: "O+" },
    { id: 7, name: "O-" },
];

const genderOptions = [
    { id: 0, value: 'Male' },
    { id: 1, value: 'Female' },
    { id: 2, value: 'Other' }
];

const weightOptions = [
    { id: 0, value: "lbs" },
    { id: 1, value: "kg" }
]

const heightOptions = [
    { id: 0, value: "ft" },
    { id: 1, value: "cm" }
]

const initialDetails = { zipcode: "", country: '', dob: '' };

export default function EditSeniorDialog({ masterAccountId, open, handleClose, sensors, countries, seniorInfo, snackbarNotification }) {
    const { dispatch } = useContext(GlobalContext);

    const [seniorDetails, setSeniorDetails] = useState({ ...seniorInfo?.senior });
    const [profileImgFile, setFile] = useState({})
    const [profileImgName, setFileName] = useState({})
    const [profileImgUrl, setProfileImgUrl] = useState({})
    const [uploaded, setUploaded] = useState(false)
    const [validation, setValidation] = useState([])
    const [country, setCountry] = useState('');
    const [inValid, setInValid] = useState({});
    const [openDialog, setOpenDialog] = useState(false)
    const [pillCENDialogOpen, setPillCENDialogOpen] = useState(false)
    const [doorCENDialogOpen, setDoorCENDialogOpen] = useState(false)
    const [watchCENDialogOpen, setWatchCENDialogOpen] = useState(false)
    const [watch, setWatch] = useState('');
    const [isAddFinish, setIsAddFinish] = useState(false)
    const [city, setCity] = useState(null)
    const [currentState, setCurrentState] = useState(null)
    const [doorNotifications, setDoorNotifications] = useState({})
    const [pillNotifications, setPillNotifications] = useState({})
    const [mobile, setMobile] = useState();
    const [height, setHeight] = useState({ ...seniorInfo?.senior }['height_unit'] === 'ft' ? 0 : 1)
    const [weight, setWeight] = useState({ ...seniorInfo?.senior }['weight_unit'] === 'lbs' ? 0 : 1)
    const mandatoryInputFields = [
        'fname',
        'lname',
        'age',
        'dob',
        'gender',
        'address',
        'zipcode',
        'city',
        'state',
        'country'
    ]

    console.log("senior Info",new Date(`${seniorInfo?.senior_dob} 00:00`))
    const storeHandler = (type, payload) => dispatch({ type, payload })

    const addDetail = (key, value) => {
        if (key === 'fname' || key === 'lname') {
            if (isValidName(value)) {
                const data = { ...seniorDetails, [key]: value };
                setInValid({ ...inValid, [key]: false })
                setSeniorDetails({ ...data });
            }
        } else if (key === 'height') {
            switch (height) {
                case 0: if (isHeight(value)) {
                    const data = { ...seniorDetails, [key]: value };
                    setSeniorDetails({ ...data });
                }
                    break;
                case 1: if (isHeight(value)) {
                    const data = { ...seniorDetails, [key]: value };
                    setSeniorDetails({ ...data });
                }
                    break;
                case 2: if (isHeight(value)) {
                    const data = { ...seniorDetails, [key]: value };
                    setSeniorDetails({ ...data });
                }
                    break;
                default: break;
            }
            setInValid({ ...inValid, [key]: false })
        } else if (key === 'inchs') {
            if (isHeight(value)) {
                const data = { ...seniorDetails, [key]: value };
                setSeniorDetails({ ...data });
                setInValid({ ...inValid, [key]: false })
            } else {
                // setInValid({ ...inValid, inchs: true })
            }
            // } else if (key === 'oz') {
            //     if (isWeight(value)) {
            //         const data = { ...seniorDetails, [key]: value };
            //         setSeniorDetails({ ...data });
            //         setInValid({ ...inValid, [key]: false })
            //     } else {
            //         // setInValid({ ...inValid, inchs: true })
            //     }
        } else if (key === 'weight') {
            if (isWeight(value)) {
                const data = { ...seniorDetails, [key]: value };
                setSeniorDetails({ ...data });
                setInValid({ ...inValid, [key]: false })
            }
        } else if (key === 'mobile') {
            if (isMobileValid(value)) {
                const data = { ...seniorDetails, [key]: value };
                setSeniorDetails({ ...data });
                mobileValidator(value)
            } else {
                mobileValidator(value)
            }
        } else if (key === 'dob') {
            const date = new Date(value)
            const year = date.getFullYear().toString().length === 4 ? date.getFullYear().toString() : date.getFullYear().toString().length === 3 ? ('0' + date.getFullYear().toString()) : date.getFullYear().toString().length === 2 ? ('00' + date.getFullYear().toString()) : date.getFullYear().toString().length === 1 ? ('000' + date.getFullYear().toString()) : '0000';
            const month = (date.getMonth() + 1).toString().length === 2 ? (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString().length === 1 ? ('0' + (date.getMonth() + 1).toString()) : '00'
            const dateString = date.getDate().toString().length === 2 ? date.getDate().toString() : date.getDate().toString().length === 1 ? ('0' + date.getDate().toString()) : '00'
            let dateStr = year + '-' + month + '-' + dateString;
            const age = calculateAge(value);
            if (age <= 0) {
                setInValid({ ...inValid, dob: true, age: true });
                const data = { ...seniorDetails, dob: '' }
                setSeniorDetails(data)
                return;
            }
            setInValid({ ...inValid, dob: false, age: false })
            const data = { ...seniorDetails, [key]: dateStr, age };
            setSeniorDetails({ ...data });
        } else if (key === 'zipcode') {
            setInValid({ ...inValid, zipcode: false })
            const data = { ...seniorDetails, [key]: value };
            setSeniorDetails({ ...data });
        }else if (key === 'email'){
            if(value.length > 50)
                return
            else{
                setInValid({ ...inValid, email: false })
                const data = { ...seniorDetails, [key]: value };
                setSeniorDetails({ ...data });
            }
        } else if (key === 'room_no') {
            if (isRoom(value)) {
                const data = { ...seniorDetails, [key]: value };
                setSeniorDetails({ ...data });
                setInValid({ ...inValid, [key]: false })
            }
        }
        else {
            setInValid({ ...inValid, [key]: false })
            const data = { ...seniorDetails, [key]: value };
            setSeniorDetails({ ...data });
        }
    }

    const uploadFile = (event) => {
        const file = event.target.files[0];
        if (file?.type?.startsWith("image") && file.type !== "image/gif") {
            setFile(file);
            setFileName(event.target.value);
            setProfileImgUrl(URL.createObjectURL(file));
            setUploaded(true);
        } else {
            snackbarNotification(Notification?.invalidProfile)
        }
    }

    const mobileValidator = (value) => {
        if (value?.length > 0 && value?.length <= 10)
            setInValid({ ...inValid, mobile: !isPhonenoValid(value) });
        else setInValid({ ...inValid, mobile: false });
    }

    const inchsValidator = (value) => {
        if (value?.length > 0 && value?.length <= 5)
            setInValid({ ...inValid, inchs: !isPersonalInfoValid(value) });
        else setInValid({ ...inValid, inchs: false });
    }

    const emailValidator = (event) => {
        const value = event.target.value;
        if (value?.length > 0){
            setInValid({ ...inValid, email: !isEmailValid(value) });}
        else{
            setInValid({ ...inValid, email: false });}
    }


    const heightValidator = (event) => {
        const value = event.target.value;
        if (value?.length > 0)
            setInValid({ ...inValid, height: isNaN(value) });
        else setInValid({ ...inValid, height: false });
    }

    const weightValidator = (event) => {
        const value = event.target.value;
        setInValid({ ...inValid, weight: isNaN(value) });
    }

    const validate = () => {
        let invalidFields = [];
        const newInValid = {};
        mandatoryInputFields.forEach(item => {
            if (seniorDetails[item] === '' || seniorDetails[item] === null || seniorDetails[item] === undefined) {
                invalidFields?.push(item)
                newInValid[item] = true;
                console.log(item)
            }
        })
        if (invalidFields?.length || watch === '') {
            setValidation([...invalidFields])
            console.log("invalidFields found", invalidFields)
            if (watch === '') {
                invalidFields.push('watch');
                newInValid['watch'] = true;
            }
            setInValid({ ...inValid, ...newInValid });
        }
        else {
            for (let key in inValid) {
                if (inValid[key]) {
                    return;
                }
            }
            setValidation([]);
            editSenior();
        }
    }

    const calculateAge = (dob) => {
        const today = new Date();
        const year = parseInt(today.getFullYear().toString().length === 4 ? today.getFullYear().toString() : today.getFullYear().toString().length === 3 ? ('0' + today.getFullYear().toString()) : today.getFullYear().toString().length === 2 ? ('00' + today.getFullYear().toString()) : today.getFullYear().toString().length === 1 ? ('000' + today.getFullYear().toString()) : '0000');
        const month = parseInt((today.getMonth() + 1).toString().length === 2 ? (today.getMonth() + 1).toString() : (today.getMonth() + 1).toString().length === 1 ? ('0' + (today.getMonth() + 1).toString()) : '00');
        const date = parseInt(today.getDate().toString().length === 2 ? today.getDate().toString() : today.getDate().toString().length === 1 ? ('0' + today.getDate().toString()) : '00');
        const birthDate = new Date(dob);
        let age = year - birthDate.getFullYear();
        const m = month - birthDate.getMonth();
        if (m < 0 || (m === 0 && date < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    const editSenior = async () => {
        const formData = new FormData();
        if (uploaded)
            formData.append('profile', profileImgFile, profileImgName);
        formData.append('m_acc_id', masterAccountId);
        // formData.append('gender', genderOptions[gender].value)
        Object.keys(seniorDetails).forEach((key) => {
            if (key === 'inchs' && height === 0) {
                formData.append(key, seniorDetails[key]);
            } else if (!['inchs', 'height_unit','weight_unit']?.includes(key)) {
                formData.append(key, seniorDetails[key]);
            }
        })
        switch (watch) {
            case 0: formData.append('watch', true)
                formData.append('sos', false)
                break;
            case 1: formData.append('watch', false)
                formData.append('sos', true)
                break;
            case 2: formData.append('watch', true)
                formData.append('sos', true)
                break;
            default: break;
        }
        formData.append('height_unit', heightOptions[height].value)
        formData.append('weight_unit', weightOptions[weight].value)
        storeHandler(actions.SHOW_LOADER, true);
        try {
            const id = seniorDetails?._id?.$oid;
            const data = await AuthServices.editSenior(id, formData);
            const { message } = data || 'Successfully updated the details.';
            snackbarNotification({
                message,
                class: 'success'
            })
            handleClose(true);
            storeHandler(actions.SHOW_LOADER, false);
        } catch (err) {
            const { data } = err;
            const { message } = data || 'We are facing some issues!!, please try later.';
            snackbarNotification({
                message,
                class: 'error'
            })
            storeHandler(actions.SHOW_LOADER, false)
        }
    }

    const handleWatchChange = (e) => {
        setWatch(e.target.value)
        setInValid({ ...inValid, watch: false });
    }

    const handleBlur = (event) => {
        if (!(!country === "" || seniorDetails.zipcode === "")) {
            storeHandler(actions.SHOW_LOADER, true)
            AuthServices.zipcodeGetCityState(country?.code, seniorDetails.zipcode)
                .then(({ data }) => {
                    setCity(data?.place_name !== null ? data?.place_name : "")
                    setCurrentState(data?.state_name !== null ? data?.state_name : "")
                    storeHandler(actions.SHOW_LOADER, false)
                }).catch((err) => {
                    const { data } = err || {};
                    const { message } = data || {};
                    snackbarNotification({
                        message,
                        class: "error",
                    });
                    storeHandler(actions.SHOW_LOADER, false);
                })
        }
    }

    useEffect(() => {
        const data = { ...seniorDetails, city, state: currentState }
        const newInValid = { ...inValid }
        if (currentState)
            newInValid.state = false
        if (city)
            newInValid.city = false
        setInValid({ ...newInValid })
        setSeniorDetails(data)
    }, [countries, city, currentState])

    const handleDoorCEN = (data) => {
        setDoorNotifications(data)
    }

    const handlePillCEN = (data) => {
        setPillNotifications(data)
    }

    const handleHeightChange = (event) => {
        setHeight(event.target.value)
    }

    const handleWeightChange = (event) => {
        setWeight(event.target.value)
    }

    const handleChangeMobile = (event) => {
        const value = event?.target?.value?.split('.')?.join('')
        if (isMobileValid(value)) {
            setMobile(mobileFormatter(value))
            addDetail('mobile', value);
        }
    }

    useEffect(() => {
        setMobile(mobileFormatter(seniorInfo?.senior?.phone));
        addDetail('mobile', seniorInfo?.senior?.phone);
        setCountry(countries.find(item => item.name === seniorInfo?.senior?.country));
        const date = seniorInfo?.senior_dob ? new Date(`${seniorInfo?.senior_dob} 00:00`) : seniorInfo?.senior_dob
        addDetail('dob', date);
        if (seniorInfo?.senior?.watch === true && seniorInfo?.senior?.sos === false) {
            setWatch(0)
        }
        else if (seniorInfo?.senior?.watch === false && seniorInfo?.senior?.sos === true) {
            setWatch(1)
        }
        else if (seniorInfo?.senior?.watch === true && seniorInfo?.senior?.sos === true) {
            setWatch(2)
        }
        else {
            setWatch('');
        }
        console.log(seniorInfo)
        setProfileImgUrl(seniorInfo?.senior?.profile)
    }, [])

    const HandleAvatarDelete = async ()=>{
        storeHandler(actions.SHOW_LOADER, true);
        try {
            if(!profileImgUrl) throw new Error("Profile Image not Present")
            const response = await AuthServices.deleteProfileIMG("senior/"+seniorInfo?.senior?._id['$oid']);
            const { message } = response;
            snackbarNotification({
                message,
                class: "success",
            });
            handleClose(true);
            storeHandler(actions.SHOW_LOADER, false);
        } catch (error) {
            snackbarNotification({
                message:error.message || "Profile Image not Present",
                class: "error",
            });
            storeHandler(actions.SHOW_LOADER, false);
        }
    }

    return (
        <>
            <Dialog
                open={open}
                BackdropProps={{ style: { backgroundColor: 'black', opacity: "0.9" } }}
                onClose={() => handleClose(false)}
                className="edit-senior-dialog-box"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <CustomDialogTitle onClose={handleClose}>Edit Senior</CustomDialogTitle>
                <Box className="allDetails">
                    <Grid className="edit-senior" sx={{ width: "75%" }} container spacing={0}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Item className="spcl">
                                <AddSeniorInput helperText={inValid?.fname && 'Enter your first name'} id="fname" value={seniorDetails?.fname || ''} onChange={(event) => addDetail('fname', event.target.value)} label="First name" required />
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Item className="spcl">
                                <AddSeniorInput helperText={inValid?.lname && 'Enter your last name'} id="lname" value={seniorDetails?.lname || ''} onChange={(event) => addDetail('lname', event.target.value)} label="Last name" required />
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Item className="spcl">
                                <AddSeniorInput id="email" helperText={inValid?.email && 'Enter a valid email address'} value={seniorDetails?.email || ''} onChange={(e) => { addDetail('email', e.target.value); emailValidator(e); }} label="Email" />
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Item className="spcl">
                                <SelectInputMod helperText={inValid?.gender && 'Select your gender'} value={seniorDetails?.gender || ''} onChange={(e) => addDetail('gender', e.target.value)} label="Gender" required>
                                    {genderOptions?.map((option, index) => {
                                        return (
                                            <MenuItem key={index} value={option?.value} >
                                                {option.value || ''}
                                            </MenuItem>
                                        );
                                    })}
                                </SelectInputMod>
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Item className="spcl">
                                <AddSeniorInput type="text" helperText={inValid?.mobile && inputValidationMessage.inValidMobileNumber} id="mobile" value={mobile || ''} onChange={(event) => handleChangeMobile(event)} label="Phone no" />
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Item className="spcl">
                                <BasicDatePicker  senior = { true }helperText={inValid?.dob && 'Please enter a valid date'} id="dob" value={seniorDetails?.dob || ''} onChange={(newValue) => addDetail('dob', newValue)} required />
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Item className="spcl">
                                <SelectInputMod id="bloodgroup" value={seniorDetails?.blood_group || ''} onChange={(e) => addDetail('blood_group', e.target.value)} label="Blood Group">
                                    {bloodGroupOptions?.map((option, index) => {
                                        return (
                                            <MenuItem key={index} value={option?.name} >
                                                {option.name || ''}
                                            </MenuItem>
                                        );
                                    })}
                                </SelectInputMod>
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Item className="spcl">
                                <SelectInputMod helperText={inValid?.country && 'Select your country'} label="Country" required value={country} onBlur={handleBlur} onChange={(e) => { addDetail('country', e.target.value.name); setCountry(e.target.value) }}>
                                    {
                                        countries?.map((option, index) => {
                                            return <MenuItem key={index} value={option} >{option.name}</MenuItem>
                                        })
                                    }
                                </SelectInputMod>
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Item className="spcl">
                                <AddSeniorInput helperText={inValid?.zipcode && 'Enter your zipcode'} id="zipcode" value={seniorDetails?.zipcode || ''} onBlur={handleBlur} onChange={(e) => { addDetail('zipcode', e.target.value) }} label="Zip Code" required />
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Item className="spcl">
                                <AddSeniorInput helperText={inValid?.address && 'Enter your address'} id="address" value={seniorDetails?.address || ''} onChange={(e) => addDetail('address', e.target.value)} label="Address" required />
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Item className="spcl">
                                <AddSeniorInput helperText={inValid?.city && 'Enter your city'} id="city" value={seniorDetails.city || ''} onChange={(e) => addDetail('city', e.target.value)} label="City" required />
                            </Item>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Item className="spcl">
                                <AddSeniorInput helperText={inValid?.state && 'Enter your state'} id="state" value={seniorDetails.state || ''} onChange={(e) => addDetail('state', e.target.value)} label="State" required />
                            </Item>
                        </Grid>
                        <Grid className="grid-senior-item" item xs={12} sm={6} md={4}>
                            <Item>
                                <AddSeniorInput id="Room NO" helperText={inValid?.room_no} value={seniorDetails?.room_no || ''} onChange={(e) => addDetail('room_no', e.target.value)} label="Room Number" />
                            </Item>
                        </Grid>
                        <Grid className="grid-senior-item" item xs={12} sm={6} md={3}>
                            <Item>
                                <AddSeniorInput id="height" helperText={inValid?.height && 'Enter a valid height'} height={height} handleHeightChange={handleHeightChange} field="height" endAdornment={true} value={seniorDetails?.height || ''} onChange={(e) => { addDetail('height', e.target.value) }} label="Height" />
                            </Item>
                        </Grid>
                        {(height === 0) && (
                            <Grid className="grid-senior-item" item xs={12} sm={6} md={3}>
                                <Item>
                                    <AddSeniorInput id="inchs" helperText={inValid?.inchs && 'Enter a valid inchs'} field="inchs" value={seniorDetails?.inchs || ''} onChange={(e) => { addDetail('inchs', e.target.value) }} label="Inch" />
                                </Item>
                            </Grid>
                        )}
                        <Grid className="grid-senior-item" item xs={12} sm={6} md={3}>
                            <Item>
                                <AddSeniorInput id="weight" helperText={inValid?.weight && 'Enter a valid weight'} weight={weight} handleWeightChange={handleWeightChange} field="weight" endAdornment={true} value={seniorDetails?.weight || ''} onChange={(e) => { addDetail('weight', e.target.value) }} label="Weight" />
                            </Item>
                        </Grid>
                        {/* {weight === 0 && (
                            <Grid className="grid-senior-item" item xs={12} sm={6} md={3}>
                                <Item>
                                    <AddSeniorInput id="oz" helperText={inValid?.inchs && 'Enter a valid oz'} field="oz" value={seniorDetails?.oz || ''} onChange={(e) => { addDetail('oz', e.target.value) }} label="Oz" />
                                </Item>
                            </Grid>
                        )
                        } */}
                    </Grid>
                    <Box
                        sx={{
                            width: '20%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                        }}
                    >
                        <Box className="parent-box">
                            <Box className="parent-box-box1">
                                <Box
                                    sx={{
                                        padding: '1rem 2rem',
                                        textAlign: 'center',
                                        borderRadius: '1rem',
                                    }}
                                >
                                    {profileImgUrl?.length ?
                                        <img
                                            src={profileImgUrl}
                                            style={{ height: '6.5rem', width: '6.5rem', borderRadius: '50%' }}
                                            alt=""
                                        />
                                        :
                                        <Avatar />
                                    }
                                </Box>
                                <Box>
                                    <Button
                                        component='label'
                                        sx={{
                                            background: 'transparent',
                                            padding: '0.5rem',
                                            color: 'primary',
                                            fontSize: '0.65rem',
                                            borderRadius: '1rem',
                                            borderColor: 'primary.main',
                                            border: '2px solid',
                                            minWidth: "101%",
                                            margin: "0 -1px -3px -1px",
                                        }}
                                        fullWidth
                                        startIcon={<UploadIcon />}
                                    >
                                        {vpnValidator(profileImgUrl) ? "Upload Image" : "Change Image"}
                                        <input type="file" onChange={uploadFile} hidden accept="image/png, image/jpeg, image/jpg"  />
                                    </Button>
                                </Box>
                                {!vpnValidator(seniorInfo?.senior?.profile) && (
                                    <Box>
                                        <Button
                                            component='label'
                                            sx={{
                                                background: 'transparent',
                                                padding: '0.25rem',
                                                color: 'red',
                                                fontSize: '0.65rem',
                                                borderRadius: '1rem',
                                                borderColor: 'red',
                                                border: '2px solid',
                                                margin: "5px 0 3px 0" ,
                                            }}
                                            fullWidth
                                            onClick={HandleAvatarDelete}
                                        >
                                            Delete Image
                                        </Button>
                                    </Box>
                                )}


                            </Box>
                            {/* <Typography
                                sx={{
                                    fontSize: '.8rem',
                                    color: '#d8d8d8',
                                    marginBottom: '1rem',
                                }}
                            >
                                Setup Products Notification
                            </Typography> */}
                            <Box className="box-spn">
                                <Grid container spacing={0}>
                                    {
                                        sensors.map((sensor, index) => {
                                            switch (sensor.name) {
                                                case "PillBox": return (
                                                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                                        <SensorButton onClick={() => setPillCENDialogOpen(true)}>
                                                            <PillIcon width="1rem" height="1rem" />
                                                        </SensorButton>
                                                    </Grid>
                                                )
                                                case "Door": return (
                                                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                                        <SensorButton onClick={() => setDoorCENDialogOpen(true)}>
                                                            <DoorIcon width="1rem" height="1rem" />
                                                        </SensorButton>
                                                    </Grid>
                                                )
                                                case "BathRoom": return (
                                                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                                        <SensorButton>
                                                            <BathIcon width="1rem" height="1rem" />
                                                        </SensorButton>
                                                    </Grid>
                                                )
                                                case "FallDetection": return (
                                                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                                        <SensorButton>
                                                            <WatchIcon className='green' width="1rem" height="1rem" />
                                                        </SensorButton>
                                                    </Grid>
                                                )
                                                case "watch": return (
                                                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                                        <SensorButton onClick={() => setWatchCENDialogOpen(true)}>
                                                            <WatchIcon className='green' width="1rem" height="1rem" />
                                                        </SensorButton>
                                                    </Grid>
                                                )
                                                default: return <></>;
                                            }
                                        })
                                    }
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: { xs: "75%", sm: "75%", md: "50%" },
                        margin: '0 auto',
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{
                            color: '#fff',
                            marginTop: '2.5rem',
                            width: "50%"
                        }}
                        onClick={validate}
                        disabled={isAddFinish ? true : false}
                        startIcon={<TickIcon />}
                    >
                        Save Details
                    </Button>
                </Box>
                <AlertDialog
                    open={openDialog}
                    text="The Details Have Been Updated Successfully"
                    handleClose={() => {
                        AuthServices.finishAccountCreation(masterAccountId).then((data) => {
                            setOpenDialog(false)
                        })
                    }}
                />
            </Dialog>
        </>
    );
}