import React, { useContext, useEffect } from 'react';
import { Switch } from 'react-router-dom';
import WebFont from 'webfontloader';
import { PublicRoute } from 'routes';
import { PrivateRoute } from 'routes';
import { PreLoginLayout } from 'layouts';
import Login from 'app/login/Login';
import ForgotPassword from 'app/forgot-password/ForgotPassword';
import Dashboard from 'app/dashboard/Dashboard';
import PostLoginLayout from 'layouts/PostLogin/PostLoginLayout';
import AccountDetails from 'app/accounts/account-details/AccountDetails';
import CreateAccount from 'app/create-account/CreateAccount';
import Accounts from 'app/accounts/Accounts';
import UserRoles from 'app/user-roles/UserRoles';
import AddUserRole from 'app/user-roles/Components/pages/AddUserRole';
import EditProfile from './dashboard/edit-profile/EditProfile';
import Utility from 'app/utility/Utility';
import Nhub from 'app/nhub/Nhub';
import DeviceDetails from 'app/nhub/components/device-details/DeviceDetails';
import Notification from 'app/notification/Notification';
import Reports from './reports-section/Reports';
import { actions, GlobalContext } from 'context';
import Help from './help/Help';
import { Box, CircularProgress } from '@mui/material';
import EditUser from './user-roles/Components/pages/edit-user/EditUser';
import '../index.scss';
import Device from './vnhub/device/Device';
import VnhubLayout from 'layouts/vnhubLayout/VnhubLayout';
import Account from './vnhub/account/Account';

function App() {

  const { state: { showLoader }, dispatch } = useContext(GlobalContext);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Montserrat', 'Open Sans'],
      },
    });
  }, []);

  useEffect(() => {
    validateLogin();
  }, [])

  const validateLogin = async () => {
    // const acces_token = refresh();
    // dispatch(actions.LOG_IN, !!acces_token);
    let token = localStorage.getItem('authToken');
    storeHandler(actions.LOG_IN, !!token);
  }

  const storeHandler = (type, payload) => dispatch({ type, payload })

  return (
    <>
      {showLoader && <Box
        sx={{
          position: 'fixed',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#24292dcc',
          width: '100%',
          height: '100vh',
          zIndex: '1400',
        }}
      >
        <CircularProgress className='loader' />
      </Box>}
      <Switch>
        <PublicRoute
          exact
          path="/"
          component={Login}
          layout={PreLoginLayout}
        />
        <PublicRoute
          exact
          path="/login"
          component={Login}
          layout={PreLoginLayout}
        />
        <PublicRoute
          exact
          path="/forgot-password"
          component={ForgotPassword}
          layout={PreLoginLayout}
        />
        <PrivateRoute
          exact
          path="/accounts"
          component={Accounts}
          layout={PostLoginLayout}
        />
        <PrivateRoute
          exact
          path="/account-details/:id"
          component={AccountDetails}
          layout={PostLoginLayout}
        />
        <PrivateRoute
          exact
          path="/edit-profile"
          component={EditProfile}
          layout={PostLoginLayout}
        />
        <PrivateRoute
          exact
          path="/edit-staff"
          component={EditUser}
          layout={PostLoginLayout}
        />
        <PrivateRoute
          exact
          path="/create-account"
          component={CreateAccount}
          layout={PostLoginLayout}
        />
        <PrivateRoute
          exact
          path="/staff-roles"
          component={UserRoles}
          layout={PostLoginLayout}
        />
        <PrivateRoute
          exact
          path="/add-staff-role"
          component={AddUserRole}
          layout={PostLoginLayout}
        />
        <PrivateRoute
          exact
          path="/utility"
          component={Utility}
          layout={PostLoginLayout}
        />
        <PrivateRoute
          exact
          path="/nhub"
          component={Nhub}
          layout={PostLoginLayout}
        />
        <PrivateRoute
          exact
          path="/nhub-details"
          component={DeviceDetails}
          layout={PostLoginLayout}
        />
        <PrivateRoute
          exact
          path="/notification"
          component={Notification}
          layout={PostLoginLayout}
        />
        <PrivateRoute
          exact
          path="/reports"
          component={Reports}
          layout={PostLoginLayout}
        />
        <PrivateRoute
          exact
          path="/dashboard"
          component={Dashboard}
          layout={PostLoginLayout}
        />
        <PrivateRoute
          exact
          path="/"
          component={Dashboard}
          layout={PostLoginLayout}
        />
        <PrivateRoute
          exact
          path="/vnhub/device"
          component={Device}
          layout={VnhubLayout}
        />
        <PrivateRoute
          exact
          path="/vnhub/account"
          component={Account}
          layout={VnhubLayout}
        />
        <PublicRoute
          exact
          path="/help"
          component={Help}
          layout={PreLoginLayout}
        />
      </Switch>
    </>
  );
}
export default App;
