import { Dialog, DialogContent } from '@mui/material';
import './MemberDetailsDialog.scss';
import locationIcon from 'assets/img/location-white.svg';
import mail from 'assets/img/mail.svg';
import phoneIcon from 'assets/img/phone.svg';
import closeIcon from 'assets/img/close.svg';
import avatar from 'assets/img/default.svg';
import { ReactComponent as Executive } from 'assets/img/executive_icon.svg';

const MemberDetailsDialog = ({ open, details, handleClose }) => {
    
    return (
        <>
            <Dialog
                maxWidth={'xs'}
                open={open}
                onClose={handleClose}
                fullWidth={true}
                className={'member-info'}
            >
                <DialogContent className={'member-info-card'}>
                    <div>
                        <div className={`user-profile`}>
                            <img src={closeIcon} alt="close" className={`close-icon cursor`} onClick={handleClose} />
                            {
                                details?.executive ?
                                    <div className='executive-div'>
                                        <img src={details?.profile || avatar} alt='user' className={`user-image`} />
                                        <Executive className='executive-svg' />
                                    </div> :
                                    <img src={details?.profile || avatar} alt='user' className={`user-image`} />
                            }
                            <p>{details?.fname} {details?.lname}</p>
                            <span>Age {details?.age}, {details?.sex}</span>
                        </div>
                        <div className={`user-details`}>
                            <div className='d-flex'>
                                <span><img src={mail} alt="mail" className={`icon`} />{details?.email || "-"}</span>
                                <span><img src={phoneIcon} alt="phone" className={`icon`} />{details?.mobile || "-"}</span>
                            </div>
                            <div className='d-flex'>
                                <span className='span-location'><img src={locationIcon} alt="location" className={'location-custom'} /> {details?.address}, Zip code - {details?.zipcode}</span>
                            </div>
                        </div>
                    </div>
                    <div className={`user-measurements d-flex`}>
                        <div className='parent-permissions-div'>
                            <div>Permissions</div>
                            <div className='div-permissions'>
                                <div className={`permissions-btn ${details?.permission?.live_video ? 'active-permission-btn' : 'normal-btn'}`}>
                                    Live Video
                                </div>
                                <div className={`permissions-btn chat-btn ${details?.permission?.chat ? 'active-permission-btn' : 'normal-btn'}`}>
                                    Chat
                                </div>
                                <div className={`permissions-btn ${details?.permission?.view_video ? 'active-permission-btn' : 'normal-btn'}`}>
                                    View Video
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default MemberDetailsDialog;