import { FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';
import './SelectMenuFilled.scss';

const SelectMenuFilled = ({ value, onChange, options, where }) => {
  return (
    <FormControl
      size="small"
      className={where === "Notification" ? "Notification" : "Reports"}
      sx={{
        background: 'transparent', color: '#d8d8d8',
      }}
    >
      <Select variant={where === "Notification" ? "standard" : "outlined"} disableUnderline={where==="Notification"} defaultValue={"Year"} value={value} onChange={onChange} displayEmpty className={where === "Notification" ? 'select-mui' : where === "Reports" ? 'reports-mui' : ""}
        sx={{ 
          color: where === "Reports" ? "#000000" : '#d8d8d8',
          border: 'solid #9C9C9C 1px',
         }}
      >
        {options.map((option, index) => {
          return (
            <MenuItem key={index} value={option} >
              {option}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectMenuFilled;
