import { Box, Typography } from '@mui/material';
import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import './navilink.scss';

function VnhubNavLink({ icon: Icon, text, ...rest }) {
    const history = useHistory();
    let parentUrlState;
    switch (true) {
        case rest.to === "vnhub/device" && (history.location.pathname.startsWith("/vnhub/device")):
            parentUrlState = true;
            break;
        case rest.to === "vnhub/account" && (history.location.pathname.startsWith("/vnhub/device")):
            parentUrlState = true;
            break;
        default:
            parentUrlState = false;
            break;
    }

    return (
        <Box color="#9c9c9c" sx={{ margin: '1rem 0' }} >
            <NavLink {...rest} className={parentUrlState && 'active'}>
                <Box sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
                    <Box fontSize="12px">
                        <Icon />
                    </Box>
                    <Typography fontSize="14px" textAlign="center">
                        {text}
                    </Typography>
                </Box>
            </NavLink>
        </Box>
    )
}

export default VnhubNavLink