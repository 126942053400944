import React, { useRef } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers';
import "./Timepicker.scss";
import { Box, InputLabel } from '@mui/material';

const Timepicker = ({ value, onChange, labelText, helperText, placeholder,disabled}) => {
  const timePickerRef = useRef('');
  const clicked = () => {
    timePickerRef.current.querySelector('button').click();
  };
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
      <InputLabel
        sx={{
          color: '#D8D8D8',
          fontSize: '1rem',
          '&.Mui-focused': {
            color: '#D8D8D8',
          },
        }}
        shrink
      >
        {labelText}
      </InputLabel>
        <TimePicker
          ampm={true}
          InputProps={{
            disableUnderline: true,
          }}
          ref={timePickerRef}
          value={value || ''}
          onChange={onChange}
          // minTime={new Date('0','0','0',hours)}
          // maxTime={new Date('0','0','0',hours+11,59-minutes)}
          disabled={disabled}
          renderOptions
          renderInput={(params) => <TextField 
          sx={{
            'label + &': {
              color: '#9c9c9c',
              backgroundColor: '#fff',
              borderRadius: '0.5rem',
            },
            '& .MuiInputBase-input': {
              position: 'relative',
              width: '100%',
              fontSize: '1rem',
              padding: '0.5rem 1rem',
              color: '#000',
              '&::placeholder': {
                color: '#000',
                opacity: 0.6
              }
            },
            '&:focus': {
              color: '#9c9c9c',
            },
          }} 
          fullWidth required={true} size="small" error="false" variant='filled' {...params} inputProps={{
            ...params.inputProps,
            readOnly: true,
            placeholder: placeholder,
          }} onClick={clicked} />}
        />
      </LocalizationProvider>
      <Box sx={{ marginTop: '-1rem' }}>
        <label style={{ fontSize: '.7rem', fontWeight: '500', color: 'red', padding: '0 0 0 10px' }} >{helperText}</label>
      </Box>
    </>
  );
}
export default Timepicker