import { Box, Paper, Snackbar, Stack, Typography } from '@mui/material'
import React, { useContext, useState, useEffect } from 'react'
import Avatar from 'assets/img/default_image.png'
import styles from './Account.module.scss'
import { FaRegWindowClose } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import { CiLocationOn } from "react-icons/ci";
import { useLocation } from 'react-router-dom'
import { GlobalContext, actions } from 'context'
import AuthServices from 'api/services/auth-services'
import VnhubDialog from 'components/dialogs/VnhubConformBox/VnhubDialog'
import { ReactComponent as FallIcon } from 'assets/img/falldetection13x13.svg';


function Account() {

    const { dispatch } = useContext(GlobalContext);
    const storeHandler = (type, payload) => dispatch({ type, payload });
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const nhub_id = decodeURIComponent(searchParams.get('nhub_id'))
    const senior_id = decodeURIComponent(searchParams.get('senior_id'))

    const [registerSeniorsData, setRegisterSeniorsData] = useState({});
    const { fname, lname, age, gender, email, phone, address, city, country, } = registerSeniorsData || {}
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarContent, setSnackBarContent] = useState({ message: 'This is an error', class: 'error' });
    const [open, setOpen] = useState(false)

    const handleClose = (val) => {
        setOpen(val)
    }

    const snackbarNotification = (data) => {
        setSnackBarContent(data)
        setShowSnackBar(true)
    }

    const deleteUser = async () => {
        storeHandler(actions.SHOW_LOADER, true);
        try {
            const res = await AuthServices.deleteVnhubSenior(senior_id)
            storeHandler(actions.SHOW_LOADER, false);
            snackbarNotification({
                message: res.message,
                class: 'success'
            })
            const timeref = setTimeout(() => {
                window.close()
                clearTimeout(timeref)
            }, 4000)
        } catch (err) {
            const { data } = err || {}
            const { message } = data || {}
            storeHandler(actions.SHOW_LOADER, false);
            snackbarNotification({
                message,
                class: 'error'
            })
        }
    };

    const getAllSeniors = async () => {

        storeHandler(actions.SHOW_LOADER, true);

        try {
            const res = await AuthServices.getSeniorDetails(senior_id)
            if ([null, undefined].includes(res?.data?.nhub)) {
                window.close()
            } else {
                setRegisterSeniorsData(res?.data?.senior);
            }
            storeHandler(actions.SHOW_LOADER, false);

        } catch (err) {
            const { data } = err || {}
            const { message } = data || {}
            storeHandler(actions.SHOW_LOADER, false);
            snackbarNotification({
                message,
                class: 'error'
            })
        }
    };

    useEffect(() => {
        getAllSeniors();
        document.title = 'Ntel Virtual Nhub Portal';
        return () => {
            document.title = 'Ntelcare Admin Portal';
        }
    }, []);


    return (
        <Box className={`${styles.account_box}`}>
            <Box className={`${styles.title}`}>
                <Typography component='h1'>
                    Accounts
                </Typography>
            </Box>

            <Box className={`${styles.account_profile_box}`}>

                <Paper className={`${styles.account_box_paper}`}>

                    <Box className={`${styles.senior_title}`}>
                        <img src={Avatar} height={'75px'} />
                        <Stack direction={'column'}>
                            <Typography component={'h2'}>{fname} {lname}</Typography>
                            <Typography>Age {age || '-'}, {gender || '-'}</Typography>

                        </Stack>
                    </Box>

                    <Box sx={{ marginY: '2rem' }}>

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px', marginY: '.8rem' }}>
                            <MdOutlineEmail />
                            <Typography sx={{ fontSize: '.8rem' }}>
                                {email || '-'}
                            </Typography>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px', marginY: '.8rem' }}>
                            <BsTelephone />
                            <Typography sx={{ fontSize: '.8rem' }}>
                                {phone || '-'}
                            </Typography>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px', marginY: '.8rem' }}>
                            <CiLocationOn />
                            <Typography sx={{ fontSize: '.8rem' }}>
                                {address}, {city}, {country}
                            </Typography>
                        </Box>

                    </Box>

                    <Paper>
                        <Box className={styles.sensor_list}>
                            <FallIcon style={{ height: '1.2rem', width: '1.2rem' }} />
                        </Box>
                    </Paper>

                    <Box className={`${styles.delete_account}`} onClick={() => handleClose(true)}>
                        <Typography>Delete Account</Typography>
                        <FaRegWindowClose />
                    </Box>

                </Paper>

            </Box >

            <Snackbar open={showSnackBar}
                message={snackbarContent?.message || ''}
                className={snackbarContent?.class || ''}
                autoHideDuration={3000}
                onClose={setShowSnackBar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                sx={{
                    '.MuiSnackbarContent-root': {
                        color: '#fff',
                        fontWeight: '400',
                        backgroundColor: snackbarContent.class === 'error' ? 'red' : (snackbarContent.class === 'warning' ? '#ffcc00' : '#00b89f'),
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: '10rem'
                    }
                }}
            />

            <VnhubDialog
                open={open}
                handleClose={handleClose}
                handleConform={deleteUser}
                title={'Delete account confirmation'}
                text1={''}
                text2={`Are you sure you want to delete ${fname} ${lname} senior from this NHub device.`}
            />

        </Box >
    )
}

export default Account