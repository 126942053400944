import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { BsSearch } from 'react-icons/bs';
import "./SearchBar.scss";

const SearchBar = ({ search, tab, utility }) => {
  const [searchString, setSearchString] = useState('');

  return (
    <TextField
      size="small"
      hiddenLabel
      value={searchString}
      onChange={(e) => {
        setSearchString(e.target.value); 
        search(e.target.value);
      }}
      className='search-width'
      sx={{
        backgroundColor: '#1c2125',
        margin: 0,
        borderRadius: '10px',
        width: utility && '100%',
      }}
      // {...props}
      InputProps={{
        placeholder: utility ? 'Search MAC Addresses' : 'Search',
        disableUnderline: true,
        sx: { borderRadius: '10px',color: '#d8d8d8' },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              sx={{ color: 'primary.main' }}
              disableRipple={true}
              onClick={() => search(searchString)}
            >
              <BsSearch className='search-icon' />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBar;
