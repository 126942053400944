import DashHeader from 'components/dash-header/DashHeader';
import {
  styled,
  Button,
  Box,
  Paper,
  Snackbar,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import TabOne from './Tabs/TabOne/TabOne';
import TabTwo from './Tabs/TabTwo/TabTwo';
import TabThree from './Tabs/TabThree/TabThree';
import AuthServices from 'api/services/auth-services';
import { actions, GlobalContext } from 'context';
import "./Notification.scss";

const TabBtn = styled(Button)({
  backgroundColor: 'transparent',
  border: '0 solid',
  fontSize: '1rem',
  color: '#6C7175',
  fontWeight: '300',
  flexBasis: '30%',
  marginBottom: '0',
  minWidth: '100px'
});

const ActiveTabBtn = styled(Button)({
  backgroundColor: '#00B89F',
  border: '0 solid',
  fontSize: '1rem',
  color: '#D8D8D8',
  fontWeight: '300',
  flexBasis: '30%',
  marginBottom: '0',
  minWidth: '100px'
});

const TabNav = ({ activeIn, changeActiveIn }) => {
  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center', marginBottom: '2rem' }}
    >
      <Box
        sx={{
          minWidth: '30%',
          backgroundColor: '#1A1A1A',
          display: 'flex',
          justifyContent: 'space-between',
          borderRadius: '0.5rem'
        }}
      >
        {activeIn === 0 ? (
          <ActiveTabBtn variant='contained'>Bulk</ActiveTabBtn>
        ) : (
          <TabBtn onClick={() => changeActiveIn(0)}>Bulk</TabBtn>
        )}
        {activeIn === 1 ? (
          <ActiveTabBtn variant='contained'>Individual</ActiveTabBtn>
        ) : (
          <TabBtn onClick={() => changeActiveIn(1)}>Individual</TabBtn>
        )}
        {activeIn === 2 ? (
          <ActiveTabBtn variant='contained'>Conditional</ActiveTabBtn>
        ) : (
          <TabBtn onClick={() => changeActiveIn(2)}>Conditional</TabBtn>
        )}
      </Box>
    </Box>
  );
};

const Notification = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [seniors, setSeniors] = useState([]);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });

  const snackbarNotification = (data) => {
    setSnackBarContent(data);
    setShowSnackBar(true);
  }

  const { dispatch } = useContext(GlobalContext);

  const storeHandler = (type, payload) => dispatch({ type, payload })

  useState(() => {
    storeHandler(actions.SHOW_LOADER, true);
    AuthServices.getSeniorList().then(({ data }) => {
      setSeniors(data?.seniors)
      storeHandler(actions.SHOW_LOADER, false);
    }).catch((err) => {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    })
  }, [])

  return (
    <>
      <DashHeader
        pageTitle="Notification message"
        usrImg="https://randomuser.me/api/portraits/men/79.jpg"
      />
      <div className="wrap">
        <TabNav activeIn={activeIndex} changeActiveIn={setActiveIndex} />
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Paper sx={{ padding: '2rem', maxWidth: activeIndex === 2 ? '50rem' : 'auto' ,width: activeIndex === 2 ? '100%' : activeIndex === 1 ? '37.5rem' : 'fit-content' }}>
            {activeIndex === 0 && <TabOne />}
            {activeIndex === 1 && <TabTwo seniors={seniors} storeHandler={storeHandler} snackbarNotification={snackbarNotification} />}
            {activeIndex === 2 && <TabThree snackbarNotification={snackbarNotification} />}
          </Paper>
        </Box>
      </div>
      <Snackbar open={showSnackBar}
        message={snackbarContent?.message || ''}
        className={snackbarContent?.class || ''}
        autoHideDuration={2000}
        onClose={setShowSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
        sx={{
          '.MuiSnackbarContent-root': {
            color: '#fff',
            fontWeight: '400',
            backgroundColor: snackbarContent?.class === 'error' ? 'red' : (snackbarContent?.class === 'warning' ? '#ffcc00' : '#00b89f'),
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '10rem'
          }
        }}
      />
    </>
  );
};

export default Notification;
