import { Button, Dialog, Stack, Typography } from "@mui/material";
import { Box } from "@mui/material";
import React, { useState } from "react";
// import spO2 from "assets/img/pulse-oximeter.png";
import { ReactComponent as BPMIcon } from "assets/img/bpm_icon.svg";
import { ReactComponent as StepsIcon } from "assets/img/steps_icon.svg";
import { ReactComponent as BatteryIcon } from "assets/img/battery_icon.svg";
import { ReactComponent as Spo2Icon } from "assets/img/act.svg";
import { ReactComponent as SleepIcon } from "assets/img/sleep_icon.svg";
import { ReactComponent as HeartRateIcon } from "assets/img/heart_rate_gray.svg";
import { ReactComponent as TickIcon } from "assets/img/tick_icon.svg";
import FeatureInfo from "app/notification/components/styled-componets/FeatureInfo";
import SimpleInputField from "components/simple-inputfield/SimpleInputField";
import { TransparentBtn } from "app/notification/components/styled-componets/style";
import "./WatchNotifications.scss";
import { CustomDialogTitle } from "components/dialogs/custom-dialog-title/CustomDialogTitle";

const WatchNotifications = ({ open, handleClose, saveConfig }) => {
  const [selected, setSelected] = useState("heart_rate");
  const [invalid, setInvalid] = useState({});
  const [invalidModes, setInvalidModes] = useState(false);

  const [settings, setSettings] = useState({
    heart_rate: {
      device: "watch",
      sensor: "heart_rate",
      message: "",
      lesser_message: "",
      max: "",
      min: "",
      noti_type: { email: false, sms: false, push: false }
    },
    blood_pressure: {
      device: "watch",
      sensor: "blood_pressure",
      message: "",
      lesser_message: "",
      max_sys: "",
      min_sys: "",
      max_dia: "",
      min_dia: "",
      noti_type: { email: false, sms: false, push: false }
    },
    sleep: {
      device: "watch",
      sensor: "sleep",
      message: "",
      lesser_message: "",
      max: "",
      min: "",
      noti_type: { email: false, sms: false, push: false }
    },
    steps: {
      device: "watch",
      sensor: "steps",
      message: "",
      lesser_message: "",
      max: "",
      min: "",
      noti_type: { email: false, sms: false, push: false }
    },
    blood_oxygen: {
      device: "watch",
      sensor: "blood_oxygen",
      message: "",
      lesser_message: "",
      max: "",
      min: "",
      noti_type: { email: false, sms: false, push: false }
    },
    battery: {
      device: "watch",
      sensor: "battery",
      message: "",
      // lesser_message: "",
      max: "100",
      min: "",
      noti_type: { email: false, sms: false, push: false }
    },
  });
  const [bool, setBool] = useState(false);

  const handleModeClick = (e) => {
    const mode = e.target.name;
    const prev = settings[selected].noti_type[mode];
    const prevSettings = settings[selected];
    setSettings(prevState => ({...prevState, [selected] : {...prevSettings, noti_type : {...prevSettings.noti_type, [mode]: !prev}} }));
    invalid.modes = false;
    setInvalidModes(false)

  }

  const handleChange = (value, key, sensor) => {
    let data = settings;
    data[sensor][key] = value;
    setSettings(data);
    setBool((prev) => !prev);
    const newInvalid = { ...invalid };
    newInvalid[selected][key] = false;
    setInvalid(newInvalid);
  };

  const validate = () => {
    let invalidData = {};
    let valid = true;
    Object.keys(settings[selected]).forEach((key) => {
      if (settings[selected][key] === "") {
        invalidData[key] = true;
        valid = false;
      }
    })
    setInvalid({ ...invalid, [selected]: invalidData });
    const notificatonModes = {...settings?.[selected]?.noti_type}
    const isValidModes = Object.values(notificatonModes).some(item => item)
    setInvalidModes(!isValidModes)
    if (valid && isValidModes) {
      saveConfig(settings[selected]);
      handleClose();
    }
  }
  const isNumber = (val) => {
    const regex = new RegExp(/^\d+$/);
    return regex.test(val) || !val
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      BackdropProps={{ style: { backgroundColor: "black", opacity: "0.9" } }}
      className="dialog-watch"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
       <div style={{position:"relative" , display: 'flex', direction: 'row', justifyContent: 'center' }}>
          <Typography sx={{mt:".3rem", mb: '2rem', color: 'primary.main', fontWeight: 'bold', fontSize: '1rem' }}>
          Critical Event Notifications
            </Typography>
            <CustomDialogTitle onClose={() => {handleClose()}} style={{padding:0,margin:0 , position:"absolute" , right:"0" , top:"0"}} ></CustomDialogTitle>
        </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0rem 0 3rem 0",
          gap: "0.8rem",
        }}
      >
        <FeatureInfo
          className={`default-icon ${selected === "heart_rate" && "active-watch-icon" }`}
          primary={selected === "heart_rate"}
          onClick={() => setSelected("heart_rate")}
          icon={HeartRateIcon}
        >
          Heart Rate
        </FeatureInfo>
        <FeatureInfo
          className={`default-icon ${selected === "blood_pressure" && "active-watch-icon" }`}
          primary={selected === "blood_pressure"}
          onClick={() => setSelected("blood_pressure")}
          icon={BPMIcon}
        >
          BP
        </FeatureInfo>
        <FeatureInfo
          className={`default-icon ${selected === "sleep" && "active-watch-icon" }`}
          primary={selected === "sleep"}
          onClick={() => setSelected("sleep")}
          icon={SleepIcon}
        >
          Sleep
        </FeatureInfo>
        <FeatureInfo
          special={true}
          className={`spo2 ${selected === "steps" && "active-watch-icon" }`}
          primary={selected === "steps"}
          onClick={() => setSelected("steps")}
          icon={StepsIcon}
        >
          Steps
        </FeatureInfo>
        <FeatureInfo
          stroke={true}
          className={`default-icon ${selected === "blood_oxygen" && "active-watch-icon" }`}
          primary={selected === "blood_oxygen"}
          onClick={() => setSelected("blood_oxygen")}
          icon={Spo2Icon}
        >
          SPO2
        </FeatureInfo>
        <FeatureInfo
          stroke={true}
          className={`default-icon ${selected === "battery" && "active-watch-icon" }`}
          primary={selected === "battery"}
          onClick={() => setSelected("battery")}
          icon={BatteryIcon}
        >
          Battery
        </FeatureInfo>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box className="notifications-box-second">
          <Box className="notification-content">
            {selected === "blood_pressure" ? (
              <Box style={{display:'grid'}}>
                <Stack className="stack-row">
                  <Box className="box-row">
                    <SimpleInputField
                      type="Other"
                      helperText={invalid[selected]?.min_sys && "Please enter the value"}
                      value={settings[selected]?.max_sys}
                      labelText="Sys Max"
                      onChange={(event) =>{
                        if(!isNumber(event.target.value)) return
                          if(event.target.value.length <= 3) {
                        handleChange(event.target.value, "max_sys", selected)}}
                      }
                    />
                  </Box>
                  <Box>
                    <SimpleInputField
                      type="Other"
                      helperText={invalid[selected]?.max_sys && "Please enter the value"}
                      value={settings[selected]?.min_sys}
                      labelText="Sys Min"
                      onChange={(event) =>{
                        if(!isNumber(event.target.value)) return
                        if(event.target.value.length <=3){
                        handleChange(event.target.value, "min_sys", selected)}}
                      }
                    />
                  </Box>
                </Stack>
                <Stack className="stack-row">
                  <Box className="box-row">
                    <SimpleInputField
                      type="Other"
                      helperText={invalid[selected]?.max_dia && "Please enter the value"}
                      value={settings[selected]?.max_dia}
                      labelText="Dia Max"
                      onChange={(event) =>{
                        if(!isNumber(event.target.value)) return
                        if(event.target.value.length <=3){
                        handleChange(event.target.value, "max_dia", selected)}}
                      }
                    />
                  </Box>
                  <Box>
                    <SimpleInputField
                      type="Other"
                      helperText={invalid[selected]?.min_dia && "Please enter the value"}
                      value={settings[selected]?.min_dia}
                      labelText="Dia Min"
                      onChange={(event) =>{
                        if(!isNumber(event.target.value)) return
                        if(event.target.value.length <=3){
                        handleChange(event.target.value, "min_dia", selected)}}
                      }
                    />
                  </Box>
                </Stack>
              </Box>
            ) : (
              <Box style={{display:'grid',width: '35%'}}> 
              {
                  selected !== 'battery' &&
                  (
                    <Box>
                <SimpleInputField
                  type="Other"
                  helperText={invalid[selected]?.max && "Please enter the value"}
                  value={settings[selected]?.max}
                  labelText="Greater than"
                  placeholder={selected === 'sleep' ? 'Hours': ''}
                  onChange={(event) =>{
                    if(!isNumber(event.target.value)) return
                      let maxlen =  selected === 'sleep' ? 2: 3
                      if(event.target.value.length <= maxlen || selected === 'steps'){
                    handleChange(event.target.value, "max", selected)}}
                  }
                />
                </Box>)}
                <Box>
                <SimpleInputField
                  type="Other"
                  helperText={invalid[selected]?.min && "Please enter a value"}
                  value={settings[selected]?.min}
                  placeholder={selected === 'sleep' ? 'Hours': ''}
                  labelText="Lesser than"
                  onChange={(event) =>{
                    if(!isNumber(event.target.value) || (selected === "battery" && event.target.value >  100)) return
                      let maxlen =  selected === 'sleep' ? 2: 3
                      if(event.target.value.length <= maxlen  || selected === 'steps' ){
                    handleChange(event.target.value, "min", selected)}}
                  }
                />
                </Box>
              </Box>
            )}
            <Box className="notification-content-second">
          
                    <SimpleInputField
                    type="Other"
                    value={settings[selected]?.message}
                    helperText={invalid[selected]?.message && "Message is required"}
                    labelText={`${selected === 'battery' ? "CEN for Lesser than" : "CEN for Greater than"} `}
                    onChange={(event) =>
                      handleChange(event.target.value, "message", selected)
                    }
                    multiline={8}
                    placeholder="Enter Critical Event Notification"
                    maxRows={8}
                  />
                  
                {
                  selected !== 'battery' &&
                  (
              <SimpleInputField
                type="Other"
                value={settings[selected]?.lesser_message}
                helperText={invalid[selected]?.lesser_message && "Message is required"}
                labelText="CEN for Lesser than"
                onChange={(event) =>
                  handleChange(event.target.value, "lesser_message", selected)
                }
                multiline={8}
                placeholder="Enter Critical Event Notification"
                maxRows={8}
              />)
            }
            </Box>
          </Box>
        </Box> 
      </Box>
      {invalidModes && <Box sx={{color: 'red', fontSize: '0.85rem'}}>Please select at least one medium</Box>}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.8rem' }}>
            <TransparentBtn sx={{ color: settings[selected].noti_type?.email ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='email' variant="outlined">Email</TransparentBtn>
            <TransparentBtn sx={{ color: settings[selected].noti_type?.sms ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='sms' variant="outlined">SMS</TransparentBtn>
            <TransparentBtn sx={{ color: settings[selected].noti_type?.push ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='push' variant="outlined">Push</TransparentBtn>
          </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{ color: "#fff", width: "15rem" }}
          startIcon={<TickIcon />}
          onClick={validate}
        >
          Save
        </Button>
      </Box>
    </Dialog>
  );
};

export default WatchNotifications;
