import { Button, MenuItem, Paper, Snackbar, Stack, Typography } from '@mui/material';
import InputField from 'components/inputfield/InputField';
import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as NextIcon } from 'assets/img/next_icon.svg';
import { actions, GlobalContext } from 'context';
import AuthServices from 'api/services/auth-services';

const CreAccStep1 = ({ changeTab }) => {

  const { dispatch } = useContext(GlobalContext)
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'success' })

  const snackbarNotification = (data) => {
    setSnackBarContent(data)
    setShowSnackBar(true)
  }

  const [options, setOptions] = useState([])
  const [category, setCategory] = useState('');

  const storeHandler = (type, payload) => dispatch({ type, payload });

  const selectCategory = () => {
    storeHandler(actions.CATEGORY_ID, category);
    registerAccount();
  }

  const registerAccount = async () => {
    const formData = new FormData();
    formData.append('category_id', category);
    try {
      storeHandler(actions.SHOW_LOADER, true);
      const data = await AuthServices.createMasterAccount(formData);
      const { message, mas_acc_id } = data;
      storeHandler(actions.MASTER_ACCOUNT_ID, mas_acc_id);
      storeHandler(actions.SHOW_LOADER, false);
      changeTab(1);
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    }
  }

  const handleChange = (event) => {
    setCategory(event.target.value);
  }

  const getContract = async () => {
    try {
      storeHandler(actions.SHOW_LOADER, true);
      const data = await AuthServices.getContractDetails()
      setOptions(data['category'] || {})
      setCategory(data['category']?.[0]?.['id'] || {})
      storeHandler(actions.CONTRACT_DATA, { ...data });
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    }
  }

  useEffect(() => {
    getContract();
  }, [])

  return (
    <Stack direction="row" justifyContent="center" sx={{ marginTop: '2rem' }}>
      <Paper sx={{ borderRadius: '1rem', width: '40rem', padding: '2.5rem' }}>
        <Typography sx={{ color: '#D8D8D8', marginBottom: '0.5rem' }}>
          Account category
        </Typography>
        {options?.length > 0 && <InputField sx={{ backgroundColor: "#fff" }} fullWidth select value={category} onChange={handleChange}>
          {options.map((option) => {
            return (
              <MenuItem key={option?.id} value={option?.id} >
                {option.name || ''}
              </MenuItem>
            );
          })}
        </InputField>}
        <Button
          fullWidth
          variant="contained"
          color="primary"
          sx={{ color: '#fff', marginTop: '2.5rem' }}
          onClick={selectCategory}
          endIcon={<NextIcon />}
        >
          Next
        </Button>
      </Paper>
      <Snackbar open={showSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={snackbarContent?.message || ''}
        autoHideDuration={4000}
        onClose={() => setShowSnackBar(false)}
        sx={{
          '.MuiSnackbarContent-root': {
            color: '#fff',
            fontWeight: '400',
            backgroundColor: snackbarContent.class === 'error' ? 'red' : (snackbarContent.class === 'warning' ? '#ffcc00' : '#00b89f'),
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '10rem'
          }
        }}
      />
    </Stack>
  );
};

export default CreAccStep1;