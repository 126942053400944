import { IconButton,styled,Button} from "@mui/material";
import { useTheme} from "@mui/material/styles";
import { getIcon } from "app/dashboard/icons";
import styles from './table-pagination-actions.module.scss';

const useTabBtn = styled((Button) => ({
  root: {
    flexShrink: 0,
    marginLeft: Button.spacing(2.5),
  },
}));

export const TablePaginationActions = (props) => {
  const classes = useTabBtn();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root} style={{display:'flex'}}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        className={styles.paginationIconButton}
      >
        {theme.direction === 'rtl' ? getIcon('LastPageIcon', '#00B89F', styles.paginationIcon) : getIcon('FirstPageIcon', '#00B89F', styles.paginationIcon)}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} className={styles.paginationIconButton} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? getIcon('KeyboardArrowRight', '#00B89F', styles.paginationIcon) : getIcon('KeyboardArrowLeft', '#00B89F', styles.paginationIcon)}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        className={styles.paginationIconButton}
      >
        {theme.direction === 'rtl' ? getIcon('KeyboardArrowLeft', '#00B89F', styles.paginationIcon) : getIcon('KeyboardArrowRight', '#00B89F', styles.paginationIcon)}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        className={styles.paginationIconButton}
      >
        {theme.direction === 'rtl' ? getIcon('FirstPageIcon', '#00B89F', styles.paginationIcon) : getIcon('LastPageIcon', '#00B89F', styles.paginationIcon)}
      </IconButton>
    </div>
  );
}