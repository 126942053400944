export const getTimein24HrFormat = (time) => {
  const timeString = time?.toLocaleTimeString('en-US', { hour12: false, hour: 'numeric', minute: 'numeric' });
  return timeString;
}

export const getUserTimeZome = () => {
  const timeZoneArray = Intl.DateTimeFormat().resolvedOptions();
  if (timeZoneArray.timeZone) {
    return timeZoneArray.timeZone;
  }
  return new Error("Invalid time zone");
};

export const getTimeZoneOffset = () => {
  function toHoursAndMinutes(totalMinutes) {
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);

    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  const _date = new Date();
  const _offset = _date.getTimezoneOffset();
  const splited = _offset.toString().split("-");
  return splited?.length > 1
    ? `+${toHoursAndMinutes(splited[1])}`
    : `-${toHoursAndMinutes(splited[0])}`;
};