import React, { useState } from 'react'
import { Box, Tab, Avatar, Stack, Typography } from '@mui/material'
import Tabs, { tabsClasses } from '@mui/material/Tabs'


const TabCard = ({ senior }) => {
    const { profile, senior_id, room_no, name, age, gender } = senior || {}

    return (
        <>
            <Stack direction="row" spacing={1.5} flex={1}>
                <Stack alignItems="center" justifyContent="center">
                    <Box sx={{ width: '4rem' }}>
                        <Avatar
                            sx={{ width: '3.2rem', height: '3.2rem', marginRight: '0rem', marginBottom: "0.2rem", marginX: 'auto' }}
                            src={profile}
                            alt=""
                        />
                    </Box>
                    <Typography
                        sx={{ fontSize: '0.6rem', color: '#9c9c9c', marginTop: "0.2rem" }}
                    >{`ID: ${senior_id}`}</Typography>


                </Stack>
                <Box flex={1} style={{}}>
                    <div style={{}}>
                        <Typography
                            sx={{
                                textAlign: 'start',
                                fontSize: '.9rem',
                                color: 'primary.main',
                                marginBottom: '0.25rem',
                                hyphens: 'manual',
                                textTransform: 'none'
                            }}
                        >
                            {name}
                        </Typography>

                    </div>

                    <Typography
                        sx={{
                            textAlign: 'start',
                            fontSize: '0.65rem',
                            color: '#9c9c9c',
                            marginBottom: '0.25rem',
                        }}
                    >
                        {`Age ${age || ''}, ${gender}`}
                    </Typography>

                    {room_no && <Typography sx={{ textAlign: 'start', fontSize: '0.65rem', color: '#9c9c9c', marginTop: "0.2rem" }}>
                        Room No : {room_no || '-'}
                    </Typography>}

                </Box>
            </Stack>
        </>
    )
}

export default function SeniorCards({ list }) {

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', my: '1rem' }}>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                aria-label="visible arrows tabs example"

                sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                    },
                    '& .MuiTabs-scroller': {
                        '& .MuiTabs-indicator': {
                            display: 'none',
                        },
                        '& .MuiTabs-flexContainer': {
                            gap: '10px',
                        },
                    },
                }}
            >
                {list?.map((item, index) => (
                    <Tab
                        key={index}
                        disableRipple
                        sx={{ background: '#24292d', borderRadius: '10px' }}
                        label={<TabCard senior={item} />}
                    />
                ))}
            </Tabs>
        </Box>
    )
}
