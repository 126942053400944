import { Avatar, Box, Stack, Typography } from '@mui/material';
import React from 'react';

const ExecutiveMemberLink = ({ im, name, onClick }) => {
  return (
    <Box
      sx={{
        border: '2px solid #9c9c9c',
        cursor: 'pointer',
        paddingTop: '0.25rem',
        paddingBottom: '0.25rem',
        paddingLeft: '0.25rem',
        borderRadius: '1rem',
        width: 'max-content',
        minWidth: '8rem',
      }}
      onClick={onClick}
    >
      <Stack direction="row" sx={{ gap: '0.5rem' }} alignItems="center">
        <Avatar
          sx={{ height: '1.5rem', width: '1.5rem' }}
          src={im}
          alt={name}
        />
        <Typography
          flex={1}
          sx={{
            fontSize: '0.7rem',
            fontWeight: '300',
            color: '#9c9c9c',
          }}
        >
          {name}
        </Typography>
      </Stack>
    </Box>
  );
};

export default ExecutiveMemberLink;
