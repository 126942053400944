import React, { useContext, useState } from "react";
import { Button, Box, Stack, Tabs, Tab } from "@mui/material";
// import moment from "moment";
import SimpleInputField from "components/simple-inputfield/SimpleInputField";
import { TransparentBtn } from "../../../components/styled-componets/style";
import AuthServices from "api/services/auth-services";
import { actions, GlobalContext } from "context";
import { ReactComponent as TickIcon } from "assets/img/tick_icon.svg";
import { getTimeZoneOffset, getUserTimeZome } from "utils/datetime-formatter";

const SubTabThreeSos = ({
  currentSelected,
  selectedSenior,
  addOrSave,
  goBack,
  snackbarNotification,
  setInvalidSenior,
  macId,
  ...rest
}) => {
  const initialState = {
    "device": "SOS",
    "message": ""
  };

  const { dispatch } = useContext(GlobalContext);
  const storeHandler = (type, payload) => dispatch({ type, payload });

  const [settings, setSettings] = useState({ ...initialState });
  const [invalid, setInvalid] = useState({});

  const [notificatonModes, setNotificationModes] = useState({ email: false, sms: false, push: false });
  const [invalidModes, setInvalidModes] = useState(false);


  const handleModeClick = (e) => {
    const mode = e.target.name;
    const prev = notificatonModes[mode];
    setNotificationModes({ ...notificatonModes, [mode]: !prev });
    invalid.modes = false;
    setInvalidModes(false)

  }

  const handleChange = (value, key) => {
    let data = settings;
    data[key] = value;
    setInvalid({ ...invalid, [key]: false });
    setSettings(data);
  };

  const validate = () => {
    let data = settings;
    let invalidData = {};
    let isValid = true;
    if (currentSelected === "seniorSpecific") {
      if (!selectedSenior?.id) {
        invalidData["senior"] = true;
        isValid = false;
        setInvalidSenior({ senior: true });
      }
    }
    Object.keys(data).forEach((key) => {
      if (data[key] === "") {
        invalidData[key] = true;
        isValid = false;
      }
    });
    setInvalid(invalidData);
    const isValidModes = Object.values(notificatonModes).some(item => item)
    setInvalidModes(!isValidModes)
    if (isValid && (currentSelected == "generic" || isValidModes))
      handleAdd();
  };

  const handleAdd = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      if (currentSelected === "generic") {
        const notification = { ...settings, timezone: getUserTimeZome(), origin: getTimeZoneOffset() };
        const { message } = await AuthServices.addGenericNotifications(
          notification
        );
        snackbarNotification({ message: message, class: 'success' });
      } else {
        const { message } = await AuthServices.addCentricNotifications(
          selectedSenior?.id,
          { ...settings, noti_type: notificatonModes, timezone: getUserTimeZome(), origin: getTimeZoneOffset(),mac_id:macId }
        );
        snackbarNotification({ message: message, class: 'success' });
      }
      goBack();
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    }
  };
  const [value, setValue] = React.useState(1);

	const handleTabChange = (event, newValue) => {
		setValue(newValue);
	};



  return (
    <>
      <Stack>
        <Box sx={{ paddingTop: '1.5rem' }}>
          <SimpleInputField
            labelText="CEN"
            multiline={8}
            placeholder="Enter Critical Event Notification"
            type="Other"
            helperText={invalid?.message ? "Please enter a valid message" : ""}
            value={settings?.message}
            onChange={(event) => handleChange(event.target.value, "message")}
          />
        </Box>
      </Stack>
      {
        currentSelected !== "generic" && (
          <>
            {invalidModes && <Box sx={{ color: 'red', fontSize: '0.85rem' }}>Please select at least one medium</Box>}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.8rem' }}>
              <TransparentBtn sx={{ color: notificatonModes?.email ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='email' variant="outlined">Email</TransparentBtn>
              <TransparentBtn sx={{ color: notificatonModes?.sms ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='sms' variant="outlined">SMS</TransparentBtn>
              <TransparentBtn sx={{ color: notificatonModes?.push ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='push' variant="outlined">Push</TransparentBtn>
            </Box>
          </>
        )
      }
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{ color: "#fff", width: "15rem", marginBottom: "5rem" }}
          startIcon={<TickIcon />}
          onClick={validate}
        >
          {addOrSave === "add" ? "Add" : "Update"}
        </Button>
      </Box>
    </>
  );
};

export default SubTabThreeSos;
