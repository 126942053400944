import { Button, Dialog, Stack, MenuItem, Typography } from "@mui/material";
import { Box } from '@mui/material'
import { ReactComponent as TickIcon } from 'assets/img/tick_icon.svg';
import SimpleInputField from "components/simple-inputfield/SimpleInputField";
import SelectInputMod from 'app/create-account/Components/select-input-mod/SelectInputMod';
import { TransparentBtn } from "app/notification/components/styled-componets/style";
// import Timepicker from "components/time-picker/Timepicker";
import React, { useState } from 'react'
// import { getTimein24HrFormat } from "utils/datetime-formatter";
import "./PillboxNotifications.scss";
import { format, isValid } from 'date-fns'
import { CustomDialogTitle } from "components/dialogs/custom-dialog-title/CustomDialogTitle";
import { fontWeight, style } from "@mui/system";

const refillArr = ['30','45','60','75','90','105','120','135','150', '165', '180']

const PillboxNotifications = ({ open, handleClose, saveConfig }) => {
  const initialState = {
    device: "PillBox",
    pills_perday: "",
    times_perday: "",
    pills: [
      {
        time: null,
        message: null,
      },
      {
        time: null,
        message: null,
       },
       
      {
        time: null,
        message: null,
      },
    ],
    schedule_message: "",
    refill_interval: "",
    refill_message: "",
    morning: "",
    afternoon: "",
    night: "",
  };

  const timings = Array.from({ length: 24 }).map((item, i) => i < 12
  ? (i === 0 ? `${12}:00 am` : `${i}:00 am`)
  : (i === 12 ? `${12}:00 pm` : `${i - 12}:00 pm`))

  const [settings, setSettings] = useState({ ...initialState });
  const [invalid, setInvalid] = useState({});
  const [invalidModes, setInvalidModes] = useState(false);
  const [medsPerDay, setMedsPerDay] = useState('3')

  const [notificatonModes, setNotificationModes] = useState({ email: false, sms: false, push: false });
  const handleModeClick = (e) => {
    const mode = e.target.name;
    const prev = notificatonModes[mode];
    setNotificationModes({ ...notificatonModes, [mode]: !prev });
    invalid.modes = false;
    setInvalidModes(false)

  }

  const handleChange = (value, key, index, pill) => {
    let data = settings;
    if (pill) {
      const date = new Date().toLocaleDateString()
      const val = pill === "time" ? new Date(`${date} ${value}`).toUTCString() : value
      data[key][index][pill] = val;
      setInvalid({ ...invalid, [pill]: false, noPillData: false });
    } else {
      data[key] = value;
      setInvalid({ ...invalid, [key]: false });
    }
    setSettings(data);
  };


  const validate = () => {
    let data = settings;
    let invalidData = {};
    if (!data.refill_interval) {
      invalidData.refill_interval = true;
    }
    if (!data.refill_message) {
      invalidData.refill_message = true;
    }
    let timesChoosed = 0;

    data?.pills?.forEach(item => {
      if(item.time) {
        timesChoosed = timesChoosed + 1
      }
    })

    if (timesChoosed < Number(medsPerDay)) {
      invalidData.noPillData = true
    }

    let isAllMessageAvailalbe = data?.pills?.every(item => !!item.time ? !!item.message : true )

    if(!isAllMessageAvailalbe) {
      invalidData.noMessage = true
    }
    setInvalid(invalidData);
    const isValidModes = Object.values(notificatonModes).some(item => item)
    setInvalidModes(!isValidModes)
    if (Object.keys(invalidData).length > 0 || !isValidModes) {
      return false;
    }
    saveConfig({...data, noti_type: notificatonModes});
    handleClose();
  };
  const getValue = (date) => {
    return isValid(new Date(date)) ? format(new Date(date), 'h:mm aaa') : date
  }

  const isNumber = (val) => {
    const regex = new RegExp(/^\d+$/);
    return regex.test(val) || !val
  }
  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      BackdropProps={{ style: { backgroundColor: 'black', opacity: "0.9" } }}
      className="dialog-pill"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box width="40rem">
        <div style={{position:"relative" , display: 'flex', direction: 'row', justifyContent: 'center'}}>
          <Typography sx={{mt:".3rem", mb: '2rem', color: 'primary.main', fontWeight: 'bold', fontSize: '1rem' }}>
              Critical Event Notification
            </Typography>
            <CustomDialogTitle onClose={() => {handleClose()}} style={{padding:0,margin:0 , position:"absolute" , right:"0" , top:"0"}} ></CustomDialogTitle>
        </div>
        <Box className="w-xs-100" sx={{ "& .MuiFormControl-root": { width: "165px", marginRight: '1rem' } }}>
          <Typography sx={{ fontSize: "0.8rem", paddingBottom: "0.5rem" }}> Medications Per Day</Typography>
          <SelectInputMod sx={{ "& .MuiSelect-select": { padding: "0.5rem", paddingRight: "32px" } }} value={medsPerDay} onChange={(event) => setMedsPerDay(event.target.value)}>
            {['1', '2', '3'].map((option, index) => <MenuItem key={index} value={option} > {`Meds ${option || '0'}x`} </MenuItem>)}
          </SelectInputMod>
        </Box>
        <div className="d-flex flex-row justify-content-between">
        
        <div className="d-flex flex-wrap flex-row justify-content-start">
          <Box sx={{"& .MuiFormControl-root": { width: "165px",marginRight: '1rem'}}}>
            <Typography sx={{ fontSize: "0.8rem", paddingBottom: "0.5rem" }}> Meds 1 </Typography>
            <SelectInputMod sx={{ "& .MuiSelect-select": { padding: "0.5rem", paddingRight: "32px" } }} value={settings?.pills?.[0]?.time ? getValue(settings?.pills?.[0]?.time) : ''} helperText={invalid?.noPillData && !settings?.pills?.[0]?.time && 'Required'}  onChange={(event) => handleChange(event.target.value, "pills", 0, "time")}>
              {timings.map((option, index) => <MenuItem key={index} value={option} > {option || ''} </MenuItem>)}
            </SelectInputMod>
          </Box>
          {medsPerDay > 1 &&  <Box sx={{"& .MuiFormControl-root": { width: "165px",marginRight: '1rem'}}}>
            <Typography sx={{ fontSize: "0.8rem", paddingBottom: "0.5rem" }}> Meds 2</Typography>
            <SelectInputMod sx={{ "& .MuiSelect-select": { padding: "0.5rem", paddingRight: "32px" } }} value={settings?.pills?.[1]?.time ? getValue(settings?.pills?.[1]?.time) : ''} helperText={invalid?.noPillData && !settings?.pills?.[1]?.time && 'Required'} onChange={(event) => handleChange(event.target.value, "pills", 1, "time")}>
              {timings.map((option, index) => <MenuItem key={index} value={option} > {option || ''} </MenuItem>)}
            </SelectInputMod>
          </Box>}
          {medsPerDay > 2 && <Box sx={{"& .MuiFormControl-root": { width: "165px",marginRight: '1rem'}}}>
            <Typography sx={{ fontSize: "0.8rem", paddingBottom: "0.5rem" }}> Meds 3</Typography>
            <SelectInputMod sx={{ "& .MuiSelect-select": { padding: "0.5rem", paddingRight: "32px" } }} value={settings?.pills?.[2]?.time ? getValue(settings?.pills?.[2]?.time) : ''} helperText={invalid?.noPillData && !settings?.pills?.[2]?.time && 'Required'} onChange={(event) => handleChange(event.target.value, "pills", 2, "time")}>
              {timings.map((option, index) => <MenuItem key={index} value={option} > {option || ''} </MenuItem>)}
            </SelectInputMod>
          </Box>}
          <Box className="w-xs-100" sx={{ "& .MuiFormControl-root": { width: "165px", marginRight: '1rem' } }}>
          <Typography sx={{ fontSize: "0.8rem", paddingBottom: "0.5rem" }}> Refill Interval</Typography>
          <SelectInputMod helperText={invalid?.refill_interval && 'Required'} sx={{ "& .MuiSelect-select": { padding: "0.5rem", paddingRight: "32px" } }} value={settings?.refill_interval || ''} onChange={(event) => handleChange(event.target.value, "refill_interval")}>
            {refillArr.map((option, index) => <MenuItem key={index} value={option.toString()} > {option} days </MenuItem>)}
          </SelectInputMod>
        </Box>
          {/* <Box
          sx={{
            "& .MuiFormControl-root": {
              width: "150px",
            },
          }}
        >
          <SimpleInputField
            labelText="Refill Interval"
            placeholder="0"
            type="Other"
            helperText={invalid?.refill_interval && "Required"}
            value={settings?.refill_interval}
            marginBottom0={true}
            onChange={(event) =>{
              if(!isNumber(event.target.value)) return
                if(event.target.value.length <=3){
              handleChange(event.target.value, "refill_interval")}}
            }
          />
        </Box> */}
        </div>
      </div>
        <Stack
        direction="row"
        // spacing={10}
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: 'wrap',
          justifyContent: "space-between",
          margin: "2rem 0 1rem 0",
        }}
      >

        <Stack sx={{ width: '45%' }}>

          <Box>
            <SimpleInputField
              labelText="1st Medication"
              multiline={8}
              placeholder="Enter your message"
              maxRows={8}
              type="Other"
              helperText={((invalid.noPillData && !settings?.pills?.[0]?.message) ||(invalid.noMessage &&  settings?.pills?.[0]?.time)) && !settings?.pills?.[0]?.message && "Required"}

              value={settings?.pills?.[0]?.message}
              onChange={(event) =>
                handleChange(event.target.value, "pills", 0, "message")
              }
            />
          </Box>
        </Stack>
        {medsPerDay > 1 && <Stack
          sx={{
            width: '45%',
            justifyContent: "space-between",
          }}
        >
          <Box>
            <SimpleInputField
              labelText="2nd Medication"
              multiline={8}
              placeholder="Enter your message"
              maxRows={8}
              type="Other"
              helperText={((invalid.noPillData && !settings?.pills?.[1]?.message) || (invalid.noMessage &&  settings?.pills?.[1]?.time && !settings?.pills?.[1]?.message))  && "Required"}
              value={settings?.pills?.[1]?.message}

              onChange={(event) =>
                handleChange(event.target.value, "pills", 1, "message")
              }
            />
          </Box>
        </Stack>}
        {medsPerDay > 2 &&  <Stack
          sx={{
            width: '45%',
            justifyContent: "space-between",
          }}
        >
          <Box>
            <SimpleInputField
              labelText="3rd Medication"
              multiline={8}
              placeholder="Enter your message"
              maxRows={8}
              type="Other"
              value={settings?.pills?.[2]?.message}
              helperText={((invalid.noPillData && !settings?.pills?.[2]?.message) ||(invalid.noMessage &&  settings?.pills?.[2]?.time && !settings?.pills?.[2]?.message )) && "Required"}
              onChange={(event) =>
                handleChange(event.target.value, "pills", 2, "message")
              }
            />
          </Box>
        </Stack>}
        <Stack
          sx={{
            width: '45%',
            justifyContent: "space-between",
          }}
        >
          <Box>
            <SimpleInputField
              labelText="Refill Message"
              multiline={8}
              helperText={invalid?.refill_message && "Refill message is required"}
              placeholder="Enter your message"
              maxRows={8}
              type="Other"
              value={settings?.refill_message}

              onChange={(event) =>
                handleChange(event.target.value, "refill_message")
              }
            />
          </Box>
        </Stack>
      </Stack>
      {invalidModes && <Box sx={{color: 'red', fontSize: '0.85rem'}}>Please select at least one medium</Box>}

          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.8rem' }}>
            <TransparentBtn sx={{ color: notificatonModes?.email ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='email' variant="outlined">Email</TransparentBtn>
            <TransparentBtn sx={{ color: notificatonModes?.sms ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='sms' variant="outlined">SMS</TransparentBtn>
            <TransparentBtn sx={{ color: notificatonModes?.push ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='push' variant="outlined">Push</TransparentBtn>
          </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{ color: "#fff", width: "15rem" }}
            startIcon={<TickIcon />}
            onClick={validate}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default PillboxNotifications

