import React, { useContext, useEffect, useState } from "react";
import { Button, Box, Stack, MenuItem, Typography } from "@mui/material";
import moment from "moment";
import SimpleInputField from "components/simple-inputfield/SimpleInputField";
import { TransparentBtn } from "../../components/styled-componets/style";
import AuthServices from "api/services/auth-services";
import { actions, GlobalContext } from "context";
import { ReactComponent as TickIcon } from "assets/img/tick_icon.svg";
import Timepicker from "components/time-picker/Timepicker";
import SelectInputMod from "app/create-account/Components/select-input-mod/SelectInputMod";
import { getTimeZoneOffset, getUserTimeZome } from "utils/datetime-formatter";
// import { getTimein24HrFormat } from "utils/datetime-formatter";

const BathRoomInputs = ({
	currentSelected,
	selected,
	setting,
	handleFinish,
	snackbarNotification,
	isGeneric,
	macId,
	...rest
}) => {
	const { dispatch } = useContext(GlobalContext);
	const storeHandler = (type, payload) => dispatch({ type, payload });

	const [settings, setSettings] = useState({});
	const [invalid, setInvalid] = useState({});
	const [bool, setBool] = useState(false);
	const [notificatonModes, setNotificationModes] = useState({ email: setting?.notification_type?.email, sms: setting?.notification_type?.sms, push: setting?.notification_type?.push });
	const [invalidModes, setInvalidModes] = useState(false);
	const [isValid, setIsValid] = useState(true)
	const MinArr = [
		"1",
		"2",
		"3",
		"4",
		"5",
		"6",
		"7",
		"8",
		"9",
		"10",
		"11",
		"12",
	];
	const MaxArr = [
		"1",
		"2",
		"3",
		"4",
		"5",
		"6",
		"7",
		"8",
		"9",
		"10",
		"11",
		"12",
		"13",
		"14",
		"15",
		"16",
		"17",
		"18",
		"19",
		"20",
		"21",
		"22",
		"23",
		"24",
	];
	useEffect(() => {
		const data = {
			...setting,
			start_time: setting?.thresholds?.start_time,
			end_time: setting?.thresholds?.end_time,
			min: setting?.thresholds?.min,
			max: setting?.thresholds?.max,
		}
		setSettings({ ...data })
	}, [isGeneric, setting])

	const handleModeClick = (e) => {
		const mode = e.target.name;
		const prev = notificatonModes[mode];
		setNotificationModes({ ...notificatonModes, [mode]: !prev });
		invalid.modes = false;
		setInvalidModes(false);
	};

	const handleChange = (value, key) => {
		let invalidData = { ...invalid };
		invalidData[key] = false;
		let data = settings;
		data[key] = value;
		setSettings({ ...data });
		// setInvalid(newInvalid);
		if (key === "start_time" && settings?.end_time) {
			if (moment.utc(value).diff(settings.end_time, "minute") > 0) {
				invalidData.start_time = "Start time should be less than end time";
				invalidData.end_time = false;
				setIsValid(false)
			} else if (moment.utc(value).diff(settings.end_time, "minute") === 0) {
				invalidData.start_time = "Start time and end time should not be equal";
				invalidData.end_time = "Start time and end time should not be equal";
				setIsValid(false)
			} else {
				invalidData.start_time = false;
				invalidData.end_time = false;
				setIsValid(true)
			}
		} else if (key === "end_time" && settings?.start_time) {
			if (moment.utc(settings.start_time).diff(value, "minute") > 0) {
				invalidData.end_time = "End time should be greater than start time";
				invalidData.start_time = false;
				setIsValid(false)
			} else if (moment.utc(value).diff(settings.start_time, "minute") === 0) {
				invalidData.start_time = "Start time and end time should not be equal";
				invalidData.end_time = "Start time and end time should not be equal";
				setIsValid(false)
			} else {
				invalidData.start_time = false;
				invalidData.end_time = false;
				setIsValid(true)
			}
		} else if (key === "min" && settings?.max) {
			if (Number(value) > Number(settings.max)) {
				invalidData.min = "min should be less than max";
				invalidData.max = false;
				setIsValid(false)
			} else if (Number(value) === Number(settings.max)) {
				invalidData.min = "min and max should not be equal";
				invalidData.max = "min and max should not be equal";
				setIsValid(false)
			} else {
				invalidData.min = false;
				invalidData.max = false;
				setIsValid(true)
			}
		} else if (key === "max" && settings?.min) {
			if (Number(settings.min) > Number(value)) {
				invalidData.max = "max should be greater than min";
				invalidData.min = false;
				setIsValid(false)
			} else if (Number(value) === Number(settings?.min)) {
				invalidData.max = "min and max should not be equal";
				invalidData.min = "min and max should not be equal";
				setIsValid(false)
			} else {
				invalidData.min = false;
				invalidData.max = false;
				setIsValid(true)
			}
		}
		else {
			invalid[key] = false;
		}
		if (key == "min") { data.thresholds.min = value }
		else if (key == "max") { data.thresholds.max = value }
		setInvalid((prevInvalid) => ({
			...prevInvalid,
			...invalidData,
		}));
		setBool((prev) => !prev);
	};

	const validate = () => {
		let data = settings;
		// let invalidData = {};
		let isvalid = true;
		if (data?.min_message === "") {
			invalid.min_message = "Please enter valid input";
			isvalid = false
		}
		if (data?.max_message === "") {
			invalid.max_message = "Please enter valid input";
			isvalid = false
		}
		const isValidModes = Object.values(notificatonModes).some((item) => item);
		setInvalidModes(!isValidModes);
		if (isValid && isvalid && (currentSelected == "generic" || isValidModes)) { handleEdit(); }
	};

	const handleEdit = async () => {
		storeHandler(actions.SHOW_LOADER, true);
		try {
			const data = { ...settings, start_time: moment(settings.start_time).toISOString(), end_time: moment(settings.end_time), min: settings?.min, max: settings?.max, timezone: getUserTimeZome(), origin: getTimeZoneOffset() }
			if (currentSelected === "generic") {
				const { message } = await AuthServices.editGenericNotifications(data);
				snackbarNotification({ message, class: "success" });
			} else {
				const { message } = await AuthServices.editCentricNotifications(
					selected?.id,
					{ ...data, noti_type: notificatonModes, timezone: getUserTimeZome(), origin: getTimeZoneOffset(),mac_id:macId }
				);
				snackbarNotification({ message, class: "success" });
			}
			handleFinish(true);
			storeHandler(actions.SHOW_LOADER, false);
		} catch (err) {
			const { data } = err || {};
			const { message } = data || {};
			snackbarNotification({
				message,
				class: "error",
			});
			storeHandler(actions.SHOW_LOADER, false);
		}
	};

	return (
		<>
			<Stack>
				<Stack
					direction="row"
					// spacing={10}
					sx={{
						width: "100%",
						display: "flex",
						flexWrap: "wrap",
						justifyContent: "space-between",
						margin: "2rem 0 1rem 0",
					}}
				>
					<Stack className="w-xs-100" sx={{ width: "45%" }}>
						<Box width="100%">
							<Timepicker
								labelText="Start Time"
								placeholder="00:00"
								helperText={invalid?.start_time ? invalid?.start_time : ""}
								value={settings?.start_time}
								onChange={(newValue) => handleChange(newValue, "start_time")}
							/>
						</Box>
					</Stack>
					<Stack className="w-xs-100" sx={{ width: "45%" }}>
						<Box width="100%">
							<Timepicker
								labelText="End Time"
								placeholder="00:00"
								helperText={invalid?.end_time ? invalid?.end_time : ""}
								value={settings?.end_time}
								onChange={(newValue) => handleChange(newValue, "end_time")}
							/>
						</Box>
					</Stack>
				</Stack>
				<Stack>
					<Stack
						direction="row"
						// spacing={10}
						sx={{
							width: "100%",
							display: "flex",
							flexWrap: "wrap",
							justifyContent: "space-between",
							margin: "0rem 0 1rem 0",
						}}
					>
						<Box
							width="100%"
							sx={{
								display: "flex",
								justifyContent: "space-between",
								flexWrap: "wrap",
							}}
						>
							<Box className="w-xs-100" width="45%">
								<Typography
									sx={{ fontSize: "0.8rem", paddingBottom: "0.5rem" }}
								>
									Min Number of Visits in a Day
								</Typography>
								<SelectInputMod
									helperText={invalid?.min && invalid?.min}
									sx={{
										"& .MuiSelect-select": {
											padding: "0.5rem",
											paddingRight: "32px",
										},
									}}
									value={settings?.thresholds?.min || ''}
									onChange={(event) => handleChange(event.target.value, "min")}
								>
									{MinArr.map((option, index) => (
										<MenuItem key={index} value={option.toString()}>
											{option || " "}
										</MenuItem>
									))}
								</SelectInputMod>
							</Box>
							<Box className="w-xs-100" width="45%">
								<Typography
									sx={{ fontSize: "0.8rem", paddingBottom: "0.5rem" }}
								>
									Max Number of Visits in a Day
								</Typography>
								<SelectInputMod
									helperText={invalid?.max && invalid?.max}
									sx={{
										"& .MuiSelect-select": {
											padding: "0.5rem",
											paddingRight: "32px",
											maxHeight: "10px",
										}
									}}
									value={settings?.thresholds?.max || ''}
									onChange={(event) => handleChange(event.target.value, "max")}
								>
									{MaxArr.map((option, index) => (
										<MenuItem key={index} value={option.toString()}>
											{option || " "}
										</MenuItem>
									))}
								</SelectInputMod>
							</Box>
						</Box>
					</Stack>
				</Stack>
				<Stack
					direction="row"
					sx={{
						width: "100%",
						display: "flex",
						flexWrap: "wrap",
						justifyContent: "space-between",
						margin: "1rem 0 1rem 0",
					}}
				>
					<Stack className="w-xs-100" sx={{ width: "45%" }}>
						<Box>
							<SimpleInputField
								labelText="CEN for Min Number of Visits in a Day"
								multiline={8}
								placeholder="Enter Critical Event Notification"
								type="Other"
								helperText={
									invalid?.min_message ? "Please enter a valid message" : ""
								}
								value={settings?.min_message}
								onChange={(event) =>
									handleChange(event.target.value, "min_message")
								}
							/>
						</Box>
					</Stack>
					<Stack className="w-xs-100" sx={{ width: "45%" }}>
						<Box>
							<SimpleInputField
								labelText="CEN for Max Number of Visits in a Day"
								multiline={8}
								placeholder="Enter Critical Event Notification"
								type="Other"
								helperText={
									invalid?.max_message ? "Please enter a valid message" : ""
								}
								value={settings?.max_message}
								onChange={(event) =>
									handleChange(event.target.value, "max_message")
								}
							/>
						</Box>
					</Stack>
				</Stack>
			</Stack>
			{currentSelected !== "generic" && (
				<>
					{invalidModes && (
						<Box sx={{ color: "red", fontSize: "0.85rem" }}>
							Please select at least one medium
						</Box>
					)}
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							marginBottom: "0.8rem",
						}}
					>
						<TransparentBtn
							sx={{ color: notificatonModes?.email ? "#00b89f" : "#4d4c4c" }}
							onClick={handleModeClick}
							name="email"
							variant="outlined"
						>
							Email
						</TransparentBtn>
						<TransparentBtn
							sx={{ color: notificatonModes?.sms ? "#00b89f" : "#4d4c4c" }}
							onClick={handleModeClick}
							name="sms"
							variant="outlined"
						>
							SMS
						</TransparentBtn>
						<TransparentBtn
							sx={{ color: notificatonModes?.push ? "#00b89f" : "#4d4c4c" }}
							onClick={handleModeClick}
							name="push"
							variant="outlined"
						>
							Push
						</TransparentBtn>
					</Box>
				</>
			)}
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Button
					variant="contained"
					sx={{ color: "#fff", width: "15rem", marginBottom: "5rem" }}
					startIcon={<TickIcon />}
					onClick={validate}
				>
					Update
				</Button>

			</Box>
		</>
	);
};

export default BathRoomInputs;
