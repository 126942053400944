import React from 'react';
import Button from '@mui/material/Button';
import { ReactComponent as NextIcon } from 'assets/img/next_icon.svg';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import "./dialog.scss";
import { CustomDialogTitle } from 'components/dialogs/custom-dialog-title/CustomDialogTitle';

export default function AlertDialog({ text, subText, open, handleClose, handleAccept }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        BackdropProps={{ style: { backgroundColor: 'black', opacity: "0.9" } }}
        className="dialog-box"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          sx={{
            color: '#D8D8D8',
            margin: '0 2rem',
            marginTop: '0.5rem',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ mt: ".3rem", mb: '2rem', color: 'primary.main', fontWeight: 'bold', fontSize: '1rem', textAlign: 'center' }}>
            {text}
            <br />
            {subText || ''}
          </Typography>
          <CustomDialogTitle onClose={() => { handleClose() }} style={{ padding: 0, margin: 0, position: "absolute", right: "0", top: "0" }} ></CustomDialogTitle>
          <Button
            variant="contained"
            color="primary"
            sx={{
              color: '#D8D8D8',
              marginTop: '1.5rem',
              marginBottom: '0rem',
              width: '55%',
              whiteSpace: 'noWrap',
              fontWeight: '300',
              fontSize: '1.1rem',
            }}
            endIcon={<NextIcon />}
            onClick={() => handleAccept(3)}
          >
            Yes, Next step
          </Button>
        </Box>
      </Dialog>
    </div>
  );
}