const LOG_IN = 'LOG_IN';
const SHOW_LOADER = 'SHOW_LOADER';
const USER_PROFILE = 'USER_PROFILE';
const ACCOUNTS_DATA = 'ACCOUNTS_DATA';
const DASHBOARD_DATA = 'DASHBOARD_DATA';
const CONTRACT_DATA = 'CONTRACT_DATA'
const SHOW_NAV = 'SHOW_NAV';
const MASTER_ACCOUNT_ID = 'MASTER_ACCOUNT_ID';
const PLAN_ID = 'PLAN_ID';
const CATEGORY_ID = 'CATEGORY_ID';
const USER_IMAGE = 'USER_IMAGE';
const USERS_DATA = 'USERS_DATA';
const DURATION = 'DURATION';
const MAINTENANCE = 'MAINTENANCE';

export {
  LOG_IN,
  SHOW_LOADER,
  ACCOUNTS_DATA,
  SHOW_NAV,
  DASHBOARD_DATA,
  CONTRACT_DATA,
  MASTER_ACCOUNT_ID,
  PLAN_ID,
  CATEGORY_ID,
  USER_PROFILE,
  USER_IMAGE,
  USERS_DATA,
  DURATION,
  MAINTENANCE
};  
