import React, { useContext, useState } from 'react';
import { Box, Button, Paper, Snackbar, Typography } from '@mui/material';
import logo from 'assets/img/logo.png';
import styles from './login.module.scss';
import InputField from 'components/inputfield/InputField';
import sendIcon from 'assets/img/SendIcon.svg';
import { Link } from 'react-router-dom';
import { GlobalContext, actions } from 'context';
import { isMobileValid, isPhonenoValid, mobileFormatter } from 'utils/validator';
import AuthServices from 'api/services/auth-services';

const INVALID_MOBILE = 'mobile number is invalid';
const INVALID_PASSWORD = 'password is mismatch';
const NOT_ADMIN = 'You are not Admin';

const inputValidationMessage = {
  mobile: 'Enter Mobile Number',
  password: 'Enter Password',
  invaild: 'Invalid Credientials',
  emptyMobile: 'Enter a mobile number',
  inValidMobileNumber: 'Enter a 10-digit mobile number'
}

const Notification = {
  mobile: { message: 'Please enter a valid mobile number', class: 'error' },
  wrongMobile: { message: 'Check the Mobile Number', class: 'error' },
  wrongPassword: { message: 'The password was found incorrect !!', class: 'error' },
  notAdmin: { message: 'Please register as an Admin to login', class: 'error' },
  error: { message: 'We are facing some issues, Please try later!!', class: 'error' }
}

const Login = () => {
  const { dispatch } = useContext(GlobalContext);
  const [credentials, setCredentials] = useState({});
  const [validation, setValidation] = useState([]);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({ message: 'This is an error', class: 'error' });
  const inputFields = ['mobile', 'password'];
  const [wrongMobile, setWrongMobile] = useState();
  const [mobile, setMobile] = useState();
  const [invalidMobile, setInvalidMobile] = useState(false);
  const [message, setMessage] = useState('');

  const snackbarNotification = (data) => {
    setSnackBarContent(data)
    setShowSnackBar(true)
  }

  const validate = (e) => {
    e.preventDefault()
    let invalidFields = [];
    inputFields.forEach(item => {
      if (!credentials[item]) {
        invalidFields.push(item)
      }
    })
    if (invalidFields.length) {
      setValidation([...invalidFields])
    } else {
      if (!invalidMobile) {
        setValidation([]);
        logIn();
      }
      if (invalidMobile) {
        if (credentials['mobile'] === '') {
          setMessage(inputValidationMessage.emptyMobile)
        } else {
          setMessage(inputValidationMessage.inValidMobileNumber)
        }
      }
    }
  }

  const loginInfo = (key, value) => {
    const invalidFields = [...validation];
    const index = invalidFields.indexOf(key);
    if (index > -1) {
      invalidFields.splice(index, 1);
      setValidation([...invalidFields]);
    }
    if (key === "mobile") {
      if (value.toString().length > 10) {
        setInvalidMobile(false)
      } else if (value.toString().length === 10) {
        setInvalidMobile(false)
        const data = { ...credentials, [key]: value };
        setCredentials({ ...data });
      } else if (isMobileValid(value)) {
        const data = { ...credentials, [key]: value };
        setCredentials({ ...data });
        if (isPhonenoValid(value)) {
          setInvalidMobile(false)
        } else if (value === '') {
          setMessage(inputValidationMessage.emptyMobile)
        } else {
          setInvalidMobile(true)
          setMessage(inputValidationMessage.inValidMobileNumber)
        }
      } else {
        setInvalidMobile(true)
        if (value === '') {
          setMessage(inputValidationMessage.emptyMobile)
        } else {
          setMessage(inputValidationMessage.inValidMobileNumber)
        }
      }
    } else {
      const data = { ...credentials, [key]: value };
      setCredentials({ ...data });
    }
  };

  const storeHandler = (type, payload) => dispatch({ type, payload });

  const logIn = async () => {
    const formData = new FormData();
    for (let key in credentials) {
      formData.append(key, credentials[key])
    }
    setWrongMobile('')
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const { access_token, refresh_token, message } = await AuthServices.login(formData);
      snackbarNotification({
        message,
        class: 'success'
      })
      localStorage.setItem('authToken', access_token);
      localStorage.setItem('refreshToken', refresh_token);
      setTimeout(() => storeHandler(actions.LOG_IN, true), 1000);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      const { data } = err || {}
      const { message } = data || {}
      if (message === INVALID_MOBILE) {
        setWrongMobile('Check the Mobile Number')
        snackbarNotification(Notification.wrongMobile)
      }
      else if (message === INVALID_PASSWORD) {
        snackbarNotification(Notification.wrongPassword)
      }
      else if (message === NOT_ADMIN) {
        snackbarNotification(Notification.notAdmin)
      } else {
        snackbarNotification({
          message,
          class: 'error'
        });
      }
      storeHandler(actions.SHOW_LOADER, false);
    }
  }

  const mobileValidator = (event) => {
    const value = event?.target?.value?.split('.')?.join('');
    if (value?.length > 0 && value?.length <= 10)
      setInvalidMobile(!isPhonenoValid(value))
    else setInvalidMobile(false)
  }

  const handleChangeMobile = (event) => {
    const value = event?.target?.value?.split('.')?.join('')
    let newVal = value.replaceAll(/[a-zA-Z !@#\$%\^\&*\)\(+=_-\{\}\[\]\<\>\?\,\.\/\|\\\'\"\;\:\-\~]/ig,'')

    setMobile(mobileFormatter(newVal))
    loginInfo('mobile', newVal);
  }

  return (
    <Paper elevation={0} style={{ width: '27rem' }}>
      <Box
        style={{
          position: 'relative',
          width: '17.5rem',
          margin: '0 auto 2rem auto',
        }}
      >
        <img className={styles.logo} src={logo} alt="logo" />
        <Typography
          sx={{
            color: 'primary.main',
            position: 'absolute',
            bottom: '0',
            right: '0',
            letterSpacing: '0.5rem',
          }}
        >
          Admin Portal
        </Typography>
      </Box>
      <form onSubmit={validate}>
        <InputField
          sx={{ '.MuiFormHelperText-root': { color: 'red', fontWeight: '600' } }} label="Mobile Number"
          variant="filled" fullWidth type="text" value={mobile || ''}
          helperText={(validation.includes('mobile') && message) || (invalidMobile && message)}
          onChange={(event) => { mobileValidator(); handleChangeMobile(event); }}
        />
        <InputField
          sx={{ '.MuiFormHelperText-root': { color: 'red', fontWeight: '600' } }}
          label="Password" variant="filled" fullWidth type="password" passwordfield={true} value={credentials?.password || ''}
          helperText={(validation.includes('password') && inputValidationMessage.password)}
          onChange={(event) => loginInfo('password', event.target.value)}
        />
        <span>
          <Button
            variant="contained" color="primary" fullWidth sx={{ textShadow: '1px 0 0 currentColor' }}
            startIcon={<Box mr={1.5} pb={0} mb={-1}><img src={sendIcon} alt="" /> </Box>} type='submit'
          >
            Sign in
          </Button>
        </span>
      </form>
      <Typography color="primary" textAlign="center">
        <Link to="/forgot-password">Forgot password</Link>
      </Typography>
      <Snackbar open={showSnackBar}
        message={snackbarContent?.message || ''}
        className={snackbarContent?.class || ''}
        autoHideDuration={4000}
        onClose={setShowSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
        sx={{
          '.MuiSnackbarContent-root': {
            color: '#fff',
            fontWeight: '400',
            backgroundColor: snackbarContent.class === 'error' ? 'red' : (snackbarContent.class === 'warning' ? '#ffcc00' : '#00b89f'),
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '10rem'
          }
        }}
      />
    </Paper>
  );
};

export default Login;
