import React, { useContext, useEffect, useState } from "react";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
  Box,
  Snackbar,
} from "@mui/material";
import { ExtraInfo, TransparentBtn } from "../../components/styled-componets/style";
import SimpleInputField from 'components/simple-inputfield/SimpleInputField';
import AuthServices from "api/services/auth-services";
import { actions, GlobalContext } from "context";
import { ReactComponent as TickIcon } from "assets/img/tick_icon.svg";
import EmailDialog from "app/notification/components/email-dialog/EmailDialog";
const TabOne = () => {
  const { dispatch } = useContext(GlobalContext);

  const [value, setValue] = useState(0);
  const [status, setStatus] = useState({});

  const [notificatonModes, setNotificationModes] = useState({ email: false, sms: false, push: false });

  const masterUnchecked = { master: false, senior: false, executive: false, member: false }
  const masterChecked = { master: true, senior: true, executive: true, member: true }

  const [checkedState, setCheckedState] = useState(masterUnchecked)
  const [message, setMessage] = useState('')
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'success' })
  const [invalid, setInvalid] = useState({});

  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [invalidModes, setInvalidModes] = useState(false);


  const snackbarNotification = (data) => {
    setSnackBarContent(data)
    setShowSnackBar(true)
  }

  const storeHandler = (type, payload) => dispatch({ type, payload });

  const handleModeClick = (e) => {
    const mode = e.target.name;
    const prev = notificatonModes[mode];
    setNotificationModes({ ...notificatonModes, [mode]: !prev });
    invalid.modes = false;
    setInvalidModes(false)

  }

  const handleChange = (check) => {
    setInvalid({ ...invalid, accountTypeCount: false })
    switch (check) {
      case 0: checkedState?.master === false ? setCheckedState(masterChecked) : setCheckedState(masterUnchecked)
        break
      case 1: checkedState?.senior === false ? setCheckedState({ ...checkedState, senior: true }) :
        setCheckedState({ ...checkedState, master: false, senior: false })
        break;
      case 2: checkedState?.executive === false ? setCheckedState({ ...checkedState, executive: true }) :
        setCheckedState({ ...checkedState, master: false, executive: false })
        break;
      case 3: checkedState?.member === false ? setCheckedState({ ...checkedState, member: true }) :
        setCheckedState({ ...checkedState, master: false, member: false })
        break;
      default: break;
    }
  }

  const handleMessageChange = (e) => {
    //do not allow initial spaces
    if (e.target.value[0] === ' ')
      return;
    setMessage(e.target.value)
    invalid.message = false;
  }

  const validate = () => {
    const newInvalid = { ...invalid };
    let valid = true;
    let count = notificatonModes.email + notificatonModes.sms + notificatonModes.push;
    let accountTypeSelected = 0;
    for (let key in checkedState) {
      if (checkedState[key] === true)
        accountTypeSelected++;
    }
    if (accountTypeSelected === 0){
      newInvalid.accountTypeCount = true;
      valid = false;}
    if (count === 0) {
      newInvalid.modes = true;
      valid = false;
    }
    setInvalid(newInvalid);

    if (message?.length <= 0) {
      newInvalid.message = true;
      valid = false;
    }
    const isValidModes = Object.values(notificatonModes).some(item => item)
    setInvalidModes(!isValidModes)

    if (valid  && isValidModes)
      if (notificatonModes?.email === true)
        setEmailDialogOpen(true)
      else
        handleSend()
  }

  const handleSend = async () => {
    const formData = new FormData();
    Object.keys(checkedState).forEach((key) => {
      formData.append(key, checkedState[key])
    })
    Object.keys(notificatonModes).forEach((key) => {
      formData.append(key, notificatonModes[key])
    })
    formData.append("message", message)
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const data = await AuthServices.sendBulkNotification(formData);
      snackbarNotification({
        message: data?.message,
        class: 'success'
      });
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    }
  }

  const getBulkNotificationStatus = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const data = await AuthServices.getBulkNotificationStatus();
      setStatus(data)
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    }
  }

  useEffect(() => {
    getBulkNotificationStatus()
  }, [])

  useEffect(() => {
    let count = 0;
    (checkedState?.senior && checkedState?.executive && checkedState?.member) && setCheckedState(masterChecked)
    checkedState?.master ===true ? setValue(status?.masters) :
      Object.keys(checkedState).forEach((key) => {
        if (checkedState[key]) {
          count += status[`${key}s`]
        }
      })
    checkedState?.master !== true && setValue(count)
  }, [checkedState])

  return (
    <div style={{ height: "60%" }}>
      <FormGroup>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormControlLabel
            sx={{ marginRight: '1.5rem', color: 'primary.main' }}
            control={<Checkbox checked={checkedState?.master} onChange={() => handleChange(0)} />}
            label="Master"
          />
          <FormControlLabel
            sx={{ marginRight: '1.5rem', color: 'primary.main' }}
            control={<Checkbox checked={checkedState?.senior} onChange={() => handleChange(1)} />}
            label="Seniors"
          />
          <FormControlLabel
            sx={{ marginRight: '1.5rem', color: 'primary.main' }}
            control={<Checkbox checked={checkedState?.executive} onChange={() => handleChange(2)} />}
            label="Executives"
          />
          <FormControlLabel
            sx={{ color: 'primary.main' }}
            control={<Checkbox checked={checkedState?.member} onChange={() => handleChange(3)} />}
            label="Members"
          />
        </Box>
      </FormGroup>
      <ExtraInfo>{
        invalid?.accountTypeCount ? <Box sx={{ fontSize: '0.8rem', fontWeight: '500', color: 'red' }}>Please select accounts</Box> :
          <Box sx={{ fontSize: '0.8rem', fontWeight: '500' }}>{value} people will get notified</Box>
      }
      </ExtraInfo>
      <SimpleInputField helperText={invalid?.message && `Message is required`} placeholder="Please enter a message" labelText="Broadcast message" multiline={4} type="Other" value={message} onChange={handleMessageChange} />
      {invalidModes && <Box sx={{color: 'red', fontSize: '0.85rem'}}>Please select at least one medium</Box>}
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1.5rem' }}>
        <TransparentBtn sx={{ color: notificatonModes?.email ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='email' variant="outlined">Email</TransparentBtn>
        <TransparentBtn sx={{ color: notificatonModes?.sms ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='sms' variant="outlined">SMS</TransparentBtn>
        <TransparentBtn sx={{ color: notificatonModes?.push ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='push' variant="outlined">Push</TransparentBtn>
      </Box>
      {/* <Box sx={{ display: 'flex', flexDirections: 'row', justifyContent: 'center', marginBottom: '1.5rem', fontSize: '0.9rem', fontWeight: '500', color: 'red', }}>
        <label>{invalid?.modes && `Please select at least one medium`}</label>
      </Box> */}
      <Box >
        <Button
          variant="contained"
          fullWidth
          sx={{ color: '#fff' ,marginBottom:"5rem"}}
          startIcon={<TickIcon />}
          onClick={validate}
        >
          Send Notifications
        </Button>
      </Box>
      <EmailDialog open={emailDialogOpen} func={() => {
        handleSend();
        setEmailDialogOpen(false);
      }} handleClose={() => setEmailDialogOpen(false)} />
      <Snackbar open={showSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={snackbarContent?.message || ''}
        autoHideDuration={4000}
        onClose={() => setShowSnackBar(false)}
        sx={{
          '.MuiSnackbarContent-root': {
            color: '#fff',
            fontWeight: '400',
            backgroundColor: snackbarContent.class === 'error' ? 'red' : (snackbarContent.class === 'warning' ? '#ffcc00' : '#00b89f'),
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '10rem'
          }
        }}
      />
    </div>
  );
};
export default TabOne;
