import { Avatar, Box, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as EngineerIcon } from 'assets/img/EngineerHelmet.svg';
import { ReactComponent as EmailIcon } from 'assets/img/email_icon.svg';
import { ReactComponent as PhoneIcon } from 'assets/img/phone_icon.svg';
import "./UserRolesUserCard.scss";

const UserRolesUserCard = ({ name, phone, email, isExecutive, image, minWidth, handleClick }) => {
  const { sm, md, lg, xl } = minWidth
  return (
    <Paper
      sx={{
        width: {
          xs: '100%',
          sm: '88.5%',
          md: '19rem',
          marginBottom:'1.5rem',
        },
        minWidth: {
          sm,
          md,
          lg,
          xl,
        },
        maxWidth: '20rem'
      }}
      className="card-paper"
      onClick={handleClick}
    >
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            position: 'relative',
            width: '4rem',
            height: '4rem',
            marginRight: '0.5rem',
          }}
        >
          <Avatar
            src={image}
            alt=""
            sx={{ width: '4rem', height: '4rem', marginRight: '1rem' }}
          />
          {isExecutive==='engineer' && (
            <Box
              sx={{
                position: 'absolute',
                top: '2.6rem',
                transform: 'scale(2)',
                right: '0.1rem',
              }}
            >
              <EngineerIcon style={{ width: "0.7rem", height: "0.7rem" }} />
            </Box>
          )}
        </Box>
        <Box>
          <Typography sx={{ color: 'primary.main', marginBottom: '0.5rem', maxWidth: "14rem", overflowWrap: 'break-word', hyphens: 'manual' }}>
            {name}
          </Typography>
          <Stack
            mb="0.5rem"
            direction="row"
            alignItems="center"
            spacing="0.5rem"
          >
            <EmailIcon />
            <Typography sx={{ fontSize: '0.7rem', color: '#d8d8d8', overflowWrap: "break-word" }}>
              {email}
            </Typography>
          </Stack>
          <Stack
            mb="0.5rem"
            direction="row"
            alignItems="center"
            spacing="0.5rem"
          >
            <PhoneIcon />
            <Typography sx={{ fontSize: '0.7rem', color: '#d8d8d8' }}>
              {phone}
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Paper>
  );
};

export default UserRolesUserCard;
