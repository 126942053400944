import {
  Box,
  Paper,
  Typography,
  Button,
  Snackbar,
  IconButton,
  Switch,
  FormControlLabel,
} from "@mui/material";
import AddUserInput from "components/add-user-input/AddUserInput";
import DashHeader from "components/dash-header/DashHeader";
import { ReactComponent as TickIcon } from "assets/img/tick_icon.svg";
import { ReactComponent as Avatar } from "assets/avatar.svg";
import { ReactComponent as UploadIcon } from "assets/img/upload_icon.svg";
import React, { useContext, useEffect, useState } from "react";
import {
  isEmailValid,
  isMobileValid,
  isName,
  isPhonenoValid,
  mobileFormatter,
  vpnValidator,
} from "utils/validator";
import AuthServices from "api/services/auth-services";
import { actions, GlobalContext } from "context";
import { useHistory } from "react-router";
import { RiDeleteBin6Line as DeleteIcon } from "react-icons/ri";
import { MdKeyboardBackspace as BackIcon } from "react-icons/md";
import ConfirmationDialog from "components/dialogs/ConfirmationDialog/ConfirmationDialog";
import closeIcon from "assets/img/close.svg";
const Notification = {
  profile: { message: "Please upload a profile image", class: "error" },
  invalidProfile: { message: 'Please upload a valid profile image', class: 'error' },
  phone: { message: "Please enter a valid phone number", class: "error" },
  email: { message: "Please enter a valid email address", class: "error" },
  mobile: { message: "Please enter a valid mobile number", class: "error" },
  passwordMatch: {
    message: "Password and Confirm Password do not match",
    class: "error",
  },
  passwordLenErr: {
    message: "Password should be minimum 8 characters",
    class: "error",
  },
  incomplete: { message: "Please fill all the fields", class: "error" },
  editSuccess: { message: "Profile updated successfully", class: "success" },
  error: {
    message: "We are facing some issues, Please try later!!",
    class: "error",
  },
};

const EditUser = () => {
  const {
    state: { userProfile: current, userProfile },
    dispatch,
  } = useContext(GlobalContext);
  const storeHandler = (type, payload) => dispatch({ type, payload });
  const history = useHistory();

  const initialState = {
    fname: "",
    lname: "",
    email: "",
    mobile: "",
    profile: "",
    accountType: "",
  };

  const [isProfileIMG, setIsProfileIMG] = useState();
  const [userDetails, setUserDetails] = useState({ ...initialState });
  const [validation, setValidation] = useState([]);
  const [invalidMobile, setInvalidMobile] = useState(false);
  const [passwordLenErr, setPasswordLenErr] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [inValid, setInValid] = useState([]);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [mobile, setMobile] = useState();
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });
  const [handleDeleteStaff, setDeleteStaff] = useState(false);

  const [profileImgFile, setFile] = useState({});
  const [profileImgName, setFileName] = useState({});
  const [profileImgUrl, setProfileImgUrl] = useState(current.profile);
  const [uploaded, setUploaded] = useState(false);
  const [id, setId] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  const inputFields = ["fname", "lname", "email", "mobile"];

  const uploadFile = (e) => {
    const file = e?.target?.files[0];
    if (file?.type?.startsWith("image") && file.type !== "image/gif") {
      setFile(file);
      setFileName(file.name);
      setProfileImgUrl(URL.createObjectURL(file));
      setUploaded(true);
    } else {
      snackbarNotification(Notification?.invalidProfile)
    }
  };

  const userInfo = (key, value) => {
    if (key === "fname" || key === "lname") {
      if (isName(value)) {
        const data = { ...userDetails, [key]: value };
        setInValid({ ...inValid, [key]: false });
        setUserDetails({ ...data });
      }
    } else if (key === "mobile") {
      if (isMobileValid(value)) {
        const data = { ...userDetails, [key]: value };
        setUserDetails({ ...data });
        mobileValidator(value);
      } else {
        mobileValidator(value);
      }
    } else if (key === 'data') {
      const data = { ...userDetails, ...value };
      setUserDetails({ ...data });
    } else {
      setInValid({ ...inValid, [key]: false });
      const data = { ...userDetails, [key]: value };
      setUserDetails({ ...data });
    }
  };

  const passwordLenValidator = (value) => {
    if (value?.length < 8 && value?.length !== 0) {
      setPasswordLenErr(true);
    } else {
      setPasswordLenErr(false);
    }
  };

  const passwordMatchValidator = (value) => {
    if (value !== userDetails?.password) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
  };

  const emailValidator = (e) => {
    const value = e?.target?.value;
    if (value.length > 0)
      setInValid({ ...inValid, email: !isEmailValid(value) });
    else setInValid({ ...inValid, email: false });
  };

  const mobileValidator = (value) => {
    if (value?.length > 0 && value?.length <= 10)
      setInValid({ ...inValid, mobile: !isPhonenoValid(value) });
    else setInValid({ ...inValid, mobile: false });
  };

  const snackbarNotification = (data) => {
    setSnackBarContent(data);
    setShowSnackBar(true);
  };

  const validate = () => {
    let invalidFields = [];
    const newInValid = {};
    inputFields.forEach((item) => {
      if (!userDetails[item] || inValid[item]) {
        invalidFields.push(item);
        newInValid[item] = true;
      }
    });
    if (invalidFields?.length || passwordLenErr || passwordMatch) {
      setValidation([...invalidFields]);
      setInValid({ ...newInValid, ...inValid });
      // snackbarNotification(Notification.incomplete)
    }
    // else if (invalidEmail) {
    //   snackbarNotification(Notification.email);
    // }
    else if (
      userDetails?.password?.length < 8 &&
      userDetails?.password?.length !== 0
    ) {
      snackbarNotification(Notification.passwordLenErr);
    } else if (userDetails?.password !== userDetails?.confirm_password) {
      snackbarNotification(Notification.passwordMatch);
    } else {
      setValidation([]);
      edit();
    }
  };

  const edit = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    const formData = new FormData();
    if (uploaded)
      if (profileImgName !== {})
        formData.append("profile", profileImgFile, profileImgName);
    for (let key in userDetails) {
      if (key !== "profile") formData.append(key, userDetails[key]);
    }
    try {
      const response = await AuthServices.editUserProfile(id, formData);
      const { message } = response;
      snackbarNotification({
        message,
        class: "success",
      });
      editSuccess();
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  const editSuccess = () => {
    storeHandler(actions.USER_PROFILE, {});
    resetForm();
    setTimeout(() => {
      history.push("/staff-roles");
    }, 2000);
  };

  const resetForm = () => {
    setFile({});
    setMobile("");
    setFileName({});
    setUploaded(false);
    setTimeout(() => {
      setUserDetails({});
    }, 1000);
  };

  const handleChangeMobile = (event) => {
    const value = event?.target?.value?.split(".")?.join("");
    if (isMobileValid(value)) {
      setMobile(mobileFormatter(value));
      userInfo("mobile", value);
    }

  };

  useEffect(() => {
    setIsProfileIMG(history.location?.state?.profile);
    setId(history.location?.state?.id);
    setUserDetails({
      fname: history.location?.state?.fname,
      lname: history.location?.state?.lname,
      email: history.location?.state?.email,
      mobile: history.location?.state?.phone,
      accountType: history.location?.state?.type,
    });
    setMobile(mobileFormatter(history?.location?.state?.phone));
    setProfileImgUrl(history.location?.state?.profile);
  }, []);

  // capitalise a string
  const capitalise = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };

  const HandleAvatarDelete = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      if (!profileImgUrl) throw new Error("Profile Image not Present");
      const response = await AuthServices.deleteProfileIMG("staff/" + id);
      const { message } = response;
      snackbarNotification({
        message,
        class: "success",
      });
      editSuccess();
      storeHandler(actions.SHOW_LOADER, false);
    } catch (error) {
      snackbarNotification({
        message: error.message || "Profile Image not Present",
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  return (
    <>
      <DashHeader pageTitle="Profile" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90%",
        }}
      >
        <Paper
          sx={{
            width: { xs: "100%", md: "auto" },
            height: "100%",
            overflowY: "scroll",
          }}
        >
          <Box
            mb="2.25rem"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <IconButton onClick={() => history.push("/staff-roles")}>
              <BackIcon color="#00B89F" />
            </IconButton>
            <Typography color="primary" textAlign="center">
              Edit Staff Profile
            </Typography>
            <Box sx={{ width: "1.5rem", cursor: "pointer" }}>
              <img
                src={closeIcon}
                alt="close"
                onClick={() => history.push("/staff-roles")}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { md: "repeat(2 , 16rem)", xs: "100%" },
              gridTemplateRows: {
                md: "repeat(7 , auto)",
                xs: "repeat(12, auto)",
              },
              gap: "0 2rem",
            }}
          >
            <Box sx={{ gridArea: { md: "1/1/2/2", xs: "4/1/5/1" } }}>
              <AddUserInput
                sx={{ maxWidth: { md: "16rem" } }}
                labelText="First name"
                helperText={inValid?.fname && "Enter the first name"}
                value={userDetails?.fname || ""}
                onChange={(e) => userInfo("fname", e.target.value)}
              />
            </Box>
            <Box sx={{ gridArea: { md: "2/1/3/2", xs: "5/1/6/1" } }}>
              <AddUserInput
                labelText="Last name"
                value={userDetails?.lname || ""}
                onChange={(e) => userInfo("lname", e.target.value)}
                helperText={inValid?.lname && "Enter a valid last name"}
              />
            </Box>
            <Box sx={{ gridArea: { md: "3/1/4/2", xs: "6/1/7/1" } }}>
              <AddUserInput
                value={capitalise(userDetails?.accountType) || ""}
                helperText={inValid?.type && "Select an account type"}
                // onChange={(e) => userInfo('type', e.target.value)}
                readOnly
                labelText="Account type"
              // fieldType="select"
              // options={['select', 'admin', 'engineer']} disabled={0}
              />
            </Box>
            <Box sx={{ gridArea: { md: "4/1/5/2", xs: "7/1/8/1" } }}>
              <AddUserInput
                labelText="Email"
                helperText={inValid?.email && "Enter a valid email address"}
                value={userDetails?.email || ""}
                onChange={(e) => {
                  userInfo("email", e.target.value);
                  emailValidator(e);
                }}
              />
            </Box>

            <Box
              sx={{
                gridArea: { md: "1/2/4/3", xs: "1/1/1/1" },
                border: "2px solid ",
                borderRadius: "0.5rem",
                borderColor: "primary.main",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                margin: "auto",
                marginBottom: "1rem",
                minWidth: "11rem",
                minHeight: "11rem",
                maxWidth: "11rem",
                maxHeight: "min-content",
              }}
            >
              <Box
                sx={{
                  padding: "1rem 2rem",
                  textAlign: "center",
                  borderRadius: "1rem",
                }}
              >
                {profileImgUrl?.length ? (
                  <img
                    src={profileImgUrl}
                    style={{
                      height: "6.5rem",
                      width: "6.5rem",
                      borderRadius: "50%",
                    }}
                    alt=""
                  />
                ) : (
                  <Avatar />
                )}
              </Box>
              <Box>
                <Button
                  component="label"
                  sx={{
                    background: "transparent",
                    padding: "0.25rem",
                    color: "primary",
                    fontSize: "0.65rem",
                    borderRadius: "0.5rem",
                    borderColor: "primary.main",
                    border: "1px solid",
                    margin: 0,
                  }}
                  fullWidth
                  startIcon={<UploadIcon />}
                >
                  {vpnValidator(profileImgUrl) ||
                    typeof profileImgUrl == "object"
                    ? "Upload Image"
                    : "Change Image"}
                  <input type="file" onChange={uploadFile} hidden accept="image/png, image/jpeg, image/jpg" />
                </Button>
              </Box>
              {!vpnValidator(isProfileIMG) && (
                <Box>
                  <Button
                    component="label"
                    sx={{
                      background: "transparent",
                      padding: "0.25rem",
                      color: "red",
                      fontSize: "0.65rem",
                      borderRadius: "0.5rem",
                      borderColor: "red",
                      border: "1px solid",
                      margin: "5px 0 3px 0",
                    }}
                    fullWidth
                    onClick={HandleAvatarDelete}
                  >
                    Delete Image
                  </Button>
                </Box>
              )}
            </Box>
            <Box sx={{ gridArea: { md: "4/2/5/2", xs: "8/1/9/1" } }}>
              <AddUserInput
                sx={{ mt: "auto", mb: "0px" }}
                labelText="Mobile"
                value={mobile || ""}
                placeholder="Enter the mobile number"
                helperText={inValid?.mobile && "Enter a valid phone number"}
                onChange={(e) => handleChangeMobile(e)}
              />
            </Box>
            <Box sx={{ gridArea: { md: "5/1/6/1", xs: "9/1/10/1" } }}>
              <FormControlLabel
                control={<Switch />}
                onChange={() => {
                  setShowPassword(!showPassword)
                  userInfo("data", { 'password': "", 'confirm_password': "" });
                  setPasswordLenErr(false);
                  setPasswordMatch(false);
                }}
                checked={showPassword}
                label="Change Password"
              ></FormControlLabel>
            </Box>
            {showPassword && (
              <Box
                sx={{
                  gridArea: { md: "6/1/7/1", xs: "10/1/11/1" },
                  mb: { md: "0.5rem" },
                }}
              >
                <AddUserInput
                  fieldType="password"
                  labelText="Enter password"
                  value={userDetails?.password || ""}
                  onChange={(e) => {
                    userInfo("password", e.target.value);
                    passwordLenValidator(e.target.value);
                  }}
                  helperText={
                    inValid?.password
                      ? "Enter a valid password"
                      : passwordLenErr
                        ? "Enter min 8 characters"
                        : ""
                  }
                />
              </Box>
            )}

            {showPassword && (
              <Box
                sx={{
                  gridArea: { md: "6/2/6/2", xs: "11/1/12/1" },
                  mb: { md: "0.5rem" },
                }}
              >
                <AddUserInput
                  fieldType="password"
                  labelText="Confirm password"
                  value={userDetails?.confirm_password || ""}
                  helperText={
                    inValid?.confirm_password
                      ? "Enter a valid password"
                      : passwordMatch
                        ? "Passwords do not match"
                        : ""
                  }
                  onChange={(e) => {
                    userInfo("confirm_password", e.target.value);
                    passwordMatchValidator(e.target.value);
                  }}
                />
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                color: "#fff",
                width: { xs: "50%", md: "25%" },
                mb: "1rem",
              }}
              startIcon={<TickIcon />}
              onClick={validate}
            >
              Save
            </Button>
            {userProfile.id !== history.location?.state?.id && (
              <Button
                variant="contained"
                color="error"
                sx={{
                  color: "#fff",
                  width: { xs: "50%", md: "25%" },
                  mb: "1rem",
                }}
                startIcon={<DeleteIcon />}
                onClick={() => setDeleteStaff(true)}
              >
                Delete
              </Button>
            )}
          </Box>
        </Paper>
        <Snackbar
          open={showSnackBar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          message={snackbarContent?.message || ""}
          autoHideDuration={4000}
          onClose={() => setShowSnackBar(false)}
          sx={{
            ".MuiSnackbarContent-root": {
              color: "#fff",
              fontWeight: "400",
              backgroundColor:
                snackbarContent.class === "error"
                  ? "red"
                  : snackbarContent.class === "warning"
                    ? "#ffcc00"
                    : "#00b89f",
              display: "flex",
              justifyContent: "center",
              borderRadius: "10rem",
            },
          }}
        />
        <ConfirmationDialog
          text="Do you really want to delete this staff?"
          open={handleDeleteStaff}
          handleClose={(value) => {
            if (value === true) {
              AuthServices.deleteEngineer(id).then(({ message }) => {
                snackbarNotification({
                  message,
                  class: "success",
                });
                setTimeout(() => {
                  history.push("/staff-roles");
                }, 1000);
              });
            }
            setDeleteStaff(false);
          }}
        />
      </Box>
    </>
  );
};

export default EditUser;
