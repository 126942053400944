import React, { useContext } from 'react';
import { GlobalContext } from 'context';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

const PrivateRoute = ({ layout: Layout, component: Component }) => {
  const {
    state: { isLoggedIn },
  } = useContext(GlobalContext);
  let token = localStorage.getItem("authToken");

  return (
    (isLoggedIn!==null || !!!token) && <Route>
      { isLoggedIn ? (
        <Layout>
          <Component />
        </Layout>
      ) : (
        <Redirect exact to="/login" />
      )}
    </Route>
  );
};

export default PrivateRoute;
