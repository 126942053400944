import React, { useContext, useEffect, useState } from 'react';
import DashHeader from 'components/dash-header/DashHeader';
import { Box, Button, Grid, Snackbar, Typography } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as AddAccountIcon } from 'assets/img/add_account_icon.svg';
import UserRolesUserCard from 'app/user-roles/Components/user-roles-user-card/UserRolesUserCard';
import AuthServices from 'api/services/auth-services';
import { actions, GlobalContext } from 'context';
import "./UserRoles.scss";

const UserRoles = () => {
  const { dispatch } = useContext(GlobalContext);
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'success' })

  const snackbarNotification = (data) => {
    setSnackBarContent(data)
    setShowSnackBar(true)
  }
  
  const storeHandler = (type, payload) => dispatch({ type, payload });
  const history = useHistory();

  useEffect(() => {
    getUsersData()
  }, []);

  const getUsersData = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    setMessage("");
    try {
      const { data } = await AuthServices.getAllUsers();
      setData(data);
      setMessage("No users found");
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      })
      storeHandler(actions.SHOW_LOADER, false)
    }
  }

  return (
    <>
      <DashHeader pageTitle="NtelCare Staff roles" />
      <Box sx={{ maxWidth: '97%', margin: '0 auto', padding: '1rem', height: "100%" }}>
        <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Link to="/add-staff-role">
            <Button
              sx={{ fontWeight: '300', fontSize: '0.8rem', color: '#d8d8d8' }}
              startIcon={<AddAccountIcon />}
            >
              Add Staff
            </Button>
          </Link>
        </Box>
        <Box
          sx={{ backgroundColor: 'primary.main', width: '100%', height: '1px', marginBottom: '1rem' }}
        />
        {data.length > 0 ?
          <div className="user-roles">
            <Grid container spacing={1} sx={{ paddingTop: "1rem", display:'flex', justifyContent :{xs: 'center', sm: 'flex-start'}}}>
              {data.map((user, index) => (
                <Grid item sx={{ display: "flex" }} className="grid-item" direction="row" alignItems="stretch" justifyContent="center" xs={12} sm={6} md={6} lg={4} xl={3}>
                  <UserRolesUserCard
                    name={user.fname + " " + user.lname}
                    image={`${user.profile}?timestamp=${new Date()}`}
                    email={user.email}
                    phone={user.phone}
                    isExecutive={user.type}
                    key={index}
                    minWidth={{ sm: "16rem", md: "26rem", lg: "18rem", xl: "16rem" }}
                    handleClick={() => {
                      history.push({
                        pathname: "/edit-staff",
                        state: user
                      })
                    }} //Edit Profile Link
                  />
                </Grid>
              ))
              }
            </Grid>
          </div> :
          <div className="no-users">
            <Typography className="text-green">{message}</Typography>
          </div>
        }
      </Box>
      <Snackbar open={showSnackBar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          message={snackbarContent?.message || ''}
          autoHideDuration={4000}
          onClose={() => setShowSnackBar(false)}
          sx={{
            '.MuiSnackbarContent-root': {
              color: '#fff',
              fontWeight: '400',
              backgroundColor: snackbarContent.class === 'error' ? 'red' : (snackbarContent.class === 'warning' ? '#ffcc00' : '#00b89f'),
              display: 'flex',
              justifyContent: 'center',
              borderRadius: '10rem'
            }
          }}
      />
    </>
  );
};

export default UserRoles;
