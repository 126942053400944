import { Button, IconButton, Paper, Snackbar, Stack, Typography } from '@mui/material';
import { BsArrowLeftShort } from 'react-icons/bs';
import { Link, useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import InputField from 'components/inputfield/InputField';
import AuthServices from 'api/services/auth-services';

const inputValidationMessage = {
  password: 'Enter min 8 character',
  mismatch: 'Passwords do not match'
}

const ChangePassword = ({ email }) => {

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [showSnackBar, setShowSnackBar] = useState(false)
  const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'success' })
  const [invalidPassword, setInvalidPassword] = useState(false)
  const [passwordMissmatch, setPasswordMissmatch] = useState(false)

  const history = useHistory();

  const validatePassword = (event) => {
    event.preventDefault()
    if (password === confirmPassword && password.length >= 8) {
      resetPassword()
    }
  }

  const resetPassword = async () => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    formData.append('confirm_password', confirmPassword);

    try {
      const { message } = await AuthServices.resetPassword(formData);
      setShowSnackBar(true)
      setSnackBarContent({
        message,
        class: 'success'
      })
      setTimeout(() => { history.push('/login') }, 1000)
    }
    catch (err) {
      const { data } = err || {}
      const { message } = data || {}
      if (message) {
        const data = { message, class: 'error' }
        setSnackBarContent({ ...data })
        setShowSnackBar(true)
      }
    }
  }

  const passwordValidation = () => {
    setInvalidPassword(!!password && password.length < 8)
    setPasswordMissmatch(!!confirmPassword && password !== confirmPassword)
  }

  return (
    <>
      <Paper elevation={0} style={{ width: '27.5rem' }}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems="center"
          mb={2}
        >
          <IconButton disableRipple={true} color="primary" flex={1}>
            <Link to="/login">
              <BsArrowLeftShort size="39px" />
            </Link>
          </IconButton>
          <Typography textAlign="center" variant="h3" flex={1} sx={{ paddingRight: '3rem' }}>
            Change password
          </Typography>
          <div> </div>
        </Stack>
        <form onSubmit={validatePassword}>
          <InputField
            label="New password"
            variant="filled"
            fullWidth
            passwordfield={true}
            type="password"
            value={password}
            onBlur={passwordValidation}
            helperText={invalidPassword && inputValidationMessage.password}
            onChange={(e) => { setPassword(e.target.value) }}
          />
          <InputField
            label="Re-enter new password"
            variant="filled"
            fullWidth
            passwordfield={true}
            type="password"
            value={confirmPassword}
            onBlur={passwordValidation}
            helperText={passwordMissmatch && inputValidationMessage.mismatch}
            onChange={(e) => { setConfirmPassword(e.target.value) }}
          />
          <Button onClick={validatePassword} type='submit' variant="contained" color="primary" fullWidth sx={{ textShadow: '1px 0 0 currentColor' }}>
            Confirm
          </Button>
        </form>
        <Snackbar open={showSnackBar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          message={snackbarContent?.message || ''}
          autoHideDuration={10000}
          onClose={() => setShowSnackBar(false)}
          sx={{
            '.MuiSnackbarContent-root': {
              color: '#fff',
              fontWeight: '400',
              backgroundColor: snackbarContent.class === 'error' ? 'red' : (snackbarContent.class === 'warning' ? '#ffcc00' : '#00b89f'),
              display: 'flex',
              justifyContent: 'center',
              borderRadius: '10rem'
            }
          }}
        />
      </Paper>
    </>
  );
};

export default ChangePassword;
