import { Box } from '@mui/material';
import CreateAccountHeader from 'app/create-account/Components/create-account-header/CreateAccountHeader';
import CreAccStep1 from 'app/create-account/pages/create-account-step1/CreAccStep1';
import CreAccStep2 from 'app/create-account/pages/create-account-step2/CreAccStep2';
import DashHeader from 'components/dash-header/DashHeader';
import React, { useState } from 'react';
import CreAccStep3 from 'app/create-account/pages/create-account-step3/CreAccStep3';
import CreAccStep4 from 'app/create-account/pages/create-account-step4/CreAccStep4';

const userIm = 'https://randomuser.me/api/portraits/men/79.jpg';

const CreateAccount = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [seniorList, setseniorList] = useState([])

  return (
    <div style={{
      height: "100%"
    }}>
      <DashHeader
        searchBar={false}
        usrImg={userIm}
        pageTitle="Create Accounts"
      />
      <div style={{
        height: "80%",
      }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '1rem',
            height: "15%",
            overflowY: "hidden"
          }}
        >
          <Box sx={{ width: '50rem' }}>
            {tabIndex === 0 && (
              <CreateAccountHeader
                c1="#14B498"
                c2="#6C7175"
                c3="#6C7175"
                c4="#6C7175"
                changeTab={setTabIndex}
              />
            )}
            {tabIndex === 1 && (
              <CreateAccountHeader
                c1="#14B498"
                c2="#14B498"
                c3="#6C7175"
                c4="#6C7175"
                changeTab={setTabIndex}
              />
            )}
            {tabIndex === 2 && (
              <CreateAccountHeader
                c1="#14B498"
                c2="#14B498"
                c3="#14B498"
                c4="#6C7175"
                changeTab={setTabIndex}
              />
            )}
            {tabIndex === 3 && (
              <CreateAccountHeader
                c1="#14B498"
                c2="#14B498"
                c3="#14B498"
                c4="#14B498"
                changeTab={setTabIndex}
              />
            )}
          </Box>
        </Box>
        <Box sx={{ padding: '3rem', height: '100%', overflowY: 'scroll' }}>
          {tabIndex === 0 && (
            <CreAccStep1 changeTab={setTabIndex} />
          )}
          {tabIndex === 1 && (
            <CreAccStep2 changeTab={setTabIndex} setseniorList={setseniorList} />
          )}
          {tabIndex === 2 && (
            <CreAccStep3 changeTab={setTabIndex} seniorList={seniorList} />
          )}
          {tabIndex === 3 && (
            <CreAccStep4 />
          )}
        </Box>
      </div>
    </div>
  );
};

export default CreateAccount;
