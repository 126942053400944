import React, { useState } from 'react'
import { Box, Drawer, Stack, IconButton } from '@mui/material';
import VnhubSidebar from 'components/vnhub-sidebar/VnhubSidebar';
import { BiMenu } from 'react-icons/bi';

function VnhubLayout({ children }) {

    const [navOpen, setNavOpen] = useState(false);

    const toggleDrawer = (open) => {
        setNavOpen(open);
    };

    return (
        <Stack direction="row" height="100vh" sx={{ backgroundColor: '#292929' }}>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <VnhubSidebar />
            </Box>

            <IconButton
                onClick={() => toggleDrawer(true)}
                sx={{
                    position: 'absolute',
                    top: '.5rem',
                    left: '0',
                    color: 'primary.main',
                    display: { xs: 'block', md: 'none' },
                    marginTop: '0.24rem'
                }}
            >
                <BiMenu size={'2rem'} />
            </IconButton>

            <Drawer
                anchor="left"
                sx={{
                    display: { xs: 'block', md: 'none', },
                    '& .MuiDrawer-paper': {
                        padding: 0
                    }
                }}
                open={navOpen}
                onClose={() => toggleDrawer(false)}
            >
                <VnhubSidebar />
            </Drawer>

            <Box flex={1} sx={{ padding: '1rem', overflowY: 'auto', }}>
                {children}
            </Box>
        </Stack>
    )
}

export default VnhubLayout