import {
  Button,
  Stack,
  Box,
  Menu,
  MenuItem,
  Grid,
  Typography,
  Checkbox,
  ListItemText,
  Snackbar,
} from '@mui/material';
import AccountsInfoCard from 'app/accounts/components/accounts-info-card/AccountsInfoCard';
import DashHeader from 'components/dash-header/DashHeader';
import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext, actions } from 'context';
import { ReactComponent as SortIcon } from 'assets/img/sort_icon.svg';
import { ReactComponent as FilterIcon } from 'assets/img/filter_icon.svg';
import { ReactComponent as AddAccountIcon } from 'assets/img/add_account_icon.svg';
import AuthServices from 'api/services/auth-services';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import "./Accounts.scss";


const Accounts = () => {
  const { dispatch, state } = useContext(GlobalContext);
  const { accountsData } = state;
  const [accountsCategoryList, setAccountsCategoryList] = useState([]);
  const [accountsDataCopy, setAccountsDataCopy] = useState([]);
  const [openFilterType, setOpenFilterType] = useState(null);
  const [openSortType, setOpenSortType] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [sortNameToggle, setSortNameToggle] = useState(false);
  const [sortAgeToggle, setSortAgeToggle] = useState(false);
  const [sortLocationToggle, setSortLocationToggle] = useState(false);
  const [sortType, setSortType] = useState("");
  const [message, setMessage] = useState("");

  const storeHandler = (type, payload) => dispatch({ type, payload })
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'success' })

  const snackbarNotification = (data) => {
    setSnackBarContent(data)
    setShowSnackBar(true)
  }

  const handleToggle = (index) => {
    // handling the filter type menu toggles
    const currentIndexInState = selectedFilters.indexOf(index);
    const newSelectedFilters = [...selectedFilters];
    if (currentIndexInState === -1) {
      newSelectedFilters.push(index);
    }
    else {
      newSelectedFilters.splice(currentIndexInState, 1);
    }
    setSelectedFilters(newSelectedFilters);
  }

  const filterAccounts = () => {
    if (selectedFilters.length === 0) {
      setAccountsDataCopy(accountsData);
    }
    else {
      setAccountsDataCopy(accountsData.filter(account => {
        for (let index of selectedFilters) {
          if (account.category === accountsCategoryList[index]) {
            return true;
          }
        }
        return false;
      }));
    }
  }

  useEffect(() => {
    filterAccounts();
  }, [selectedFilters]);

  const handleFilterClick = (e) => {
    setOpenFilterType(e.currentTarget);
  };
  const handleSortClick = (e) => {
    setOpenSortType(e.currentTarget);
  }
  const handleSortClose = () => {
    setOpenSortType(null);
  }
  const handleFilterClose = () => {
    setOpenFilterType(null);
  };

  const onChangeNameSort = () => {
    setSortType('name');
    const newToggle = !sortNameToggle;
    setSortNameToggle(newToggle);
    let sortedData = accountsDataCopy.sort((a, b) =>
      newToggle ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name)
    );
    setAccountsDataCopy(sortedData);
  };
  const onChangeAgeSort = () => {
    setSortType('age');
    const newToggle = !sortAgeToggle;
    setSortAgeToggle(newToggle);
    let sortedData = accountsDataCopy.sort((a, b) =>
      newToggle ? a.age - b.age : b.age - a.age
    );
    setAccountsDataCopy(sortedData);
  };
  const onChangeLocationSort = () => {
    setSortType('location');
    const newToggle = !sortLocationToggle;
    setSortLocationToggle(newToggle);
    let sortedData = accountsDataCopy.sort((a, b) =>
      newToggle
        ? a.address.localeCompare(b.address)
        : b.address.localeCompare(a.address)
    );
    setAccountsDataCopy(sortedData);
  };

  const checkMatch = (search, data) => {
    let start = 0;
    if (data.includes(search))
      return true;
    while (start < search.length) {
      let ind = data.indexOf(' ', start);
      if (ind === -1) {
        return false;
      }
      if (data.substring(ind + 1).includes(search)) {
        return true;
      }
      start = ind + 1;
    }
    return false;
  }

  const search = (searchString) => {
    setSortType('');
    let filteredData = accountsData.filter((account) => {
      const name = account.name.toLowerCase();
      const address = account.address.toLowerCase();
      const room_no = account?.room_no?.toLowerCase();
      const searchStringLower = searchString.toLowerCase();
      return checkMatch(searchStringLower, name) || checkMatch(searchStringLower, room_no || '') || checkMatch(searchStringLower, address) || account.senior_id.toLowerCase().startsWith(searchStringLower)|| account.senior_id.toLowerCase().startsWith(searchStringLower);
    });
    setAccountsDataCopy(filteredData);
  }

  useEffect(() => {
    getAccountsData();
  }, []);

  const getAccountsData = async () => {
    setMessage("");
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const data = await AuthServices.getAccountsData();
const { data: { seniors } } = data;
      if (seniors?.length) {
        storeHandler(actions.ACCOUNTS_DATA, [...seniors]);
        setAccountsDataCopy([...seniors]);
      }
      setTimeout(() => {
        storeHandler(actions.SHOW_LOADER, false)
        setMessage("No accounts found")
      }, 2000)
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    }
  };
  useEffect(() => {
    const accountsCatList = accountsData?.map((item) => item.category);
    let categoryList = [...new Set(accountsCatList)];
    setAccountsCategoryList(categoryList);
  }, [accountsData])

  return (
    <div style={{ height: "100%" }}>
      <DashHeader pageTitle="Accounts" searchBar={true} searchFn={search} />
      <Box sx={{ height: "100%", padding: '1rem', maxWidth: '1800px', marginInline: 'auto', width: "96%", overflowY: "hidden" }}>
        <Stack direction="row-reverse">
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Button
              startIcon={<SortIcon />}
              sx={{ color: '#d8d8d8', fontSize: '0.7rem' }}
              className='btn-accounts'
              onClick={handleSortClick}
            >
              Sort
            </Button>
            <Button
              startIcon={<FilterIcon />}
              sx={{ color: '#d8d8d8', fontSize: '0.7rem' }}
              className='btn-accounts'
              onClick={handleFilterClick}
            >
              Filter
            </Button>
            <Menu
              anchorEl={openSortType}
              keepMounted
              open={Boolean(openSortType)}
              onClose={handleSortClose}
              anchorOrigin={{ vertical: 'bottom' }}
            >
              <MenuItem selected={sortType === 'name'} onClick={onChangeNameSort}>Name</MenuItem>
              <MenuItem selected={sortType === 'age'} onClick={onChangeAgeSort}>Age</MenuItem>
              <MenuItem selected={sortType === 'location'} onClick={onChangeLocationSort}>Location</MenuItem>
            </Menu>
            <Menu
              anchorEl={openFilterType}
              keepMounted
              multiple
              open={Boolean(openFilterType)}
              disableRipple={true}
              onClose={handleFilterClose}
              anchorOrigin={{ vertical: 'bottom' }}
            >
              {accountsCategoryList.map((filter, index) => (
                <MenuItem
                  key={filter}
                  onClick={() => handleToggle(index)}
                  selected={selectedFilters.includes(index)}
                >
                  <Checkbox
                    checked={selectedFilters.includes(index)}
                  />
                  <ListItemText primary={filter} />
                </MenuItem>
              ))}
            </Menu>
            <Link to="/create-account">
              <Button
                startIcon={<AddAccountIcon />}
                sx={{ color: '#d8d8d8', fontSize: '0.7rem' }}
              >
                Add account
              </Button>
            </Link>
          </Stack>
        </Stack>
        <Box
          sx={{
            backgroundColor: 'primary.main', width: '100%', height: '1px',
            marginBottom: "0.5rem",
          }}
        />
        {accountsDataCopy.length > 0 ? <Box sx={{ height: "100%", overflowY: "scroll" }} className='accounts-scroll'>
          <Grid container className="account-grid" spacing={2} sx={{ padding: "0.5rem", marginTop: '1rem', display: 'flex', justifyContent: { xs: 'center', sm: 'normal' } }}>
            {accountsDataCopy.map(
              ({ name, profile, id, age, address, senior_id, category, gender, nhub, room_no }, index) => {
                return (
                  <Grid item sx={{ display: "flex", flexBasis: "19.5rem" }} className="grid-item" direction="row" alignItems="stretch" justifyContent="center" xs={12} sm={6} md={5} lg={4} xl={4}>
                    <NavLink to={{ pathname: `/account-details/${id}` }} className="nav-link">
                      <AccountsInfoCard
                        key={index}
                        id={id}
                        image={profile}
                        name={name}
                        senior_id={senior_id}
                        age={age}
                        sex={gender}
                        location={address}
                        category={category}
                        time={nhub?.vpn_update_date}
                        nhub={nhub?.vpn}
                        wifi_strength={nhub?.wifi_strength}
                        network_type={nhub?.network_type}
                        virtual={nhub?.virtual}
                        room_no={room_no}
                        external_wifi={nhub?.external_wifi}
                      />
                    </NavLink>
                  </Grid>
                );
              }
            )}
          </Grid>
        </Box> :
          <Box className="box-accounts">
            <Typography className="typography-accounts text-green">{message}</Typography>
          </Box>}
      </Box>
      <Snackbar open={showSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={snackbarContent?.message || ''}
        autoHideDuration={4000}
        onClose={() => setShowSnackBar(false)}
        sx={{
          '.MuiSnackbarContent-root': {
            color: '#fff',
            fontWeight: '400',
            backgroundColor: snackbarContent.class === 'error' ? 'red' : (snackbarContent.class === 'warning' ? '#ffcc00' : '#00b89f'),
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '10rem'
          }
        }}
      />
    </div>
  );
};

export default Accounts;
