import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import showIcon from 'assets/img/view.svg';
import hideIcon from 'assets/img/eye-closed.svg';

const InputField = (props) => {
  const { passwordfield } = props;
  const [showPass, setShowPass] = useState(false);
  const onKeyDown = (e) => {};
  if (passwordfield) {
    let passwordfieldprops = { ...props };
    delete passwordfieldprops.passwordfield;
    return (
      <TextField
      // sx={{
      //   ".css-1yc0u6m-MuiInputBase-input-MuiFilledInput-input:-webkit-autofill": {
      //     borderTopLeftRadius: "inherit",
      //     borderTopRightRadius: "inherit",
      //     borderBottomLeftRadius: "inherit",
      //     borderBottomRightRadius: "inherit",
      //   }
      // }}
        {...passwordfieldprops}
        inputProps={{
          type: showPass ? 'text' : 'password',
          autoComplete: 'new-password',
        }}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disableRipple={true}
                onClick={() => setShowPass(!showPass)}
              >
                <img src={showPass ? hideIcon : showIcon} alt="" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
  return (
    <TextField
      // sx={{
      //   ".css-1yc0u6m-MuiInputBase-input-MuiFilledInput-input:-webkit-autofill": {
      //     borderTopLeftRadius: "inherit",
      //     borderTopRightRadius: "inherit",
      //     borderBottomLeftRadius: "inherit",
      //     borderBottomRightRadius: "inherit",
      //   }
      // }}
      placeholder={props.placeholder}
      {...props}
      InputProps={{ disableUnderline: true,}}
      onKeyDown={onKeyDown}
    />
  );
};

export default InputField;
