import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { DialogTitle } from '@mui/material';

export default function VnhubDialog({ open, handleClose, handleConform, title, text1, text2 }) {

    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            BackdropProps={{ style: { backgroundColor: 'black', opacity: "0.9" } }}
            sx={{
                '& .MuiPaper-root': {
                    backgroundColor: '#262626'
                }
            }}
        >
            <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', color: '#d14d4d' }}>
                {title}
            </DialogTitle>

            <DialogContent sx={{ borderBottom: '1px solid #ACACAC' }}>
                <DialogContentText id="alert-dialog-description" sx={{ color: '#ACACAC', marginTop: '1rem' }}>
                    {text1}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description" sx={{ color: '#ACACAC', marginTop: '1rem' }}>
                    {text2}
                </DialogContentText>
            </DialogContent>

            <DialogActions sx={{ gap: '1rem', marginTop: '1rem' }}>
                <Button
                    autoFocus
                    variant="contained"
                    sx={{ fontSize: '1rem', color: 'white', fontWeight: '500', width: '100%' }}
                    onClick={() => {
                        handleClose()
                        handleConform()
                    }}
                >
                    Yes
                </Button>
                <Button
                    variant="contained"
                    sx={{ fontSize: '1rem', color: 'white', fontWeight: '500', width: '100%' }}
                    onClick={() => handleClose(false)}
                >
                    No
                </Button>
            </DialogActions>
        </Dialog >
    );
}