import React, { useContext, useState } from "react";
import { Button, Box, Stack, Typography, MenuItem } from "@mui/material";
import moment from "moment";
import SimpleInputField from "components/simple-inputfield/SimpleInputField";
import AuthServices from "api/services/auth-services";
import { TransparentBtn } from "../../../components/styled-componets/style";
import { actions, GlobalContext } from "context";
import { ReactComponent as TickIcon } from "assets/img/tick_icon.svg";
import Timepicker from "components/time-picker/Timepicker";
import { getTimeZoneOffset, getUserTimeZome } from "utils/datetime-formatter";
import { isNumber } from "utils/validator";
import SelectInputMod from "app/create-account/Components/select-input-mod/SelectInputMod";
import { weekdaysArray } from "app/notification/components/edit-inputs/ShowerInput";
// import { getTimein24HrFormat } from "utils/datetime-formatter";

const SubTabThreeShower = ({
  currentSelected,
  selectedSenior,
  addOrSave,
  goBack,
  snackbarNotification,
  setInvalidSenior,
  macId,
  ...rest
}) => {
  const initialState = {
    device: "Shower",
    message: "",
    time: "",
    // week_count: null,
    // week_day: null,
    // week_msg: "",
    // thresholds: {
    //   week_count: null,
    //   week_day: null,
    // }
  };

  const { dispatch } = useContext(GlobalContext);
  const storeHandler = (type, payload) => dispatch({ type, payload });

  const [settings, setSettings] = useState({ ...initialState });
  const [invalid, setInvalid] = useState({});
  const [invalidModes, setInvalidModes] = useState(false);

  const handleChange = (value, key) => {
    let data = settings;
    data[key] = value;
    // if (key === 'week_count') {
    //   data.thresholds.week_count = value
    // }
    // if (key === 'week_day') {
    //   data.thresholds.week_day = value
    // }
    setInvalid({ ...invalid, [key]: false });
    setSettings(data);
  };

  const [notificatonModes, setNotificationModes] = useState({ email: false, sms: false, push: false });
  const handleModeClick = (e) => {
    const mode = e.target.name;
    const prev = notificatonModes[mode];
    setNotificationModes({ ...notificatonModes, [mode]: !prev });
    invalid.modes = false;
    setInvalidModes(false)

  }

  const validate = () => {
    let data = settings;
    let invalidData = {};
    let isValid = true;
    if (currentSelected === "seniorSpecific") {
      if (!selectedSenior?.id) {
        invalidData["senior"] = true;
        isValid = false;
        setInvalidSenior({ senior: true });
      }
    }
    Object.keys(data).forEach((key) => {
      if (data[key] === "") {
        invalidData[key] = true;
        isValid = false;
      }
      // if (key === 'thresholds') {
      //   if (!data.thresholds.week_count) {
      //     invalidData['week_count'] = true;
      //     isValid = false;
      //   }
      //   if (!data.thresholds.week_day) {
      //     invalidData['week_day'] = true;
      //     isValid = false;
      //   }
      // }
    });
    setInvalid(invalidData);
    const isValidModes = Object.values(notificatonModes).some(item => item)
    setInvalidModes(!isValidModes)
    if (isValid && (currentSelected == "generic" || isValidModes)) {
      handleAdd();
    }

  };

  const handleAdd = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {

      // let newSettingsList = settings
      // if (typeof settings.thresholds === 'object') {
      //   newSettingsList = { ...settings, ...settings.thresholds }
      //   delete newSettingsList.thresholds
      // }

      if (currentSelected === "generic") {
        const notification = { ...settings, time: moment(settings.time).toISOString(), timezone: getUserTimeZome(), origin: getTimeZoneOffset() };
        const { message } = await AuthServices.addGenericNotifications(
          notification
        );
        snackbarNotification({ message: message, class: 'success' });
      } else {
        const { message } = await AuthServices.addCentricNotifications(
          selectedSenior?.id,
          { ...settings, noti_type: notificatonModes, timezone: getUserTimeZome(), origin: getTimeZoneOffset(), mac_id: macId }
        );
        snackbarNotification({ message: message, class: 'success' });
      }
      goBack();
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  return (
    <Box>

      <Stack>

        <Box>
          <Stack>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: "1rem 0 1rem 0",
              }}
            >
              <Box
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Box width="100%" sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  <Box className="w-xs-100">
                    <Timepicker
                      labelText="Shower Time"
                      placeholder="00:00"
                      helperText={invalid?.time ? "Please enter a valid time" : ""}
                      value={settings?.time}
                      onChange={(newValue) => handleChange(newValue, "time")}
                    />
                  </Box>
                  <Box className="w-xs-100" mt={1.3}>
                    {/* <Box className="w-xs-100"> */}
                    <Typography sx={{ marginLeft: '0.8rem' }}>Notification will be pushed if shower is not taken before this time everyday.</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box>
              <SimpleInputField
                labelText="CEN"
                multiline={8}
                placeholder="Enter Critical Event Notification"
                type="Other"
                helperText={invalid?.message ? "Please enter a valid message" : ""}
                value={settings?.message}
                onChange={(event) => handleChange(event.target.value, "message")}
              />
            </Box>
          </Stack>
        </Box>

        {/* <Box>
          <Stack>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: "1rem 0 1rem 0",
              }}
            >
              <Box
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Box width="100%" sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  <Box className="w-xs-100">
                    <Box className="w-xs-100">
                      <Stack direction={'row'} spacing={1}>

                        <Box>
                          <SimpleInputField
                            value={settings?.thresholds?.week_count}
                            helperText={invalid?.week_count && <span style={{ fontSize: '.7rem' }}>Please enter valid input</span>}
                            labelText="Shower Count"
                            type="Other"
                            onChange={(event) => {
                              if (!isNumber(event.target.value)) return
                              if (event.target.value.length <= 5) { handleChange(event.target.value, "week_count") }
                            }
                            }
                          />
                        </Box>

                        <Box sx={{ "& .MuiFormControl-root": { minWidth: "165px", marginRight: '1rem' } }}>
                          <Typography sx={{ paddingBottom: "0.3rem", color: '#D8D8D8', position: 'relative', top: '-2px' }}>Alert Day Starts</Typography>
                          <SelectInputMod key={settings?.thresholds?.week_day} sx={{ "& .MuiSelect-select": { padding: "0.5rem", paddingRight: "32px" } }} value={settings?.thresholds?.week_day} helperText={invalid?.week_day && 'Please enter valid input'} onChange={(event) => handleChange(event.target.value, "week_day")}>
                            {weekdaysArray.map((option, index) => <MenuItem key={index} value={option.key} > {option.value || ''} </MenuItem>)}
                          </SelectInputMod>
                        </Box>

                      </Stack>
                    </Box>
                  </Box>
                  <Box className="w-xs-100" mt={'-.6rem'}>
                    <Typography>If the senior did not surpass the total number of shower required within a 7-day period, a notification will be sent.</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box>
              <SimpleInputField
                labelText="Shower Count CEN"
                multiline={8}
                placeholder="Enter Critical Event Notification"
                type="Other"
                helperText={invalid?.week_msg ? "Please enter a valid message" : ""}
                value={settings?.week_msg}
                onChange={(event) => handleChange(event.target.value, "week_msg")}
              />
            </Box>
          </Stack>

        </Box> */}

      </Stack>

      {
        currentSelected !== "generic" && (
          <>
            {invalidModes && <Box sx={{ color: 'red', fontSize: '0.85rem' }}>Please select at least one medium</Box>}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.8rem' }}>
              <TransparentBtn sx={{ color: notificatonModes?.email ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='email' variant="outlined">Email</TransparentBtn>
              <TransparentBtn sx={{ color: notificatonModes?.sms ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='sms' variant="outlined">SMS</TransparentBtn>
              <TransparentBtn sx={{ color: notificatonModes?.push ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='push' variant="outlined">Push</TransparentBtn>
            </Box>
          </>
        )
      }

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{ color: "#fff", width: "15rem", marginBottom: "5rem" }}
          startIcon={<TickIcon />}
          onClick={validate}
        >
          {addOrSave === "add" ? "Add" : "Update"}
        </Button>
      </Box>

    </Box>
  );
};

export default SubTabThreeShower;
