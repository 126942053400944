import { Paper, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { IconDisplay } from '../styled-componets/style'

function SmsTemplateCard({ name, message, icon }) {
    return (
        <Paper sx={{display:"flex", flexDirection:"row", alignItems:"center",mb:"0.5rem"}}>
            <Box >
            <Tooltip title={name}>
                <IconDisplay
                    className={`icon-display-active-new`}
                    sx={{ boxShadow: "none" }}
                >
                    {icon}
                </IconDisplay>
            </Tooltip>
            
            </Box>
            <Typography variant='body1' sx={{whiteSpace: 'pre-wrap'}}
            dangerouslySetInnerHTML={{__html:message}}>
            </Typography>
        </Paper>
    )
}

export default SmsTemplateCard