import { Typography, Box } from "@mui/material";
import { ReactComponent as AvatarImage } from "assets/avatar.svg";

const DropdownItem = ({ id, name, gender, age, profile, getSelected }) => {
  return (
    <Box
      className="dropdown-item"
      onClick={() =>
        getSelected({
          id,
          name,
          gender,
          age,
          profile,
        })
      }
    >
      {profile?.length > 0 ? (
        <img src={profile} alt="" className="avatar-senior" />
      ) : (
        <AvatarImage className="avatar-senior" />
      )}
      <Box style={{overflow: 'hidden'}}>
        <Typography className="dropdown-text-primary">{name}</Typography>
        <Typography className="dropdown-text-secondary">
          {age ? `Age ${age}, ` : ""}
          {gender}
        </Typography>
      </Box>
    </Box>
  );
};

export default DropdownItem;
