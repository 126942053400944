import { DialogTitle, IconButton, Typography } from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/img/close-icon.svg";
import "./CustomDialogTitle.scss";

export const CustomDialogTitle = (props) => {
    const { children, onClose, nhub, ...other } = props;
  
    return (
      <DialogTitle className="custom-title" sx={{ m: 0, p: nhub ? 0 : 2, display: nhub && 'flex', justifyContent: nhub && 'space-between', width: nhub && '100%' }} {...other}>
        <Typography className="text-green">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              // position: 'absolute',
              // right: 8,
              // top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon className="close-button"/>
          </IconButton>
        ) : null}
      </DialogTitle>
    );
};