import { Box, Divider, Stack, Typography } from '@mui/material';
import React from 'react';

const CreateAccountHeader = ({ c1, c2, c3, c4, contract, category, senior, member, changeTab }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-evenly"
      divider={<Divider orientation="vertical" flexItem />}
    >
      <Box sx={{ color: c1 }}
      // onClick={() => changeTab((prev) =>{
      //   if(prev >=0) return 0;
      //   return prev;
      // })}
      >
        <Typography sx={{ fontSize: '0.6rem' }}>Step 1</Typography>
        <Typography sx={{ fontSize: '1.5em' }}>Add Category</Typography>
        {contract && (
          <Typography sx={{ fontSize: '.6rem', color: '#D8D8D8' }}>
            {contract}
          </Typography>
        )}
      </Box>
      <Box sx={{ color: c2 }}
      // onClick={() => changeTab((prev) =>{
      //   if(prev >=1) return 1;
      //   return prev;
      // })}
      >
        <Typography sx={{ fontSize: '0.6rem' }}>Step 2</Typography>
        <Typography sx={{ fontSize: '1.5em' }}>Add Seniors</Typography>
      </Box>
      <Box sx={{ color: c3 }}
      // onClick={() => changeTab((prev) =>{
      //   if(prev >=2) return 2;
      //   return prev;
      // })}
      >
        <Typography sx={{ fontSize: '0.6rem' }}>Step 3</Typography>
        <Typography sx={{ fontSize: '1.5em' }}>Add Terms</Typography>
      </Box>
      <Box sx={{ color: c4 }}
      // onClick={() => changeTab((prev) =>{
      //   if(prev >=3) return 3;
      //   return prev;
      // })} 
      >
        <Typography sx={{ fontSize: '0.6rem' }}>Step 4</Typography>
        <Typography sx={{ fontSize: '1.5em' }}>Add Members</Typography>
      </Box>
    </Stack>
  );
};

export default CreateAccountHeader;
