import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  Paper,
  RadioGroup,
  Radio,
  Avatar,
} from "@mui/material";
import {
  TransparentBtn,
  RadioLabel,
  MemberTag,
  MemberName,
} from "../../components/styled-componets/style";
import DropdownItem from "../../components/styled-componets/DropdownItem";
import AuthServices from "api/services/auth-services";
import { actions } from "context";
import LightSearchBar from "components/light-search-bar/LightSearchBar";
import { ReactComponent as TickIcon } from "assets/img/tick_icon.svg";
import SimpleInputField from "components/simple-inputfield/SimpleInputField";
import "../../Notification.scss";
import SeniorDetailsDialog from "app/notification/components/dialogs/senior-details-dialog/SeniorDetailsDialog";
import MemberDetailsDialog from "app/notification/components/dialogs/member-details-dialog/MemberDetailsDialog";
import close from '../../../../assets/img/close.png'

const TabTwo = ({ seniors, storeHandler, snackbarNotification }) => {

  const [targetAudience, setTargetAudience] = useState('seniors');
  const [members, setMembers] = useState([])
  const [filteredMembers, setFilteredMembers] = useState(seniors)
  const [filteredSeniors, setFilteredSeniors] = useState([])
  const [isFocused, setIsFocused] = useState(true)
  const [searchValueSenior, setSearchValueSenior] = useState('')
  const [invalid, setInvalid] = useState({});
  const [selectedSeniors, setSelectedSeniors] = useState([])
  const [searchValueMember, setSearchValueMember] = useState('')
  const [selectedMembers, setSelectedMembers] = useState([])
  const [notificatonModes, setNotificationModes] = useState({ email: false, sms: false, push: false });
  const [broadcastMessageSenior, setBroadcastMessageSenior] = useState('');
  const [broadcastMessageMember, setBroadcastMessageMember] = useState('');
  const [handleSeniorOpen, setHandleSeniorOpen] = useState(false);
  const [handleMemberOpen, setHandleMemberOpen] = useState(false);
  const [selectedSeniorId, setSelectedSeniorId] = useState();
  const [selectedMemberId, setSelectedMemberId] = useState();
  const [details, setDetails] = useState({})
  const [memberDetails, setMemberDetails] = useState({});
  const [invalidModes, setInvalidModes] = useState(false);


  const Notifications = {
    sentToSeniors: { message: 'Notifications sent to selected seniors', class: 'success' },
    sentToMembers: { message: 'Notifications sent to selected members', class: 'success' },
    emptyMessage: { message: 'Please enter a message', class: 'error' },
    emptySelection: { message: 'Please select at least one senior or member', class: 'error' },
    emptyNotificationMode: { message: 'Please select at least one notification mode', class: 'error' },
    error: { message: 'Something went wrong !!', class: 'error' },
  }


  const getMembers = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const { members } = await AuthServices.getMembersList()
      setMembers(members)
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    }
  }

  useEffect(() => {
    getMembers()
  }, [])

  useEffect(() => {
    getSeniorDetails()
    getMemberDetails()
  }, [selectedSeniorId, selectedMemberId])

  const getSelected = (senior) => {
    const selected = selectedSeniors.findIndex((selected) => selected.id === senior.id)
    if (selected > -1) {

      // const newSelected = selectedSeniors;
      // newSelected.splice(selected, 1);
      // setSelectedSeniors(newSelected);
    }
    else
      setSelectedSeniors([...selectedSeniors, senior])
    invalid.seniors = false;
    setIsFocused(false);
    setSearchValueSenior('');
  }

  const RemoveSelected = (senior) => {

    const selected = selectedSeniors.findIndex((selected) => selected.id === senior.id)
    if (selected > -1) {

      const newSelected = selectedSeniors;
      newSelected.splice(selected, 1);
      setSelectedSeniors([...newSelected]);
    }
  }

  const getSelectedMember = (member) => {
    const selected = selectedMembers.findIndex((selected) => selected.id === member.id)
    if (selected > -1) {
      // const newSelected = selectedMembers;
      // newSelected.splice(selected, 1);
      // setSelectedMembers(newSelected);
    }
    else
      setSelectedMembers([...selectedMembers, member])
    setIsFocused(false);
    invalid.members = false;
    setSearchValueMember('');
  }

  const RemoveSelectedMember = (member) => {

    const selected = selectedMembers.findIndex((selected) => selected.id === member.id)
    if (selected > -1) {

      const newSelected = selectedMembers;
      newSelected.splice(selected, 1);
      setSelectedMembers([...newSelected]);
    }
  }

  const handleModeClick = (e) => {
    const mode = e.target.name;
    const prev = notificatonModes[mode];
    setNotificationModes({ ...notificatonModes, [mode]: !prev });
    invalid.modes = false;
    setInvalidModes(false)

  }

  const checkMatch = (search, data) => {
    let start = 0;
    if (data.startsWith(search))
      return true;
    while (start < search.length) {
      let ind = data.indexOf(' ', start);
      if (ind === -1) {
        return false;
      }
      if (data.substring(ind + 1).startsWith(search)) {
        return true;
      }
      start = ind + 1;
    }
    return false;
  }

  const searchSen = (searchString) => {
    let filteredSeniorsID =
      seniors.length > 0
        ? seniors?.filter((senior) => {
          return senior?.senior_id
            .toString()
            .toLowerCase()
            .includes(searchString.toLowerCase());
        })
        : [];
    const filteredSeniorsName =
      seniors.length > 0
        ? seniors?.filter((senior) => {
          return senior?.name
            .toString()
            .toLowerCase()
            .includes(searchString.toLowerCase());
        })
        : [];
    filteredSeniorsID = [...filteredSeniorsID, ...filteredSeniorsName];
    const noDuplicates =
      filteredSeniorsID.length > 0
        ? filteredSeniorsID?.filter((senior, index) => {
          return filteredSeniorsID.indexOf(senior) === index;
        })
        : [];
    setFilteredSeniors(noDuplicates);
  }

  const searchMem = (searchString) => {
    let filteredMembersID =
    members.length > 0
        ? members?.filter((members) => {
          return members?.member_id
            .toString()
            .toLowerCase()
            .includes(searchString.toLowerCase());
        })
        : [];
    const filteredMembersName =
      members.length > 0
        ? members?.filter((members) => {
          return members?.name
            .toString()
            .toLowerCase()
            .includes(searchString.toLowerCase());
        })
        : [];
    filteredMembersID = [...filteredMembersID, ...filteredMembersName];
    const noDuplicates =
      filteredMembersID.length > 0
        ? filteredMembersID?.filter((senior, index) => {
          return filteredMembersID.indexOf(senior) === index;
        })
        : [];

    setFilteredMembers(noDuplicates);
  }

  useEffect(() => {
    searchSen(searchValueSenior)
  }, [searchValueSenior])

  useEffect(() => {
    searchMem(searchValueMember)
  }, [searchValueMember])


  const handleBroadcastMessage = (e) => {
    //do not allow initial spaces
    if (e.target.value[0] === ' ')
      return;

    if (targetAudience === 'seniors') {
      setBroadcastMessageSenior(e.target.value)
      invalid.messageSenior = false;
    }
    else {
      setBroadcastMessageMember(e.target.value)
      invalid.messageMember = false;
    }
  }



  const getSelectedIds = () => {
    let ids;
    targetAudience === 'seniors' ? ids = selectedSeniors.map(senior => senior.id) : ids = selectedMembers.map(member => member.id)
    return ids;
  }

  // validation that no field is empty
  const validate = () => {
    const newInvalid = { ...invalid };
    let valid = true;
    let count = notificatonModes.email + notificatonModes.sms + notificatonModes.push;

    if (targetAudience === 'seniors') {
      if (broadcastMessageSenior?.length <= 0) {
        newInvalid.messageSenior = true;
        valid = false;
      }
      if (selectedSeniors?.length <= 0) {
        newInvalid.seniors = true;
        valid = false;
      }
    }
    if (targetAudience === 'members') {
      if (broadcastMessageMember?.length <= 0) {
        newInvalid.messageMember = true;
        valid = false;
      }
      if (selectedMembers?.length <= 0) {
        newInvalid.members = true;
        valid = false;
      }
    }
    if (count === 0) {
      newInvalid.modes = true;
      valid = false;
    }
    setInvalid(newInvalid);
    const isValidModes = Object.values(notificatonModes).some(item => item)
    setInvalidModes(!isValidModes)
    if (valid && isValidModes)
      sendSelectedIndividuals();
  }

  const sendSelectedIndividuals = async () => {
    const broadcastMessage = targetAudience === 'seniors' ? broadcastMessageSenior : broadcastMessageMember;
    const allIds = getSelectedIds();
    if (broadcastMessage === '') {
      console.log(broadcastMessage);
      snackbarNotification(Notifications.emptyMessage);
    }
    else if (allIds?.length <= 0) {
      snackbarNotification(Notifications.emptySelection);
    }
    else {
      const data = {
        recivers: getSelectedIds(),
        ...notificatonModes,
        message: broadcastMessage
      }
      try {
        storeHandler(actions.SHOW_LOADER, true);
        const response = await AuthServices.sendIndividualNotification(data);
        console.log(response);
        storeHandler(actions.SHOW_LOADER, false);
        sendSuccess();
      } catch (err) {
        const { data } = err || {};
        const { message } = data || {};
        snackbarNotification({
          message,
          class: "error",
        });
        storeHandler(actions.SHOW_LOADER, false);
      }
    }
  }

  const getSeniorDetails = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      if (selectedSeniorId) {
        const { data } = await AuthServices.getSeniorDetails(selectedSeniorId);
        setDetails(data);
      }
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    }
  }

  const getMemberDetails = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      if (selectedMemberId) {
        const { member } = await AuthServices.getMember(selectedMemberId);
        console.log(member)
        setMemberDetails(member);
      }
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    }
  }

  const sendSuccess = () => {
    if (targetAudience === 'seniors') {
      snackbarNotification(Notifications.sentToSeniors);
      setBroadcastMessageSenior('');
      setSelectedSeniors([]);
      setSearchValueSenior('');
    }
    else {
      snackbarNotification(Notifications.sentToMembers);
      setBroadcastMessageMember('');
      setSelectedMembers([]);
      setSearchValueMember('');
    }
    setNotificationModes({ email: false, sms: false, push: false })
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '1.5rem',
          flexWrap: 'wrap'
        }}
      >
        <Box onFocus={() => setIsFocused(true)} className={`search-bar-box`}>
          {targetAudience === 'seniors' ? <><LightSearchBar helperText={invalid?.seniors && "Please select at least one senior"} value={searchValueSenior} setSearchValue={setSearchValueSenior} />
            <Paper className={`paper-dropdown ${isFocused ? ((searchValueSenior === '' || filteredSeniors.length === 0) && 'invisible') : 'invisible'}`}>
              {
                filteredSeniors?.length > 0 && filteredSeniors?.map((senior, index) => {
                  return <DropdownItem getSelected={getSelected} key={index} id={senior?.id} name={senior?.name} gender={senior?.gender} age={senior?.age || 'N/A'} profile={senior?.profile} />
                })
              }
            </Paper></>
            :
            <>
              <LightSearchBar helperText={invalid?.members && "Please select at least one member"} value={searchValueMember} setSearchValue={setSearchValueMember} />
              <Paper className={`paper-dropdown ${isFocused ? ((searchValueMember === '' || filteredMembers.length === 0) && 'invisible') : 'invisible'}`}>
                {
                  filteredMembers?.length > 0 && filteredMembers?.map((member, index) => {
                    return <DropdownItem getSelected={getSelectedMember} key={index} id={member?.id} name={member?.name} gender={member?.gender} age={member?.age || 'N/A'} profile={member?.profile} />
                  })
                }
              </Paper>
            </>
          }
        </Box>
        <RadioGroup className='radio-group-center' defaultValue="seniors" onChange={(e) => { setTargetAudience(e.target.value); console.log(e.target.value, targetAudience) }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <RadioLabel value="seniors" control={<Radio />} label="Seniors" />
            <RadioLabel value="members" control={<Radio />} label="Members" />
          </Box>
        </RadioGroup>
      </Box>
      <Box className='box-scroll' sx={{ display: 'flex', width: '100%', overflowX: 'scroll', marginBottom: '2rem' }}>
        {targetAudience === 'seniors' ? selectedSeniors?.map((senior) => {
          return (
            <Box style={{ position: "relative" }}>
              <Box className='box-member-tag' onClick={() => {
                setSelectedSeniorId(senior?.id)
                setHandleSeniorOpen(true)
              }}>
                <MemberTag sx={{ mr: '1rem' }}>
                  <Avatar
                    src={senior?.profile}
                    size='1rem'
                  />
                  <MemberName>{senior?.name}</MemberName>
                </MemberTag>
              </Box>
              <img style={
                {
                  position: "absolute",
                  top: "0",
                  right: "10px",
                  width: "18px",
                  height: "18px",
                  padding: "2px",
                  borderRadius: "50%",
                  backgroundColor: "rgb(0,184,159)",
                  cursor: "pointer"
                }
              } src={close} alt=""
                onClick={() => RemoveSelected(senior)}
              />
            </Box>
          )
        }) : selectedMembers?.map((member) => {
          return (
            <Box style={{ position: "relative" }}>
              <Box className='box-member-tag' onClick={() => {
                setSelectedMemberId(member?.id)
                setHandleMemberOpen(true)
              }}>
                <MemberTag sx={{ mr: '1rem', cursor: 'pointer' }}>
                  <Avatar
                    src={member?.profile}
                    size='1rem'
                  />
                  <MemberName>{member?.name}</MemberName>
                </MemberTag>
              </Box>
              <img style={
                {
                  position: "absolute",
                  top: "0",
                  right: "10px",
                  width: "18px",
                  height: "18px",
                  padding: "2px",
                  borderRadius: "50%",
                  backgroundColor: "rgb(0,184,159)",
                  cursor: "pointer"
                }
              } src={close} alt=""
                onClick={() => RemoveSelectedMember(member)}
              />
            </Box>
          )
        })}
      </Box>
      {
        targetAudience === 'seniors' ?
          <SimpleInputField helperText={invalid?.messageSenior && `Message is required`} type="Other" onChange={handleBroadcastMessage} value={broadcastMessageSenior} labelText="Broadcast message" multiline={4} placeholder="Please enter a message" />
          : <SimpleInputField helperText={invalid?.messageMember && `Message is required`} type="Other" onChange={handleBroadcastMessage} value={broadcastMessageMember} labelText="Broadcast message" multiline={4} placeholder="Please enter a message" />
      }
      {invalidModes && <Box sx={{ color: 'red', fontSize: '0.85rem' }}>Please select at least one medium</Box>}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '0rem'
        }}
      >
        <TransparentBtn sx={{ color: notificatonModes?.email ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='email' variant="outlined">Email</TransparentBtn>
        <TransparentBtn sx={{ color: notificatonModes?.sms ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='sms' variant="outlined">SMS</TransparentBtn>
        <TransparentBtn sx={{ color: notificatonModes?.push ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='push' variant="outlined">Push</TransparentBtn>
      </Box>
      {/* <Box sx={{ display: 'flex', flexDirections: 'row', justifyContent: 'center', marginBottom: '1.5rem', fontSize: '0.9rem', fontWeight: '500', color: 'red', }}>
        <label>{invalid?.modes && `Please select at least one medium.`}</label>
      </Box> */}

      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1.5rem' }}
      >
        <Button
          variant="contained"
          sx={{ color: '#fff',marginBottom:"5rem"}}
          startIcon={<TickIcon />}
          onClick={validate}
          fullWidth
        >
          Send Notification
        </Button>
      </Box>
      <SeniorDetailsDialog open={handleSeniorOpen} handleClose={() => setHandleSeniorOpen(false)} seniorId={selectedSeniorId} details={details?.senior} />
      <MemberDetailsDialog open={handleMemberOpen} handleClose={() => setHandleMemberOpen(false)} memberId={selectedMemberId} details={memberDetails} />
    </>
  );
};

export default TabTwo;