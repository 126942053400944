import React, { useContext, useState } from "react";
import { Button, Box, Stack, Typography, MenuItem } from "@mui/material";
import moment from "moment";
import SimpleInputField from "components/simple-inputfield/SimpleInputField";
import AuthServices from "api/services/auth-services";
import { TransparentBtn } from "../../../components/styled-componets/style";
import { actions, GlobalContext } from "context";
import { ReactComponent as TickIcon } from "assets/img/tick_icon.svg";
import Timepicker from "components/time-picker/Timepicker";
import { getTimeZoneOffset, getTimein24HrFormat, getUserTimeZome } from "utils/datetime-formatter";
import SelectInputMod from 'app/create-account/Components/select-input-mod/SelectInputMod';

const intervalArr = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
const beltTypeArr = ['bed', 'couch/chair']
const currentDate = new Date().toDateString()
const SubTabThreeSleepBelt = ({
  currentSelected,
  selectedSenior,
  addOrSave,
  goBack,
  snackbarNotification,
  setInvalidSenior,
  couch,
  macId,
  ...rest
}) => {
  const initialState = {
    device: couch ? "SleepBelt-Chair" : "SleepBelt",
    day_interval: "",
    day_message: "",
    night_interval: "",
    night_message: "",
    type: couch ? "SleepBelt-Chair" : "SleepBelt",
    day: {
      start_time: new Date(`${currentDate} 6:00`).toUTCString(),
      end_time: new Date(`${currentDate} 17:59:59`).toUTCString()
    },
    night: {
      start_time: new Date(`${currentDate} 18:00`).toUTCString(),
      end_time: new Date(`${currentDate} 5:59:59`).toUTCString()
    }
  };

  const { dispatch } = useContext(GlobalContext);
  const storeHandler = (type, payload) => dispatch({ type, payload });

  const [settings, setSettings] = useState({ ...initialState });
  const [invalid, setInvalid] = useState({});
  const [invalidModes, setInvalidModes] = useState(false);


  const handleChange = (value, key) => {
    let data = settings;
    data[key] = value;
    setInvalid({ ...invalid, [key]: false });
    setSettings(data);
  };

  const [notificatonModes, setNotificationModes] = useState({
    email: false,
    sms: false,
    push: false,
  });
  const handleModeClick = (e) => {
    const mode = e.target.name;
    const prev = notificatonModes[mode];
    setNotificationModes({ ...notificatonModes, [mode]: !prev });
    invalid.modes = false;
    setInvalidModes(false)

  };

  const validate = () => {
    let data = settings;
    let invalidData = {};
    let isValid = true;
    if (currentSelected === "seniorSpecific") {
      if (!selectedSenior?.id) {
        invalidData["senior"] = true;
        isValid = false;
        setInvalidSenior({ senior: true });
      }
    }
    Object.keys(data).forEach((key) => {
      if (data[key] === "") {
        invalidData[key] = true;
        isValid = false;
      }
    });
    setInvalid(invalidData);
    const isValidModes = Object.values(notificatonModes).some(item => item)
    setInvalidModes(!isValidModes)
    if (isValid && (currentSelected == "generic" || isValidModes)) handleAdd();
  };

  const handleAdd = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      if (currentSelected === "generic") {
        const notification = {
          ...settings,
          timezone: getUserTimeZome(), origin: getTimeZoneOffset()
        };
        const { message } = await AuthServices.addGenericNotifications(
          notification
        );
        snackbarNotification({ message: message, class: "success" });
      } else {
        const { message } = await AuthServices.addCentricNotifications(
          selectedSenior?.id,
          { ...settings, noti_type: notificatonModes, timezone: getUserTimeZome(), origin: getTimeZoneOffset(),mac_id:macId }
        );
        snackbarNotification({ message: message, class: "success" });
      }
      goBack();
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  return (
    <>
      <Stack>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin: "1rem 0 1rem 0",
          }}
        >
          <Box
            width="100%"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box width="100%" sx={{ display: "flex", justifyContent: "space-between", flexWrap: 'wrap' }}>
              <Box className="w-xs-100" width="45%">
                <Typography sx={{ fontSize: "0.8rem", paddingBottom: "0.5rem" }}> Day Time Interval (hrs) <span style={{ color: '#9c9c9c' }}>6 Am to 6 Pm</span> </Typography>
                <SelectInputMod helperText={invalid?.day_interval && "Required"} sx={{ "& .MuiSelect-select": { padding: "0.5rem", paddingRight: "32px" } }} onChange={(event) => handleChange(event.target.value, "day_interval")}>
                  {intervalArr.map((option, index) => <MenuItem key={index} value={option.toString()} > {option} hrs</MenuItem>)}
                </SelectInputMod>
              </Box>
              <Box className="w-xs-100" width="45%">
                <Typography sx={{ fontSize: "0.8rem", paddingBottom: "0.5rem" }}> Night Time Interval (hrs)  <span style={{ color: '#9c9c9c' }}> 6 Pm to 6 Am</span></Typography>
                <SelectInputMod helperText={invalid?.night_interval && "Required"} sx={{ "& .MuiSelect-select": { padding: "0.5rem", paddingRight: "32px" } }} onChange={(event) => handleChange(event.target.value, "night_interval")}>
                  {intervalArr.map((option, index) => <MenuItem key={index} value={option.toString()} > {option} hrs </MenuItem>)}
                </SelectInputMod>
              </Box>
            </Box>
            <Box width="100%" sx={{ display: "flex", justifyContent: "space-between", flexWrap: 'wrap' }}>
              <Box className="w-xs-100" width="45%">
                <SimpleInputField
                  type="Other"
                  value={settings?.day_message}
                  helperText={invalid?.day_message && "Message is required"}
                  labelText={"CEN for Day Time"}
                  onChange={(event) =>
                    handleChange(event.target.value, "day_message")
                  }
                  multiline={8}
                  placeholder="Enter Critical Event Notification"
                  maxRows={8}
                />
              </Box>
              <Box className="w-xs-100" width="45%">
                <SimpleInputField
                  type="Other"
                  value={settings?.night_message}
                  helperText={invalid?.night_message && "Message is required"}
                  labelText="CEN for Night Time"
                  onChange={(event) =>
                    handleChange(event.target.value, "night_message")
                  }
                  multiline={8}
                  placeholder="Enter Critical Event Notification"
                  maxRows={8}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Stack>
      {currentSelected !== "generic" && (
        <>
          {invalidModes && <Box sx={{ color: 'red', fontSize: '0.85rem' }}>Please select at least one medium</Box>}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "0.8rem",
            }}
          >
            <TransparentBtn
              sx={{ color: notificatonModes?.email ? "#00b89f" : "#4d4c4c" }}
              onClick={handleModeClick}
              name="email"
              variant="outlined"
            >
              Email
            </TransparentBtn>
            <TransparentBtn
              sx={{ color: notificatonModes?.sms ? "#00b89f" : "#4d4c4c" }}
              onClick={handleModeClick}
              name="sms"
              variant="outlined"
            >
              SMS
            </TransparentBtn>
            <TransparentBtn
              sx={{ color: notificatonModes?.push ? "#00b89f" : "#4d4c4c" }}
              onClick={handleModeClick}
              name="push"
              variant="outlined"
            >
              Push
            </TransparentBtn>
          </Box>
        </>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{ color: "#fff", width: "15rem", marginBottom: "5rem" }}
          startIcon={<TickIcon />}
          onClick={validate}
        >
          {addOrSave === "add" ? "Add" : "Update"}
        </Button>
      </Box>
    </>
  );
};

export default SubTabThreeSleepBelt;
