import { Box, Button, Grid, Paper, Snackbar, Stack, styled } from '@mui/material';
import createTypography from '@mui/material/styles/createTypography';
import moment from 'moment/moment';
import AuthServices from 'api/services/auth-services';
import DashHeader from 'components/dash-header/DashHeader'
import ReportsBarChart from 'app/reports-section/reports-barchart/ReportsBarChart';
import SelectMenuFilled from 'components/select-menu-filled/SelectMenuFilled';
import { ReactComponent as PDFIcon } from 'app/reports-section/assets/PDF.svg';
import { ReactComponent as ExcelIcon } from 'app/reports-section/assets/Excel.svg';
import { ReactComponent as EmailIcon } from 'app/reports-section/assets/EmailAsReport.svg';
import { actions, GlobalContext } from 'context';
import React, { useContext, useEffect, useState } from 'react'
import { BsPrinter } from 'react-icons/bs';
import "./Reports.scss";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  backgroundColor: 'transparent',
  boxShadow: "0 0 0 0",
  color: theme.palette.text.secondary,
}));

const userIm = 'https://randomuser.me/api/portraits/men/79.jpg';

// const options = ['2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012', '2011'];
const years = (back) => {
  const year = new Date().getFullYear();
  return Array.from({length: back}, (v, i) => (year - back + i + 1).toString());
}
const options = years(7).reverse();
const reportsData = ['Accounts', 'Critical Event Notifications', 'Users', 'Sensors', 'Products','Contract Plan', 'Mobile Downloads', 'NHUBS','VIRTUAL NHUBS', 'Emergency Buttons'];
const productOptions = ["Bronze", "Silver", "Gold", "Platinum"];
const platformOptions = ["Android", "IOS"];
const contractOptions= ["06 Months","12 Months","24 Months","36 Months"]
const activeOptions = ["Active", "Inactive"];
const userRolesOptions = ["All", "Executives", "Members"];
const sensorReports = [];
const nhubReports = [];
let sensorOptions = [];
let categoryOptions = [];

const sortMonths = (unsorted) => {
  const sortedMonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  return Object.keys(unsorted).sort((a, b) => {
    return sortedMonths.indexOf(a) - sortedMonths.indexOf(b)
  }).reduce(
    (obj, key) => {
      obj[key] = unsorted[key];
      return obj;
    },
    {}
  );
}

const Reports = () => {

  const { dispatch } = useContext(GlobalContext);
  const storeHandler = (type, payload) => dispatch({ type, payload });

  const [downloadAsPDFData, setDownloadAsPDFData] = useState('Accounts');
  const [downloadAsExcelData, setDownloadAsExcelData] = useState('Accounts');
  const [emailData, setEmailData] = useState('Accounts');
  const [sensorType, setSensorType] = useState(sensorOptions[1] ? sensorOptions[1] : 'Door')
  const [categoryDefault, setCategoryDefault] = useState('')
  const [categoryType, setCategoryType] = useState(categoryOptions[0] ? categoryOptions[0] : categoryDefault);
  const [productPlanType, setProductPlanType] = useState("Bronze")
  const [platformType, setPlatformType] = useState("Android")
  const [activeType, setActiveType] = useState("Active")
  const [contractSelected,setContractSelected] = useState(contractOptions[0]);
  const [accountReportType, setAccountReportType] = useState(options[0]);
  const [criticalReportType, setCriticalReportType] = useState(options[0]);
  const [userReportType, setUserReportType] = useState(options[0]);
  const [sensorReportType, setSensorReportType] = useState(options[0]);
  const [productReportType, setProductReportType] = useState(options[0]);
  const [mobileDownloadReportType, setMobileDownloadReportType] = useState(options[0]);
  const [nhubReportType, setNhubReportType] = useState(options[0]);
  const [virtualNhubReportType, setVirtualNhubReportType] = useState(options[0]);
  const [sosReportType, setSosReportType] = useState(options[0]);
  const [contractType,setContractType] = useState(options[0]);
  const [accountReportData, setAccountReportData] = useState([]);
  const [userRole, setUserRole] = useState(userRolesOptions[0]);
  const [criticalReportData, setCriticalReportData] = useState([]);
  const [userReportData, setUserReportData] = useState([]);
  const [sensorReportData, setSensorReportData] = useState([]);
  const [productReportData, setProductReportData] = useState([]);
  const [mobileDownloadReportData, setMobileDownloadReportData] = useState([]);
  const [contractReportData,setContractReportData] = useState([]);
  const [nhubReportData, setNhubReportData] = useState([]);
  const [virtualNhubReportData, setVirtualNhubReportData] = useState([]);
  const [sosReportData, setSosReportData] = useState([]);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });

  const snackbarNotification = (data) => {
    setSnackBarContent(data);
    setShowSnackBar(true);
  }

  const getAccountReports = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    const accountReports = [];
    AuthServices.getAccountReports({ year: accountReportType }).then((response) => {
      const data = sortMonths(response.data);
      for (let ele in data) {
        accountReports.push({ month: ele.slice(0, 3), val: data[ele] })
      }
      setAccountReportData(accountReports);
      storeHandler(actions.SHOW_LOADER, false);
    }).catch((err) => {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    })
  }

  const getCriticalReports = () => {
    storeHandler(actions.SHOW_LOADER, true);
    const criticalReports = [];
    AuthServices.getCriticalReports({ year: criticalReportType }).then((response) => {
      const data = sortMonths(response.data);
      for (let ele in data) {
        criticalReports.push({ month: ele.slice(0, 3), val: data[ele] })
      }
      setCriticalReportData(criticalReports)
      storeHandler(actions.SHOW_LOADER, false);
    }).catch((err) => {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    })
  }

  const getUsersReports = () => {
    storeHandler(actions.SHOW_LOADER, true);
    const userReports = [];
    AuthServices.getUserReports({ year: userReportType, user: userRole }).then((response) => {
      const data = sortMonths(response.data);
      for (let ele in data) {
        userReports.push({ month: ele.slice(0, 3), val: data[ele] })
      }
      setUserReportData(userReports);
      storeHandler(actions.SHOW_LOADER, false);
    }).catch((err) => {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    })
  }

  const getSensorReports = () => {
    storeHandler(actions.SHOW_LOADER, true);
    const sensorReports = [];
    AuthServices.getContractDetails().then((data) => {
      categoryOptions = data.category.map((ele) => ele.name)
      setCategoryDefault(data.category[0].name);
      sensorOptions = data.sensors.map((ele) => {
        switch (ele?.name) {
          case "PillBox":
            return "PillBox"
          case "BathRoom":
            return "BathRoom";
          case "SOS":
            return "Urgent Band";
          case "watch":
            return "Health Watch";
          case "FallDetection":
            return "Fall Detection";
          case "SleepBelt":
              return "Sleep Belt-Bed";
          case "SleepBelt-Chair":
            return "Sleep Belt-Chair/Couch";
          case "GPSWatch":
            return "GPS Watch";        
          default: return ele?.name;
        }
      })
      let cid = 0;
      let sid = 0;
      data.category.forEach((category) => category.name === (categoryType ? categoryType : data.category[0].name) && (cid = category.id));
      data.sensors.forEach((sensor) => sensor.name === sensorType && (sid = sensor.id));
      AuthServices.getSensorReports({ year: sensorReportType, sensor: sid, category: cid }).then((response) => {
        const data = sortMonths(response.data);
        for (let ele in data) {
          sensorReports.push({ month: ele.slice(0, 3), val: data[ele] })
        }
        setSensorReportData(sensorReports)
      }).catch((err) => {
        storeHandler(actions.SHOW_LOADER, false);
      });
      storeHandler(actions.SHOW_LOADER, false);
    }).catch((err) => {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    })
  }

  const getProductReports = () => {
    storeHandler(actions.SHOW_LOADER, true);
    const productReports = [];
    AuthServices.getContractDetails().then((data) => {
      let plan_id;
      data.plans.forEach((plan) => plan.contract_name === productPlanType && (plan_id = plan.id))
      AuthServices.getProductReports({ year: productReportType, plan_id }).then((response) => {
        const data = sortMonths(response.data);
        for (let ele in data) {
          productReports.push({ month: ele.slice(0, 3), val: data[ele] })
        }
        setProductReportData(productReports)
      }).catch((err) => {
        storeHandler(actions.SHOW_LOADER, false);
      });
      storeHandler(actions.SHOW_LOADER, false);
    }).catch((err) => {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    })
  }

  const getMobileDownloadReports = () => {
    storeHandler(actions.SHOW_LOADER, true);
    const mobileDownloadReports = [];
    AuthServices.getMobileDownloadReports({ year: mobileDownloadReportType, os: platformType }).then((response) => {
      const data = sortMonths(response.data);
      for (let ele in data) {
        mobileDownloadReports.push({ month: ele.slice(0, 3), val: data[ele] })
      }
      setMobileDownloadReportData(mobileDownloadReports)
      storeHandler(actions.SHOW_LOADER, false);
    }).catch((err) => {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    })
  }

  const getNhubReports = () => {
    storeHandler(actions.SHOW_LOADER, true);
    const nhubReports = [];
    AuthServices.getNhubReports({ year: nhubReportType, active: activeType }).then((response) => {
      const data = sortMonths(response.data);
      for (let ele in data) {
        nhubReports.push({ month: ele.slice(0, 3), val: data[ele] })
      }
      setNhubReportData(nhubReports)
      storeHandler(actions.SHOW_LOADER, false);
    }).catch((err) => {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    })
  }

  const getVNhubReports = () => {
    storeHandler(actions.SHOW_LOADER, true);
    const vnhubReports = [];
    AuthServices.getVirtualNhubReports({ year: virtualNhubReportType }).then((response) => {
      const data = sortMonths(response.data);
      for (let ele in data) {
        vnhubReports.push({ month: ele.slice(0, 3), val: data[ele] })
      }
      setVirtualNhubReportData(vnhubReports)
      storeHandler(actions.SHOW_LOADER, false);
    }).catch((err) => {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    })
  }

  const getSosReports = () => {
    storeHandler(actions.SHOW_LOADER, true);
    const sosReports = [];
    AuthServices.getSosReports({ year: sosReportType }).then((response) => {
      const data = sortMonths(response.data);
      for (let ele in data) {
        sosReports.push({ month: ele.slice(0, 3), val: data[ele] })
      }
      setSosReportData(sosReports)
      storeHandler(actions.SHOW_LOADER, false);
    }).catch((err) => {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    })
  }

  const getContractReports = () =>{
    storeHandler(actions.SHOW_LOADER, true);
    const contractReportData = []
    AuthServices.getContractReports({year:contractType,contract_terms:contractSelected}).then((response)=>
    {
      const data = sortMonths(response.data);
      for (let ele in data) {
        contractReportData.push({ month: ele.slice(0, 3), val: data[ele] })
      }
      setContractReportData(contractReportData);
      storeHandler(actions.SHOW_LOADER, false);
    }
    ).catch((err)=>{
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    })

  }

  useEffect(() => {
    getAccountReports()
    getCriticalReports()
    getUsersReports()
    getSensorReports()
    getProductReports()
    getMobileDownloadReports()
    getNhubReports()
    getVNhubReports()
    getSosReports()
    getContractReports()
  }, [])

  useEffect(() => {
    getAccountReports()
  }, [accountReportType])

  useEffect(() => {
    getCriticalReports()
  }, [criticalReportType])

  useEffect(() => {
    getUsersReports()
  }, [userReportType, userRole])

  useEffect(() => {
    getSensorReports()
  }, [sensorReportType, sensorType, categoryType])

  useEffect(() => {
    getProductReports()
  }, [productReportType, productPlanType])

  useEffect(() => {
    getMobileDownloadReports()
  }, [mobileDownloadReportType, platformType])

  useEffect(() => {
    getNhubReports()
    getVNhubReports()
  }, [nhubReportType,virtualNhubReportType, activeType])

  useEffect(() => {
    getSosReports()
  }, [sosReportType])

  useEffect(() => {
    getContractReports()
  }, [contractSelected])

  const handleDownloadAsPDF = async () => {
    storeHandler(actions.SHOW_LOADER, true)
    try {
      let report;
      switch (downloadAsPDFData) {
        case "Accounts": report = 'account'
          break;
        case "Critical Event Notifications": report = 'cn'
          break;
        case "Users": report = 'user'
          break;
        case "Sensors": report = 'sensor'
          break;
        case "Products": report = 'product'
          break;
        case "NHUBS": report = 'nhub'
          break;
        case "VIRTUAL NHUBS": report = 'vnhub'
          break;
        case "Emergency Buttons": report = 'sos'
          break;
        case "Contract Plan": report = "contract_plan"
          break;
        default: storeHandler(actions.SHOW_LOADER, false); return
        // break;
      }
      const { data } = await AuthServices.downloadReportsExcel();
      const link = document.createElement("a");
      link.href = `${data}/reports/download/${report}/pdf`;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      storeHandler(actions.SHOW_LOADER, false)
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      })
      storeHandler(actions.SHOW_LOADER, false)
    }
  }

  const handleDownloadAsExcel = async () => {
    storeHandler(actions.SHOW_LOADER, true)
    try {
      let report;
      switch (downloadAsExcelData) {
        case "Accounts": report = 'account'
          break;
        case "Critical Event Notifications": report = 'cn'
          break;
        case "Users": report = 'user'
          break;
        case "Sensors": report = 'sensor'
          break;
        case "Products": report = 'product'
          break;
        case "NHUBS": report = 'nhub'
          break;
        case "VIRTUAL NHUBS": report = 'vnhub'
          break;
        case "Emergency Buttons": report = 'sos'
          break;
        case "Contract Plan": report = "contract_plan"
          break;
        default: report = 'account'
          break;
      }
      const { data } = await AuthServices.downloadReportsExcel();
      const link = document.createElement("a");
      link.href = `${data}/reports/download/${report}/xlsx`;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      storeHandler(actions.SHOW_LOADER, false)
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      })
      storeHandler(actions.SHOW_LOADER, false)
    }
  }

  const handleSendEmail = async () => {
    storeHandler(actions.SHOW_LOADER, true)
    try {
      let report;
      switch (emailData) {
        case "Accounts": report = 'account'
          break;
        case "Critical Event Notifications": report = 'cn'
          break;
        case "Users": report = 'user'
          break;
        case "Sensors": report = 'sensor'
          break;
        case "Products": report = 'product'
          break;
        case "NHUBS": report = 'nhub'
          break;
        case "VIRTUAL NHUBS": report = 'vnhub'
          break;
        case "Emergency Buttons": report = 'sos'
          break;
        case "Contract Plan": report = "contract_plan"
          break;
        default: report = 'account'
          break;
      }
      const { data, id } = await AuthServices.downloadReportsExcel();
      fetch(`${data}/reports/download/${report}/email/${id}`, {
        method: 'GET', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then((response) => response.json())
        .then((data) => {
          snackbarNotification({
            message:'Email Sent Successfully',
            class: "success",
          })
        })
        .catch((error) => {
          snackbarNotification({
            message:'Issue in sending email',
            class: "error",
          })
        });
      storeHandler(actions.SHOW_LOADER, false)
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      })
      storeHandler(actions.SHOW_LOADER, false)
    }
  }

  return (
    <>
      <DashHeader pageTitle="Reports" />
      <Stack direction="row-reverse" marginTop='-25px'>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" flexWrap="wrap">
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: '#1C2125',
              borderRadius: '15px',
              padding: '0.9rem 0.5rem 0 0.5rem',
              margin: '1rem'
            }}

          >
            <Stack
              sx={{
                '& .MuiFormControl-root': {
                  width: '150px',
                  backgroundColor: 'white',
                },
                height: '45px',
              }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <SelectMenuFilled
                where="Reports"
                value={downloadAsPDFData}
                onChange={(e) => {
                  setDownloadAsPDFData(e.target.value)
                }}
                options={reportsData}
              />
            </Stack>
            <Box
              sx={{
                color: '#d8d8d8',
                backgroundColor: '#00B89F',
                marginLeft: '1rem',
                borderRadius: '10px',
                padding: "4px",
                paddingBottom: "0px",
                marginBottom: "14px"
              }}
            >
              <PDFIcon style={{ cursor: "pointer" }} onClick={handleDownloadAsPDF} fontSize="2rem" />
            </Box>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: '#1C2125',
              borderRadius: '15px',
              padding: '0.9rem 0.5rem 0 0.5rem',
              margin: '0.85rem'
            }}
          >
            <Stack
              sx={{
                '& .MuiFormControl-root': {
                  width: '150px',
                  backgroundColor: 'white',
                },
                height: '45px',
              }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <SelectMenuFilled
                value={downloadAsExcelData}
                where="Reports"
                onChange={(e) => {
                  setDownloadAsExcelData(e.target.value)
                }}
                options={reportsData}
              />
            </Stack>
            <Box
              sx={{
                color: '#d8d8d8',
                backgroundColor: '#00B89F',
                marginLeft: '1rem',
                borderRadius: '10px',
                padding: "4px",
                paddingBottom: "0px",
                marginBottom: "14px"
              }}
            >
              <ExcelIcon style={{ cursor: "pointer" }} onClick={handleDownloadAsExcel} fontSize="2rem" />
            </Box>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: '#1C2125',
              borderRadius: '15px',
              padding: '1rem 0.5rem 0rem 0.5rem',
              margin: '1rem'
            }}
          >
            <Stack
              sx={{
                '& .MuiFormControl-root': {
                  width: '150px',
                  height: 'auto',
                  backgroundColor: 'white',
                },
                height: '45px',
              }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <SelectMenuFilled
                where="Reports"
                value={emailData}
                onChange={(e) => {
                  setEmailData(e.target.value)
                }}
                options={reportsData}
              />
            </Stack>
            <Box
              sx={{
                color: '#d8d8d8',
                backgroundColor: '#00B89F',
                marginLeft: '1rem',
                borderRadius: '10px',
                padding: "4px",
                paddingBottom: "0px",
                marginBottom: "14px"
              }}
            >
              <EmailIcon style={{ cursor: "pointer" }} onClick={handleSendEmail} fontSize="2rem" />
            </Box>
          </Stack>
        </Stack>
      </Stack>
      <Grid sx={{ alignSelf: "center", width: "100%", height: "80%", overflowY: "scroll" }} container spacing={0}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
          <Item>
            <ReportsBarChart title={"ACCOUNTS"} options={options} data={accountReportData} type={accountReportType} setType={setAccountReportType} />
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
          <Item>
            <ReportsBarChart title={"CRITICAL EVENT NOTIFICATIONS"} options={options} data={criticalReportData} type={criticalReportType} setType={setCriticalReportType} />
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
          <Item>
            <ReportsBarChart title={"USERS"} options={options} data={userReportData} type={userReportType} setType={setUserReportType} userRolesOptions={userRolesOptions} userRole={userRole} setUserRole={setUserRole} />
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
          <Item>
            <ReportsBarChart title={"SENSORS"} options={options} sensorOptions={sensorOptions} sensorType={sensorType} setSensorType={setSensorType} categoryOptions={categoryOptions} categoryType={categoryType ? categoryType : categoryDefault} setCategoryType={setCategoryType} data={sensorReportData} type={sensorReportType} setType={setSensorReportType} />
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
          <Item>
            <ReportsBarChart title={"PRODUCTS"} options={options} productOptions={productOptions} productPlanType={productPlanType} setProductPlanType={setProductPlanType} data={productReportData} type={productReportType} setType={setProductReportType} />
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
          <Item>
            <ReportsBarChart title={"CONTRACT PLAN"} options={options} contractOptions={contractOptions} contractType={contractSelected} setContractSelected={setContractSelected} data={contractReportData} type={contractType} setType={setContractType} />
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
          <Item>
            <ReportsBarChart title={"MOBILE DOWNLOADS"} options={options} platformOptions={platformOptions} platformType={platformType} setPlatformType={setPlatformType} data={mobileDownloadReportData} type={mobileDownloadReportType} setType={setMobileDownloadReportType} />
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
          <Item>
            <ReportsBarChart title={"NHUBS"} options={options} activeOptions={activeOptions} activeType={activeType} setActiveType={setActiveType} data={nhubReportData} type={nhubReportType} setType={setNhubReportType} />
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
          <Item>
            <ReportsBarChart title={"EMERGENCY BUTTON"} options={options} data={sosReportData} type={sosReportType} setType={setSosReportType} />
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
          <Item>
            <ReportsBarChart title={"VIRTUAL NHUBS"} options={options} data={virtualNhubReportData} type={virtualNhubReportType} setType={setVirtualNhubReportType} />
          </Item>
        </Grid>
        {/* <ReportsBarChart title={"MOBILE PAGES"} options={options} />
          <ReportsBarChart title={"BANDS NOT WORN"} options={options} /> */}
      </Grid>
      {/* <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: {xs: 'space-between', sm: 'space-between', md: 'flex-start'}
        }}
      >
        
      </Box> */}
      <Snackbar open={showSnackBar}
        message={snackbarContent?.message || ''}
        className={snackbarContent?.class || ''}
        autoHideDuration={4000}
        onClose={setShowSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
        sx={{
          '.MuiSnackbarContent-root': {
            color: '#fff',
            fontWeight: '400',
            backgroundColor: snackbarContent?.class === 'error' ? 'red' : (snackbarContent?.class === 'warning' ? '#ffcc00' : '#00b89f'),
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '10rem'
          }
        }}
      />
    </>
  )
}

export default Reports