import React, { useRef } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import "./Datepicker.scss";
import { Box } from '@mui/material';
import { subYears, isValid } from 'date-fns';
import { useState, useEffect } from 'react';

export default function BasicDatePicker({ value, onChange, required, helperText, senior }) {

  const datePickerRef = useRef('');
  // const [isOpened, setIsOpened] = useState(true);
  const [isOpen, setIsOpen] = useState(null);
  const dateObj = isValid(new Date(`${value} 00:00`)) ? new Date(`${value} 00:00`) : value
  const clicked = () => {

    datePickerRef.current.querySelector('button').click();
    // setValuee(subYears(new Date(), 40))\

  };



  // React.useEffect(() => {
  //   const listener = event => {
  //     if (event.code === "Enter" || event.code === "NumpadEnter") {
  //       event.preventDefault();
  //       datePickerRef.current.focus()
  //       textFieldRef.current.focus()
  //     }
  //   };
  //   document.addEventListener("keydown", listener);
  //   return () => {
  //     document.removeEventListener("keydown", listener);
  //   };
  // }, []);


  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          onOpen={() => setIsOpen(senior ? subYears(new Date(), 40) : new Date())}
          onClose={() => setIsOpen(null)}
          label="Date of Birth"
          className='date-picker white-icons'
          inputFormat="MM/dd/yyyy"
          InputProps={{
            disableUnderline: true,
          }}
          ref={datePickerRef}
          value={dateObj || isOpen}

          onChange={onChange}
          maxDate={senior ? subYears(new Date(), 40) : new Date()}
          renderInput={(params) => <TextField fullWidth required={required} size="small" error="false" variant='filled' {...params} inputProps={{
            ...params.inputProps,
            readOnly: true,
            placeholder: "MM/DD/YYYY"
          }} onClick={clicked} />}
        />
      </LocalizationProvider>
      <Box sx={{ marginTop: '-1rem' }}>
        <label style={{ fontSize: '0.65rem', fontWeight: '500', color: 'red', padding: '2px 0 0 10px' }} >{helperText}</label>
      </Box>
    </>
  );
}
