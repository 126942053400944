import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  styled,
  Switch,
  Typography,
} from '@mui/material';
import DashHeader from 'components/dash-header/DashHeader';
import SearchBar from 'components/search-bar/SearchBar';
import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as FilterIcon } from 'assets/img/filter_icon.svg';
import { ReactComponent as DoorIcon } from 'assets/img/door_icon.svg';
import { ReactComponent as PillIcon } from "assets/img/pill.svg";
import { ReactComponent as WatchIcon } from 'assets/img/watch.svg';
import { ReactComponent as FallDetectionIcon } from 'assets/img/falldetection28x28green.svg';
import { ReactComponent as SOSIcon } from 'assets/img/sos.svg';
import { ReactComponent as ShowerIcon } from 'assets/img/shower.svg';
import { ReactComponent as SleepBeltIcon } from 'assets/img/belt.svg';
import { ReactComponent as CouchIcon } from 'assets/img/couch.svg';
import { ReactComponent as CamerasmallIcon } from "assets/img/camera.svg";
import { ReactComponent as BathRoomIcon } from "assets/img/32px_Bathroom_Sensor_IconP_Active.svg"

import AddUserInput from 'components/add-user-input/AddUserInput';
import { ReactComponent as TickIcon } from 'assets/img/tick_icon.svg';
import { Link, useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import AuthServices from 'api/services/auth-services';
import { actions, GlobalContext } from 'context';
import "./DeviceDetails.scss";
import { vpnValidator } from 'utils/validator';
import NaviLink from 'components/navilink/NaviLink';

const filters = ["ID", "Name"];

const Notification = {
  MessageSent: { message: "Broadcast message sent successfully", class: "success" },
  EmptyMessage: { message: "Please enter a message", class: "error" },
  NoSeniors: { message: "No seniors present to notify", class: "error" }
}

const SimpleBtn = styled(Button)({
  color: '#AFAFAF',
  fontSize: '1rem',
  fontWeight: '300',
  margin: 0,
});
const ConnectBtn = styled(Button)({
  color: '#14B498',
  fontSize: '.5rem',
  fontWeight: '700',
  background: 'transparent',
  borderRadius: '1rem',
  padding: '0.25rem 1rem',
  border: '1px solid #14B498',
});
const Title = styled(Typography)({
  color: '#14B498',
  textTransform: 'capitalize',
  marginBottom: '1rem',
});
const DeviceTitle = styled(Typography)({
  color: '#14B498',
  textTransform: 'capitalize',
  margin: 0
});
const DeviceID = styled(Typography)({
  color: '#6C7175',
  textTransform: 'uppercase',
  marginBottom: '0.5rem',
  fontSize: '0.8rem'
});
const Device = styled(Typography)({
  color: '#6C7175',
  fontSize: '0.8rem',
  marginBottom: '1rem',
});
const Subtitle = styled(Typography)({
  color: '#D8D8D8',
  textTransform: 'capitalize',
  fontSize: '0.8rem',
  marginBottom: '1rem',
});
const CardSubtitle = styled(Typography)({
  color: '#9C9C9C',
  textTransform: 'capitalize',
  fontSize: '1rem',
  marginBottom: '.5rem',
});
const RadioLabel = styled(FormControlLabel)({
  color: '#00B89F',
});
const SensorTitle = styled(Typography)({
  color: '#E5E5E5',
  fontSize: '0.9rem',
  padding: '0',
  margin: '0'
});
const SensorSubtitle = styled(Typography)({
  color: '#9C9C9C',
  fontSize: '0.65rem',
  padding: '0',
  margin: '0'
});
const SensorName = styled(Typography)({
  color: '#9C9C9C',
  fontSize: '0.7rem',
  padding: '0',
  margin: '0'
});
const Senior = ({ senior }) => {
  return (
    <Box sx={{ display: 'flex', flexBasis: '45%', marginBottom: '.5rem' }}>
      <Avatar
        sx={{ marginRight: '0.5rem' }}
        size="3rem"
        src={senior.profile}
      />
      <Box flex={1}>
        <DeviceTitle>{senior.name}</DeviceTitle>
        <CardSubtitle sx={{ fontSize: '0.8rem', marginBottom: 0 }}>
          {senior.senior_id}
        </CardSubtitle>
      </Box>
    </Box>
  );
}
const Sensor = ({ title, subtitle, icon: Icon, name }) => {
  let sensor_name = "";
  let fillSensor = false;
  switch (title) {
    case "watch": sensor_name = "Healthcare Watch";
      break;
    case "FallDetection": sensor_name = "Fall Detection ";
      break;
    case "SOS": sensor_name = "Urgent Band";
      break;
    case "PillBox": sensor_name = "PillBox Sensor";
      break;
    case "Door": sensor_name = "Door Sensor";
      break;
    case "BathRoom": sensor_name = "BathRoom Sensor";
      break;
    case "Shower": sensor_name = "Shower Sensor";
      break;
    case "SleepBelt": sensor_name = "Sleep Belt (Bed)";
      break;
    case "SleepBelt-Chair": sensor_name = "Sleep Belt (Couch / Chair)";
      break;
    case "camera": sensor_name = "Camera";
      break;
    default: sensor_name = "Sensor";
      break;
  }
  return (
    <Box className="sensor-box" sx={{ flexBasis: { xs: '45%', md: '22.5%' } }}>
      <Box sx={{ marginRight: '0.5rem', padding: 0 }}>
        <Icon className={`${fillSensor ? 'svg-styles-fill' : 'svg-styles'}`} />
      </Box>
      <Box>
        <SensorTitle>{sensor_name}</SensorTitle>
        <SensorName>{name}</SensorName>
        <SensorSubtitle>{subtitle}</SensorSubtitle>
      </Box>
    </Box>
  );
}
const DeviceDetails = () => {
  const { dispatch } = useContext(GlobalContext)
  const location = useLocation()
  const { id } = location.state
  const history = useHistory();
  const [device, setDevice] = useState({})
  const [masAccID, setMasAccID] = useState(0)
  const [seniors, setSeniors] = useState([])
  const [sensors, setSensors] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [filteredSeniors, setFilteredSeniors] = useState([])
  const [openFilterType, setOpenFilterType] = useState(null)
  const [selectedFilter, setSelectedFilter] = useState(0)
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'success' })
  const [macSensors, setMacSensors] = useState([])
  const [roomno,setRoomno]= useState()
  const [radio, setRadio] = useState('softwareUpdate')
  const [message, setMessage] = useState("")
  const snackbarNotification = (data) => {
    setSnackBarContent(data)
    setShowSnackBar(true)
  }

  const handleFilterClick = (e) => {
    setOpenFilterType(e.currentTarget);
  }

  const changeFilter = (index) => {
    setSelectedFilter(index)
    handleClose()
  }

  useEffect(() => {
    searchFn(searchValue)
  }, [selectedFilter])

  const handleClose = () => {
    setOpenFilterType(null);
  }

  const storeHandler = (type, payload) => dispatch({ type, payload })
  const getNhubDeviceDetails = () => {
    storeHandler(actions.SHOW_LOADER, true)
    AuthServices.getNhubDeviceDetails(id).then(({ data }) => {
      if (data?.seniors[0]?.camera_url) {
        setDevice(data.device)
        setMasAccID(data.mas_acc_id)
        setSeniors(data.seniors)
        setRoomno(data?.seniors[0]?.room_no)
        data.mac_sensors.push({ sensor_name: "camera" });
        setMacSensors(data.mac_sensors)
        let sensors = []
        for (let ele in data.sensors) {
          sensors.push({ name: [ele], count: data.sensors[ele] })
        }
        setSensors(sensors)
        storeHandler(actions.SHOW_LOADER, false)
      }
      else {
        setDevice(data.device)
        setMasAccID(data.mas_acc_id)
        setSeniors(data.seniors)
        setMacSensors(data.mac_sensors)
        setRoomno(data?.seniors[0]?.room_no)
        let sensors = []
        for (let ele in data.sensors) {
          sensors.push({ name: [ele], count: data.sensors[ele] })
        }
        setSensors(sensors)
        storeHandler(actions.SHOW_LOADER, false)
      }
    }).catch((err) => {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    })
  }

  const handleRadioChange = (event) => {
    setRadio(event.target.value)
  }

  const handleMessageChange = (event) => {
    setMessage(event.target.value)
  }

  const handleSendClick = async () => {
    if (message === "") {
      snackbarNotification(Notification.EmptyMessage)
    } else if (filteredSeniors.length === 0) {
      snackbarNotification(Notification.NoSeniors)
    } else {
      storeHandler(actions.SHOW_LOADER, true)
      try {
        const body = {
          id,
          software: radio === "softwareUpdate" ? true : false,
          maintenance: radio === "maintainance" ? true : false,
          message
        }
        await AuthServices.sendNhubBroadcastMessage(body)
        snackbarNotification(Notification.MessageSent)
        setMessage("")
        storeHandler(actions.SHOW_LOADER, false)
      } catch (err) {
        const { data } = err || {};
        const { message } = data || {};
        snackbarNotification({
          message,
          class: "error",
        });
        storeHandler(actions.SHOW_LOADER, false);
      }
    }
  }

  const searchFn = (value) => {
    setSearchValue(value)
    const filtered = seniors.filter((senior) => {
      return selectedFilter === 0 ? senior.senior_id.toLowerCase().includes(value.toLowerCase()) : senior.name.toLowerCase().includes(value.toLowerCase())
    })
    setFilteredSeniors(filtered)
  }

  const handleChange = async () => {
    storeHandler(actions.SHOW_LOADER, true)
    AuthServices.deleteNhubDevice(id).then(({ message }) => {
      snackbarNotification({
        message,
        class: "success",
      })
      setTimeout(() => {
        history.push("/nhub");
      }, 1000)
      storeHandler(actions.SHOW_LOADER, false)
    }).catch((err) => {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      })
      storeHandler(actions.SHOW_LOADER, false)
    })
  }

  useEffect(() => {
    getNhubDeviceDetails()
  }, [])

  useEffect(() => {
    seniors !== [] && setFilteredSeniors(seniors)
  }, [seniors])

  const handleConnect = () => {
    storeHandler(actions.SHOW_LOADER, true);
    let URL = process.env.REACT_APP_VPN.replace('lookup2', 'lookup') + '/' + device.vpn_url.trim() + '/';
    AuthServices.getNhubStatus(URL)
      .then((res) => {
        storeHandler(actions.SHOW_LOADER, false);
        const url = `${process.env.REACT_APP_VPN}/${device.vpn_url.trim()}/`;
        const link = document.createElement("a");
        link.href = url;
        link.target = "blank";
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }).catch(err => {
        snackbarNotification({
          message: "Nhub is Rebooting,Please wait for sometime, refresh the page, and try connecting again",
          class: "error",
        });
        storeHandler(actions.SHOW_LOADER, false);
      })

  }

  return (
    <>
      <DashHeader pageTitle="Nhub" tab="nhub" deviceName={device.nhubs_id} />

      <Box sx={{ maxWidth: '1400px', height: "90%", overflowY: "scroll", }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', md: 'flex-end' },
            marginBottom: '1rem',
          }}
        >
          <Box sx={{ display: 'none' }}>
            <SearchBar search={searchFn} nhub={true} />
            <SimpleBtn onClick={handleFilterClick} style={{ marginLeft: "1rem" }} startIcon={<FilterIcon />}>Filter by</SimpleBtn>
            <Menu
              anchorEl={openFilterType}
              keepMounted
              open={Boolean(openFilterType)}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'bottom' }}
            >
              {filters.map((filter, index) => (
                <MenuItem
                  key={filter}
                  onClick={() => changeFilter(index)}
                  selected={selectedFilter === index}
                >
                  {filter}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Box>
        <Box
          sx={{
            display: { md: 'flex' },
            justifyContent: { md: 'space-between' },
            alignItems: { md: 'flex-start' },
          }}
        >
          <Box
            sx={{
              display: { md: 'flex' },
              justifyContent: { md: 'space-between' },
              flexWrap: { md: 'wrap' },
              flexBasis: { md: '59%' },
            }}
          >
            <Paper sx={{ flexBasis: { md: '39%', marginBottom: '1rem' } }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                  <DeviceTitle>Device Name</DeviceTitle>
                  <DeviceID>{device?.nhubs_id}</DeviceID>
                </div>
                <Switch sx={{ margin: 0 }} onChange={handleChange} checked={device?.active} />
              </div>
              {!device.virtual && (
                <>
                  <Device>Bluetooth Mac ID: {device?.bt_mac}</Device>
                  <Device>Wifi Mac ID: {device?.wifi_mac}</Device>
                  <Device>VPN URL: {vpnValidator(device?.vpn_url) ? "--:--" : moment.utc().diff(device?.vpn_update_date, 'minutes') < 5 ? device?.vpn_url : "--:--"}</Device>
                  <Device>Ethernet ID: {device?.eth_mac || "--:--"}</Device>
                </>
              )}
              {device.virtual && seniors?.length > 0 && (
                <center>
                  <Button
                    target='_blank'
                    href={`/vnhub/device?nhub_id=${encodeURIComponent(device?.nhubs_id)}&senior_id=${seniors?.[0]?.id}`}
                    variant="outlined"
                    sx={{
                      fontSize: "0.7rem",
                      padding: "0.2rem, 0.5rem",
                      marginTop: "25px",
                    }}
                  >
                    Connect to VNhub
                  </Button>
                </center>

              )}
              {moment.utc().diff(device?.vpn_update_date, 'minutes') < 5 ? (
                <ConnectBtn onClick={handleConnect} className='btn-connect-nhub' disabled={device?.vpn_url?.includes('None') || !(device?.vpn_url)}>Connect</ConnectBtn>
              ) : null}
            </Paper>
            <Paper sx={{ flexBasis: { md: '59%', marginBottom: '1rem' } }}>
              <DeviceTitle>Master Account</DeviceTitle>
              <DeviceID>{masAccID}</DeviceID>
              <CardSubtitle>Seniors</CardSubtitle>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                }}
              >
                {
                  seniors.map((senior, index) => <Senior key={index} senior={senior} />)
                }
              </Box>
              {roomno && <Box
                sx={{
                  display: 'flex',
                  // justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  color: '#9C9C9C'
                }}
              >
                Room No : {roomno}
              </Box>}
            </Paper>
            <Paper sx={{ flexBasis: { md: '100%', marginBottom: '1rem' }, minWidth: '100%' }}>
              <DeviceTitle style={{ marginBottom: "1rem" }}>Sensors</DeviceTitle>
              <div
                style={{
                  marginRight: '1rem',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexWrap: 'wrap'
                }}
              >
                {macSensors.map((sensor) => {
                  let icon;
                  switch (sensor.sensor_name) {
                    case "watch": icon = WatchIcon;
                      break;
                    case "BathRoom": icon = BathRoomIcon;
                      break;
                    case "Door": icon = DoorIcon;
                      break;
                    case "PillBox": icon = PillIcon;
                      break;
                    case "Shower": icon = ShowerIcon;
                      break;
                    case "FallDetection": icon = FallDetectionIcon;
                      break;
                    case "SOS": icon = SOSIcon;
                      break;
                    case "SleepBelt": icon = SleepBeltIcon;
                      break;
                    case "SleepBelt-Chair": icon = CouchIcon;
                      break;
                    case "camera": icon = CamerasmallIcon;
                      break;
                    default: icon = DoorIcon;
                  }
                  return <Sensor
                    style={{ marginRight: "1rem" }}
                    title={sensor.sensor_name}
                    name={`Device Name: ${sensor.sensor_name === "camera" ? seniors?.map(item => item?.camera_name ? item?.camera_name : "-:-") : sensor.name}`}
                    subtitle={sensor.sensor_name === "camera" ? '' : `Mac ID: ${sensor.mac_id}`}
                    icon={icon}
                  />
                })}
              </div>
            </Paper>
          </Box>
          {!device.virtual && (
            <Paper sx={{ flexBasis: { md: '39%' } }}>
              <RadioGroup defaultValue="softwareUpdate" onChange={handleRadioChange}>
                <Title>Management</Title>
                <Box
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                  mb="2rem"
                >
                  <RadioLabel
                    value="softwareUpdate"
                    control={<Radio />}
                    label="Software Update"
                  />
                  <RadioLabel
                    value="maintainance"
                    control={<Radio />}
                    label="Maintainance"
                  />
                </Box>
              </RadioGroup>
              <AddUserInput
                labelText="Broadcast Message"
                placeholder="Please enter a message"
                multiline={true}
                minRows={4}
                value={message}
                handleChange={handleMessageChange}
              />
              <Box sx={{ display: 'none', alignItems: 'center', }}>
                <AvatarGroup sx={{ marginRight: '1rem' }} max={4}>
                  {
                    filteredSeniors.map((senior, index) => {
                      return <Avatar
                        key={index}
                        alt=""
                        src={senior.profile}
                      />
                    })
                  }
                </AvatarGroup>
                <Subtitle sx={{ margin: 0, color: filteredSeniors.length === 0 && 'red' }}>{filteredSeniors.length > 0 ? filteredSeniors.length === 1 ? (filteredSeniors.length + " senior will be notified") : (filteredSeniors.length + " seniors will be notified") : "No senior will be notified"}</Subtitle>
              </Box>
              <Button
                variant="contained"
                color="primary"
                sx={{ color: '#fff', width: '50%', margin: '1rem 0 2rem' }}
                onClick={handleSendClick}
                startIcon={<TickIcon />}
              >
                Send
              </Button>
            </Paper>)
          }
        </Box>
        <Snackbar open={showSnackBar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          message={snackbarContent?.message || ''}
          autoHideDuration={4000}
          onClose={() => setShowSnackBar(false)}
          sx={{
            '.MuiSnackbarContent-root': {
              color: '#fff',
              fontWeight: '400',
              backgroundColor: snackbarContent.class === 'error' ? 'red' : (snackbarContent.class === 'warning' ? '#ffcc00' : '#00b89f'),
              display: 'flex',
              justifyContent: 'center',
              borderRadius: '10rem'
            }
          }}
        />
      </Box>
    </>
  );
};

export default DeviceDetails;
