import { Box, Button, Dialog } from "@mui/material"
import { CustomDialogTitle } from "components/dialogs/custom-dialog-title/CustomDialogTitle"
import FileInput from "components/file-input/FileInput"
import { useState } from "react"
import './CreateUpdate.scss'

const acceptedTypes = [
    'zip',
    'application/octet-stream',
    'application/zip',
    'application/x-zip',
    'application/x-zip-compressed'
]

const CreateUpdate = ({ open, handleClose, addPatch }) => {

    const [inValid, setInValid] = useState(false)
    const [patch, setPatch] = useState();
    const[text,setText]=useState(false);
    
    const validate = () => {
        if (!patch || inValid) {
            setInValid(true)
        } else {
            setInValid(false)
            addPatch(patch);
        }
    }

    const handleChangePatch = (file) => {
      if (!file) {
        setPatch()
        setText(false)
        return
      }
      if (acceptedTypes?.includes(file?.type)) {
        setPatch(file);
        setInValid(false);
        setText(true)
      } else {
        setPatch()
        setInValid(true);
      }
    }

    return (
        <div>
            <Dialog
                open={open}
                BackdropProps={{ style: { backgroundColor: 'black', opacity: "0.9" } }}
                onClose={() => {handleClose(); setPatch(); setText(false);}}
                className="patch-dialog-box"
                aria-labelledby="patch-title"
                aria-describedby="patch-description"
                keepMounted
            >
                <CustomDialogTitle onClose={() => {handleClose(); setPatch(); setText(false);}}>Browse Patch</CustomDialogTitle>
                <Box className='patch-dialog-center'>
                    <FileInput
                        parentClasses='mb-1'
                        helperText={inValid === true && 'Please upload files of zip format only'}
                        title=''
                        fileNameOpacity={text}
                        handleChangeFile={(event) => handleChangePatch(event)}
                        inputProps={{
                            accept: 'zip, application/octet-stream, application/zip, application/x-zip, application/x-zip-compressed'
                        }}
                    />
                    <Box className='w-75'>
                        <Button
                            variant="contained"
                            fullWidth
                            sx={{ color: '#FFF' }}
                            onClick={validate}
                        >
                            Create
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </div>
    )
}

export default CreateUpdate