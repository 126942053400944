import { Box, Button, Paper, Stack, Typography, Switch, Snackbar, Grid, styled, MenuItem } from '@mui/material';
import AddSeniorInput from 'components/add-senior-input/AddSeniorInput';
import SeniorInfo from 'components/senior-info/SeniorInfo';
import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as TickIcon } from 'assets/img/tick_icon.svg';
import { ReactComponent as Avatar } from 'assets/avatar.svg';
import { ReactComponent as UploadIcon } from 'assets/img/upload_icon.svg';
import { actions, GlobalContext } from 'context';
import AuthServices from 'api/services/auth-services';
import AlertDialog from 'app/create-account/pages/create-account-step4/dialog';
import BasicDatePicker from 'components/datepicker/Datepicker';
import "./step4.scss";
import { isEmailValid, isMobileValid, isName, isPhonenoValid, isValidName, mobileFormatter } from 'utils/validator';
import SelectInputMod from 'app/create-account/Components/select-input-mod/SelectInputMod';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog/ConfirmationDialog';
import moment from 'moment';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  boxShadow: "0 0 0 0",
  color: theme.palette.text.secondary,
}));

const inputValidationMessage = {
  "fname": "Enter your first name",
  "lname": "Enter your last name",
  "email": "Enter your email",
  "gender": "Select your gender",
  "dob": "Please enter a valid date",
  "country": "Select your country",
  "zipcode": "Enter your ZIP code",
  "address": "Enter your address",
  "city": "Enter your city",
  "state": "Enter your state",
  "relation_id": "Select your relationship",
  "inValidMobileNumber": "Enter a 10-digit mobile number"
}

const genderOptions = [
  { key: 'Male', value: 'male' },
  { key: 'Female', value: 'female' },
  { key: 'Other', value: 'other' },
]

const Notification = {
  profile: { message: 'Please upload a profile image', class: 'error' },
  invalidProfile: { message: 'Please upload a valid profile image', class: 'error' },
  phone: { message: 'Please enter a valid phone number', class: 'error' },
  email: { message: 'Please enter a valid email address', class: 'error' },
  incomplete: { message: 'Please fill all the fields', class: 'error' },
  addMemberSuccess: { message: 'Member added successfully', class: 'success' },
  addedAllMembers: { message: 'Successfully added all members', class: 'success' },
  invalid: { message: 'Invalid Fields found, please check the details', class: 'error' },
  atleastOneExecutive: { message: 'Atleast one member should be executive', class: 'error' },
  date: { message: 'Please select a valid date', class: 'error' },
  error: { message: 'We are facing some issues, Please try later!!', class: 'error' },
  mobileNoInUse: { message: 'Mobile number already registered', class: 'error' },
  emailInUse: { message: 'Email already registered', class: 'error' },
}

const CreAccStep4 = ({ changeTab }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { masterAccountId, contractData: { plans } } = state;
  const [memberLimit, setMemberLimit] = useState(1)

  const storeHandler = (type, payload) => dispatch({ type, payload })

  const initialDetails = { executive: false, live_video: false, view_video: false, chat: false, cen: false, zipcode: "", country: '', gender: '' }

  const [members, setMembers] = useState([]);

  //This useEffect is used for storing the user data on input change
  const [memberDetails, setMemberDetails] = useState({ ...initialDetails })

  //This useEffect are related to profile image only
  const [profileImgFile, setFile] = useState({})
  const [profileImgName, setFileName] = useState({})
  const [profileImgUrl, setProfileImgUrl] = useState({})
  const [uploaded, setUploaded] = useState(false)

  //This useEffect is only for storing the invalid fields
  const [inValid, setInValid] = useState({})

  const [openDialog, setOpenDialog] = useState(false)
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });
  const [disabled, setDisabled] = useState(false)
  const [isAddFinish, setIsAddFinish] = useState(false)
  // const [country, setCountry] = useState('')
  const [countries, setCountries] = useState([])
  // const [city, setCity] = useState(null)
  // const [currentState, setCurrentState] = useState(null)
  // const [mobile, setMobile] = useState()
  const [relations, setRelations] = useState([])

  //This useEffect used to check fields value is changed or not after onBlur
  const [isValueChanged, setIsValueChanged] = useState({})

  const mandatoryInputFields = [
    'fname',
    'lname',
    'email',
    'relation_id',
    'mobile',
    'executive',
    'live_video',
    'view_video',
    'chat',
    'cen',
    'address',
    'gender',
    'zipcode',
    'city',
    'state',
    'country',
  ]

  const [emailDialogBox, setEmailDialogBox] = useState({ open: false, member: {}, seniors: [] })
  const [seniorDialogBox, setSeniorDialogBox] = useState({ open: false, seniorlist: [] })

  const addDetail = (key, value) => {

    if (key === 'fname' || key === 'lname') {
      if (isValidName(value)) {
        const data = { ...memberDetails, [key]: value };
        setMemberDetails({ ...data });
      }
    }
    else if (key === 'dob') {
      const date = new Date(value)
      const year = date.getFullYear().toString().length === 4 ? date.getFullYear().toString() : date.getFullYear().toString().length === 3 ? ('0' + date.getFullYear().toString()) : date.getFullYear().toString().length === 2 ? ('00' + date.getFullYear().toString()) : date.getFullYear().toString().length === 1 ? ('000' + date.getFullYear().toString()) : '0000';
      const month = (date.getMonth() + 1).toString().length === 2 ? (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString().length === 1 ? ('0' + (date.getMonth() + 1).toString()) : '00'
      const dateString = date.getDate().toString().length === 2 ? date.getDate().toString() : date.getDate().toString().length === 1 ? ('0' + date.getDate().toString()) : '00'
      let dateStr = year + '-' + month + '-' + dateString;
      const age = calculateAge(value);
      if (age <= 0) {
        setInValid({ ...inValid, dob: true, age: true })
        const data = { ...memberDetails, dob: '' };
        setMemberDetails(data);
        return;
      }
      setInValid({ ...inValid, dob: false, age: false })
      const data = { ...memberDetails, [key]: dateStr, age };
      setMemberDetails({ ...data });
    } else if (key === 'mobile') {
      if (isMobileValid(value)) {
        setInValid({ ...inValid, mobile: !isPhonenoValid(value) });
        const data = { ...memberDetails, [key]: value };
        setMemberDetails({ ...data });
        // mobileValidator(value)
      }
      // else {
      //   mobileValidator(value)
      // }
    } else if (key === 'executive') {
      const data = { ...memberDetails, [key]: value, live_video: value, view_video: value, chat: value, cen: value };
      setMemberDetails({ ...data });
    } else if (key === 'live_video' || key === 'view_video' || key === 'chat' || key === 'cen') {
      const data = { [key]: value };
      if (!value) {
        data.executive = false;
      }
      setMemberDetails({ ...memberDetails, ...data });
    }
    // else if (key === 'zipcode') {
    //   setInValid({ ...inValid, zipcode: false })
    //   const data = { ...memberDetails, [key]: value };
    //   setMemberDetails({ ...data });
    // }
    else if (key === 'email') {
      // if (value.length > 50)
      //   return
      // else {
      setInValid({ ...inValid, email: !isEmailValid(value) })
      // setInValid({ ...inValid, email: false })
      const data = { ...memberDetails, [key]: value };
      setMemberDetails({ ...data });
      // }
    }
    // else if (key === 'relation_id') {
    //   setInValid({ ...inValid, relations_id: false })
    //   let relation_name = relations?.filter(item => item?.id === value)
    //   const data = { ...memberDetails, [key]: value, 'relation': relation_name?.[0]?.name || '' };
    //   setMemberDetails({ ...data });
    // }
    else {
      setInValid({ ...inValid, [key]: false })
      const data = { ...memberDetails, [key]: value };
      setMemberDetails({ ...data });
    }
  }

  const uploadFile = (e) => {
    const file = e.target.files[0];
    if (file.type.startsWith("image") && file.type !== "image/gif") {
      setFile(file);
      setFileName(e.target.value);
      setProfileImgUrl(URL.createObjectURL(file));
      setUploaded(true);
    } else {
      snackbarNotification(Notification.invalidProfile)
    }
  }

  const mobileValidator = (value) => {
    if (value.length > 0 && value.length <= 10)
      setInValid({ ...inValid, mobile: !isPhonenoValid(value) });
    else setInValid({ ...inValid, mobile: false });
  }

  const emailValidator = (e) => {
    const value = e.target.value;
    if (value.length > 0)
      setInValid({ ...inValid, email: !isEmailValid(value) });
    else setInValid({ ...inValid, email: false });
  }

  const validate = () => {
    let invalidFields = [];
    const newInValid = {};
    mandatoryInputFields.forEach(item => {
      if (memberDetails[item] === '' || memberDetails[item] === null || memberDetails[item] === undefined) {
        invalidFields.push(item)
        newInValid[item] = true;
      }
    })
    console.log(invalidFields)
    if (invalidFields.length) {
      setInValid({ ...newInValid });
    }
    else {

      let isDataChanged = handleCompareValue()
      isDataChanged ? handleMemberSeniorList() : addMember()
      setInValid({});

    }
  }

  const handleCompareValue = () => {

    //checking if profile image is uploaded or not
    if (uploaded) return true

    const member = { ...emailDialogBox?.['member'] }

    if (typeof member === 'object' && Object.keys(member)?.length > 0) {

      member['gender'] = member['sex']
      member['dob'] = member['dob'] ? moment(member['dob']).format('YYYY-MM-DD') : member['dob']
      delete member['sex']
      delete member['profile']

      for (let key in member) {
        const excludearr = ['executive', 'permission', 'age', 'cen', 'chat', 'live_video', 'relation', 'view_video']
        if (!excludearr.includes(key)) {
          if (memberDetails[key] != member[key]) {
            console.log('Not Equal', key, memberDetails[key], member[key])
            //means this value is changed!
            return true
          }
        }
      }

      //menas no value is changed!
      return false

    } else {
      console.log("Error while comparing the value")
      return false
    }

  }

  const handleMemberSeniorList = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const { seniors } = await AuthServices.getMemberSeniorList({
        old_member_id: null,
        new_member_id: emailDialogBox?.['member']?.id || null,
        senior_id: null
      })
      setSeniorDialogBox({ open: true, seniorlist: seniors })
      storeHandler(actions.SHOW_LOADER, false);
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
      let senior = error?.data?.seniors
      if (senior?.length === 0) {
        addMember()
      }
      console.log(error)
    }
  }

  const snackbarNotification = (data) => {
    setSnackBarContent(data)
    setShowSnackBar(true)
  }

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const addMember = async () => {

    const formData = new FormData();
    if (uploaded) formData.append('profile', profileImgFile, profileImgName);
    formData.append('m_acc_id', masterAccountId);

    Object.keys(memberDetails).forEach((key) => {
      if (key === 'relation_id') {
        formData.append('relation', memberDetails[key]);
      } else if (key !== 'relation') {
        formData.append(key, memberDetails[key]);
      }
    })

    let executiveCount = 0;
    members.forEach((member) => {
      member.executive === true && executiveCount++;
    })
    storeHandler(actions.SHOW_LOADER, true);
    try {
      if (executiveCount <= 0) {
        if (members.length + 1 === memberLimit) {
          if (memberDetails?.executive === true) {
            const data = await AuthServices.addMember(formData);
            setMembers([...members, data]);
            storeHandler(actions.SHOW_LOADER, false);
            addNextMember();
          } else {
            snackbarNotification(Notification.atleastOneExecutive)
            storeHandler(actions.SHOW_LOADER, false);
          }
        } else {
          const data = await AuthServices.addMember(formData);
          setMembers([...members, data]);
          storeHandler(actions.SHOW_LOADER, false);
          addNextMember();
        }
      } else {
        if (members.length + 1 === memberLimit) {
          const data = await AuthServices.addMember(formData);
          setMembers([...members, data]);
          snackbarNotification(Notification.addedAllMembers)
          setOpenDialog(true)
          storeHandler(actions.SHOW_LOADER, false);
          addNextMember();
        } else {
          const data = await AuthServices.addMember(formData);
          setMembers([...members, data]);
          storeHandler(actions.SHOW_LOADER, false);
          addNextMember();
        }
      }
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      const { data } = err;
      const { message } = data || '';
      snackbarNotification({
        message,
        class: 'error'
      })
      storeHandler(actions.SHOW_LOADER, false);
    }
  }

  // resetForm();
  const resetForm = () => {
    setMemberDetails({ ...initialDetails });
    // setMobile('');
    // setCountry('');
    setFile({});
    setFileName({});
    setProfileImgUrl({});
    setUploaded(false);
  }


  const addNextMember = () => {
    const data = {}
    data.name = memberDetails.fname + ' ' + memberDetails.lname;
    data.age = memberDetails.age;
    data.sex = memberDetails.gender;
    data.profileImg = profileImgUrl;
    data.executive = memberDetails.executive;
    snackbarNotification(Notification.addMemberSuccess)
    resetForm();
    // if (members.length + 1 >= memberLimit) {
    //   snackbarNotification(Notification.addedAllMembers)
    //   setOpenDialog(true)
    // }
    setMembers([...members, data]);
  }

  const handleBlur = (key) => {

    //This condition to check wheather the input value is changed or not!
    if (isValueChanged[key] !== memberDetails[key]) {
      setIsValueChanged({ ...isValueChanged, [key]: memberDetails[key] })
    } else {
      console.log(key, 'not changed')
      return
    }
    //...................................................................


    if ((memberDetails?.['country'] && memberDetails?.['zipcode'])) {
      storeHandler(actions.SHOW_LOADER, true)
      const filteredCountry = countries.find(item => item.name === memberDetails['country'])
      AuthServices.zipcodeGetCityState(filteredCountry?.code, memberDetails.zipcode)
        .then(({ data }) => {

          let city = data?.place_name !== null ? data?.place_name : ""
          let state = data?.state_name !== null ? data?.state_name : ""
          setMemberDetails({ ...memberDetails, city, state })

          // setCity(data?.place_name !== null ? data?.place_name : "")
          // setCurrentState(data?.state_name !== null ? data?.state_name : "")
          storeHandler(actions.SHOW_LOADER, false)
        }).catch((err) => {
          const { data } = err || {};
          const { message } = data || {};
          snackbarNotification({
            message,
            class: "error",
          });
          storeHandler(actions.SHOW_LOADER, false);
        })
    }
  }

  const getDashboard = () => {
    storeHandler(actions.SHOW_LOADER, true);
    AuthServices.getDashboard().then(({ data }) => {
      setCountries(data.countries);
      addDetail('country', '');
      storeHandler(actions.SHOW_LOADER, false);
    }).catch((err) => {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    })
  }

  const getRelations = () => {
    storeHandler(actions.SHOW_LOADER, true);
    AuthServices.getRelations().then(({ data }) => {
      setRelations(data);
      storeHandler(actions.SHOW_LOADER, false);
    }).catch((err) => {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    })
  }

  useEffect(() => {
    getDashboard()
    getRelations()

    plans?.forEach(item => {
      if (item['contract_name'] === "default_plan") {
        setMemberLimit(+item['member_limit'] || 1)
      }
    })
  }, [])

  // useEffect(() => {
  //   const data = { ...memberDetails, city, state: currentState }
  //   const newInValid = { ...inValid }
  //   if (currentState)
  //     newInValid.state = false
  //   if (city)
  //     newInValid.city = false
  //   setInValid({ ...newInValid })
  //   setMemberDetails(data)
  // }, [countries, city, currentState])

  useEffect(() => {
    handleBlur()
  }, [memberDetails.zipcode]);

  useEffect(() => {
    if (members?.length <= 0) {
      setDisabled(true)
    } else {
      setDisabled(false)
      setIsAddFinish(false)
    }
  }, [members])

  const handleChangeMobile = (event) => {
    const value = event?.target?.value?.split('.')?.join('')
    if (isMobileValid(value)) {
      // setMobile(mobileFormatter(value))
      addDetail('mobile', value);
    }
  }
  const handleEmailBlur = () => {

    let key = 'email' //because in this fucntion key always comes as email

    //This condition to check wheather the input value is changed or not!
    if (isValueChanged[key] !== memberDetails[key]) {
      setIsValueChanged({ ...isValueChanged, [key]: memberDetails[key] })
    } else {
      console.log(key, 'not changed')
      return
    }
    //...................................................................

    if (!(memberDetails.email === "" || !memberDetails.email) && isEmailValid(memberDetails.email)) {
      storeHandler(actions.SHOW_LOADER, true)
      AuthServices.getMemberEmailExist({ email: memberDetails.email })
        .then((data) => {
          const { member, seniors } = data || {}
          setEmailDialogBox({ open: true, member, seniors })
          storeHandler(actions.SHOW_LOADER, false)
        }).catch((err) => {
          const { data } = err || {};
          const { message } = data || { message: "Someting went worng!" };
          setEmailDialogBox({ open: false, member: {}, seniors: [] })
          // snackbarNotification({
          //     message,
          //     class: "error",
          // });
          storeHandler(actions.SHOW_LOADER, false);
        })
    }
  }
  const handleEmailBlurData = (member) => {
    if (typeof member === 'object') {
      const { profile, sex, dob, ...resting } = member;
      const dateOfBirth = dob?.includes('GMT') ? new Date(dob).toISOString().split('T')[0] : dob
      let newMemData = { ...resting, dob: dateOfBirth, gender: sex, executive: true, chat: true, live_video: true, cen: true, view_video: true, }
      setMemberDetails(newMemData);
      // setMobile(mobileFormatter(mobile))
      setProfileImgUrl(profile)
      // if (profile) {
      //   setProfileImgUrl(profile);
      // }
    }
  }

  return (
    <>
      <Paper sx={{ overflowX: "scroll", minWidth: '95%', marginBottom: '1rem' }}>
        <Typography sx={{ color: '#d8d8d8', marginBottom: '1rem' }}>
          {members?.length ? members?.length === 1 ? "1 Member Added" : `${members?.length} Members Added` : "No Member Added"}
        </Typography>
        {members && <Stack direction="row" spacing={1}>
          {members.map((member, index) => {
            return (
              <SeniorInfo
                key={index}
                name={member.name}
                age={member.age || "-"}
                sex={member.sex}
                image={member.profileImg}
                isExecutive={member.executive}
              />
            );
          })}
        </Stack>}
      </Paper>
      <Paper>
        <Typography sx={{ margin: "0.5rem", fontSize: '1rem', color: '#d8d8d8' }}>
          Member #{members?.length + 1}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Grid container spacing={1} sx={{ width: "78%" }}>
            <Grid className="grid-item" item xs={12} sm={6} md={4} lg={3} xl={3}>
              <Item>
                <AddSeniorInput helperText={inValid?.fname && inputValidationMessage['fname']} id="fname" value={memberDetails?.fname || ''} onChange={(e) => addDetail('fname', e.target.value)} label="First name" required />
              </Item>
            </Grid>
            <Grid className="grid-item" item xs={12} sm={6} md={4} lg={3} xl={3}>
              <Item>
                <AddSeniorInput helperText={inValid?.lname && inputValidationMessage['lname']} id="lname" value={memberDetails?.lname || ''} onChange={(e) => addDetail('lname', e.target.value)} label="Last name" required />
              </Item>
            </Grid>
            <Grid className="grid-item" item xs={12} sm={6} md={4} lg={3} xl={3}>
              <Item>
                <AddSeniorInput helperText={inValid?.email && inputValidationMessage['email']} id="email" value={memberDetails?.email || ''} onChange={e => addDetail('email', e.target.value?.substring(0, 50))} label="Email" onBlur={handleEmailBlur} required />
              </Item>
            </Grid>
            <Grid className="grid-item" item xs={12} sm={6} md={4} lg={3} xl={3}>
              <Item className="item-dropdown">
                <SelectInputMod helperText={inValid?.gender && inputValidationMessage['gender']} label="Gender" value={memberDetails?.gender?.toLowerCase() || ''} onChange={(e) => addDetail('gender', e.target.value)} required>
                  {genderOptions.map(({ key, value }, index) => {
                    return (
                      <MenuItem key={index} value={value} >
                        {key || ''}
                      </MenuItem>
                    );
                  })}
                </SelectInputMod>
              </Item>
            </Grid>
            <Grid className="grid-item" item xs={12} sm={6} md={4} lg={3} xl={3}>
              <Item>
                <AddSeniorInput type="text" helperText={inValid?.mobile && inputValidationMessage.inValidMobileNumber} id="phoneno" value={memberDetails.mobile ? mobileFormatter(memberDetails.mobile) : ''} onChange={(e) => addDetail('mobile', e.target.value?.split('.')?.join(''))} label="Phone Number" required />
              </Item>
            </Grid>
            <Grid className="grid-item" item xs={12} sm={6} md={4} lg={3} xl={3}>
              <Item>
                <BasicDatePicker helperText={inValid?.dob && inputValidationMessage['dob']} variant="filled" id="dob" value={memberDetails?.dob || ''} onChange={(newValue) => addDetail('dob', newValue)} />
              </Item>
            </Grid>
            <Grid className="grid-item" item xs={12} sm={6} md={4} lg={3} xl={3}>
              <Item className="item">
                <SelectInputMod helperText={inValid?.country && inputValidationMessage['country']} label="Country" value={memberDetails?.country || ""} onChange={e => addDetail('country', e.target.value)} onBlur={() => handleBlur('country')} required>
                  {
                    countries?.map((option, index) => {
                      return option?.code && <MenuItem key={index} value={option?.name}>{option?.name}</MenuItem>
                    })
                  }
                </SelectInputMod>
              </Item>
            </Grid>
            <Grid className="grid-item" item xs={12} sm={6} md={4} lg={3} xl={3}>
              <Item className="item">
                <AddSeniorInput helperText={inValid?.zipcode && inputValidationMessage['zipcode']} id="zipcode" value={memberDetails?.zipcode || ''} onChange={(e) => addDetail('zipcode', e.target.value)} onBlur={() => handleBlur('zipcode')} label="Zip Code" required />
              </Item>
            </Grid>
            <Grid className="grid-item" item xs={12} sm={6} md={4} lg={3} xl={3}>
              <Item>
                <AddSeniorInput helperText={inValid?.address && inputValidationMessage['address']} id="address" value={memberDetails?.address || ''} onChange={(e) => addDetail('address', e.target.value)} label="Address" required />
              </Item>
            </Grid>
            <Grid className="grid-item" item xs={12} sm={6} md={4} lg={3} xl={3}>
              <Item>
                <AddSeniorInput helperText={inValid?.city && inputValidationMessage['city']} id="city" value={memberDetails?.city || ''} onChange={(e) => addDetail('city', e.target.value)} label="City" required />
              </Item>
            </Grid>
            <Grid className="grid-item" item xs={12} sm={6} md={4} lg={3} xl={3}>
              <Item className="item">
                <AddSeniorInput helperText={inValid?.state && inputValidationMessage['state']} id="state" value={memberDetails?.state || ''} onChange={(e) => addDetail('state', e.target.value)} label="State" required />
              </Item>
            </Grid>
            <Grid className="grid-item" item xs={12} sm={6} md={4} lg={3} xl={3}>
              <Item className="item">
                <SelectInputMod helperText={inValid?.relation_id && inputValidationMessage['relation_id']} label="Relationship" value={memberDetails?.relation_id || ''} onChange={(e) => addDetail('relation_id', e.target.value)} required>
                  {
                    relations?.map((option, index) => {
                      return option?.id && <MenuItem key={index} value={option?.id}>{option?.name}</MenuItem>
                    })
                  }
                </SelectInputMod>
              </Item>
            </Grid>
          </Grid>
          <Box
            sx={{
              width: '20%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  border: '2px solid',
                  borderRadius: '1rem',
                  borderColor: 'primary.main',
                  marginBottom: '1rem',
                  width: "75%"
                }}
              >
                <Box
                  sx={{
                    padding: '1rem',
                    textAlign: 'center',
                    borderRadius: '1rem',
                  }}
                >
                  {profileImgUrl?.length ?
                    <img
                      src={profileImgUrl}
                      style={{ height: '6.5rem', width: '6.5rem', borderRadius: '50%' }}
                      alt="Profile_Image"
                    />
                    :
                    <Avatar />
                  }
                </Box>
                <Box>
                  <Button
                    component='label'
                    sx={{
                      background: 'transparent',
                      padding: '0.5rem',
                      color: 'primary',
                      fontSize: '0.65rem',
                      borderRadius: '1rem',
                      borderTopLeftRadius: '0.5rem',
                      borderTopRightRadius: '0.5rem',
                      borderColor: 'primary.main',
                      border: '2px solid',
                      minWidth: "101%",
                      margin: "0 -1px -3px -1px",
                      borderBottom: "0px"
                    }}
                    fullWidth
                    startIcon={<UploadIcon />}
                  >
                    Upload Image
                    <input type="file" value={memberDetails?.profile || ''} onChange={uploadFile} hidden accept="image/png, image/jpeg, image/jpg" />
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Grid className="executive-grid" container spacing={2}>
          <Grid className="executive-grid-item" item xs={12} sm={12} md={6} lg={3} xl={2}>
            <Typography sx={{ fontSize: '1rem', color: '#9c9c9c', whiteSpace: 'noWrap' }}>
              Executive member
            </Typography>
            <Switch checked={memberDetails?.executive} inputProps={{ 'aria-label': 'controlled' }} onChange={(e) => addDetail('executive', e.target.checked)} />
          </Grid>
        </Grid>
        <Typography className="permissions-typography">
          Permissions
        </Typography>
        <Grid className="permissions-grid" container spacing={2}>
          <Grid className="permissions-grid-item" item xs={5} sm={5} md={3}>
            <Typography sx={{ fontSize: '1rem', color: 'primary.main' }}>
              View Motion Clips
            </Typography>
            <Switch checked={memberDetails?.view_video} inputProps={{ 'aria-label': 'controlled' }} onChange={(e) => addDetail('view_video', e.target.checked)} />
          </Grid>
          <Grid sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} item xs={5} sm={5} md={3}>
            <Typography sx={{ fontSize: '1rem', color: 'primary.main' }}>
              Live video
            </Typography>
            <Switch checked={memberDetails?.live_video} inputProps={{ 'aria-label': 'controlled' }} onChange={(e) => addDetail('live_video', e.target.checked)} />
          </Grid>
          <Grid sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} item xs={5} sm={5} md={3}>
            <Typography sx={{ fontSize: '1rem', color: 'primary.main' }}>
              Chat
            </Typography>
            <Switch checked={memberDetails?.chat} inputProps={{ 'aria-label': 'controlled' }} onChange={(e) => addDetail('chat', e.target.checked)} />
          </Grid>
          <Grid sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} item xs={5} sm={5} md={3}>
            <Typography sx={{ fontSize: '1rem', color: 'primary.main' }}>
              CEN
            </Typography>
            <Switch checked={memberDetails?.cen} inputProps={{ 'aria-label': 'controlled' }} onChange={(e) => addDetail('cen', e.target.checked)} />
          </Grid>
        </Grid>
        <Box
          sx={{
            width: '50%',
            margin: '0 auto',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{
              color: '#fff',
              marginTop: '2.5rem',
              width: '45%',
            }}
            startIcon={<TickIcon />}
            className={isAddFinish ? 'disabled' : 'enabled'}
            disabled={isAddFinish ? true : false}
            onClick={validate}
          >
            Add Member
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              color: '#fff',
              marginTop: '2.5rem',
              width: '45%',
            }}
            startIcon={<TickIcon />}
            disabled={disabled}
            className={disabled ? 'disabled' : 'enabled'}
            onClick={() => setOpenDialog(true)}
          >
            Finish
          </Button>
        </Box>
      </Paper>
      <Snackbar open={showSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={snackbarContent?.message || ''}
        autoHideDuration={4000}
        onClose={() => setShowSnackBar(false)}
        sx={{
          '.MuiSnackbarContent-root': {
            color: '#fff',
            fontWeight: '400',
            backgroundColor: snackbarContent.class === 'error' ? 'red' : (snackbarContent.class === 'warning' ? '#ffcc00' : '#00b89f'),
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '10rem'
          }
        }}
      />
      <AlertDialog
        open={openDialog}
        text="Account Has Been Created Successfully"
        handleClose={() => setOpenDialog(false)}
      />

      {emailDialogBox['open'] && <ConfirmationDialog
        text="The provided email address is already associated with another senior account. Do you still want to continue with the same email address?"
        seniorList={emailDialogBox?.['seniors'] || []}
        open={emailDialogBox?.open}
        handleClose={(val) => {
          if (val === true) handleEmailBlurData(emailDialogBox?.['member'])
          setEmailDialogBox({ ...emailDialogBox, open: false });
        }}
      />}

      {seniorDialogBox['open'] && <ConfirmationDialog
        text="Editing the member details may impact other associated member accounts. Proceed with changes?"
        seniorList={seniorDialogBox['seniorlist'] || []}
        open={seniorDialogBox?.open}
        handleClose={(val) => {
          if (val === true) addMember()
          setSeniorDialogBox({ ...seniorDialogBox, open: false });
        }}
      />}

    </>
  );
};

export default CreAccStep4;
