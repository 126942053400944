import httpClient from '../http-client';
import {
  LOGIN,
  FORGOT_PASSWORD,
  REFRESH_TOKEN,
  RESET_PASSWORD,
  VERIFY_OTP,

  DASHBOARD,
  DASHBOARD_CHART,
  GET_USER_PROFILE,
  EDIT_USER_PROFILE,
  GET_ALL_USERS,
  GET_SPECIFIC_USER,
  ADD_USER,
  EDIT_SPECIFIC_USER,

  ADD_MEMBER,
  EDIT_MEMBER,
  ADD_SENIOR,
  EDIT_SENIOR,
  CREATE_MASTER_ACCOUNT,
  GET_MEMBER,
  GET_SENIOR_LIST,
  GET_SENIOR_DETAILS,

  CONTRACT_DETAILS,

  GET_NHUB_DEVICES,
  ACCOUNTS,
  ACCOUNT_REPORTS,
  CRITICAL_REPORTS,
  USER_REPORTS,
  PRODUCT_REPORTS,
  MOBILE_DOWNLOADS_REPORTS,
  NHUB_REPORTS,
  SOS_REPORTS,
  SENSOR_REPORTS,
  GET_NHUB_DEVICE_DETAILS,
  SENSOR_LIST,
  DELETE_NHUB_DEVICE,
  BROADCAST_MANAGEMENT_MESSAGE,

  SEND_INDIVIDUAL_NOTIFICATION,

  ACCOUNT_REPORTS_EXCEL,
  CITY_STATE,
  ZIPCODE_CITY_STATE,
  GET_RELATIONS,
  GET_LATEST_UPDATES,
  FINISH_ACCOUNT_CREATION,
  EDIT_USER_ROLES_PROFILE,
  DASHBOARD_COUNTRY,
  RASPBERRY_PI_VPN,
  DELETE_MEMBER,
  GET_MEMBERS,
  GET_GENERIC_NOTIFICATIONS,
  GET_CENTRIC_NOTIFICATIONS,
  ADD_GENERIC_NOTIFICATIONS,
  ADD_CENTRIC_NOTIFICATIONS,
  EDIT_GENERIC_NOTIFICATIONS,
  EDIT_CENTRIC_NOTIFICATIONS,
  DELETE_GENERIC_NOTIFICATIONS,
  DELETE_CENTRIC_NOTIFICATIONS,
  GET_BULK_NOTIFICATION_STATUS,
  SEND_NOTIFICATION_BULK,
  NHUBS_LIST,
  NHUBS_UPLOAD,
  DELETE_SENIOR,
  APP_STATS,
  DELETE_ENGINEER,
  GET_All_CEN,
  CONFIG_ALL_CEN,
  DELETE_PROFILE_IMG,
  GET_NHUB_STATUS,
  GET_SENIOR_DETAILS_NOTIFICATIONS,
  CONTRACT_TERM,
  CONTRACT_REPORTS,
  DELETE_VNHUB_SENIOR,
  VNHUB_ADDSENSOR,
  VNHUB_DELETE_SENSOR,
  VNHUBCREATE,
  VIRTUALNHUB_REPORTS,
  VNHUBFILE,
  MEMBER_EMAIL_EXIST,
  GET_MEMBER_SENIOR_LIST
} from '../config';

const AuthServices = {
  login: (data) => httpClient.post(LOGIN, data).then(response => response.data),
  forgetPassword: (data) => httpClient.post(FORGOT_PASSWORD, data).then(response => response.data),
  verifyOtp: (data) => httpClient.post(VERIFY_OTP, data).then(response => response.data),
  resetPassword: (data) => httpClient.post(RESET_PASSWORD, data).then(response => response.data),
  refreshToken: (data) => httpClient.get(REFRESH_TOKEN).then(response => response.data),

  getDashboard: () => httpClient.get(DASHBOARD).then((response => response.data)),
  getDashboardChart: (month, medium) => httpClient.get(`${DASHBOARD_CHART}/${month}/${medium}`).then(response => response.data),
  getDashboardCountry: () => httpClient.get(DASHBOARD_COUNTRY).then((response => response.data)),
  getCityState: (code) => httpClient.get(`${CITY_STATE}/${code}`).then((response => response.data)),
  filterActiveAccounts: (city, state, country) => httpClient.get(`${DASHBOARD}/${city}/${state}/${country}`).then((response => response.data)),
  getProfile: () => httpClient.get(GET_USER_PROFILE).then((response => response.data)),
  editProfile: (data) => httpClient.post(EDIT_USER_PROFILE, data).then((response => response.data)),
  editUserProfile: (id, data) => httpClient.post(`${EDIT_USER_ROLES_PROFILE}/${id}`, data).then((response => response.data)),
  getAllUsers: () => httpClient.get(GET_ALL_USERS).then((response => response.data)),
  getSpecificUser: (id) => httpClient.get(`${GET_SPECIFIC_USER}/${id}`).then((response => response.data)),
  addUser: (data) => httpClient.post(ADD_USER, data).then((response => response.data)),
  editUserSpecific: (id, data) => httpClient.post(`${EDIT_SPECIFIC_USER}/${id}`, data).then((response => response.data)),
  getLatestUpdates: () => httpClient.get(GET_LATEST_UPDATES).then((response => response.data)),
  addMember: (data) => httpClient.post(ADD_MEMBER, data).then((response => response.data)),
  getRelations: () => httpClient.get(GET_RELATIONS).then((response => response.data)),
  editMember: (id, data) => httpClient.post(`${EDIT_MEMBER}/${id}`, data).then((response => response.data)),
  deleteMember: (id, m_acc_id) => httpClient.delete(`${DELETE_MEMBER}/?member_id=${id}&master_id=${m_acc_id}`).then((response => response.data)),
  addSenior: (data) => httpClient.post(ADD_SENIOR, data).then((response => response.data)),
  deleteSenior: (id) => httpClient.delete(`${DELETE_SENIOR}/${id}`).then((response => response.data)),
  deleteEngineer: (id) => httpClient.delete(`${DELETE_ENGINEER}/${id}`).then((response => response.data)),
  getAccounts: () => httpClient.get(ACCOUNTS).then((response => response.data)),
  getAccountsData: () => httpClient.get(ACCOUNTS).then((response) => response.data),
  zipcodeGetCityState: (code, zipcode) => httpClient.get(`${ZIPCODE_CITY_STATE}/${code}/${zipcode}`).then(response => response.data),
  getMemberEmailExist: (data) => httpClient.post(MEMBER_EMAIL_EXIST, data).then(response => response.data),
  editSenior: (id, data) => httpClient.post(`${EDIT_SENIOR}/${id}`, data).then((response => response.data)),
  createMasterAccount: (data) => httpClient.post(CREATE_MASTER_ACCOUNT, data).then((response => response.data)),
  getMember: (id, m_acc_id) => httpClient.get(`${GET_MEMBER}/${id}/${m_acc_id}`).then(response => response.data),
  finishAccountCreation: (mas_acc_id) => httpClient.get(`${FINISH_ACCOUNT_CREATION}/${mas_acc_id}`).then((response => response.data)),
  getSeniorList: () => httpClient.get(GET_SENIOR_LIST).then(response => response.data),
  getMembersList: () => httpClient.get(GET_MEMBERS).then(response => response.data),
  getSeniorDetails: (id) => httpClient.get(`${GET_SENIOR_DETAILS}/${id}`).then(response => response.data),
  getContractDetails: () => httpClient.get(CONTRACT_DETAILS).then(response => response.data),
  contractTerms: (id, data) => httpClient.post(`${CONTRACT_TERM}/${id}`, data).then(response => response.data),
  getSensorList: (planId) => httpClient.get(`${SENSOR_LIST}/${planId}`).then(response => response.data),
  getNhubDevices: () => httpClient.get(GET_NHUB_DEVICES).then(response => response.data),
  deleteNhubDevice: (id) => httpClient.post(`${DELETE_NHUB_DEVICE}/${id}`).then(response => response.data),
  getNhubDeviceDetails: (id) => httpClient.get(`${GET_NHUB_DEVICE_DETAILS}/${id}`).then(response => response.data),
  sendNhubBroadcastMessage: (data) => httpClient.post(`${BROADCAST_MANAGEMENT_MESSAGE}`, data).then(response => response.data),
  getNhubStatus: (url) => httpClient.get(GET_NHUB_STATUS + '?url=' + url),
  getVpnTable: () => httpClient.get(RASPBERRY_PI_VPN).then((response => response.data)),
  getNhubsList: () => httpClient.get(NHUBS_LIST).then((response => response.data)),
  uploadNhub: (data) => httpClient.post(NHUBS_UPLOAD, data).then((response => response.data)),
  getAppStats: () => httpClient.get(APP_STATS).then((response => response.data)),
  sendBulkNotification: (data) => httpClient.post(SEND_NOTIFICATION_BULK, data).then((response => response.data)),
  getBulkNotificationStatus: () => httpClient.get(GET_BULK_NOTIFICATION_STATUS).then((response => response.data)),
  sendIndividualNotification: (data) => httpClient.post(SEND_INDIVIDUAL_NOTIFICATION, data).then(response => response.data),

  addGenericNotifications: (data) => httpClient.post(ADD_GENERIC_NOTIFICATIONS, data).then((response => response.data)),
  editGenericNotifications: (data) => httpClient.post(EDIT_GENERIC_NOTIFICATIONS, data).then((response => response.data)),
  deleteGenericNotifications: (device, sensor, id) => httpClient.delete(`${DELETE_GENERIC_NOTIFICATIONS}?device=${device}&sensor=${sensor}&id=${id}`).then((response => response.data)),
  getGenericNotifications: () => httpClient.get(GET_GENERIC_NOTIFICATIONS).then((response => response.data)),
  addCentricNotifications: (id, data) => httpClient.post(`${ADD_CENTRIC_NOTIFICATIONS}/${id}`, data).then((response => response.data)),
  getCentricNotifications: (id) => httpClient.get(`${GET_CENTRIC_NOTIFICATIONS}/${id}`).then((response => response.data)),
  editCentricNotifications: (id, data) => httpClient.post(`${EDIT_CENTRIC_NOTIFICATIONS}/${id}`, data).then((response => response.data)),
  deleteCentricNotifications: (id, device, sensor, device_id) => httpClient.delete(`${DELETE_CENTRIC_NOTIFICATIONS}/${id}?device=${device}&sensor=${sensor}&id=${device_id}`).then((response => response.data)),
  getAccountReports: (data) => httpClient.post(ACCOUNT_REPORTS, data).then(response => response.data),
  getCriticalReports: (data) => httpClient.post(CRITICAL_REPORTS, data).then(response => response.data),
  getUserReports: (data) => httpClient.post(USER_REPORTS, data).then(response => response.data),
  getSensorReports: (data) => httpClient.post(SENSOR_REPORTS, data).then(response => response.data),
  getProductReports: (data) => httpClient.post(PRODUCT_REPORTS, data).then(response => response.data),
  getMobileDownloadReports: (data) => httpClient.post(MOBILE_DOWNLOADS_REPORTS, data).then(response => response.data),
  getNhubReports: (data) => httpClient.post(NHUB_REPORTS, data).then(response => response.data),
  getVirtualNhubReports: (data) => httpClient.post(VIRTUALNHUB_REPORTS, data).then(response => response.data),
  getSosReports: (data) => httpClient.post(SOS_REPORTS, data).then(response => response.data),
  getContractReports: (data) => httpClient.post(CONTRACT_REPORTS, data).then(response => response.data),
  downloadReportsExcel: () => httpClient.get(ACCOUNT_REPORTS_EXCEL).then(response => response.data),
  getAllCen: () => httpClient.get(GET_All_CEN).then(response => response.data),
  configAllCen: (data) => httpClient.post(CONFIG_ALL_CEN, data).then(response => response.data),
  deleteProfileIMG: (data) => httpClient.post(DELETE_PROFILE_IMG + data).then(response => response.data),
  getSeniorCenDetails: (id) => httpClient.get(`${GET_SENIOR_DETAILS_NOTIFICATIONS}/${id}`).then(response => response.data),
  deleteVnhubSenior: (id) => httpClient.post(`${DELETE_VNHUB_SENIOR}/${id}`).then(response => response.data),
  vnhubAddSensor: (data) => httpClient.post(VNHUB_ADDSENSOR, data).then(response => response.data),
  deleteVnhubSensor: (data) => httpClient.post(VNHUB_DELETE_SENSOR, data).then(response => response.data),
  vnhubCreate: (data) => httpClient.post(VNHUBCREATE, data).then(response => response.data),
  vnhubdownloadfile: () => httpClient.get(VNHUBFILE).then(response => response.data),
  getMemberSeniorList: (data) => httpClient.post(GET_MEMBER_SENIOR_LIST, data).then(response => response.data),
};

export default AuthServices;
