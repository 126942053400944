import { Box, Paper, Typography, Button, Snackbar, IconButton } from '@mui/material';
import AddUserInput from 'components/add-user-input/AddUserInput';
import DashHeader from 'components/dash-header/DashHeader';
import { ReactComponent as TickIcon } from 'assets/img/tick_icon.svg';
import { ReactComponent as Avatar } from 'assets/avatar.svg';
import { ReactComponent as UploadIcon } from 'assets/img/upload_icon.svg'
import React, { useContext, useState } from 'react';
import { isEmailValid, isMobileValid, isName, isPhonenoValid, mobileFormatter, vpnValidator } from 'utils/validator';
import AuthServices from 'api/services/auth-services';
import { actions, GlobalContext } from 'context';
import { useHistory } from 'react-router';
import { MdKeyboardBackspace as BackIcon } from 'react-icons/md';
import { useEffect } from 'react';


const Notification = {
  profile: { message: 'Please upload a profile image', class: 'error' },
  invalidProfile: { message: 'Please upload a valid profile image', class: 'error' },
  phone: { message: 'Please enter a valid phone number', class: 'error' },
  email: { message: 'Please enter a valid email address', class: 'error' },
  mobile: { message: 'Please enter a valid mobile number', class: 'error' },
  passwordMatch: { message: 'Password and Confirm Password do not match', class: 'error' },
  passwordLenErr: { message: 'Password should be minimum 8 characters', class: 'error' },
  incomplete: { message: 'Please fill all the fields', class: 'error' },
  editSuccess: { message: 'Profile updated successfully', class: 'success' },
  error: { message: 'We are facing some issues, Please try later!!', class: 'error' }
}

const EditProfile = () => {

  const { state: { userProfile: current }, dispatch } = useContext(GlobalContext);
  const storeHandler = (type, payload) => dispatch({ type, payload });
  const history = useHistory();

  const initialState = {
    fname: current.fname,
    lname: current.lname,
    email: current.email,
    mobile: mobileFormatter(current?.phone),
    accountType: 'Admin'
  }

  const [userDetails, setUserDetails] = useState({ ...initialState });
  const [validation, setValidation] = useState([]);
  const [invalidMobile, setInvalidMobile] = useState(false);
  const [passwordLenErr, setPasswordLenErr] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [inValid, setInValid] = useState({});
  const [mobile, setMobile] = useState();
  const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });

  // Profile Image states
  const [profileImgFile, setFile] = useState({})
  const [profileImgName, setFileName] = useState({})
  const [profileImgUrl, setProfileImgUrl] = useState(current.profile)
  const [uploaded, setUploaded] = useState(false)

  const inputFields = [
    'fname',
    'lname',
    'email',
    'mobile',
  ]

  const uploadFile = (e) => {
    const file = e.target.files[0];
    setFile(file);
    if (file.type.startsWith("image")&& file.type !== "image/gif") {
      setFile(file);
      setFileName(e.target.value);
      setProfileImgUrl(URL.createObjectURL(file));
      setUploaded(true);
    } else {
      snackbarNotification(Notification.invalidProfile)
    }
  }

  const userInfo = (key, value) => {
    if (key === 'fname' || key === 'lname') {
      if (isName(value)) {
        const data = { ...userDetails, [key]: value };
        setInValid({ ...inValid, [key]: false })
        setUserDetails({ ...data });
      }
    }
    else if (key === 'mobile') {
      if (isMobileValid(value)) {
        const data = { ...userDetails, [key]: value};
        setUserDetails({ ...data });
        mobileValidator(value)
      } else {
        mobileValidator(value)
      }
    }
    else {
      setInValid({ ...inValid, [key]: false })
      const data = { ...userDetails, [key]: value };
      setUserDetails({ ...data });
    }
  }

  const passwordLenValidator = (value) => {
    if (value?.length < 8) {
      setPasswordLenErr(true);
    } else {
      setPasswordLenErr(false);
    }
  }

  const passwordMatchValidator = (value) => {
    if (value !== userDetails?.password) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
  }

  const emailValidator = (e) => {
    const value = e?.target?.value;
    setInvalidEmail(!isEmailValid(value))
  }

  const mobileValidator = (e) => {
    const value = e?.target?.value;
    setInvalidMobile(!isPhonenoValid(value))
  }

  const snackbarNotification = (data) => {
    setSnackBarContent(data)
    setShowSnackBar(true)
  }

  const validate = () => {
    let invalidFields = [];
    const newInValid = {};
    inputFields.forEach(item => {
      if (!userDetails[item]) {
        invalidFields.push(item)
        newInValid[item] = true;
      }
    })
    if (invalidFields?.length || passwordLenErr || passwordMatch) {
      setValidation([...invalidFields])
      setInValid({ ...inValid, ...newInValid });
    }
    else if (userDetails?.password?.length < 8 && userDetails?.password?.length !== 0) {
      snackbarNotification(Notification.passwordLenErr);
    }
    else if (userDetails?.password !== userDetails?.confirm_password) {
      snackbarNotification(Notification.passwordMatch);
    }
    else {
      setValidation([]);
      edit();
    }
  }

  const edit = async () => {
    const formData = new FormData();
    if (uploaded)
      formData.append('profile', profileImgFile, profileImgName);
    for (let key in userDetails) {
      formData.append(key, userDetails[key]);
    }
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const response = await AuthServices.editProfile(formData);
      const { message } = response;
      snackbarNotification({
        message,
        class: "success",
      });
      editSuccess();
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    }
  }

  const editSuccess = () => {
    snackbarNotification(Notification.editSuccess);
    storeHandler(actions.USER_PROFILE, {});
    resetForm();
    setTimeout(() => {
      history.push("/dashboard");
    }, 2000);
  }

  const handleChangeMobile = (event) => {
    const value = event?.target?.value?.split('.')?.join('')
    setMobile(mobileFormatter(value))
    userInfo('mobile', value)
  }

  const resetForm = () => {
    setFile({});
    setMobile('');
    setFileName({});
    setProfileImgUrl({});
    setUploaded(false);
    setTimeout(() => {
      setUserDetails({});
    }, 1000);
  }

  const HandleAvatarDelete = async ()=>{
    storeHandler(actions.SHOW_LOADER, true);
    try {
      if(!profileImgUrl) throw new Error("Profile Image not Present")
      const response = await AuthServices.deleteProfileIMG("staff/"+current?.id);
      const { message } = response;
      snackbarNotification({
        message,
        class: "success",
      });
      editSuccess();
      storeHandler(actions.SHOW_LOADER, false);
    } catch (error) {
      snackbarNotification({
        message:error.message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    }
    
  }

  useEffect(() => {
    setMobile(mobileFormatter(current?.phone))
  }, [])

  return (
    <>
      <DashHeader pageTitle="Profile" />

      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90%' }}
      >
        <Paper
          sx={{ padding: '1.5rem 1rem', width: { xs: '100%', md: 'auto' }, height: '100%', overflowY: 'auto' }}
        >
          <Box mb="2.25rem" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <IconButton onClick={() => history.push('/dashboard')}>
              <BackIcon color='#00B89F' />
            </IconButton>
            <Typography color="primary" textAlign="center">
              Edit Admin Profile
            </Typography>
            <Box sx={{ width: '2rem' }}></Box>
          </Box>
          <Box sx={{ display: { md: 'flex' }, gap: { md: '1rem' } }}>
            <Box>
              <Box>
                <AddUserInput
                  labelText="First name" helperText={inValid?.fname && 'Enter the first name'}
                  value={userDetails?.fname || ''} onChange={(e) => userInfo('fname', e.target.value)}
                />
              </Box>
              <Box>
                <AddUserInput labelText="Last name" value={userDetails?.lname || ''}
                  onChange={(e) => userInfo('lname', e.target.value)} helperText={inValid?.lname && 'Enter a valid last name'}
                />
              </Box>
              <Box>
                <AddUserInput
                  // fieldType="select"
                  // options={['select', 'admin', 'engineer']} disabled={0}
                  // onChange={(e) => userInfo('type', e.target.value)}
                  // value={userDetails?.type || 'select'} helperText={inValid?.type && 'Select an account type'}
                  // labelText="Account type"
                  value={(userDetails?.accountType) || ''} helperText={inValid?.type && 'Select an account type'}
                  // onChange={(e) => userInfo('type', e.target.value)}
                  readOnly
                  labelText="Account type"
                />
              </Box>
              <Box>
                <AddUserInput labelText="Email" helperText={inValid?.email && 'Enter a valid email address'} value={userDetails?.email || ''}
                  onChange={(e) => { userInfo('email', e.target.value); emailValidator(e); }}
                />
              </Box>
              <Box sx={{ marginBottom: '1rem' }}>
                <AddUserInput fieldType="password" labelText="Enter password" value={userDetails?.password || ''}
                  onChange={(e) => { userInfo('password', e.target.value); passwordLenValidator(e.target.value); }}
                  helperText={inValid?.password ? 'Enter a valid password' : passwordLenErr ? 'Enter min 8 characters' : ''}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    border: '2px solid',
                    borderRadius: '0.5rem',
                    borderColor: 'primary.main',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    marginBottom: '1rem',
                    minWidth: '11rem',
                    minHeight: '11rem',
                    maxWidth: '11rem',
                    maxHeight: 'min-content',
                  }}
                >
                  <Box
                    sx={{
                      padding: '1rem 2rem',
                      textAlign: 'center',
                      borderRadius: '1rem',
                    }}
                  >
                    {!vpnValidator(profileImgUrl) ?
                      <img
                        src={profileImgUrl}
                        style={{ height: '6.5rem', width: '6.5rem', borderRadius: '50%' }}
                        alt=""
                      />
                      :
                      <Avatar />
                    }
                  </Box>
                  <Box>
                    <Button
                      component='label'
                      sx={{
                        background: 'transparent',
                        padding: '0.25rem',
                        color: 'primary',
                        fontSize: '0.65rem',
                        borderRadius: '0.5rem',
                        borderColor: 'primary.main',
                        border: '1px solid',
                        margin: 0,
                      }} 
                      fullWidth
                      startIcon={<UploadIcon />}
                    >
                      {vpnValidator(profileImgUrl) ? "Upload Image" : "Change Image"}
                      <input type="file" value={userDetails?.profile || ''} onChange={uploadFile} hidden accept="image/png, image/jpeg, image/jpg" />
                    </Button>
                  </Box>
                  
                  {!vpnValidator(current.profile) && (
                    <Box>
                      <Button
                        component='label'
                        sx={{
                          background: 'transparent',
                          padding: '0.25rem',
                          color: 'red',
                          fontSize: '0.65rem',
                          borderRadius: '0.5rem',
                          borderColor: 'red',
                          border: '1px solid',
                          margin: "5px 0 3px 0" ,
                        }} 
                        fullWidth
                        onClick={HandleAvatarDelete}
                      >
                        Delete Image
                      </Button>
                    </Box>
                  )}  
                  
                </Box>
              </Box>


              <Box>
                <Box>
                  <AddUserInput
                    labelText="Mobile"
                    value={mobile || ''}
                    placeholder="Enter the mobile number"
                    helperText={inValid?.mobile && 'Enter a valid phone number'}
                    onChange={(e) => handleChangeMobile(e)}
                  />
                </Box>
                <Box sx={{ marginBottom: '1rem' }}>
                  <AddUserInput
                    fieldType="password"
                    labelText="Confirm password"
                    value={userDetails?.confirm_password || ''}
                    helperText={inValid?.confirm_password ? 'Enter a valid password' : passwordMatch ? 'Passwords do not match' : ''}
                    onChange={(e) => { userInfo('confirm_password', e.target.value); passwordMatchValidator(e.target.value) }}
                  />
                </Box>
              </Box>

            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                color: '#fff',
                width: { xs: '50%', md: '25%' },
              }}
              startIcon={<TickIcon />}
              onClick={validate}
            >
              Save
            </Button>
          </Box>
        </Paper>
        <Snackbar open={showSnackBar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          message={snackbarContent?.message || ''}
          autoHideDuration={4000}
          onClose={() => setShowSnackBar(false)}
          sx={{
            '.MuiSnackbarContent-root': {
              color: '#fff',
              fontWeight: '400',
              backgroundColor: snackbarContent.class === 'error' ? 'red' : (snackbarContent.class === 'warning' ? '#ffcc00' : '#00b89f'),
              display: 'flex',
              justifyContent: 'center',
              borderRadius: '10rem'
            }
          }}
        />
      </Box>
    </>
  );
};

export default EditProfile;
