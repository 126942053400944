import { TextField, MenuItem, styled } from '@mui/material';
import React from 'react';

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };
export const TextFieldEl = styled(TextField)({
  margin: '0 0.5rem',
  '& label': {
    color: '#d8d8d8',
  },
  '& label.Mui-focused': {
    color: '#d8d8d8',
  },
  '& .MuiOutlinedInput-root': {
    outline: 'none !important',
    border: '0px red',
    color: '#d8d8d8',
    '& .MuiSelect-outlined': {
      fontSize: '1rem',
      color: '#d8d8d8',
    },
    '& fieldset': {
      borderColor: '#d8d8d8',
      borderWidth: '2px',
      borderRadius: '.5rem',
    },
    '&:hover fieldset': {
      borderColor: 'primary',
      borderWidth: '2px',
      borderRadius: '.5rem',
    },
    '&.Mui-focused fieldset': {
      borderWidth: '2px',
      borderColor: 'primary',
      borderRadius: '0.5rem',
    },
  },
  '& .MuiSvgIcon-root': {
    color: '#d8d8d8',
  },
});
const SelectMenuOutlined = ({ labelText, value, onChange, options }) => {
  return (
    <TextFieldEl
      fullWidth
      size="small"
      label={labelText}
      value={value}
      select
      onChange={onChange}
    >
      {options?.map((option, index) => {
        return option?.code ? <MenuItem key={index} value={option.code}>{option.name}</MenuItem> : <MenuItem key={index} value={option}>{option}</MenuItem>;
      })}
    </TextFieldEl>
  );
};

export default SelectMenuOutlined;
