import React, { useContext, useState, useEffect } from "react";
import { Button, Box, Stack } from "@mui/material";
import { TransparentBtn } from "../../components/styled-componets/style";
import SimpleInputField from "components/simple-inputfield/SimpleInputField";
import AuthServices from "api/services/auth-services";
import { actions, GlobalContext } from "context";
import { ReactComponent as TickIcon } from "assets/img/tick_icon.svg";
import { getTimeZoneOffset, getUserTimeZome } from "utils/datetime-formatter";
import { isNumber } from "utils/validator";

const WatchInputs = ({
  currentSelected,
  selected,
  setting,
  snackbarNotification,
  handleFinish,
  isGeneric,
  ...rest
}) => {
  const { dispatch } = useContext(GlobalContext);
  const storeHandler = (type, payload) => dispatch({ type, payload });
  const [invalidModes, setInvalidModes] = useState(false);

  const [notificatonModes, setNotificationModes] = useState({ email: setting?.notification_type?.email, sms: setting?.notification_type?.sms, push: setting?.notification_type?.push });
  const handleModeClick = (e) => {
    const mode = e.target.name;
    const prev = notificatonModes[mode];
    setNotificationModes({ ...notificatonModes, [mode]: !prev });
    invalid.modes = false;
    setInvalidModes(false)

  }

  const [settings, setSettings] = useState({});

  const [bool, setBool] = useState(false);
  const [invalid, setInvalid] = useState({});

  useEffect(() => {
    const data = {
      ...setting,
      max: setting?.thresholds?.max,
      min: setting?.thresholds?.min,
      max_sys: setting?.thresholds?.max_sys,
      min_sys: setting?.thresholds?.min_sys,
      max_dia: setting?.thresholds?.max_dia,
      min_dia: setting?.thresholds?.min_dia,
    }
    setSettings({ ...data })
  }, [isGeneric, setting])
  const handleChange = (value, key) => {
    let newInvalid = { ...invalid };
    newInvalid[key] = false;
    setInvalid(newInvalid);
    let data = settings;
    data[key] = value;
    setSettings(data);
    setBool((prev) => !prev);
  };

  const validate = () => {
    let data = settings;
    let invalid = {};
    let valid = true;
    if (data?.message === "") {
      invalid.message = true;
      valid = false;
    }
    if (data?.lesser_message === "") {
      invalid.lesser_message = true;
      valid = false;
    }
    if (data?.setting === "blood_pressure") {
      if (data?.max_sys === "") {
        invalid.max_sys = true;
        valid = false;
      }
      if (data?.min_sys === "") {
        invalid.min_sys = true;
        valid = false;
      }
      if (data?.max_dia === "") {
        invalid.max_dia = true;
        valid = false;
      }
      if (data?.min_dia === "") {
        invalid.min_dia = true;
        valid = false;
      }
    } else {
      if (data?.max === "") {
        invalid.max = true;
        valid = false;
      }
      if (data?.min === "") {
        invalid.min = true;
        valid = false;
      }
    }
    setInvalid(invalid);
    const isValidModes = Object.values(notificatonModes).some(item => item)
    setInvalidModes(!isValidModes)
    if (valid && (isValidModes || currentSelected === "generic")) handleEdit();
  }

  const handleEdit = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const data =
        setting?.setting === "blood_pressure"
          ? {
            device: settings?.device,
            sensor: settings?.setting,
            id: settings?.id,
            max_sys: settings?.max_sys,
            min_sys: settings?.min_sys,
            max_dia: settings?.max_dia,
            min_dia: settings?.min_dia,
            message: settings?.message,
            lesser_message: settings?.lesser_message,
            timezone: getUserTimeZome(),
            origin: getTimeZoneOffset()
          }
          : {
            device: settings?.device,
            sensor: settings?.setting,
            id: settings?.id,
            max: settings?.max,
            min: settings?.min,
            message: settings?.message,
            lesser_message: settings?.lesser_message,
            timezone: getUserTimeZome(),
            origin: getTimeZoneOffset()
          };
      if (currentSelected === "generic") {
        const { message } = await AuthServices.editGenericNotifications(data);
        snackbarNotification({
          message,
          class: "success",
        });
      } else {
        const { message } = await AuthServices.editCentricNotifications(
          selected?.id,
          { ...data, noti_type: notificatonModes, timezone: getUserTimeZome(), origin: getTimeZoneOffset() }
        );
        snackbarNotification({
          message,
          class: "success",
        });
      }
      handleFinish(true);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      console.log(err);
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  return (
    <Box className="notifications-box-second" {...rest}>
      <Box className="notification-content">
        <Box className="notification-content-first">
          {setting?.setting === "blood_pressure" ? (
            <Box>
              <Stack className="stack-row">
                <Box className="box-row">
                  <SimpleInputField
                    type="Other"
                    value={settings?.a}
                    helperText={invalid?.a && "Required"}
                    labelText="Sys Max"
                    onChange={(event) => {
                      if (!isNumber(event.target.value)) return
                      if (event.target.value.length <= 3) { handleChange(event.target.value, "max_sys") }
                    }
                    }
                  />
                </Box>
                <Box style={{ display: 'grid', rowGap: '4.8rem' }}>
                  <SimpleInputField
                    type="Other"
                    value={settings?.min_sys}
                    helperText={invalid?.min_sys && "Required"}
                    labelText="Sys Min"
                    onChange={(event) => {
                      if (!isNumber(event.target.value)) return

                      if (event.target.value.length <= 3) {
                        handleChange(event.target.value, "min_sys")
                      }
                    }
                    }
                  />
                </Box>
              </Stack>
              <Stack className="stack-row">
                <Box className="box-row">
                  <SimpleInputField
                    type="Other"
                    helperText={invalid?.max_dia && "Required"}
                    value={settings?.max_dia}
                    labelText="Dia Max"
                    onChange={(event) => {
                      if (!isNumber(event.target.value)) return

                      if (event.target.value.length <= 3) {
                        handleChange(event.target.value, "max_dia")
                      }
                    }
                    }
                  />
                </Box>
                <Box>
                  <SimpleInputField
                    type="Other"
                    helperText={invalid?.min_dia && "Required"}
                    value={settings?.min_dia}
                    labelText="Dia Min"
                    onChange={(event) => {
                      if (!isNumber(event.target.value)) return

                      if (event.target.value.length <= 3) {
                        handleChange(event.target.value, "min_dia")
                      }
                    }
                    }
                  />
                </Box>
              </Stack>
            </Box>
          ) : (
            <Box style={{ display: 'grid', rowGap: '4.8rem' }}>
              {
                setting?.setting !== 'battery' &&
                (
                  <Box>
                    <SimpleInputField
                      type="Other"
                      helperText={invalid?.max && "Required"}
                      value={settings?.max}
                      labelText="Greater than"
                      placeholder={setting?.setting === 'sleep' ? 'Hours' : ''}
                      onChange={(event) => {
                        if (!isNumber(event.target.value)) return

                        let maxlen = setting?.setting === 'sleep' ? 2 : 3;
                        if (event.target.value.length <= maxlen || setting?.setting === 'steps') {
                          handleChange(event.target.value, "max")
                        }
                      }}
                    /></Box>)}
              <Box>
                <SimpleInputField
                  type="Other"
                  helperText={invalid?.min && "Required"}
                  value={settings?.min}
                  labelText="Lesser than"
                  placeholder={setting?.setting === 'sleep' ? 'Hours' : ''}
                  onChange={(event) => {
                    if (!isNumber(event.target.value) || (setting?.setting === "battery" && event.target.value > 100)) return

                    let maxlen = setting?.setting === 'sleep' ? 2 : 3;
                    if (event.target.value.length <= maxlen || setting?.setting === 'steps') {
                      handleChange(event.target.value, "min")
                    }
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box className="notification-content-second">

          <SimpleInputField
            placeholder="Please enter a message"
            type="Other"
            helperText={invalid?.message && "Message is required"}
            labelText={`${setting?.setting === 'battery' ? "CEN for Lesser than" : "CEN for Greater than"} `}
            multiline={8}
            value={settings?.message}
            onChange={(event) => handleChange(event.target.value, "message")}
          />

          {setting?.setting !== 'battery' && (<SimpleInputField
            placeholder="Please enter a message"
            type="Other"
            helperText={invalid?.lesser_message && "Message is required"}
            labelText="CEN for Lesser than"
            multiline={8}
            value={settings?.lesser_message}
            onChange={(event) => handleChange(event.target.value, "lesser_message")}
          />)}
        </Box>
      </Box>
      {
        currentSelected !== "generic" && (
          <>
            {invalidModes && <Box sx={{ color: 'red', fontSize: '0.85rem' }}>Please select at least one medium</Box>}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.8rem' }}>
              <TransparentBtn sx={{ color: notificatonModes?.email ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='email' variant="outlined">Email</TransparentBtn>
              <TransparentBtn sx={{ color: notificatonModes?.sms ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='sms' variant="outlined">SMS</TransparentBtn>
              <TransparentBtn sx={{ color: notificatonModes?.push ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='push' variant="outlined">Push</TransparentBtn>
            </Box>
          </>
        )
      }
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{ color: "#fff", width: "15rem", mb: "1rem" }}
          startIcon={<TickIcon />}
          onClick={validate}
        >
          Update
        </Button>
      </Box>
    </Box>
  );
};

export default WatchInputs;
