import React, { useContext, useState, useEffect } from "react";
import { Typography, Button, Box, Stack, MenuItem } from "@mui/material";
import { TransparentBtn } from "../../components/styled-componets/style";
import SimpleInputField from "components/simple-inputfield/SimpleInputField";
import AuthServices from "api/services/auth-services";
import { actions, GlobalContext } from "context";
import { ReactComponent as TickIcon } from "assets/img/tick_icon.svg";
import SelectInputMod from 'app/create-account/Components/select-input-mod/SelectInputMod';
import { format, isValid } from 'date-fns'
import { getTimeZoneOffset, getUserTimeZome } from "utils/datetime-formatter";

const timings = Array.from({ length: 24 }).map((item, i) => i < 12
  ? (i === 0 ? `${12}:00 am` : `${i}:00 am`)
  : (i === 12 ? `${12}:00 pm` : `${i - 12}:00 pm`))

const refillArr = ['30', '45', '60', '75', '90', '105', '120', '135', '150', '165', '180']

const PillInputs = ({
  currentSelected,
  selected,
  snackbarNotification,
  setting,
  handleFinish,
  isGeneric,
  macId,
  ...rest
}) => {
  const { dispatch } = useContext(GlobalContext);
  const storeHandler = (type, payload) => dispatch({ type, payload });
  const [medsPerDay, setMedsPerDay] = useState('3')

  const [notificatonModes, setNotificationModes] = useState({ email: setting?.notification_type?.email, sms: setting?.notification_type?.sms, push: setting?.notification_type?.push });
  const handleModeClick = (e) => {
    const mode = e.target.name;
    const prev = notificatonModes[mode];
    setNotificationModes({ ...notificatonModes, [mode]: !prev });
    invalid.modes = false;
    setInvalidModes(false)

  }

  const [settings, setSettings] = useState({});
  const [bool, setBool] = useState(false);
  const [invalid, setInvalid] = useState({});
  const [invalidModes, setInvalidModes] = useState(false);


  useEffect(() => {
    const data = {
      ...setting
    }
    if (data?.pills?.[2]?.time) {
      setMedsPerDay('3')
    } else if (data?.pills?.[1]?.time) {
      setMedsPerDay('2')
    } else if (data?.pills?.[0]?.time) {
      setMedsPerDay('1')
    } else {
      setMedsPerDay('3')
    }
    // data.refill_interval = data?.refill_interval ? data.refill_interval.toString() : ''
    setSettings({ ...data })

  }, [isGeneric, setting])

  const validate = () => {
    let data = settings;
    let invalid = {};
    let valid = true;
    if (!data.refill_interval) {
      invalid.refill_interval = true;
    }
    if (!data.refill_message) {
      invalid.refill_message = true;
    }

    let timesChoosed = 0;

    data?.pills?.forEach(item => {
      if (item.time) {
        timesChoosed = timesChoosed + 1
      }
    })

    if (timesChoosed < Number(medsPerDay)) {
      invalid.noPillData = true
    }

    let isAllMessageAvailalbe = data?.pills?.every(item => !!item.time ? !!item.message : true)

    if (!isAllMessageAvailalbe) {
      invalid.noMessage = true
    }
    setInvalid(invalid);
    const isValidModes = Object.values(notificatonModes).some(item => item)
    setInvalidModes(!isValidModes)
    if (Object.keys(invalid).length > 0 || (!isValidModes && currentSelected !== "generic")) {
      return false;
    }
    setInvalid(invalid);
    if (valid)
      handleEdit();
  }

  const handleChange = (value, key, index, pill) => {
    let data = settings;
    if (pill) {
      const date = new Date().toLocaleDateString()
      const val = pill === "time" ? new Date(`${date} ${value}`).toUTCString() : value
      setInvalid({ ...invalid, [pill]: false, noPillData: false });
      data[key][index][pill] = val;
    } else {
      data[key] = value;
      setInvalid({ ...invalid, [key]: false });
    }
    setSettings(data);
    setBool((prev) => !prev);
  };

  const handleEdit = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const data = { ...settings, timezone: getUserTimeZome(), origin: getTimeZoneOffset() }
      if (medsPerDay === '1') {
        data.pills[1].message = null
        data.pills[1].time = null
        data.pills[2].message = null
        data.pills[2].time = null
      } else if (medsPerDay === '2') {
        data.pills[2].message = null
        data.pills[2].time = null
      }
      if (currentSelected === "generic") {

        const { message } = await AuthServices.editGenericNotifications(
          data
        );
        snackbarNotification({
          message,
          class: "success",
        });
      } else {
        const { message } = await AuthServices.editCentricNotifications(
          selected?.id,
          { ...data, noti_type: notificatonModes, timezone: getUserTimeZome(), origin: getTimeZoneOffset(),mac_id:macId }
        );
        snackbarNotification({
          message,
          class: "success",
        });
      }
      handleFinish(true);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    }
  };
  const isNumber = (val) => {
    const regex = new RegExp(/^\d+$/);
    return regex.test(val) || !val
  }

  const getValue = (date) => {
    return isValid(new Date(date)) ? format(new Date(date), 'h:00 aaa') : date
  }
  return (
    <>
      <Stack sx={{ width: "45%" }}>
        <Typography
          sx={{
            fontSize: "0.8rem",
            color: "primary.main",
            paddingBottom: "1rem",
            marginTop: "1rem"
          }}
        >
          Schedules
        </Typography>
      </Stack>
      <div className="d-flex flex-row justify-content-between flex-wrap">
        <Box className="w-xs-100" sx={{ "& .MuiFormControl-root": { width: "165px", marginRight: '1rem' } }}>
          <Typography sx={{ fontSize: "0.8rem", paddingBottom: "0.5rem" }}> Medications Per Day</Typography>
          <SelectInputMod sx={{ "& .MuiSelect-select": { padding: "0.5rem", paddingRight: "32px" } }} value={medsPerDay} onChange={(event) => setMedsPerDay(event.target.value)}>
            {['1', '2', '3'].map((option, index) => <MenuItem key={index} value={option} > {`Meds ${option || '0'}x`} </MenuItem>)}
          </SelectInputMod>
        </Box>
        <div className="d-flex flex-wrap flex-row justify-content-start">
          {<Box sx={{ "& .MuiFormControl-root": { width: "165px", marginRight: '1rem' } }}>
            <Typography sx={{ fontSize: "0.8rem", paddingBottom: "0.5rem" }}> Meds 1</Typography>
            <SelectInputMod helperText={invalid?.noPillData && !settings?.pills?.[0]?.time && 'Required'} sx={{ "& .MuiSelect-select": { padding: "0.5rem", paddingRight: "32px" } }} value={settings?.pills?.[0]?.time ? getValue(settings?.pills?.[0]?.time) : ''} onChange={(event) => handleChange(event.target.value, "pills", 0, "time")}>
              {timings.map((option, index) => <MenuItem key={index} value={option} > {option || ''} </MenuItem>)}
            </SelectInputMod>
          </Box>}
          {medsPerDay > 1 && <Box sx={{ "& .MuiFormControl-root": { width: "165px", marginRight: '1rem' } }}>
            <Typography sx={{ fontSize: "0.8rem", paddingBottom: "0.5rem" }}> Meds 2</Typography>
            <SelectInputMod helperText={invalid?.noPillData && !settings?.pills?.[1]?.time && 'Required'} sx={{ "& .MuiSelect-select": { padding: "0.5rem", paddingRight: "32px" } }} value={settings?.pills?.[1]?.time ? getValue(settings?.pills?.[1]?.time) : ''} onChange={(event) => handleChange(event.target.value, "pills", 1, "time")}>
              {timings.map((option, index) => <MenuItem key={index} value={option} > {option || ''} </MenuItem>)}
            </SelectInputMod>
          </Box>}
          {medsPerDay > 2 && <Box sx={{ "& .MuiFormControl-root": { width: "165px", marginRight: '1rem' } }}>
            <Typography sx={{ fontSize: "0.8rem", paddingBottom: "0.5rem" }}> Meds 3</Typography>
            <SelectInputMod helperText={invalid?.noPillData && !settings?.pills?.[2]?.time && 'Required'} sx={{ "& .MuiSelect-select": { padding: "0.5rem", paddingRight: "32px" } }} value={settings?.pills?.[2]?.time ? getValue(settings?.pills?.[2]?.time) : ''} onChange={(event) => handleChange(event.target.value, "pills", 2, "time")}>
              {timings.map((option, index) => <MenuItem key={index} value={option} > {option || ''} </MenuItem>)}
            </SelectInputMod>
          </Box>}
          <Box sx={{ "& .MuiFormControl-root": { width: "165px", marginRight: '1rem' } }}>
            <Typography sx={{ fontSize: "0.8rem", paddingBottom: "0.5rem" }}> Refill Interval</Typography>
            <SelectInputMod helperText={invalid?.refill_interval && 'Required'} sx={{ "& .MuiSelect-select": { padding: "0.5rem", paddingRight: "32px" } }} value={settings?.refill_interval || ''} onChange={(event) => handleChange(event.target.value, "refill_interval")}>
              {refillArr.map((option, index) => <MenuItem key={index} value={option.toString()} > {option} days </MenuItem>)}
            </SelectInputMod>
          </Box>
        </div>

        {/* <Box
          sx={{
            "& .MuiFormControl-root": {
              width: "150px",
            },
          }}
        >
          <SimpleInputField
            labelText="Refill Interval"
            placeholder="0"
            type="Other"
            helperText={invalid?.refill_interval && "Required"}
            value={settings?.refill_interval}
            onChange={(event) =>
              {
                if(!isNumber(event.target.value)) return
                if(event.target.value.length <=3) {
                  handleChange(event.target.value, "refill_interval")
                }
              }
            }
          />
        </Box> */}
      </div>

      <Stack
        direction="row"
        // spacing={10}
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: 'wrap',
          justifyContent: "space-between",
          margin: "2rem 0 1rem 0",
        }}
      >

        <Stack className="w-xs-100" sx={{ width: '45%' }}>

          <Box>
            <SimpleInputField
              labelText="Medications 1"
              multiline={8}
              placeholder="Enter your message"
              maxRows={8}
              type="Other"
              helperText={((invalid.noPillData && !settings?.pills?.[0]?.message) || (invalid.noMessage && settings?.pills?.[0]?.time && !settings?.pills?.[0]?.message)) && "Required"}
              value={settings?.pills?.[0]?.message}
              onChange={(event) =>
                handleChange(event.target.value, "pills", 0, "message")
              }
            />
          </Box>
        </Stack>
        {medsPerDay > 1 && <Stack
          className="w-xs-100"
          sx={{
            width: '45%',
            justifyContent: "space-between",
          }}
        >
          <Box>
            <SimpleInputField
              labelText="Medications 2"
              multiline={8}
              placeholder="Enter your message"
              maxRows={8}
              type="Other"
              value={settings?.pills?.[1]?.message}
              helperText={((invalid.noPillData && !settings?.pills?.[1]?.message) || (invalid.noMessage && settings?.pills?.[1]?.time && !settings?.pills?.[1]?.message)) && "Required"}

              onChange={(event) =>
                handleChange(event.target.value, "pills", 1, "message")
              }
            />
          </Box>
        </Stack>}
        {medsPerDay > 2 && <Stack
          className="w-xs-100"
          sx={{
            width: '45%',
            justifyContent: "space-between",
          }}
        >
          <Box>
            <SimpleInputField
              labelText="Medications 3"
              multiline={8}
              placeholder="Enter your message"
              maxRows={8}
              type="Other"
              value={settings?.pills?.[2]?.message}
              helperText={((invalid.noPillData && !settings?.pills?.[2]?.message) || (invalid.noMessage && settings?.pills?.[2]?.time && !settings?.pills?.[2]?.message)) && "Required"}

              onChange={(event) =>
                handleChange(event.target.value, "pills", 2, "message")
              }
            />
          </Box>
        </Stack>}
        <Stack
          className="w-xs-100"
          sx={{
            width: '45%',
            justifyContent: "space-between",
          }}
        >
          <Box>
            <SimpleInputField
              labelText="Refill Message"
              multiline={8}
              helperText={invalid?.refill_message && "Refill message is required"}
              placeholder="Enter your message"
              maxRows={8}
              type="Other"
              value={settings?.refill_message}

              onChange={(event) =>
                handleChange(event.target.value, "refill_message")
              }
            />
          </Box>
        </Stack>
      </Stack>
      {
        currentSelected !== "generic" && (
          <>          {invalidModes && <Box sx={{ color: 'red', fontSize: '0.85rem' }}>Please select at least one medium</Box>}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.8rem' }}>
              <TransparentBtn sx={{ color: notificatonModes?.email ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='email' variant="outlined">Email</TransparentBtn>
              <TransparentBtn sx={{ color: notificatonModes?.sms ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='sms' variant="outlined">SMS</TransparentBtn>
              <TransparentBtn sx={{ color: notificatonModes?.push ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='push' variant="outlined">Push</TransparentBtn>
            </Box>
          </>

        )
      }
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{ color: "#fff", width: "15rem", mb: "1rem" }}
          startIcon={<TickIcon />}
          onClick={validate}
        >
          Update
        </Button>
      </Box>
    </>
  );
};

export default PillInputs;