import React, { useEffect, useState } from 'react'
import { Typography, Box, MenuItem, Button, Snackbar, Tooltip } from "@mui/material";
import { ReactComponent as TickIcon } from "assets/img/tick_icon.svg";
import { ReactComponent as BathRoomIcon } from "assets/img/24px_Bathroom_Sensor_Icon_Default.svg";
import { ReactComponent as DoorIcon } from "assets/img/door_icon.svg";
import { ReactComponent as WatchSmallIcon } from "assets/img/watch-active.svg";
import { ReactComponent as ShowerIcon } from "assets/img/shower-active.svg";
import { ReactComponent as BeltIcon } from "assets/img/belt.svg";
import { ReactComponent as CouchIcon } from "assets/img/couch.svg";
import { ReactComponent as SleepIcon } from "assets/img/sleep_icon.svg";
import { ReactComponent as HeartRateIcon } from "assets/img/heart_rate_gray.svg";
import { ReactComponent as BPMIcon } from "assets/img/bpm_icon.svg";
import { ReactComponent as StepsIcon } from "assets/img/steps_icon.svg";
import { ReactComponent as BatteryIcon } from "assets/img/battery_icon.svg";
import { ReactComponent as spo2 } from "assets/img/act.svg";
import SelectInputMod from 'app/create-account/Components/select-input-mod/SelectInputMod';
import AuthServices from 'api/services/auth-services';

const sensors = [
    {
        key: "Shower",
        icon: ShowerIcon,
        name: "Shower"
    },
    {
        key: "watch",
        icon: WatchSmallIcon,
        name: "Health Watch"
    },
    {
        key: "BathRoom",
        icon: BathRoomIcon,
        name: "BathRoom"
    },
    {
        key: "SleepBelt",
        icon: BeltIcon,
        name: "Sleep Belt"
    },
    {
        key: "SleepBelt-Chair",
        icon: CouchIcon,
        name: "Sleep Belt Chair"
    }, {
        key: "Door",
        icon: DoorIcon,
        name: "Door"
    },
]

const subSensors = [
    {
        key: "heart_rate",
        icon: HeartRateIcon,
        name: "Heart Rate"
    },
    {
        key: "blood_pressure",
        icon: BPMIcon,
        name: "Blood Pressure"
    },
    {
        key: "sleep",
        icon: SleepIcon,
        name: "Sleep"
    },
    {
        key: "steps",
        icon: StepsIcon,
        name: "Steps"
    },
    {
        key: "blood_oxygen",
        icon: spo2,
        name: "SPO2"
    }, {
        key: "battery",
        icon: BatteryIcon,
        name: "Battery"
    }
]

const timeslot = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
const count = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']

function IconBox({ primary,
    icon: Icon,
    IconImg,
    onClick,
    id,
    stroke,
    name,
    special, }) {
    return (
        <Tooltip title={name}>
        <Box
            sx={{
                display: "flex",
                borderRadius: "0.5rem",
                border: "2px solid",
                borderColor: `${primary ? "#00B89F" : "#E5E5E5"}`,
                backgroundColor: `${primary ? "#00B89F" : ""}`,
                padding: "0.5rem",
                alignItems: "center",
                minWidth: '3rem',
                maxWidth: '3rem',
                justifyContent: 'center',
                cursor:'pointer'
            }}
            onClick={onClick}
        >
            {Icon
                ? <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Icon
                        className={
                            primary
                                ? special
                                    ? "primary-spcl-active"
                                    : stroke
                                        ? "primary-stroke-active"
                                        : "primary-fill-active"
                                : special
                                    ? "primary-icon-spcl"
                                    : stroke
                                        ? "primary-icon-stroke"
                                        : "primary-icon-fill"
                        }
                    />
                </Box> :
                <Box
                    component="img"
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '1.5rem',
                        height: '1.5rem',
                        marginRight: '0.5rem',
                    }}
                    src={IconImg}
                />
            }
            <Box></Box>
        </Box>
        </Tooltip>
    );
}

const Notification = {
    successUpdate: { message: 'Successfully update CEN configuration', class: 'success' },
    updatefaild:{message:'Failed to update the CEN configuration',class:'error'}
  }
function CENSensorConfigurable() {

    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });
    const [selected, setSelected] = useState("Shower");
    const [subSelected,setSubSelected] = useState("heart_rate")
    const [selectedWatch,setSelectedWatch] = useState(false);
    const [value, setValues] = useState({count:'2', times:'8'})
    const [subValue,setSubValues] = useState({count:'2',times:'8'})
    const [selectedCount,setSelectedCount] = useState(0)
    const [cenConfig, setCenConfig] = useState({
        "Shower": {
            "count": 2,
            "timeslot": 8
        },"bathroom": {
            "count": 2,
            "timeslot": 8
        }, "sleepbelt": {
            "count": 2,
            "timeslot": 12
        }, "sleepbelt_chair": {
            "count": 2,
            "timeslot": 12
        }, "door":{
            "count": 2,
            "timeslot": 8
        },"battery": {
            "count": 2,
            "timeslot": 12
        }, "heart_rate": {
            "count": 2,
            "timeslot": 8
        },"blood_pressure": {
            "count": 2,
            "timeslot": 8
        }, "blood_oxygen": {
            "count": 2,
            "timeslot": 8
        }, "steps": {
            "count": 2,
            "timeslot": 8
        },
    })
    const snackbarNotification = (data) => {
        setSnackBarContent(data);
        setShowSnackBar(true);
      }

    const changeSensor = async (sensor)=>{
        if(sensor === "watch"){ 
            if(selectedCount === 0){
                setSelectedCount(1) ;
                setSelectedWatch(true)
                changeSubSensor("heart_rate");
            }
            else{
                setSelectedCount(0) ;
                setSelectedWatch(false)
            }
            setSubSelected("heart_rate")
        }
        else {
            setSelectedWatch(false);
            setSelectedCount(0);
        }
        setSelected(sensor)
        if(sensor in cenConfig){
            setValues({count:cenConfig[sensor].count.toString(),times:cenConfig[sensor].timeslot.toString()})
        }else{
            setValues({count:'2', times:'8' })
        }
    }

    const changeSubSensor = async (subSensor)=>{
        console.log("subsensor",subSensor);
        setSubSelected(subSensor);
        if(subSensor in cenConfig){
            setSubValues({count:cenConfig[subSensor].count.toString(),times:cenConfig[subSensor].timeslot.toString()})
        }else{
            console.log("else")
            setSubValues({count:'2', times:'8' })
        }
    }

    const getCen = async (sensor)=>{
        const cens = await AuthServices.getAllCen();
        setCenConfig(cens['cen_config'])
    }
    const UpdateCenConfig = async () => {
        console.log("value",value.times,value.count,"subValue",subValue.times,subValue.count);
        try{
        const cen = await AuthServices.configAllCen({'count':selectedWatch ? parseInt(subValue.count) : parseInt(value.count) ,'timeslot':selectedWatch ? parseInt(subValue.times) : parseInt(value.times),'sensor':selected,'sub_sensor':selected === 'watch' ? subSelected : null})
        console.log(cen);
        snackbarNotification(Notification.successUpdate);
        await getCen()
        }catch(err){
            console.log(err);
            snackbarNotification(Notification.updatefaild);
        }
    }

    useEffect(()=>{
        getCen()
    },[])

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "center", gap: "0.5rem" }}>
                {sensors.map(sensor => {
                    return <IconBox
                        stroke={true}
                        className={`default-icon ${selected === sensor.key && "active-watch-icon"
                            }`}
                        primary={selected === sensor.key}
                        onClick={() => changeSensor(sensor.key)}
                        icon={sensor.icon}
                        name={sensor.name}
                    ></IconBox>
                })}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center',mt:"0.8rem",mb:'0.8rem' }}>
                <Typography >
                    {sensors.map(sensor => {
                        return sensor.key == selected ? sensor.name : <></>
                    })}
                </Typography>
            </Box>
                {selectedWatch && 
                <>
                <Box sx={{ display: "flex", justifyContent: "center", gap: "0.5rem" }}>
                {subSensors.map(subSensor => {
                    return <IconBox
                        stroke={true}
                        className={`default-icon ${subSelected === subSensor.key && "active-watch-icon"
                            }`}
                        primary={subSelected === subSensor.key}
                        onClick={() => changeSubSensor(subSensor.key)}
                        icon={subSensor.icon}
                        name={subSensor.name}
                    ></IconBox>
                })}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center',mt:"0.8rem",mb:'0.8rem'}}>
                <Typography >
                    {subSensors.map(subSensor => {
                        return subSensor.key == subSelected ? subSensor.name : <></>
                    })}
                </Typography>
                </Box>
                </>
                }
            <Box sx={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                <Box sx={{display:'flex' ,justifyContent:"center", gap:"1.5rem", mt:"0.5rem"}}>
                    <Box sx={{ pt:"0.2rem"}}><Typography>CEN Timeslot</Typography></Box>
                    <Box sx={{minWidth:"4rem"}}><SelectInputMod value={selectedWatch ? subValue.times : value.times}
                     onChange={(e)=>selectedWatch ? setSubValues({...subValue,times:e.target.value}) : setValues({...value,times:e.target.value})}
                     sx={{ "& .MuiSelect-select": { padding: "0.5rem", paddingRight: "32px" } }}>
                        {timeslot.map(slot=>{
                            return <MenuItem key={slot} value={slot}><Typography>{slot} h</Typography></MenuItem>
                        })}
                    </SelectInputMod></Box>
                </Box>
                <Box sx={{display:'flex' ,justifyContent:"center", gap:"1.5rem", mt:"0.1rem",ml:"23px"}}>
                    <Box sx={{ pt:"0.2rem"}}><Typography>CEN count</Typography></Box>
                    <Box sx={{minWidth:"4rem"}}><SelectInputMod  value={selectedWatch ? subValue.count : value.count} 
                    onChange={(e)=>selectedWatch ? setSubValues({...subValue,count:e.target.value}) : setValues({...value,count:e.target.value})} 
                    sx={{ "& .MuiSelect-select": { padding: "0.5rem", paddingRight: "32px" } }}>
                        {count.map(slot=>{
                            return <MenuItem key={slot} value={slot} ><Typography>{slot}</Typography></MenuItem>
                        })}
                    </SelectInputMod></Box>
                </Box>
                <Button variant='contained' sx={{ color: "#fff", width: "15rem",marginTop:'10px' }}
          startIcon={<TickIcon />}
          onClick={()=>UpdateCenConfig()}
          >Save</Button>
            </Box>
            <Snackbar open={showSnackBar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          message={snackbarContent?.message || ''}
          autoHideDuration={4000}
          onClose={() => setShowSnackBar(false)}
          sx={{
            '.MuiSnackbarContent-root': {
              color: '#fff',
              fontWeight: '400',
              backgroundColor: snackbarContent.class === 'error' ? 'red' : (snackbarContent.class === 'warning' ? '#ffcc00' : '#00b89f'),
              display: 'flex',
              justifyContent: 'center',
              borderRadius: '10rem'
            }
          }}
        />
        </>
    )
}

export default CENSensorConfigurable