import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, FormControl, FormHelperText, FormLabel, InputLabel, Paper, Snackbar, TextField, Typography } from '@mui/material'
import React, { useContext, useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import Avatar from 'assets/img/default_image.png'
import styles from './Device.module.scss'
import InputField from 'components/inputfield/InputField'
import { BiRefresh } from "react-icons/bi";
import { ReactComponent as Setting } from 'assets/img/menu/services.svg'
import { FaRegWindowClose } from "react-icons/fa";
import { GlobalContext, actions } from 'context';
import AuthServices from 'api/services/auth-services';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog/ConfirmationDialog';
import VnhubDialog from 'components/dialogs/VnhubConformBox/VnhubDialog';


function Device() {

    const { dispatch } = useContext(GlobalContext);
    const storeHandler = (type, payload) => dispatch({ type, payload });
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const nhub_id = decodeURIComponent(searchParams.get('nhub_id'))
    const senior_id = decodeURIComponent(searchParams.get('senior_id'))

    const [expanded, setExpanded] = useState(false);
    const [memberData, setMemberData] = useState([]);
    const [pairedDevices, setPairedDevices] = useState([]);
    const [newMacIdData, setNewMacIdData] = useState({ mac_address: '', name: '', type: 'FallDetection' })
    const [submitButton, setSubmitButton] = useState(false)
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarContent, setSnackBarContent] = useState({ message: 'This is an error', class: 'error' });
    const [open, setOpen] = useState(false)

    const handleClose = (val) => {
        setOpen(val)
    }
    const snackbarNotification = (data) => {
        setSnackBarContent(data)
        setShowSnackBar(true)
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const getAllSeniors = async () => {

        storeHandler(actions.SHOW_LOADER, true);

        try {
            const res = await AuthServices.getSeniorDetails(senior_id)
            if ([null, undefined].includes(res?.data?.nhub)) {
                window.close()
            } else {
                setMemberData(res?.data?.senior);
                setPairedDevices(res?.data?.nhub_sen)
            }
            storeHandler(actions.SHOW_LOADER, false);

        } catch (err) {
            const { data } = err || {}
            const { message } = data || {}
            storeHandler(actions.SHOW_LOADER, false);
            snackbarNotification({
                message,
                class: 'error'
            })
        }
    };

    const handleAddSensor = async (event) => {
        event.preventDefault()

        if (!newMacIdData.mac_address || !newMacIdData.name) {
            setSubmitButton(true)
            return
        } else {
            setSubmitButton(false)
        }

        storeHandler(actions.SHOW_LOADER, true);
        try {
            const res = await AuthServices.vnhubAddSensor({ ...newMacIdData, senior_id, nhubs_id: nhub_id })
            snackbarNotification({
                message: res?.message,
                class: 'success'
            })
            setNewMacIdData({ mac_address: '', name: '', type: 'FallDetection' })
            getAllSeniors()

            storeHandler(actions.SHOW_LOADER, false);
        } catch (err) {
            console.log(err)
            const { data } = err || {}
            const { message } = data || {}
            storeHandler(actions.SHOW_LOADER, false);
            snackbarNotification({
                message,
                class: 'error'
            })
            storeHandler(actions.SHOW_LOADER, false);
        }

    }

    const handleDeleteSensor = async () => {

        storeHandler(actions.SHOW_LOADER, true);
        try {
            const res = await AuthServices.deleteVnhubSensor({ senior_id, mac_id: expanded })
            snackbarNotification({
                message: res?.message,
                class: 'success'
            })
            getAllSeniors()
            storeHandler(actions.SHOW_LOADER, false);
        } catch (err) {
            const { data } = err || {}
            const { message } = data || {}
            snackbarNotification({
                message,
                class: 'error'
            })
            storeHandler(actions.SHOW_LOADER, false);
        }

    }

    useEffect(() => {
        getAllSeniors()
        document.title = "Ntel Virtual Nhub Portal"
        return () => {
            document.title = "Ntelcare Admin Portal"
        }
    }, []);


    const handleInputChange = (event) => {
        let val = event.target.value
        let newVal = val?.split(":")?.join("")
        let ans = newVal?.match(/.{1,2}/g)?.join(":")?.toLowerCase()
        setNewMacIdData(pre => ({ ...pre, 'mac_address': ans }))
    };

    return (
        <Box className={`${styles.device_box}`}>
            <Box>
                <Typography component='h1'>
                    Manage Devices
                </Typography>
            </Box>

            <Box className={`${styles.profile_box}`}>
                <Box className={`${styles.profile}`}>
                    <img src={memberData.profile || Avatar} />
                    <Typography>{memberData?.fname} {memberData?.lname}</Typography>
                </Box>
            </Box>

            <Box className={`${styles.sensor_box}`}>

                <Paper className={`${styles.sensor_box_paper}`}>
                    <Typography className={`${styles.fall_title}`}>
                        Add Fall Detection Device
                    </Typography>

                    <FormControl className={`${styles.sensor_form}`}>
                        <FormLabel className={`${styles.label}`}>Mac ID</FormLabel>
                        <InputField
                            label='Enter MAC ID'
                            variant="filled"
                            value={newMacIdData.mac_address}
                            onChange={(e) => handleInputChange(e)}
                        />
                        {submitButton && !newMacIdData.mac_address && <FormHelperText className={`${styles.error}`}>Enter MAC ID</FormHelperText>}
                    </FormControl>

                    <FormControl className={`${styles.sensor_form}`}>
                        <FormLabel className={`${styles.label}`}>Device Name</FormLabel>
                        <InputField
                            label='Enter Device Name'
                            variant="filled"
                            value={newMacIdData.name}
                            onChange={(e) => setNewMacIdData(pre => ({ ...pre, 'name': e.target.value }))}
                        />
                        {submitButton && !newMacIdData.name && <FormHelperText className={`${styles.error}`}>Enter Device Name</FormHelperText>}
                    </FormControl>

                    <Box className={`${styles.btn_box}`}>
                        <Button onClick={handleAddSensor}>
                            Connect
                        </Button>
                    </Box>


                </Paper>

                <Paper className={`${styles.sensor_box_paper}`}>
                    <Box className={`${styles.connected_sensor}`}>
                        <Typography className={`${styles.fall_title} ${styles.sensor_title} `}>
                            Connected Devices
                        </Typography>
                        <BiRefresh
                            className={`${styles.refresh}`}
                            size={'30px'}
                            onClick={() => getAllSeniors()} />
                    </Box>

                    <Box sx={{ height: '100%' }}>
                        {pairedDevices?.length === 0 && (
                            <Box className={`${styles.no_device_connected}`}>No devices connected</Box>
                        )}
                        {pairedDevices?.length > 0 && pairedDevices?.map(item => (
                            <Accordion
                                className={`${styles.accordion}`}
                                expanded={expanded === item?.mac_id} onChange={handleChange(item?.mac_id)}>
                                <AccordionSummary
                                    className={`${styles.accordion_summary} ${expanded === item?.mac_id && styles.accordion_active}`}
                                    expandIcon={<Setting className={`${expanded === item?.mac_id ? styles.svg_active : styles.svg_default}`} />}
                                >
                                    <Typography fontSize={'1.2rem'}>{item?.name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box className={`${styles.forget_deive}`} onClick={() => handleClose(true)}>
                                        <Typography>Forget Device</Typography>
                                        <FaRegWindowClose />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Box>
                </Paper>

            </Box >

            <Snackbar open={showSnackBar}
                message={snackbarContent?.message || ''}
                className={snackbarContent?.class || ''}
                autoHideDuration={3000}
                onClose={setShowSnackBar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                sx={{
                    '.MuiSnackbarContent-root': {
                        color: '#fff',
                        fontWeight: '400',
                        backgroundColor: snackbarContent.class === 'error' ? 'red' : (snackbarContent.class === 'warning' ? '#ffcc00' : '#00b89f'),
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: '10rem'
                    }
                }}
            />

            <VnhubDialog
                open={open}
                handleClose={handleClose}
                handleConform={handleDeleteSensor}
                title={'Forget device confirmation'}
                text1={`This device have been registered with this "${memberData?.fname} ${memberData?.lname}" . Forget device will de-register from this device.`}
                text2={'Are you sure you want to forget this device ?'}
            />

        </Box >
    )
}

export default Device