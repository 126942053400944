import {
  Avatar,
  Box,
  Paper,
  Stack,
  styled,
  Typography,
  Switch,
} from '@mui/material';
import { ReactComponent as Camera } from 'assets/img/camera-gray.svg';
import { ReactComponent as Watch } from 'assets/img/watch-gray.svg';
import { ReactComponent as Door } from 'assets/img/door-gray.svg';
import { ReactComponent as Pill } from 'assets/img/pill-gray.svg';
import { ReactComponent as BathRoom } from 'assets/img/20px_Bathroom_Sensor_Icon_Default.svg';
import { ReactComponent as SOSIcon } from 'assets/img/sos-gray.svg';
import { ReactComponent as ShowerIcon } from 'assets/img/shower-gray.svg';
import { ReactComponent as FallIcon } from 'assets/img/falldetection13x13.svg';
import { ReactComponent as Fluid } from 'assets/img/fluid-gray.svg';
import { ReactComponent as Sleep } from 'assets/img/sleep-gray.svg';
import { ReactComponent as Belt } from 'assets/img/belt-gray.svg';
import { ReactComponent as Couch } from 'assets/img/couch-gray.svg';

import { ReactComponent as Gps } from 'assets/img/gps-gray.svg';
import AdultIcon from 'assets/img/adult_sensors_small.png';
import React, { useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { BsTranslate } from 'react-icons/bs';
import "./DeviceInfoCard.scss";
import { vpnValidator } from 'utils/validator';
import GoodWifi from "../../../../assets/img/icon _wifi__Good Strength (1).svg";
import ModerateWifi from "../../../../assets/img/icon _wifi__Moderate1.svg";
import WeakWifi from "../../../../assets/img/icon _wifi__Moderate2.svg";
import veryWeakWifi from "../../../../assets/img/icon _wifi__Weak.svg";
import WifiOff from "../../../../assets/img/wifi-off.svg";
import vpnoff from "../../../../assets/img/vpn_off.svg";
import vpnon from "../../../../assets/img/vpn_on.svg";
import LAN_Connected from 'assets/img/LAN_Connected.svg';
import LAN_Not_Connected from 'assets/img/LAN_Not_Connected.svg';
import { Tooltip } from '@mui/material';
import newGoodWifi from "../../../../assets/img/icon_newpatch_wifi_goodstrength.svg";
import newModerateWifi from "../../../../assets/img/icon_newpatch_Wifi_Moderate1.svg";
import newWeakWifi from "../../../../assets/img/icon_newpatch_Wifi_Moderate2.svg";
import newVeryWeakWifi from "../../../../assets/img/Icon_newpatch_wifi_Weak.svg";

const DeviceTitle = styled(Typography)({
  color: '#14B498',
  textTransform: 'capitalize',
});
const DeviceID = styled(Typography)({
  color: '#6C7175',
  textTransform: 'uppercase',
  marginBottom: '0.5rem',
});
const StatusTxt = styled(Typography)({
  color: '#D8D8D8',
  fontSize: '0.6rem',
});
const Status = styled(Typography)({
  textAlign: 'center',
  padding: '0.1rem 0.5rem',
  fontSize: '0.6rem',
  border: '1px solid',
  borderRadius: '0.5rem',
  textTransform: 'capitalize',
});
const MemberTag = styled(Box)({
  display: 'flex',
  direction: 'row',
  gap: '0.5rem',
  width: '45%',
  minWidth: 'fit-content',
  alignItems: 'center',

  border: '2px solid #6C7175',
  borderRadius: '2.5rem',
  flexWrap: 'noWrap',
  marginBottom: '0.75rem',
});
const TagTxt = styled(Typography)({
  marginRight: '0.15rem',
  wordBreak: 'break-word',
  fontSize: '0.7rem',
  color: '#D8D8D8',
  textTransform: 'capitalize',
});
const FeatureBox = styled(Box)({
  width: '1rem',
  textAlign: 'center',
});
const FeatureTxt = styled(Typography)({
  fontSize: '0.7rem',
  color: '#14B498',
});

const handleWifiStrength = (wifi_strength, external_wifi) => {
  if(external_wifi=== true){
    if (wifi_strength === "Amazing") { return GoodWifi; }
    else if (wifi_strength === "Very Good") { return ModerateWifi; }
    else if (wifi_strength === "Okay") { return WeakWifi; }
    else if (wifi_strength === "Not Good") { return veryWeakWifi; }
  }
  else if(external_wifi=== false || external_wifi===null){
    if (wifi_strength === "Amazing") { return newGoodWifi; }
    else if (wifi_strength === "Very Good") { return newModerateWifi; }
    else if (wifi_strength === "Okay") { return newWeakWifi; }
    else if (wifi_strength === "Not Good") { return newVeryWeakWifi; }
  }
  else return veryWeakWifi 
}
const Features = ({ counts, virtual }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        padding: '0.5rem 1rem',
        backgroundColor: '#2F363E',
        borderRadius: '1rem',
        boxShadow: '0 3px 10px rgba(0,0,0,0.29)',
      }}
    >
      {!virtual && (
        <>
          {
            (counts.Camera === 0 || counts?.Camera) ?
              <FeatureBox>
                <FeatureTxt>{counts.Camera < 10 ? `0${counts.Camera}` : `${counts.Camera}`}</FeatureTxt>
                <Camera />
              </FeatureBox> :
              null
          }
          {
            (counts.BathRoom === 0 || counts.BathRoom) ?
              <FeatureBox>
                <FeatureTxt>{counts.BathRoom < 10 ? `0${counts.BathRoom}` : `${counts.BathRoom}`}</FeatureTxt>
                <BathRoom />
              </FeatureBox> :
              null
          }
          {
            (counts.watch === 0 || counts.watch) ?
              <FeatureBox>
                <FeatureTxt>{counts.watch < 10 ? `0${counts.watch}` : `${counts.watch}`}</FeatureTxt>
                <Watch />
              </FeatureBox> :
              null
          }
          {
            (counts.Door === 0 || counts.Door) ?
              <FeatureBox>
                <FeatureTxt>{counts.Door < 10 ? `0${counts.Door}` : `${counts.Door}`}</FeatureTxt>
                <Door />
              </FeatureBox> :
              null
          }
          {
            (counts.PillBox === 0 || counts.PillBox) ?
              <FeatureBox>
                <FeatureTxt>{counts.PillBox < 10 ? `0${counts.PillBox}` : `${counts.PillBox}`}</FeatureTxt>
                <Pill />
              </FeatureBox> :
              null
          }
          {
            (counts.Shower === 0 || counts.Shower) ?
              <FeatureBox>
                <FeatureTxt>{counts.Shower < 10 ? `0${counts.Shower}` : `${counts.Shower}`}</FeatureTxt>
                <ShowerIcon />
              </FeatureBox> :
              null
          }
          {
            (counts.FluidConsumption === 0 || counts.FluidConsumption) ?
              <FeatureBox>
                <FeatureTxt>{counts.FluidConsumption < 10 ? `0${counts.FluidConsumption}` : `${counts.FluidConsumption}`}</FeatureTxt>
                <Fluid />
              </FeatureBox> :
              null
          }
          {
            (counts.Sleep === 0 || counts.Sleep) ?
              <FeatureBox>
                <FeatureTxt>{counts.Sleep < 10 ? `0${counts.Sleep}` : `${counts.Sleep}`}</FeatureTxt>
                <Sleep />
              </FeatureBox> :
              null
          }
          {
            (counts.SleepBelt === 0 || counts.SleepBelt) ?
              <FeatureBox>
                <FeatureTxt>{counts.SleepBelt < 10 ? `0${counts.SleepBelt}` : `${counts.SleepBelt}`}</FeatureTxt>
                <Belt />
              </FeatureBox> :
              null
          }
          {
            (counts['SleepBelt-Chair'] === 0 || counts['SleepBelt-Chair']) ?
              <FeatureBox>
                <FeatureTxt>{counts['SleepBelt-Chair'] < 10 ? `0${counts['SleepBelt-Chair']}` : `${counts['SleepBelt-Chair']}`}</FeatureTxt>
                <Couch />
              </FeatureBox> :
              null
          }
          {
            (counts.GPSWatch === 0 || counts.GPSWatch) ?
              <FeatureBox>
                <FeatureTxt>{counts.GPSWatch < 10 ? `0${counts.GPSWatch}` : `${counts.GPSWatch}`}</FeatureTxt>
                <Gps />
              </FeatureBox> :
              null
          }
          {
            (counts.SOS === 0 || counts.SOS) ?
              <FeatureBox>
                <FeatureTxt>{counts.SOS < 10 ? `0${counts.SOS}` : `${counts.SOS}`}</FeatureTxt>
                <SOSIcon />
              </FeatureBox> :
              null
          }
        </>
      )}
      {
        (counts.FallDetection === 0 || counts.FallDetection) ?
          <FeatureBox>
            <FeatureTxt>{counts.FallDetection < 10 ? `0${counts.FallDetection}` : `${counts.FallDetection}`}</FeatureTxt>
            <FallIcon />
          </FeatureBox> :
          null
      }
    </Stack>
  );
};

const DeviceInfoCard = ({ senior, id, nhubs_id, master_acc_id, status, vpn_update_date, members, featureCounts, active, handleChange, vpn_url, wifi_strength, network_type, virtual,room_no ,external_wifi}) => {
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Paper
        sx={{ height: "100%", display: "flex", flexDirection: "column", alignItems: "stretch", flexBasis: { md: '45%', lg: '22.5%' }, marginBottom: '2.5rem' }}
      >
        {/* <Link to={{
        pathname: "/nhub-details",
        state: {
          id
        },
      }} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: '100%', gap: "0rem" }}> */}
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: '100%' }}>
          <div style={{ width: "100%" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Link to={{
                pathname: "/nhub-details",
                state: {
                  id
                },
              }} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", height: '100%', gap: "0rem" }}>
                <DeviceTitle>Device Name</DeviceTitle>
              </Link>
              {active === true ? <Switch onChange={() => handleChange(id)} checked={active || false} /> : <Box className='box-nhub-inactive'><Typography sx={{ marginRight: "0.5rem" }}>Status:</Typography><Typography className='typography-font'>Inactive</Typography></Box>}
            </Stack>
            <Link to={{
              pathname: "/nhub-details",
              state: {
                id
              },
            }} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", height: '100%', gap: "0rem" }}>
              <DeviceID>{nhubs_id}</DeviceID>
              {room_no && <span style={{ color:'#6C7175'}}>Room No: {room_no}</span>}
              <Stack direction="row" alignItems="center" spacing="0.5rem" mb="1rem" mt="0.5rem">
                <StatusTxt>Status</StatusTxt>
                {virtual ? "" : <Status color={vpnValidator(vpn_url) ? "rgb(179, 2, 2)" : moment.utc().diff(vpn_update_date, 'minutes') < 5 ? 'primary' : 'rgb(179, 2, 2)'}>
                  {vpnValidator(vpn_url) ? "Offline" : moment.utc().diff(vpn_update_date, 'minutes') < 5 ? 'Online' : 'Offline'}
                </Status>}

                {!virtual && (moment.utc().diff(vpn_update_date, 'minutes') < 5 ? vpn_url !== "None:4000" ?
                  <img src={vpnon} alt="" style={{ height: "15px", width: "15px", marginRight: "2px", marginBottom: "1px" }} />
                  : <img src={vpnoff} alt="" style={{ height: "15px", width: "15px", marginRight: "2px", marginBottom: "1px" }} />
                  : <img src={vpnoff} alt="" style={{ height: "15px", width: "15px", marginRight: "2px", marginBottom: "1px" }} />
                )}


                {network_type && network_type === "LAN" && (
                  moment.utc().diff(vpn_update_date, 'minutes') < 5 ?
                    <Tooltip title={wifi_strength} placement='top'>
                      <img src={LAN_Connected} alt=" " style={{ height: "16px", width: "16px", marginTop: "1px" }} />
                    </Tooltip>
                    :
                    <Tooltip title={''}>
                      <img src={LAN_Not_Connected} alt=" " style={{ height: "15px", width: "15px" }} />
                    </Tooltip>
                )}

                {network_type && network_type === "Wi-Fi" && (
                  moment.utc().diff(vpn_update_date, 'minutes') < 5 && wifi_strength ?
                    <Tooltip title={wifi_strength} placement='top'>
                      <img src={handleWifiStrength(wifi_strength,external_wifi)} alt=" " style={{ height: "16px", width: "16px", marginTop: "1px" }} />
                    </Tooltip>
                    :
                    <Tooltip title={''}>
                      <img src={WifiOff} alt=" " style={{ height: "15px", width: "15px" }} />
                    </Tooltip>
                )}
                {virtual ? <Typography
                  sx={{
                    color: "#ffcc00",
                    border: "1px solid",
                    borderRadius: "8px",
                    fontSize: ".8rem",
                    paddingLeft: "0.5rem",
                    paddingRight: "0.5rem",
                  }}
                >
                  Virtual
                </Typography> : null}

              </Stack>
             
              <Stack
                direction="row"
                flexWrap="wrap"
                justifyContent="space-between"
                mb=".5rem"
              >
                {members.map((member, index) => {
                  return (
                    <MemberTag key={index} >
                      <Avatar src={member.profile} alt="" size="1.75rem" />
                      <TagTxt>{member.name} </TagTxt>
                     
                    </MemberTag>
                  );
                })}
              </Stack>
            </Link>
          </div>
          <div>
            <Features counts={featureCounts} virtual={virtual} />
          </div>
        </div>
        {/* </Link> */}
      </Paper>
    </div>
  );
};

export default DeviceInfoCard;
