import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import activeAcc from "assets/img/active-accounts.svg";
import "./ActiveAccount.scss";

const ActiveAccount = ({ title, number }) => {
  return (
    <Box
      className="activeaccount-flex"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Stack
        direction="row"
        alignItems="stretch"
        justifyContent="center"
        sx={{
          borderRadius: "10px",
          border: "4px solid #b7fff5",
          padding: "0",
          mb: "0.3rem",
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            padding: "0.8rem 0.6rem",
            height: "inherit",
            width: "3rem",
            border: "none",
            backgroundColor: "#b7fff5",
            borderTopLeftRadius: "5px",
            borderBottomLeftRadius: "5px",
          }}
        >
          <img sx={{ width: "1.5rem" }} alt="" src={activeAcc} />
        </Box>
        <Typography textAlign="center" sx={{ padding: "1rem" }} flex={1}>
          {number}
        </Typography>
      </Stack>
      <Box
        sx={{
          ml: "0.05rem",
        }}
      >
        <Typography sx={{ wordWrap: "break-word" }}>{title}</Typography>
      </Box>
    </Box>
  );
};

export default ActiveAccount;
