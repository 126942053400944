import React, { useContext, useEffect, useState } from "react";
import {
	Typography,
	Box,
	Paper,
	RadioGroup,
	Radio,
	Avatar,
	Tooltip,
	IconButton,
	ClickAwayListener,
	Collapse,
	Tabs,
	Tab,
	Badge,
	styled,
} from "@mui/material";
import moment from "moment";
import {
	RadioLabel,
	MemberTag,
	MemberName,
	IconDisplay,
} from "../../components/styled-componets/style";
import DropdownItem from "../../components/styled-componets/DropdownItem";
import PillInputs from "../../components/edit-inputs/PillInputs";
import SOSInputs from "../../components/edit-inputs/SosInputs";
import DoorInputs from "../../components/edit-inputs/DoorInputs";
import ShowerInput from "../../components/edit-inputs/ShowerInput";
import WatchInputs from "../../components/edit-inputs/WatchInputs";
import SleepBeltInput from "../../components/edit-inputs/SleepBeltInput";
import BathRoomInputs from "../../components/edit-inputs/BathRoomInputs";
import SubTabThreePill from "./AddInputs/SubTabThreePill";
import SubTabThreeDoor from "./AddInputs/SubTabThreeDoor";
import SubTabThreeShower from "./AddInputs/SubTabThreeShower";
import SubTabThreeWatch from "./AddInputs/SubTabThreeWatch";
import SubTabThreeSos from "./AddInputs/SubTabThreeSos";
import SubTabThreeSleepBelt from "./AddInputs/SubTabThreeSleepBelt";
import SubTabThreeFallDetection from "./AddInputs/SubTabThreeFalldetection";
import SubTabThreeBathRoom from "./AddInputs/SubTabThreeBathRoom";
import { BsSearch } from "react-icons/bs";
import LightSearchBar from "components/light-search-bar/LightSearchBar";
import DeleteConfirmationDialog from "../../components/dialogs/DeleteConfirmation";
import { ReactComponent as WatchIcon } from "assets/img/watch.svg";
import { ReactComponent as SleepIcon } from "assets/img/sleep_icon.svg";
import { ReactComponent as PillIcon } from "assets/img/pill_icon.svg";
import { ReactComponent as DoorIcon } from "assets/img/door_icon.svg";
import { ReactComponent as HeartRateIcon } from "assets/img/heart_rate_gray.svg";
import { ReactComponent as BPMIcon } from "assets/img/bpm_icon.svg";
import { ReactComponent as StepsIcon } from "assets/img/steps_icon.svg";
import { ReactComponent as BatteryIcon } from "assets/img/battery_icon.svg";
import { ReactComponent as CloseIcon } from "assets/img/circle-close.svg";
import { ReactComponent as EditIcon } from "assets/img/edit_icon_primary.svg";
import { ReactComponent as Spo2Icon } from "assets/img/normal.svg";
import { ReactComponent as AddThresholdIcon } from "assets/img/add_account_icon.svg";
import { ReactComponent as ShowerIcon } from "assets/img/shower-icon.svg";
import { ReactComponent as SleepBeltIcon } from "assets/img/belt-white.svg";
import { ReactComponent as CouchIcon } from "assets/img/couch-white.svg";
import { ReactComponent as FallDetectionIcon } from "assets/img/28x28_px_Fall_detection_outlined_green.svg";
import { ReactComponent as BathRoomIcon } from "assets/img/32px_Bathroom_Sensor_Icon_Default.svg";
import { ReactComponent as BathRoomActiveIcon } from "assets/img/32px_Bathroom_Sensor_IconP_Active.svg";
import { ReactComponent as ActiveIcon } from "assets/img/Ellipse 84.svg";

import AuthServices from "api/services/auth-services";
import { actions, GlobalContext } from "context";
import { ReactComponent as SOSIcon } from "assets/img/sos_green.svg";
import { ReactComponent as SOSIconGray } from "assets/img/sos_grey_1.svg";
import SeniorDetailsDialog from "app/notification/components/dialogs/senior-details-dialog/SeniorDetailsDialog";
import ConfirmationDialog from "components/dialogs/ConfirmationDialog/ConfirmationDialog";
import SmsTemplateCard from "app/notification/components/sms-template/SmsTemplateCard";
import smsTemplate from "utils/sms_template";
import { format, isValid } from "date-fns";

const TabThree = ({ snackbarNotification }) => {
	const { dispatch } = useContext(GlobalContext);
	const storeHandler = (type, payload) => dispatch({ type, payload });

	const [subTab, setSubTab] = useState("");
	const [searchValue, setSearchValue] = useState("");
	const [currentSelected, setCurrentSelected] = useState("generic");
	const [isFocused, setIsFocused] = useState(false);
	const [seniors, setSeniors] = useState([]);
	const [selected, setSelected] = useState({});
	const [filteredSeniors, setFilteredSeniors] = useState([]);
	const [invalid, setInvalid] = useState({ senior: false });
	const [handleSeniorOpen, setHandleSeniorOpen] = useState(false);
	const [showSave, setShowSave] = useState(false);
	const [addOrSave, setAddOrSave] = useState("");
	const [add, setAdd] = useState(false);
	const [selectedEdit, setSelectedEdit] = useState("");
	const [data, setData] = useState([]);
	const [centricData, setCentricData] = useState({});
	const [handleDialogOpen, setHandleDialogOpen] = useState([false, -1]);
	const [selectedSeniorData, setSelectedSeniorData] = useState({});
	const [sensorsConfigured, setSensorsConfigured] = useState([]);
	const [sensorsPlans, setSensorsPlans] = useState([]);

	const [showSensorConfigWarning, setShowSensorConfigWarning] = useState(false);
	const [tabToBeSelected, setTabToBeSelected] = useState();
	const [showTabs, setShowTabs] = useState(false);
	const [value, setValue] = useState(0);
	const [selectedSensor, setSelectedSensor] = useState("");
	const [openSubtab, setOpenSubTab] = useState(false);
	const [selectedSensorNotification, setSelectedSensorNotification] = useState(
		[]
	);
	const [noNotifications, setNoNotifications] = useState(false);
	const [nhubDetails, setNhubDetails] = useState();
	const [notificationMacId, setNotificationMacId] = useState("");
	const [connectedNumberOfSensors, setConnectedNumberOfSensors] = useState({});

	const getSeniors = () => {
		AuthServices.getSeniorList().then(({ data }) => {
			setSeniors(data?.seniors);
		});
	};

	const getSeniorDetails = async (seniorId) => {
		storeHandler(actions.SHOW_LOADER, true);
		try {
			const { data } = await AuthServices.getSeniorDetails(seniorId);
			const { sensors, plan, senior } = data || {};
			const { watch, sos } = senior || {};
			const { sensors: sensorsInPlan } = plan || {};
			let sensorPlans = sensorsInPlan?.map((item) => item.name) || [];
			sensorPlans = sensorPlans.filter(
				(item) =>
					(item !== "watch" && item !== "SOS") ||
					(item === "watch" && watch) ||
					(item === "SOS" && sos)
			);
			setSensorsConfigured(sensors || []);
			setSensorsPlans(sensorPlans);
			setSelectedSeniorData(data?.senior);
			storeHandler(actions.SHOW_LOADER, false);
		} catch (err) {
			const { data } = err || {};
			const { message } = data || {};
			snackbarNotification({
				message,
				class: "error",
			});
			storeHandler(actions.SHOW_LOADER, false);
		}
	};

	const getRevisedSeniors = async (seniorId) => {
		storeHandler(actions.SHOW_LOADER, true);
		try {
			const { data } = await AuthServices.getSeniorCenDetails(seniorId);
			const { nhub_sens } = data;
			setNhubDetails(nhub_sens);
			const lengths = {};
			for (const key in nhub_sens) {
				if (nhub_sens[key].length > 1) lengths[key] = nhub_sens[key].length;
			}
			if (currentSelected === "seniorSpecific") {
				setConnectedNumberOfSensors(lengths);
			}
			storeHandler(actions.SHOW_LOADER, false);
		} catch (err) {
			const { data } = err || {};
			const { message } = data || {};
			snackbarNotification({
				message,
				class: "error",
			});
			storeHandler(actions.SHOW_LOADER, false);
		}
	};

	const getSelected = (senior) => {
		setAdd(true);
		setAddOrSave("add");
		setIsFocused(false);
		setSelected(senior);
		getSeniorDetails(senior?.id);
		getRevisedSeniors(senior?.id);
		setInvalid({ senior: false });
		setSearchValue("");
		setOpenSubTab(true);
	};

	const filterSeniors = () => {
		let filteredSeniorsID =
			seniors.length > 0
				? seniors?.filter((senior) => {
					return senior?.senior_id
						.toString()
						.toLowerCase()
						.includes(searchValue.toLowerCase());
				})
				: [];
		const filteredSeniorsName =
			seniors.length > 0
				? seniors?.filter((senior) => {
					return senior?.name
						.toString()
						.toLowerCase()
						.includes(searchValue.toLowerCase());
				})
				: [];
		filteredSeniorsID = [...filteredSeniorsID, ...filteredSeniorsName];
		const noDuplicates =
			filteredSeniorsID.length > 0
				? filteredSeniorsID?.filter((senior, index) => {
					return filteredSeniorsID.indexOf(senior) === index;
				})
				: [];
		setFilteredSeniors(noDuplicates);
	};

	const getGeneric = async () => {
		storeHandler(actions.SHOW_LOADER, true);
		try {
			const { notifications } = await AuthServices.getGenericNotifications();
			const {
				watch,
				Door,
				PillBox,
				Shower,
				SOS,
				SleepBelt,
				"SleepBelt-Chair": couch,
				fall_detection,
				BathRoom,
			} = notifications;
			setData([
				...watch,
				...Door,
				...PillBox,
				...Shower,
				...SOS,
				...SleepBelt,
				...couch,
				...fall_detection,
				...BathRoom,
			]);
			storeHandler(actions.SHOW_LOADER, false);
		} catch (err) {
			const { data } = err || {};
			const { message } = data || {};
			snackbarNotification({
				message,
				class: "error",
			});
			storeHandler(actions.SHOW_LOADER, false);
		}
	};

	const getCentric = async () => {
		storeHandler(actions.SHOW_LOADER, true);
		try {
			const { notifications } = await AuthServices.getCentricNotifications(
				selected?.id
			);

			setCentricData(notifications);
			if (!selectedEdit) {
				setSelectedEdit(-1);
			} else {
				setSelectedEdit("");
			}
			storeHandler(actions.SHOW_LOADER, false);
		} catch (err) {
			const { data } = err || {};
			const { message } = data || {};
			snackbarNotification({
				message,
				class: "error",
			});
			storeHandler(actions.SHOW_LOADER, false);
		}
	};

	const handleDeleteGeneric = async (index) => {
		storeHandler(actions.SHOW_LOADER, true);
		try {
			const { message } = await AuthServices.deleteGenericNotifications(
				data[index]?.device,
				data[index]?.setting,
				data[index]?.id
			);
			snackbarNotification({
				message,
				class: "success",
			});
			setHandleDialogOpen([false, -1]);
			getGeneric();
			storeHandler(actions.SHOW_LOADER, false);
		} catch (err) {
			const { data } = err || {};
			const { message } = data || {};
			snackbarNotification({
				message,
				class: "error",
			});
			storeHandler(actions.SHOW_LOADER, false);
		}
	};

	const handleDeleteCentric = async (index) => {
		storeHandler(actions.SHOW_LOADER, true);
		try {
			const { message } = selectedSensor !== "watch" ? await AuthServices.deleteCentricNotifications(
				selected?.id,
				selectedSensorNotification?.[index]?.device,
				selectedSensorNotification?.[index]?.setting,
				selectedSensorNotification?.[index]?.id
			) : await AuthServices.deleteCentricNotifications(
				selected?.id,
				centricData?.[selectedSensor]?.[index]?.device,
				centricData?.[selectedSensor]?.[index]?.setting,
				centricData?.[selectedSensor]?.[index]?.id
			);
			snackbarNotification({
				message,
				class: "success",
			});
			setHandleDialogOpen([false, -1]);
			getRevisedSeniors(selected?.id);
			getCentric();
			if (selectedSensor === "watch")
				setSubTab(0)
			storeHandler(actions.SHOW_LOADER, false);
		} catch (err) {
			const { data } = err || {};
			const { message } = data || {};
			snackbarNotification({
				message,
				class: "error",
			});
			storeHandler(actions.SHOW_LOADER, false);
		}
	};

	useEffect(() => {
		getSeniors();
		getGeneric();
	}, []);

	useEffect(() => {
		Object.keys(selected).length > 0 && getCentric();
	}, [selected]);

	useEffect(() => {
		filterSeniors();
	}, [searchValue]);

	const handleClickAdd = () => {
		setAdd(true);
		setAddOrSave("add");
	};

	useEffect(() => {
		updatedData();
		setOpenSubTab(false);
	}, [selectedEdit]);

	const handleClickEdit = (index) => {
		selectedEdit === index ? setSelectedEdit("") : setSelectedEdit(index);
	};

	const updateSensor = (tab, sensor) => {
		setValue(0);
		if (currentSelected === "generic") {
			setSubTab(tab);
		} else if (
			(sensor === "watch" || sensor === "GPSWatch") &&
			sensorsConfigured.includes(sensor)
		) {
			setSelectedSensor(sensor);
			setSelectedSensorNotification(centricData[sensor]);
			setOpenSubTab(false);
			setSubTab(0);
			setNoNotifications(false);
		} else if (sensorsConfigured.includes(sensor)) {
			if (
				centricData[sensor].some(
					(sensorObj) => sensorObj.mac_id === nhubDetails[sensor][0]?.mac_id
				)
			) {
				const matchingItem = centricData[sensor].find(
					(item2) => item2.mac_id === nhubDetails[sensor][0].mac_id
				);
				setSelectedSensorNotification([matchingItem]);
				setNotificationMacId(matchingItem?.mac_id);
				setOpenSubTab(false);
				setSubTab("");
				setNoNotifications(false);
			} else {
				setValue(0);
				setNotificationMacId(nhubDetails?.[sensor]?.[0]?.mac_id);
				setSubTab("");
				setNoNotifications(true);
			}
			setShowTabs(true);
			setSelectedSensor(sensor);
		} else {
			setNotificationMacId(nhubDetails?.[sensor]?.[0]?.mac_id);
			setTabToBeSelected(tab);
			setShowSensorConfigWarning(true);
		}
	};

	const updatedData = () => {
		if (selectedSensor) {
			if (selectedSensor === "watch") {
				setSelectedSensor(selectedSensor);
				setSelectedSensorNotification(centricData[selectedSensor]);
				setOpenSubTab(false);
				setSubTab(0);
				setNoNotifications(false);
			} else {
				nhubDetails[selectedSensor]?.map((el, ind) => {
					if (ind === value) {
						if (centricData[selectedSensor].length === 0) {
							setSubTab("");
							setNoNotifications(true);
							setNotificationMacId(
								nhubDetails?.[selectedSensor]?.[value]?.mac_id
							);
							return;
						} else if (centricData[selectedSensor].length > 0) {
							let NotificationAdded;
							for (
								let items = 0;
								items < centricData[selectedSensor].length;
								items++
							) {
								if (
									centricData[selectedSensor][items]?.mac_id ==
									nhubDetails[selectedSensor][ind]?.mac_id
								) {
									const matchingItem = centricData[selectedSensor].find(
										(item2) =>
											item2.mac_id === nhubDetails[selectedSensor][ind].mac_id
									);
									setSelectedSensorNotification([matchingItem]);
									setNotificationMacId(matchingItem?.mac_id);
									setSubTab("");
									NotificationAdded = true;
									break;
								} else {
									setSubTab("");
									NotificationAdded = false;
								}
							}
							if (!NotificationAdded) {
								setNotificationMacId(
									nhubDetails?.[selectedSensor]?.[value]?.mac_id
								);
							}
							setSubTab("");
							setOpenSubTab(!NotificationAdded);
							setNoNotifications(!NotificationAdded);
						}
					}
					setSubTab("");
					setNotificationMacId(
						nhubDetails?.[selectedSensor]?.[value]?.mac_id
					);
				});
			}
		}
	};

	const UserTypeSelector = ({ handleChange }) => {
		return (
			<RadioGroup
				className="radio-group"
				defaultValue="generic"
				value={currentSelected}
				onChange={(event) => {
					setAddOrSave("");
					setAdd(false);
					setSelectedEdit("");
					handleChange(event.target.value);
					setSelected({});
					setSelectedSensor("");
					setSubTab("");
					setConnectedNumberOfSensors({});
					setNoNotifications(false);
					setOpenSubTab(true);
					setSelectedSensorNotification([]);
				}}
			>
				<Box className="radio-group-box">
					<RadioLabel value="generic" control={<Radio />} label="Generic" />
					<RadioLabel
						value="seniorSpecific"
						control={<Radio />}
						label="Senior Specific"
						sx={{ whiteSpace: "nowrap" }}
					/>
					<RadioLabel
						value="sms_template"
						control={<Radio />}
						label="SMS Template"
					/>
				</Box>
			</RadioGroup>
		);
	};

	const goBackToNotifications = () => {
		if (currentSelected === "generic") {
			setAddOrSave("");
			getGeneric();
		} else {
			getRevisedSeniors(selected?.id);
			getCentric();
			setAdd(true);
			setAddOrSave("add");
			if (selectedSensor === "watch") {
				setSubTab(0)
			}
			else {
				setSubTab("");
			}
			setOpenSubTab(false);
		}
	};

	const pillsCount = (pill) => {
		return !!pill.length ? pill.filter((item) => item.time)?.length || 0 : 0;
	};

	const pillsCountWithTime = (pill) => {
		return !!pill.length ? pill.filter((item) => item.time) : [];
	};

	const displaySensor = (sensorName, sensorName2) => {
		return (
			currentSelected === "generic" ||
			sensorsPlans.includes(sensorName) ||
			sensorsPlans.includes(sensorName2)
		);
	};

	const handleTabChange = (event, newValue) => {
		setValue(newValue);
		selectedSensor &&
			nhubDetails[selectedSensor]?.map((el, ind) => {
				if (ind === newValue) {
					if (centricData[selectedSensor].length === 0) {
						setSubTab("");
						setNotificationMacId(
							nhubDetails?.[selectedSensor]?.[newValue]?.mac_id
						);
						setNoNotifications(true);
						return;
					} else if (centricData[selectedSensor].length > 0) {
						let NotificationAdded;
						for (
							let items = 0;
							items < centricData[selectedSensor].length;
							items++
						) {
							if (
								centricData[selectedSensor][items]?.mac_id ==
								nhubDetails[selectedSensor][ind]?.mac_id
							) {
								const matchingItem = centricData[selectedSensor].find(
									(item2) =>
										item2.mac_id === nhubDetails[selectedSensor][ind].mac_id
								);
								setSelectedSensorNotification([matchingItem]);
								setNotificationMacId(matchingItem?.mac_id);
								setSubTab("");
								NotificationAdded = true;
								break;
							} else {
								setSubTab("");
								NotificationAdded = false;
								setNotificationMacId(
									nhubDetails?.[selectedSensor]?.[newValue]?.mac_id
								);
							}
						}
						if (!NotificationAdded) {
							setNotificationMacId(
								nhubDetails?.[selectedSensor]?.[newValue]?.mac_id
							);
						}
						setSubTab("");
						setOpenSubTab(!NotificationAdded);
						setNoNotifications(!NotificationAdded);
					}
				}
				setSubTab("");
				setNotificationMacId(
					nhubDetails?.[selectedSensor]?.[newValue]?.mac_id
				);
			});
	};

	const handleAddNotifications = () => {
		if (selectedSensor === "watch") {
			setSubTab(0);
			setOpenSubTab(true);
			setNoNotifications(false);
		} else if (selectedSensor === "Door") {
			setSubTab(1);
			setOpenSubTab(true);
			setNoNotifications(false);
		} else if (selectedSensor === "PillBox") {
			setSubTab(2);
			setOpenSubTab(true);
			setNoNotifications(false);
		} else if (selectedSensor === "Shower") {
			setSubTab(3);
			setOpenSubTab(true);
			setNoNotifications(false);
		} else if (selectedSensor === "SOS") {
			setSubTab(4);
			setOpenSubTab(true);
			setNoNotifications(false);
		} else if (selectedSensor === "SleepBelt") {
			setSubTab(5);
			setOpenSubTab(true);
			setNoNotifications(false);
		} else if (selectedSensor === "SleepBelt-Chair") {
			setSubTab(6);
			setOpenSubTab(true);
			setNoNotifications(false);
		} else if (selectedSensor === "FallDetection") {
			setSubTab(7);
			setOpenSubTab(true);
			setNoNotifications(false);
		} else {
			setSubTab(8);
			setOpenSubTab(true);
			setNoNotifications(false);
		}
	};

	const handleNewSearch = () => {
		setSelected({});
		setAddOrSave("");
		setAdd(false);
		setSelectedEdit("");
		setSelectedSensor("");
		setSubTab("");
		setNoNotifications(false);
		setOpenSubTab(true);
	};

	const TabLabel = styled("span")({
		minWidth: "100px",
		maxWidth: "150px", // Set the maximum width for the tab label
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		textTransform: "none",
	});

	console.log("selected mac ID",selectedSensorNotification);
	return (
		<>
			<Box className="tab-three-main">
				<Box className="conditional-upper-box">
					<UserTypeSelector handleChange={setCurrentSelected} />
					{currentSelected === "seniorSpecific" &&
						(Object.keys(selected).length <= 0 ? (
							<ClickAwayListener
								onClickAway={() => {
									setIsFocused(false);
								}}
							>
								<Box
									onFocus={() => setIsFocused(true)}
									className={`search-bar-box ${currentSelected === "generic" && "no-search-bar"
										}`}
								>
									<LightSearchBar
										helperText={invalid?.senior && "Please select a senior"}
										setSearchValue={setSearchValue}
									/>
									<Paper
										className={`paper-dropdown ${isFocused
											? (searchValue === "" ||
												filteredSeniors.length === 0) &&
											"invisible"
											: "invisible"
											}`}
									>
										{filteredSeniors?.length > 0 &&
											filteredSeniors?.map((senior, index) => {
												return (
													<DropdownItem
														getSelected={getSelected}
														key={index}
														id={senior?.id}
														name={senior?.name}
														gender={senior?.gender}
														age={senior?.age}
														profile={senior?.profile}
													/>
												);
											})}
									</Paper>
								</Box>
							</ClickAwayListener>
						) : (
							<Box className="box-selected-seniors">
								{Object.keys(selected).length > 0 && (
									<MemberTag
										sx={{ marginRight: "1rem", cursor: "pointer" }}
										onClick={() => setHandleSeniorOpen(true)}
									>
										<Avatar src={selected?.profile} size="1rem" />
										<MemberName>{selected?.name}</MemberName>
									</MemberTag>
								)}
								<Tooltip
									placement="right"
									color="primary"
									title="Select Someone Else"
								>
									<IconButton
										sx={{ color: "primary.main" }}
										onClick={handleNewSearch}
									>
										<BsSearch />
									</IconButton>
								</Tooltip>
							</Box>
						))}
					<Box className="w-100">
						<Box className="box-middle">
							{addOrSave === "" ? (
								<Box className="w-100">
									{currentSelected !== "sms_template" &&
										currentSelected !== "seniorSpecific" && (
											<Box className="notification-heading">
												<Typography>Notifications</Typography>
												<Tooltip
													placement="right"
													color="primary"
													title="Add Configurations"
												>
													<IconButton
														onClick={handleClickAdd}
														className={`icon-display add-new`}
														sx={{
															display:
																currentSelected === "seniorSpecific" &&
																	Object.keys(selected).length <= 0
																	? "none"
																	: "",
														}}
														disabled={
															currentSelected === "seniorSpecific" &&
															Object.keys(selected).length <= 0
														}
													>
														<AddThresholdIcon classname="add-threshold-icon" />
														<Typography sx={{ color: "#00b89f !important" }}>
															Add New
														</Typography>
													</IconButton>
												</Tooltip>
											</Box>
										)}
									<Box>
										{currentSelected === "generic" ? (
											data?.length <= 0 ? (
												<Box className="no-notifications">
													<Typography>No notifications configured</Typography>
												</Box>
											) : (
												data?.map((setting, index) => {
													return (
														<Paper key={index} className="paper-configured">
															<Box className="w-100">
																<Box className="space-between">
																	<Box className="box-configured-first  w-xs-100">
																		{setting?.setting === "heart_rate" ? (
																			<Tooltip
																				placement="left"
																				color="primary"
																				title="Heart Rate"
																			>
																				<IconDisplay
																					className={`icon-display`}
																					sx={{ boxShadow: "none" }}
																				>
																					<HeartRateIcon className="active-icons" />
																				</IconDisplay>
																			</Tooltip>
																		) : setting?.setting ===
																			"blood_pressure" ? (
																			<Tooltip
																				placement="left"
																				color="primary"
																				title="BP"
																			>
																				<IconDisplay
																					className={`icon-display`}
																					sx={{ boxShadow: "none" }}
																				>
																					<BPMIcon className="active-icons" />
																				</IconDisplay>
																			</Tooltip>
																		) : setting?.setting === "sleep" ? (
																			<Tooltip
																				placement="left"
																				color="primary"
																				title="Sleep"
																			>
																				<IconDisplay
																					className={`icon-display`}
																					sx={{ boxShadow: "none" }}
																				>
																					<SleepIcon className="active-icons" />
																				</IconDisplay>
																			</Tooltip>
																		) : setting?.setting === "steps" ? (
																			<Tooltip
																				placement="left"
																				color="primary"
																				title="Steps"
																			>
																				<IconDisplay
																					className={`icon-display`}
																					sx={{ boxShadow: "none" }}
																				>
																					<StepsIcon className="active-spcl" />
																				</IconDisplay>
																			</Tooltip>
																		) : setting?.setting === "blood_oxygen" ? (
																			<Tooltip
																				placement="left"
																				color="primary"
																				title="SPO2"
																			>
																				<IconDisplay
																					className={`icon-display`}
																					sx={{ boxShadow: "none" }}
																				>
																					<Spo2Icon className="active--icons" />
																				</IconDisplay>
																			</Tooltip>
																		) : setting?.setting === "battery" ? (
																			<Tooltip
																				placement="left"
																				color="primary"
																				title="Battery"
																			>
																				<IconDisplay
																					className={`icon-display`}
																					sx={{ boxShadow: "none" }}
																				>
																					<BatteryIcon className="active-diff" />
																				</IconDisplay>
																			</Tooltip>
																		) : setting?.setting === "Door" ? (
																			<Tooltip
																				placement="left"
																				color="primary"
																				title="Door"
																			>
																				<IconDisplay
																					className={`icon-display`}
																					sx={{ boxShadow: "none" }}
																				>
																					<DoorIcon className="active-diff" />
																				</IconDisplay>
																			</Tooltip>
																		) : setting?.setting === "Shower" ? (
																			<Tooltip
																				placement="left"
																				color="primary"
																				title="Shower"
																			>
																				<IconDisplay
																					className={`icon-display`}
																					sx={{ boxShadow: "none" }}
																				>
																					<ShowerIcon className="active-diff-pill" />
																				</IconDisplay>
																			</Tooltip>
																		) : setting?.setting === "SleepBelt" ? (
																			<Tooltip
																				placement="left"
																				color="primary"
																				title="Sleep Belt (Bed)"
																			>
																				<IconDisplay
																					className={`icon-display`}
																					sx={{ boxShadow: "none" }}
																				>
																					<SleepBeltIcon className="active-belt" />
																				</IconDisplay>
																			</Tooltip>
																		) : setting?.setting ===
																			"SleepBelt-Chair" ? (
																			<Tooltip
																				placement="left"
																				color="primary"
																				title="Sleep Belt (Chair / Couch)"
																			>
																				<IconDisplay
																					className={`icon-display`}
																					sx={{ boxShadow: "none" }}
																				>
																					<CouchIcon className="active-belt" />
																				</IconDisplay>
																			</Tooltip>
																		) : setting?.setting === "PillBox" ? (
																			<Tooltip
																				placement="left"
																				color="primary"
																				title="PillBox"
																			>
																				<IconDisplay
																					className={`icon-display`}
																					sx={{ boxShadow: "none" }}
																				>
																					<PillIcon className="active-diff-pill" />
																				</IconDisplay>
																			</Tooltip>
																		) : setting?.setting === "SOS" ? (
																			<Tooltip
																				placement="left"
																				color="primary"
																				title="Urgent Band"
																			>
																				<IconDisplay
																					className={`icon-display`}
																					sx={{ boxShadow: "none" }}
																				>
																					<SOSIcon className="" />
																				</IconDisplay>
																			</Tooltip>
																		) : setting?.setting === "FallDetection" ? (
																			<Tooltip
																				placement="left"
																				color="primary"
																				title="Fall Detection"
																			>
																				<IconDisplay
																					className={`icon-display`}
																					sx={{ boxShadow: "none" }}
																				>
																					<FallDetectionIcon className="" />
																				</IconDisplay>
																			</Tooltip>
																		) : setting?.setting === "BathRoom" ? (
																			<Tooltip
																				placement="left"
																				color="primary"
																				title="BathRoom"
																			>
																				<IconDisplay
																					className={`icon-display`}
																					sx={{ boxShadow: "none" }}
																				>
																					<BathRoomActiveIcon className="active-pillbox" />
																				</IconDisplay>
																			</Tooltip>
																		) : null}
																		<Box className="gt-lt">
																			{setting?.device === "watch" ? (
																				setting?.setting ===
																					"blood_pressure" ? (
																					<Box>
																						<Typography>
																							Sys (Max, Min):{" "}
																							{setting?.thresholds?.max_sys ||
																								""}
																							,{" "}
																							{setting?.thresholds?.min_sys ||
																								""}
																						</Typography>
																						<Typography>
																							Dia (Max, Min):{" "}
																							{setting?.thresholds?.max_dia ||
																								""}
																							,{" "}
																							{setting?.thresholds?.min_dia ||
																								""}
																						</Typography>
																					</Box>
																				) : (
																					<Box>
																						{setting?.setting !== "battery" && (
																							<Typography>
																								Greater Than:{" "}
																								{setting?.thresholds?.max || ""}
																							</Typography>
																						)}
																						<Typography>
																							Lesser Than:{" "}
																							{setting?.thresholds?.min || ""}
																						</Typography>
																					</Box>
																				)
																			) : setting?.device === "Door" ? (
																				<Box>
																					<Typography>
																						Opened:{" "}
																						{moment(
																							new Date(
																								setting?.thresholds?.after[0]?.start_time
																							)
																								.toISOString()
																								.toLocaleString()
																						).format("hh:mm A") +
																							"-" +
																							moment(
																								new Date(
																									setting?.thresholds?.after[0]?.end_time
																								)
																									.toISOString()
																									.toLocaleString()
																							).format("hh:mm A") || ""}
																					</Typography>
																					<Typography>
																						Not Opened By:{" "}
																						{moment(
																							new Date(
																								setting?.thresholds?.till
																							).toLocaleString()
																						).format("hh:mm A") || ""}
																					</Typography>
																				</Box>
																			) : setting?.device === "Shower" ? (
																				<Box>
																					<Typography>
																						No Shower Taken :{" "}
																						{moment(
																							new Date(
																								setting?.thresholds?.time
																							).toLocaleString()
																						).format("hh:mm A") || ""}
																					</Typography>
																					{/* <Typography>
																						Shower Count :{setting?.thresholds?.week_count}
																					</Typography>
																					<Typography sx={{ textTransform: 'capitalize' }}>
																						Alert Day Starts :{setting?.thresholds?.week_day}
																					</Typography> */}

																				</Box>
																			) : setting?.device === "SleepBelt" ? (
																				<Box>
																					<Typography>
																						Day Time Interval :{" "}
																						{setting?.day_interval || ""}
																					</Typography>
																					<Typography>
																						Night Time Interval :{" "}
																						{setting?.night_interval || ""}
																					</Typography>
																				</Box>
																			) : setting?.device ===
																				"SleepBelt-Chair" ? (
																				<Box>
																					<Typography>
																						Day Time Interval :{" "}
																						{setting?.day_interval || ""}
																					</Typography>
																					<Typography>
																						Night Time Interval :{" "}
																						{setting?.night_interval || ""}
																					</Typography>
																				</Box>
																			) : setting?.device === "PillBox" ? (
																				<Box>
																					<Typography>
																						Medications Per Day:{" "}
																						{pillsCount(setting?.pills) || ""}
																					</Typography>
																					{pillsCountWithTime(
																						setting?.pills
																					)?.map((item, ind) => (
																						<Typography>
																							Meds{ind + 1}:{" "}
																							{isValid(new Date(item.time))
																								? format(
																										new Date(item.time),
																										"h:00 aaa"
																								  )
																								: item.time}
																						</Typography>
																					))}
																					<Typography>
																						Refill Interval:{" "}
																						{`${setting?.thresholds
																							?.refill_interval || "0"
																							} days` || ""}
																					</Typography>
																				</Box>
																			) : setting?.device === "SOS" ? (
																				<Box>
																					<Typography>
																					</Typography>
																				</Box>
																			) : setting?.device ===
																				"FallDetection" ? (
																				<Box>
																					<Typography>
																					</Typography>
																				</Box>
																			) : setting?.device === "BathRoom" ? (
																				<Box>
																					<Typography>
																						Start Time :{" "}
																						{moment(
																							new Date(
																								setting?.thresholds?.start_time
																							).toLocaleString()
																						).format("hh:mm A") || ""}
																					</Typography>
																					<Typography>
																						End Time :{" "}
																						{moment(
																							new Date(
																								setting?.thresholds?.end_time
																							).toLocaleString()
																						).format("hh:mm A") || ""}
																					</Typography>
																					<Typography>
																						Min Visits:{" "}
																						{setting?.thresholds?.min}
																					</Typography>
																					<Typography>
																						Max Visits:{" "}
																						{setting?.thresholds?.max}
																					</Typography>
																				</Box>
																			) : null}
																		</Box>
																	</Box>
																	<Box className="box-configured-second w-xs-100">
																		{setting?.device === "SleepBelt" ? (
																			<Box className="box-message">
																				<Tooltip
																					placement="top"
																					title={setting?.day_message}
																				>
																					<Typography>
																						{setting?.day_message}
																					</Typography>
																				</Tooltip>
																				<Tooltip
																					placement="top"
																					title={setting?.night_message}
																				>
																					<Typography>
																						{setting?.night_message}
																					</Typography>
																				</Tooltip>
																			</Box>
																		) : setting?.device ===
																			"SleepBelt-Chair" ? (
																			<Box className="box-message">
																				<Tooltip
																					placement="top"
																					title={setting?.day_message}
																				>
																					<Typography>
																						{setting?.day_message}
																					</Typography>
																				</Tooltip>
																				<Tooltip
																					placement="top"
																					title={setting?.night_message}
																				>
																					<Typography>
																						{setting?.night_message}
																					</Typography>
																				</Tooltip>
																			</Box>
																		) : setting?.device === "PillBox" ? (
																			<Box className="box-pill-message">
																				<Tooltip
																					placement="top"
																					title={setting?.refill_message}
																				>
																					<Typography>
																						Pill Refill Message:{" "}
																						{setting?.refill_message}
																					</Typography>
																				</Tooltip>
																			</Box>
																		) : setting?.device === "BathRoom" ? (
																			<Box className="box-message">
																				<Tooltip
																					placement="top"
																					title={setting?.min_message}
																				>
																					<Typography>
																						{setting?.min_message}
																					</Typography>
																				</Tooltip>
																				<Tooltip
																					placement="top"
																					title={setting?.max_message}
																				>
																					<Typography>
																						{setting?.max_message}
																					</Typography>
																				</Tooltip>
																			</Box>
																		) : (
																			<Box className="box-message">
																				<Tooltip
																					placement="top"
																					title={setting?.message}
																				>
																					<Typography>
																						{setting?.message}
																					</Typography>

																				</Tooltip>

																				{setting?.week_msg && <Tooltip
																					placement="top"
																					title={setting?.week_msg}
																				>
																					<Typography sx={{ mt: '5px' }}>
																						{setting?.week_msg}
																					</Typography>

																				</Tooltip>}

																				{setting?.setting === "Door" && (
																					<Tooltip
																						placement="top"
																						title={setting?.till_message}
																					>
																						<Typography>
																							{setting?.till_message}
																						</Typography>
																					</Tooltip>
																				)}
																				{setting?.setting !== "battery" && (
																					<Tooltip
																						placement="top"
																						title={setting?.lesser_message}
																					>
																						<Typography>
																							{setting?.lesser_message}
																						</Typography>
																					</Tooltip>
																				)}
																			</Box>
																		)}
																		<Box className="box-configured-third">
																			<Tooltip
																				placement="left"
																				color="primary"
																				title="Edit Configurations"
																			>
																				<IconButton
																					onClick={() => handleClickEdit(index)}
																					className={`icon-display`}
																				>
																					<EditIcon className="edit-green" />
																				</IconButton>
																			</Tooltip>
																			<Tooltip
																				placement="right"
																				color="primary"
																				title="Delete Configurations"
																			>
																				<IconButton
																					onClick={() =>
																						setHandleDialogOpen([true, index])
																					}
																					className={`icon-display`}
																				>
																					<CloseIcon className="close-red" />
																				</IconButton>
																			</Tooltip>
																		</Box>
																	</Box>
																</Box>
																<Box>
																	{setting?.device === "watch" ? (
																		<Collapse in={selectedEdit === index}>
																			<WatchInputs
																				snackbarNotification={
																					snackbarNotification
																				}
																				currentSelected={currentSelected}
																				selected={selected}
																				setting={setting}
																				handleFinish={() => {
																					getGeneric();
																					setSelectedEdit(-1);
																				}}
																				isGeneric={true}
																			/>
																		</Collapse>
																	) : setting?.device === "Door" ? (
																		<Collapse in={selectedEdit === index}>
																			<DoorInputs
																				snackbarNotification={
																					snackbarNotification
																				}
																				currentSelected={currentSelected}
																				selected={selected}
																				setting={setting}
																				handleFinish={() => {
																					getGeneric();
																					setSelectedEdit(-1);
																				}}
																				isGeneric={true}
																			/>
																		</Collapse>
																	) : setting?.device === "Shower" ? (
																		<Collapse in={selectedEdit === index}>
																			<ShowerInput
																				snackbarNotification={
																					snackbarNotification
																				}
																				currentSelected={currentSelected}
																				selected={selected}
																				setting={setting}
																				handleFinish={() => {
																					getGeneric();
																					setSelectedEdit(-1);
																				}}
																				isGeneric={true}
																			/>
																		</Collapse>
																	) : setting?.device === "SleepBelt" ? (
																		<Collapse in={selectedEdit === index}>
																			<SleepBeltInput
																				snackbarNotification={
																					snackbarNotification
																				}
																				currentSelected={currentSelected}
																				selected={selected}
																				setting={setting}
																				handleFinish={() => {
																					getGeneric();
																					setSelectedEdit(-1);
																				}}
																				isGeneric={true}
																			/>
																		</Collapse>
																	) : setting?.device === "SleepBelt-Chair" ? (
																		<Collapse in={selectedEdit === index}>
																			<SleepBeltInput
																				snackbarNotification={
																					snackbarNotification
																				}
																				currentSelected={currentSelected}
																				selected={selected}
																				setting={setting}
																				couch={true}
																				handleFinish={() => {
																					getGeneric();
																					setSelectedEdit(-1);
																				}}
																				isGeneric={true}
																			/>
																		</Collapse>
																	) : setting?.setting === "PillBox" ? (
																		<Collapse in={selectedEdit === index}>
																			<PillInputs
																				snackbarNotification={
																					snackbarNotification
																				}
																				currentSelected={currentSelected}
																				selected={selected}
																				setting={setting}
																				handleFinish={() => {
																					getGeneric();
																					setSelectedEdit(-1);
																				}}
																				isGeneric={true}
																			/>
																		</Collapse>
																	) : setting?.setting === "SOS" ? (
																		<Collapse in={selectedEdit === index}>
																			<SOSInputs
																				snackbarNotification={
																					snackbarNotification
																				}
																				currentSelected={currentSelected}
																				selected={selected}
																				setting={setting}
																				handleFinish={() => {
																					getGeneric();
																					setSelectedEdit(-1);
																				}}
																				isGeneric={true}
																			/>
																		</Collapse>
																	) : setting?.setting === "FallDetection" ? (
																		<Collapse in={selectedEdit === index}>
																			<SOSInputs
																				snackbarNotification={
																					snackbarNotification
																				}
																				currentSelected={currentSelected}
																				selected={selected}
																				setting={setting}
																				handleFinish={() => {
																					getGeneric();
																					setSelectedEdit(-1);
																				}}
																				isGeneric={true}
																			/>
																		</Collapse>
																	) : setting?.setting === "BathRoom" ? (
																		<Collapse in={selectedEdit === index}>
																			<BathRoomInputs
																				snackbarNotification={
																					snackbarNotification
																				}
																				currentSelected={currentSelected}
																				selected={selected}
																				setting={setting}
																				handleFinish={() => {
																					getGeneric();
																					setSelectedEdit(-1);
																				}}
																				isGeneric={true}
																			/>
																		</Collapse>
																	) : null}
																</Box>
															</Box>
														</Paper>
													);
												})
											)
										) : (
											currentSelected === "sms_template" && (
												<Box sx={{ mt: "2rem" }}>
													{smsTemplate.map((sms) => {
														return (
															<SmsTemplateCard
																name={sms.name}
																message={sms.message}
																icon={sms.icon}
															/>
														);
													})}
												</Box>
											)
										)}
									</Box>
								</Box>
							) : (
								<Box className="w-100">
									{(addOrSave === "add" ||
										addOrSave === "save" ||
										Object.keys(selected).length > 0) && (
											<Box className="box-choose-device">
												<Box className="label-choose-device">
													<Typography className="choose-device-text">
														Choose Device
													</Typography>
												</Box>
												<Box className="notification-sensors">
													{displaySensor("watch", "GPSWatch") &&
														((
															currentSelected === "generic"
																? subTab === 0
																: selectedSensor === "watch" ||
																selectedSensor === "GPSWatch"
														) ? (
															<Tooltip color="primary" title="Healthcare Watch">
																<IconDisplay
																	className={
																		currentSelected === "generic"
																			? `icon-display-active-new`
																			: `icon-display-active-new-centric`
																	}
																	sx={{ boxShadow: "none" }}
																>
																	<WatchIcon className="svg-icon active-icons watch" />
																</IconDisplay>
															</Tooltip>
														) : (
															<Tooltip color="primary" title="Healthcare Watch">
																<IconDisplay
																	className={`icon-display-new`}
																	onClick={() => updateSensor(0, "watch")}
																>
																	<WatchIcon
																		className={
																			nhubDetails?.watch &&
																				currentSelected === "seniorSpecific"
																				? "svg-icon-centric watch"
																				: "svg-icon watch"
																		}
																	/>
																</IconDisplay>
															</Tooltip>
														))}
													{displaySensor("Door") &&
														((
															currentSelected === "generic"
																? subTab === 1
																: selectedSensor === "Door"
														) ? (
															<Badge
																badgeContent={
																	connectedNumberOfSensors?.Door &&
																	connectedNumberOfSensors?.Door
																}
																color="primary"
																max={10}
																size="small"
																sx={{
																	"& .MuiBadge-badge": {
																		right: "10px",
																		fontSize: "10px",
																	},
																}}
															>
																<Tooltip color="primary" title="Door Sensor">
																	<IconDisplay
																		className={
																			currentSelected === "generic"
																				? `icon-display-active-new`
																				: `icon-display-active-new-centric`
																		}
																		sx={{ boxShadow: "none" }}
																	>
																		<DoorIcon className="svg-icon active-icons" />
																	</IconDisplay>
																</Tooltip>
															</Badge>
														) : (
															<Badge
																badgeContent={
																	connectedNumberOfSensors?.Door &&
																	connectedNumberOfSensors?.Door
																}
																color="primary"
																max={10}
																size="small"
																sx={{
																	"& .MuiBadge-badge": {
																		right: "10px",
																		fontSize: "10px",
																	},
																}}
															>
																<Tooltip color="primary" title="Door Sensor">
																	<IconDisplay
																		className={`icon-display-new`}
																		onClick={() => updateSensor(1, "Door")}
																	>
																		<DoorIcon
																			className={
																				nhubDetails?.Door &&
																					currentSelected === "seniorSpecific"
																					? "svg-icon-centric"
																					: "svg-icon"
																			}
																		/>
																	</IconDisplay>
																</Tooltip>
															</Badge>
														))}
													{displaySensor("PillBox") &&
														((
															currentSelected === "generic"
																? subTab === 2
																: selectedSensor === "PillBox"
														) ? (
															<Badge
																badgeContent={
																	connectedNumberOfSensors?.PillBox &&
																	connectedNumberOfSensors?.PillBox
																}
																color="primary"
																max={10}
																size="small"
																sx={{
																	"& .MuiBadge-badge": {
																		right: "10px",
																		fontSize: "10px",
																	},
																}}
															>
																<Tooltip color="primary" title="PillBox Sensor">
																	<IconDisplay
																		className={
																			currentSelected === "generic"
																				? `icon-display-active-new`
																				: `icon-display-active-new-centric`
																		}
																		sx={{ boxShadow: "none" }}
																	>
																		<PillIcon className="svg-icon active-icons" />
																	</IconDisplay>
																</Tooltip>
															</Badge>
														) : (
															<Badge
																badgeContent={
																	connectedNumberOfSensors?.PillBox &&
																	connectedNumberOfSensors?.PillBox
																}
																color="primary"
																max={10}
																size="small"
																sx={{
																	"& .MuiBadge-badge": {
																		right: "10px",
																		fontSize: "10px",
																	},
																}}
															>
																<Tooltip color="primary" title="PillBox Sensor">
																	<IconDisplay
																		className={`icon-display-new`}
																		onClick={() => updateSensor(2, "PillBox")}
																	>
																		<PillIcon
																			className={
																				nhubDetails?.PillBox &&
																					currentSelected === "seniorSpecific"
																					? "svg-icon-centric"
																					: "svg-icon"
																			}
																		/>
																	</IconDisplay>
																</Tooltip>
															</Badge>
														))}
													{displaySensor("Shower") &&
														((
															currentSelected === "generic"
																? subTab === 3
																: selectedSensor === "Shower"
														) ? (
															<Badge
																badgeContent={
																	connectedNumberOfSensors?.Shower &&
																	connectedNumberOfSensors?.Shower
																}
																color="primary"
																max={10}
																size="small"
																sx={{
																	"& .MuiBadge-badge": {
																		right: "10px",
																		fontSize: "10px",
																	},
																}}
															>
																<Tooltip color="primary" title="Shower Sensor">
																	<IconDisplay
																		className={
																			currentSelected === "generic"
																				? `icon-display-active-new`
																				: `icon-display-active-new-centric`
																		}
																		sx={{ boxShadow: "none" }}
																	>
																		<ShowerIcon className="svg-icon active-icons" />
																	</IconDisplay>
																</Tooltip>
															</Badge>
														) : (
															<Badge
																badgeContent={
																	connectedNumberOfSensors?.Shower &&
																	connectedNumberOfSensors?.Shower
																}
																color="primary"
																max={10}
																size="small"
																sx={{
																	"& .MuiBadge-badge": {
																		right: "10px",
																		fontSize: "10px",
																	},
																}}
															>
																<Tooltip color="primary" title="Shower Sensor">
																	<IconDisplay
																		className={`icon-display-new`}
																		onClick={() => updateSensor(3, "Shower")}
																	>
																		<ShowerIcon
																			className={
																				nhubDetails?.Shower &&
																					currentSelected === "seniorSpecific"
																					? "svg-icon-centric"
																					: "svg-icon"
																			}
																		/>
																	</IconDisplay>
																</Tooltip>
															</Badge>
														))}
													{displaySensor("SOS") &&
														((
															currentSelected === "generic"
																? subTab === 4
																: selectedSensor === "SOS"
														) ? (
															<Badge
																badgeContent={
																	connectedNumberOfSensors?.SOS &&
																	connectedNumberOfSensors?.SOS
																}
																color="primary"
																max={10}
																size="small"
																sx={{
																	"& .MuiBadge-badge": {
																		right: "10px",
																		fontSize: "10px",
																	},
																}}
															>
																<Tooltip color="primary" title="Urgent Band">
																	<IconDisplay
																		className={
																			currentSelected === "generic"
																				? `icon-display-active-new`
																				: `icon-display-active-new-centric`
																		}
																		sx={{ boxShadow: "none" }}
																	>
																		<SOSIcon className="" />
																	</IconDisplay>
																</Tooltip>
															</Badge>
														) : (
															<Badge
																badgeContent={
																	connectedNumberOfSensors?.SOS &&
																	connectedNumberOfSensors?.SOS
																}
																color="primary"
																max={10}
																size="small"
																sx={{
																	"& .MuiBadge-badge": {
																		right: "10px",
																		fontSize: "10px",
																	},
																}}
															>
																<Tooltip color="primary" title="Urgent Band">
																	<IconDisplay
																		className={`icon-display-new`}
																		onClick={() => updateSensor(4, "SOS")}
																	>
																		{(nhubDetails?.SOS && currentSelected !== "generic") ? (
																			<SOSIcon className="" />
																		) : (
																			<SOSIconGray className="" />
																		)}
																	</IconDisplay>
																</Tooltip>
															</Badge>
														))}
													{displaySensor("SleepBelt") &&
														((
															currentSelected === "generic"
																? subTab === 5
																: selectedSensor === "SleepBelt"
														) ? (
															<Badge
																badgeContent={
																	connectedNumberOfSensors?.SleepBelt &&
																	connectedNumberOfSensors?.SleepBelt
																}
																color="primary"
																max={10}
																size="small"
																sx={{
																	"& .MuiBadge-badge": {
																		right: "10px",
																		fontSize: "10px",
																	},
																}}
															>
																<Tooltip color="primary" title="Sleep Belt (Bed)">
																	<IconDisplay
																		className={
																			currentSelected === "generic"
																				? `icon-display-active-new`
																				: `icon-display-active-new-centric`
																		}
																		sx={{ boxShadow: "none" }}
																	>
																		<SleepBeltIcon className="svg-icon active-icons-fill" />
																	</IconDisplay>
																</Tooltip>
															</Badge>
														) : (
															<Badge
																badgeContent={
																	connectedNumberOfSensors?.SleepBelt &&
																	connectedNumberOfSensors?.SleepBelt
																}
																color="primary"
																max={10}
																size="small"
																sx={{
																	"& .MuiBadge-badge": {
																		right: "10px",
																		fontSize: "10px",
																	},
																}}
															>
																<Tooltip color="primary" title="Sleep Belt (Bed)">
																	<IconDisplay
																		className={`icon-display-new`}
																		onClick={() => updateSensor(5, "SleepBelt")}
																	>
																		<SleepBeltIcon
																			className={
																				nhubDetails?.SleepBelt &&
																					currentSelected === "seniorSpecific"
																					? "svg-icon-centric"
																					: "svg-icon"
																			}
																		/>
																	</IconDisplay>
																</Tooltip>
															</Badge>
														))}

													{displaySensor("SleepBelt-Chair") &&
														((
															currentSelected === "generic"
																? subTab === 6
																: selectedSensor === "SleepBelt-Chair"
														) ? (
															<Badge
																badgeContent={
																	connectedNumberOfSensors["SleepBelt-Chair"] &&
																	connectedNumberOfSensors["SleepBelt-Chair"]
																}
																color="primary"
																max={10}
																size="small"
																sx={{
																	"& .MuiBadge-badge": {
																		right: "10px",
																		fontSize: "10px",
																	},
																}}
															>
																<Tooltip
																	className={
																		currentSelected === "generic"
																			? `icon-display-active-new`
																			: `icon-display-active-new-centric`
																	}
																	title="Sleep Belt (Chair / Couch)"
																>
																	<IconDisplay
																		className={`icon-display-active-new`}
																		sx={{ boxShadow: "none" }}
																	>
																		<CouchIcon className="svg-icon active-icons-fill" />
																	</IconDisplay>
																</Tooltip>
															</Badge>
														) : (
															<Badge
																badgeContent={
																	connectedNumberOfSensors["SleepBelt-Chair"] &&
																	connectedNumberOfSensors["SleepBelt-Chair"]
																}
																color="primary"
																max={10}
																size="small"
																sx={{
																	"& .MuiBadge-badge": {
																		right: "10px",
																		fontSize: "10px",
																	},
																}}
															>
																<Tooltip
																	color="primary"
																	title="Sleep Belt (Chair / Couch)"
																>
																	<IconDisplay
																		className={`icon-display-new`}
																		onClick={() =>
																			updateSensor(6, "SleepBelt-Chair")
																		}
																	>
																		<CouchIcon
																			className={
																				nhubDetails?.["SleepBelt-Chair"] &&
																					currentSelected === "seniorSpecific"
																					? "svg-icon-centric"
																					: "svg-icon"
																			}
																		/>
																	</IconDisplay>
																</Tooltip>
															</Badge>
														))}

													{displaySensor("FallDetection") &&
														((
															currentSelected === "generic"
																? subTab === 7
																: selectedSensor === "FallDetection"
														) ? (
															<Badge
																badgeContent={
																	connectedNumberOfSensors?.FallDetection &&
																	connectedNumberOfSensors?.FallDetection
																}
																color="primary"
																max={10}
																size="small"
																sx={{
																	"& .MuiBadge-badge": {
																		right: "10px",
																		fontSize: "10px",
																	},
																}}
															>
																<Tooltip color="primary" title="Fall detection">
																	<IconDisplay
																		className={
																			currentSelected === "generic"
																				? `icon-display-active-new`
																				: `icon-display-active-new-centric`
																		}
																		sx={{ boxShadow: "none" }}
																	>
																		<FallDetectionIcon className="svg-icon active-icons-fill" />
																	</IconDisplay>
																</Tooltip>
															</Badge>
														) : (
															<Badge
																badgeContent={
																	connectedNumberOfSensors?.FallDetection &&
																	connectedNumberOfSensors?.FallDetection
																}
																color="primary"
																max={10}
																size="small"
																sx={{
																	"& .MuiBadge-badge": {
																		right: "10px",
																		fontSize: "10px",
																	},
																}}
															>
																<Tooltip color="primary" title="Fall detection">
																	<IconDisplay
																		className={`icon-display-new`}
																		onClick={() =>
																			updateSensor(7, "FallDetection")
																		}
																	>
																		<FallDetectionIcon
																			className={
																				nhubDetails?.FallDetection &&
																					currentSelected === "seniorSpecific"
																					? "svg-icon active-icons-fill"
																					: "svg-icon-fill"
																			}
																		/>
																	</IconDisplay>
																</Tooltip>
															</Badge>
														))}

													{displaySensor("BathRoom") &&
														((
															currentSelected === "generic"
																? subTab === 8
																: selectedSensor === "BathRoom"
														) ? (
															<Badge
																badgeContent={
																	connectedNumberOfSensors?.BathRoom &&
																	connectedNumberOfSensors?.BathRoom
																}
																color="primary"
																max={10}
																size="small"
																sx={{
																	"& .MuiBadge-badge": {
																		right: "10px",
																		fontSize: "10px",
																	},
																}}
															>
																<Tooltip color="primary" title="BathRoom">
																	<IconDisplay
																		className={
																			currentSelected === "generic"
																				? `icon-display-active-new`
																				: `icon-display-active-new-centric`
																		}
																		sx={{ boxShadow: "none" }}
																	>
																		<BathRoomIcon className="svg-icon active-icons-fill" />
																	</IconDisplay>
																</Tooltip>
															</Badge>
														) : (
															<Badge
																badgeContent={
																	connectedNumberOfSensors?.BathRoom &&
																	connectedNumberOfSensors?.BathRoom
																}
																color="primary"
																max={10}
																size="small"
																sx={{
																	"& .MuiBadge-badge": {
																		right: "10px",
																		fontSize: "10px",
																	},
																}}
															>
																<Tooltip color="primary" title="BathRoom">
																	<IconDisplay
																		className={`icon-display-new`}
																		onClick={() => updateSensor(8, "BathRoom")}
																	>
																		<BathRoomIcon
																			className={
																				nhubDetails?.BathRoom &&
																					currentSelected === "seniorSpecific"
																					? "svg-icon active-icons-fill"
																					: "svg-icon-fill"
																			}
																		/>
																	</IconDisplay>
																</Tooltip>
															</Badge>
														))}
												</Box>
											</Box>
										)}
									{currentSelected === "seniorSpecific" &&
										selectedSensor &&
										selectedSensor !== "watch" && (
											<>
												<Tabs
													value={value}
													onChange={handleTabChange}
													variant="scrollable"
													scrollButtons="auto"
													textColor="white"
													indicatorColor="primary"
													sx={{
														mb: '1rem',
														position: "relative",
														"&::before": {
															content: '""',
															position: "absolute",
															bottom: 0,
															left: 0,
															width: "100%",
															height: "2px",
															backgroundColor: "#444A50",
															mb: "3px", // Set the desired color for the divider
														},
														"& .MuiTabs-indicator": {
															width: "100%",
															mb: "0.3rem",
														},
													}}
												>
													{selectedSensor &&
														nhubDetails[selectedSensor]?.map((el, ind) => {
															const active = centricData[selectedSensor]?.some(
																(sensor) => sensor?.mac_id === el?.mac_id
															);
															return (
																<Tab
																	value={el?.macId}
																	label={<Tooltip title={el?.name}><TabLabel>{el?.name}</TabLabel></Tooltip>}
																	icon={
																		active && (
																			<span>{<ActiveIcon />}</span>
																		)
																	}
																	iconPosition="end"
																	sx={{
																		"&.Mui-selected": {
																			bgcolor: "rgb(0 184 159 / 20%)",
																		},
																		minHeight: "45px",
																		width: "180px",
																	}}
																/>
															);
														})}
												</Tabs>
											</>
										)}
									{
										subTab === 0 && (

											<SubTabThreeWatch
												goBack={goBackToNotifications}
												setInvalidSenior={setInvalid}
												snackbarNotification={
													snackbarNotification
												}
												addOrSave={addOrSave}
												currentSelected={currentSelected}
												selectedSenior={selected}
												selectedSensorNotification={selectedSensorNotification}
											/>
										)
									}
									{noNotifications ? (
										<Box
											sx={{
												display: "flex",
												width: "100%",
												flexDirection: "row",
												justifyContent: "center",
											}}
										>
											<Tooltip
												placement="right"
												color="primary"
												title="Add Configurations"
											>
												<IconButton
													onClick={handleAddNotifications}
													className={`icon-display add-new`}
													sx={{
														display:
															currentSelected === "seniorSpecific" &&
																Object.keys(selected).length <= 0
																? "none"
																: "",
													}}
													disabled={
														currentSelected === "seniorSpecific" &&
														Object.keys(selected).length <= 0
													}
												>
													<AddThresholdIcon classname="add-threshold-icon" />
													<Typography sx={{ color: "#00b89f !important" }}>
														Add New
													</Typography>
												</IconButton>
											</Tooltip>
										</Box>
									) : (
										!openSubtab &&
										selectedSensorNotification?.map((setting, index) => {
											return (
												setting?.device === "Door" ? (
													<Box>
														<Box
															sx={{
																display: "flex",
																flexDirection: "row",
																justifyContent: "flex-end",
															}}
														>
															<Box className="box-configured-third-pill-door">
																<Tooltip
																	placement="left"
																	color="primary"
																	title="Edit Configurations"
																>
																	<IconButton
																		onClick={() => handleClickEdit(index)}
																		className={`icon-display`}
																	>
																		<EditIcon className="edit-green" />
																	</IconButton>
																</Tooltip>
																<Tooltip
																	placement="right"
																	color="primary"
																	title="Delete Configurations"
																>
																	<IconButton
																		onClick={() =>
																			setHandleDialogOpen([true, index])
																		}
																		className={`icon-display`}
																	>
																		<CloseIcon className="close-red" />
																	</IconButton>
																</Tooltip>
															</Box>
														</Box>
														{index === 0 &&
															setting?.thresholds?.after?.map(
																(el, ind) =>
																	el?.start_time &&
																	el?.end_time && (
																		<Paper
																			key={index}
																			className="pill-paper-configured"
																		>
																			<Box className="w-100">
																				<Box className="space-between">
																					<Box className="box-configured-first  w-xs-100">
																						<Tooltip
																							placement="left"
																							color="primary"
																							title="Door"
																						>
																							<IconDisplay
																								className={`icon-display`}
																								sx={{ boxShadow: "none" }}
																							>
																								<DoorIcon className="active-diff" />
																							</IconDisplay>
																						</Tooltip>
																						<Box className="gt-lt">
																							<Box>
																								<Typography>
																									Opened:{" "}
																									{setting?.thresholds?.after[0]
																										?.start_time
																										? moment(
																											new Date(
																												setting?.thresholds?.after[
																													ind
																												]?.start_time
																											)
																												?.toISOString()
																												.toLocaleString()
																									  ).format("hh:mm A") +
																									  "-" +
																									  moment(
																											new Date(
																												setting?.thresholds?.after[
																													ind
																												]?.end_time
																											)
																												?.toISOString()
																												.toLocaleString()
																									  ).format("hh:mm A")
																									: moment(
																											new Date(
																												setting?.thresholds?.after[
																													ind
																												]?.end_time
																											).toLocaleString()
																									  ).format("hh:mm A") || ""}
																							</Typography>
																							{ind === 0 && (
																								<Typography>
																									Not Opened By:{" "}
																									{moment(
																										new Date(
																											setting?.thresholds?.till
																										).toLocaleString()
																									).format("hh:mm A") || ""}
																								</Typography>
																							)}
																						</Box>
																					</Box>
																				</Box>
																				<Box className="box-configured-second  w-xs-100">
																					<Box className="box-pill-message">
																						<Tooltip
																							placement="top"
																							title={setting?.message}
																						>
																							<Typography>
																								{setting?.message}
																							</Typography>
																						</Tooltip>
																						{ind === 0 && (
																							<Tooltip
																								placement="top"
																								title={setting?.till_message}
																							>
																								<Typography>
																									{setting?.till_message}
																								</Typography>
																							</Tooltip>
																						)}
																					</Box>
																				</Box>
																			</Box>
																			<Box>
																				<Collapse in={selectedEdit === ind}>
																					<DoorInputs
																						setting={setting}
																						snackbarNotification={
																							snackbarNotification
																						}
																						handleFinish={() => {
																							getRevisedSeniors(selected?.id);
																							getCentric();
																						}}
																						currentSelected={currentSelected}
																						selected={selected}
																						isGeneric={false}
																						macId={notificationMacId}
																					/>
																				</Collapse>
																			</Box>
																		</Box>
																	</Paper>
																)
														)}
												</Box>
											) :
											setting?.device === "PillBox" ? (
												<Box>
													<Box
														sx={{
															display: "flex",
															flexDirection: "row",
															justifyContent: "flex-end",
														}}
													>
														<Box className="box-configured-third-pill-door">
															<Tooltip
																placement="left"
																color="primary"
																title="Edit Configurations"
															>
																<IconButton
																	onClick={() => handleClickEdit(index)}
																	className={`icon-display`}
																>
																	<EditIcon className="edit-green" />
																</IconButton>
															</Tooltip>
															<Tooltip
																placement="right"
																color="primary"
																title="Delete Configurations"
															>
																<IconButton
																	onClick={() =>
																		setHandleDialogOpen([true, index])
																	}
																	className={`icon-display`}
																>
																	<CloseIcon className="close-red" />
																</IconButton>
															</Tooltip>
														</Box>
													</Box>
													{ setting?.pills?.map((el,ind) =>
													el?.time && el?.message &&
													(
													<Paper key={ind} className="paper-configured">
														<Box className="w-100">
															<Box className="space-between">
																<Box className="box-configured-first  w-xs-100">
																	<Tooltip
																			placement="left"
																			color="primary"
																			title="PillBox"
																		>
																			<IconDisplay
																				className={`icon-display`}
																				sx={{ boxShadow: "none" }}
																			>
																				<PillIcon className="active-diff-pill" />
																			</IconDisplay>
																		</Tooltip>
																		<Box className="gt-lt">
																		 	<Box>
																		 		{ind === 0 && 
																				<Typography>
																		 			Medications Per Day:{" "}
																		 			{pillsCount(setting?.pills) || ""}
																		 		</Typography>}
																			{/* {pillsCountWithTime(
																					setting?.pills
																				)?.map((item, ind) => ( */}
																					<Typography>
																						Meds{ind + 1}:{" "}
																						{isValid(new Date(el.time))
																							? format(
																									new Date(el.time),
																									"h:00 aaa"
																							  )
																							: el.time}
																					</Typography>
																				{/* ))} */}
																				{ind === 0  && <Typography>
																					Refill Interval:{" "}
																					{`${
																						setting?.thresholds
																							?.refill_interval || "0"
																					} days` || ""}
																				</Typography>}
																			</Box>
																		</Box>
																</Box>
																<Box className="box-configured-second w-xs-100">
															 	<Box className="box-pill-message">
																	 		{ind === 0 && <Tooltip
																				placement="top"
																				title={setting?.refill_message}
																			>
																				<Typography>
																					Pill Refill Message:{" "}
																					{setting?.refill_message}
																				</Typography>
																			</Tooltip>}
																			<Tooltip
																				placement="top"
																				title={el?.message}
																			>
																				<Typography>
																					Message:{" "}
																					{el?.message}
																				</Typography>
																			</Tooltip>
																		</Box>
																</Box>
															</Box>
															<Collapse in={selectedEdit === ind}>
																 		<PillInputs
																			setting={setting}
																			snackbarNotification={
																				snackbarNotification
																			}
																			handleFinish={() => {
																				getRevisedSeniors(selected?.id);
																				getCentric();
																			}}
																			currentSelected={currentSelected}
																			selected={selected}
																			isGeneric={false}
																			macId={notificationMacId}
																		/>
																	</Collapse>
														</Box>
													</Paper>
													
													)
													)
													}
												</Box>
											) : 
											(
												<>
													<Paper key={index} className="paper-configured">
														<Box className="w-100">
															<Box className="space-between">
																<Box className="box-configured-first  w-xs-100">
																	{setting?.setting === "heart_rate" ? (
																		<Tooltip
																			placement="left"
																			color="primary"
																			title="Heart Rate"
																		>
																			<IconDisplay
																				className={`icon-display`}
																				sx={{ boxShadow: "none" }}
																			>
																				<HeartRateIcon className="active-icons" />
																			</IconDisplay>
																		</Tooltip>
																	) : setting?.setting === "blood_pressure" ? (
																		<Tooltip
																			placement="left"
																			color="primary"
																			title="BP"
																		>
																			<IconDisplay
																				className={`icon-display`}
																				sx={{ boxShadow: "none" }}
																			>
																				<BPMIcon className="active-icons" />
																			</IconDisplay>
																		</Tooltip>
																	) : setting?.setting === "sleep" ? (
																		<Tooltip
																			placement="left"
																			color="primary"
																			title="Sleep"
																		>
																			<IconDisplay
																				className={`icon-display`}
																				sx={{ boxShadow: "none" }}
																			>
																				<SleepIcon className="active-icons" />
																			</IconDisplay>
																		</Tooltip>
																	) : setting?.setting === "steps" ? (
																		<Tooltip
																			placement="left"
																			color="primary"
																			title="Steps"
																		>
																			<IconDisplay
																				className={`icon-display`}
																				sx={{ boxShadow: "none" }}
																			>
																				<StepsIcon className="active-spcl" />
																			</IconDisplay>
																		</Tooltip>
																	) : setting?.setting === "blood_oxygen" ? (
																		<Tooltip
																			placement="left"
																			color="primary"
																			title="SPO2"
																		>
																			<IconDisplay
																				className={`icon-display`}
																				sx={{ boxShadow: "none" }}
																			>
																				<Spo2Icon className="active-icons" />
																			</IconDisplay>
																		</Tooltip>
																	) : setting?.setting === "battery" ? (
																		<Tooltip
																			placement="left"
																			color="primary"
																			title="Battery"
																		>
																			<IconDisplay
																				className={`icon-display`}
																				sx={{ boxShadow: "none" }}
																			>
																				<BatteryIcon className="active-diff" />
																			</IconDisplay>
																		</Tooltip>
																	) : setting?.setting === "Door" ? (
																		<Tooltip
																			placement="left"
																			color="primary"
																			title="Door"
																		>
																			<IconDisplay
																				className={`icon-display`}
																				sx={{ boxShadow: "none" }}
																			>
																				<DoorIcon className="active-diff" />
																			</IconDisplay>
																		</Tooltip>
																	) : setting?.setting === "Shower" ? (
																		<Tooltip
																			placement="left"
																			color="primary"
																			title="Shower"
																		>
																			<IconDisplay
																				className={`icon-display`}
																				sx={{ boxShadow: "none" }}
																			>
																				<ShowerIcon className="active-diff-pill" />
																			</IconDisplay>
																		</Tooltip>
																	) : setting?.setting === "SleepBelt" ? (
																		<Tooltip
																			placement="left"
																			color="primary"
																			title="Sleep Belt (Bed) "
																		>
																			<IconDisplay
																				className={`icon-display`}
																				sx={{ boxShadow: "none" }}
																			>
																				<SleepBeltIcon className="active-belt" />
																			</IconDisplay>
																		</Tooltip>
																	) : setting?.setting === "SleepBelt-Chair" ? (
																		<Tooltip
																			placement="left"
																			color="primary"
																			title="Sleep Belt (Chair / Couch) "
																		>
																			<IconDisplay
																				className={`icon-display`}
																				sx={{ boxShadow: "none" }}
																			>
																				<CouchIcon className="active-belt" />
																			</IconDisplay>
																		</Tooltip>
																	// ) : setting?.setting === "PillBox" ? (
																	// 	<Tooltip
																	// 		placement="left"
																	// 		color="primary"
																	// 		title="PillBox"
																	// 	>
																	// 		<IconDisplay
																	// 			className={`icon-display`}
																	// 			sx={{ boxShadow: "none" }}
																	// 		>
																	// 			<PillIcon className="active-diff-pill" />
																	// 		</IconDisplay>
																	// 	</Tooltip>
																	) : setting?.setting === "SOS" ? (
																		<Tooltip
																			placement="left"
																			color="primary"
																			title="Urgent Band"
																		>
																			<IconDisplay
																				className={`icon-display`}
																				sx={{ boxShadow: "none" }}
																			>
																				<SOSIcon className="" />
																			</IconDisplay>
																		</Tooltip>
																	) : setting?.setting === "FallDetection" ? (
																		<Tooltip
																			placement="left"
																			color="primary"
																			title="Fall Detection"
																		>
																			<IconDisplay
																				className={`icon-display`}
																				sx={{ boxShadow: "none" }}
																			>
																				<FallDetectionIcon className="" />
																			</IconDisplay>
																		</Tooltip>
																	) : setting?.setting === "BathRoom" ? (
																		<Tooltip
																			placement="left"
																			color="primary"
																			title="BathRoom"
																		>
																			<IconDisplay
																				className={`icon-display`}
																				sx={{ boxShadow: "none" }}
																			>
																				<BathRoomActiveIcon className="" />
																			</IconDisplay>
																		</Tooltip>
																	) : null}
																	<Box className="gt-lt">
																		{setting?.device === "watch" ? (
																			setting?.setting === "blood_pressure" ? (
																				<Box>
																					<Typography>
																						Sys (Max, Min):{" "}
																						{setting?.thresholds?.max_sys || ""}
																						,{" "}
																						{setting?.thresholds?.min_sys || ""}
																					</Typography>
																					<Typography>
																						Dia (Max, Min):{" "}
																						{setting?.thresholds?.max_dia || ""}
																						,{" "}
																						{setting?.thresholds?.min_dia || ""}
																					</Typography>
																				</Box>
																			) : (
																				<Box>
																					{setting?.setting !== "battery" && (
																						<Typography>
																							Greater Than:{" "}
																							{setting?.thresholds?.max || ""}
																						</Typography>
																					)}
																					<Typography>
																						Lesser Than:{" "}
																						{setting?.thresholds?.min || ""}
																					</Typography>
																				</Box>
																			)
																		) : setting?.device === "Door" ? (
																			<Box>
																				<Typography>
																					Opened:{" "}
																					{setting?.thresholds?.after[0]
																						?.start_time
																						? moment(
																								new Date(
																									setting?.thresholds?.after[0]?.start_time
																								)
																									?.toISOString()
																									.toLocaleString()
																						  ).format("hh:mm A") +
																						  "-" +
																						  moment(
																								new Date(
																									setting?.thresholds?.after[0]?.end_time
																								)
																									?.toISOString()
																									.toLocaleString()
																						  ).format("hh:mm A")
																						: moment(
																								new Date(
																									setting?.thresholds?.after[0]?.end_time
																								).toLocaleString()
																						  ).format("hh:mm A") || ""}
																				</Typography>
																				<Typography>
																					Not Opened By:{" "}
																					{moment(
																						new Date(
																							setting?.thresholds?.till
																						).toLocaleString()
																					).format("hh:mm A") || ""}
																				</Typography>
																			</Box>
																		) : setting?.device === "Shower" ? (
																			<Box>
																				{/* <Typography>
													  Open After:{" "}
													  {setting?.thresholds?.after || ""}
													</Typography> */}
																				<Typography>
																					No Shower Taken :{" "}
																					{moment(
																						new Date(
																							setting?.thresholds?.time
																						).toLocaleString()
																					).format("hh:mm A") || ""}
																				</Typography>
																			</Box>
																		) : setting?.device === "SleepBelt" ? (
																			<Box>
																				<Typography>
																					Day Time Interval :{" "}
																					{setting?.day_interval || ""} hrs
																				</Typography>
																				<Typography>
																					Night Time Interval :{" "}
																					{setting?.night_interval || ""} hrs
																				</Typography>
																			</Box>
																		) : setting?.device ===
																		  "SleepBelt-Chair" ? (
																			<Box>
																				<Typography>
																					Day Time Interval :{" "}
																					{setting?.day_interval || ""} hrs
																				</Typography>
																				<Typography>
																					Night Time Interval :{" "}
																					{setting?.night_interval || ""} hrs
																				</Typography>
																			</Box>
																		// ) : setting?.device === "PillBox" ? (
																		// 	<Box>
																		// 		<Typography>
																		// 			Medications Per Day:{" "}
																		// 			{pillsCount(setting?.pills) || ""}
																		// 		</Typography>
																		// 		{pillsCountWithTime(
																		// 			setting?.pills
																		// 		)?.map((item, ind) => (
																		// 			<Typography>
																		// 				Meds{ind + 1}:{" "}
																		// 				{isValid(new Date(item.time))
																		// 					? format(
																		// 							new Date(item.time),
																		// 							"h:00 aaa"
																		// 					  )
																		// 					: item.time}
																		// 			</Typography>
																		// 		))}
																		// 		<Typography>
																		// 			Refill Interval:{" "}
																		// 			{`${
																		// 				setting?.thresholds
																		// 					?.refill_interval || "0"
																		// 			} days` || ""}
																		// 		</Typography>
																		// 	</Box>
																		) : setting?.device === "SOS" ? (
																			<Box>
																				<Typography>
																					{/* Message:{" "}
													  {setting?.message} */}
																				</Typography>
																			</Box>
																		) : setting?.device === "BathRoom" ? (
																			<Box>
																				<Typography>
																					Start Time :{" "}
																					{moment(
																						new Date(
																							setting?.thresholds?.start_time
																						).toLocaleString()
																					).format("hh:mm A") || ""}
																				</Typography>
																				<Typography>
																					End Time :{" "}
																					{moment(
																						new Date(
																							setting?.thresholds?.end_time
																						).toLocaleString()
																					).format("hh:mm A") || ""}
																				</Typography>
																				<Typography>
																					Min Visits: {setting?.thresholds?.min}
																				</Typography>
																				<Typography>
																					Max Visits: {setting?.thresholds?.max}
																				</Typography>
																			</Box>
																		) : null}
																	</Box>
																</Box>
																<Box className="box-configured-second w-xs-100">
																	{setting?.device === "SleepBelt" ? (
																		<Box className="box-message">
																			<Tooltip
																				placement="top"
																				title={setting?.day_message}
																			>
																				<Typography>
																					{setting?.day_message}
																				</Typography>
																			</Tooltip>
																			<Tooltip
																				placement="top"
																				title={setting?.night_message}
																			>
																				<Typography>
																					{setting?.night_message}
																				</Typography>
																			</Tooltip>
																		</Box>
																	) : setting?.device === "SleepBelt-Chair" ? (
																		<Box className="box-message">
																			<Tooltip
																				placement="top"
																				title={setting?.day_message}
																			>
																				<Typography>
																					{setting?.day_message}
																				</Typography>
																			</Tooltip>
																			<Tooltip
																				placement="top"
																				title={setting?.night_message}
																			>
																				<Typography>
																					{setting?.night_message}
																				</Typography>
																			</Tooltip>
																		</Box>
																	// ) : setting?.device === "PillBox" ? (
																	// 	<Box className="box-pill-message">
																	// 		<Tooltip
																	// 			placement="top"
																	// 			title={setting?.refill_message}
																	// 		>
																	// 			<Typography>
																	// 				Pill Refill Message:{" "}
																	// 				{setting?.refill_message}
																	// 			</Typography>
																	// 		</Tooltip>
																	// 	</Box>
																	) : setting?.device === "BathRoom" ? (
																		<Box className="box-message">
																			<Tooltip
																				placement="top"
																				title={setting?.min_message}
																			>
																				<Typography>
																					{setting?.min_message}
																				</Typography>
																			</Tooltip>
																			<Tooltip
																				placement="top"
																				title={setting?.max_message}
																			>
																				<Typography>
																					{setting?.max_message}
																				</Typography>
																			</Tooltip>
																		</Box>
																	) : (
																		<Box className="box-message">
																			<Tooltip
																				placement="top"
																				title={setting?.message}
																			>
																				<Typography>
																					{setting?.message}
																				</Typography>
																			</Tooltip>
																			{setting?.setting === "Door" && (
																				<Tooltip
																					placement="top"
																					title={setting?.till_message}
																				>
																					<Typography>
																						{setting?.till_message}
																					</Typography>
																				</Tooltip>
																			)}
																			{setting?.setting !== "battery" && (
																				<Tooltip
																					placement="top"
																					title={setting?.lesser_message}
																				>
																					<Typography>
																						{setting?.lesser_message}
																					</Typography>
																				</Tooltip>
																			)}
																		</Box>
																	)}
																	<Box className="box-configured-third">
																		<Tooltip
																			placement="left"
																			color="primary"
																			title="Edit Configurations"
																		>
																			<IconButton
																				onClick={() => handleClickEdit(index)}
																				className={`icon-display`}
																			>
																				<EditIcon className="edit-green" />
																			</IconButton>
																		</Tooltip>
																		<Tooltip
																			placement="right"
																			color="primary"
																			title="Delete Configurations"
																		>
																			<IconButton
																				onClick={() =>
																					setHandleDialogOpen([true, index])
																				}
																				className={`icon-display`}
																			>
																				<CloseIcon className="close-red" />
																			</IconButton>
																		</Tooltip>
																	</Box>
																</Box>
															</Box>
															<Box>
																{setting?.device === "watch" ? (
																	<Collapse in={selectedEdit === index}>
																		<WatchInputs
																			setting={setting}
																			snackbarNotification={
																				snackbarNotification
																			}
																			handleFinish={() => {
																				getRevisedSeniors(selected?.id);
																				getCentric();
																			}}
																			currentSelected={currentSelected}
																			selected={selected}
																			isGeneric={false}
																		/>
																	</Collapse>
																) : setting?.device === "Door" ? (
																	<Collapse in={selectedEdit === index}>
																		<DoorInputs
																			setting={setting}
																			snackbarNotification={
																				snackbarNotification
																			}
																			handleFinish={() => {
																				getRevisedSeniors(selected?.id);
																				getCentric();
																			}}
																			currentSelected={currentSelected}
																			selected={selected}
																			isGeneric={false}
																			macId={notificationMacId}
																		/>
																	</Collapse>
																) : setting?.device === "Shower" ? (
																	<Collapse in={selectedEdit === index}>
																		<ShowerInput
																			setting={setting}
																			snackbarNotification={
																				snackbarNotification
																			}
																			handleFinish={() => {
																				getRevisedSeniors(selected?.id);
																				getCentric();
																			}}
																			currentSelected={currentSelected}
																			selected={selected}
																			isGeneric={false}
																			macId={notificationMacId}
																		/>
																	</Collapse>
																) : setting?.device === "SleepBelt" ? (
																	<Collapse in={selectedEdit === index}>
																		<SleepBeltInput
																			snackbarNotification={
																				snackbarNotification
																			}
																			currentSelected={currentSelected}
																			selected={selected}
																			setting={setting}
																			handleFinish={() => {
																				getRevisedSeniors(selected?.id);
																				getCentric();
																			}}
																			isGeneric={false}
																			macId={notificationMacId}
																		/>
																	</Collapse>
																) : setting?.device === "SleepBelt-Chair" ? (
																	<Collapse in={selectedEdit === index}>
																		<SleepBeltInput
																			snackbarNotification={
																				snackbarNotification
																			}
																			currentSelected={currentSelected}
																			selected={selected}
																			setting={setting}
																			handleFinish={() => {
																				getRevisedSeniors(selected?.id);
																				getCentric();
																			}}
																			isGeneric={false}
																			couch={true}
																			macId={notificationMacId}
																		/>
																	</Collapse>
																// ) : setting?.setting === "PillBox" ? (
																// 	<Collapse in={selectedEdit === index}>
																// 		<PillInputs
																// 			setting={setting}
																// 			snackbarNotification={
																// 				snackbarNotification
																// 			}
																// 			handleFinish={() => {
																// 				getRevisedSeniors(selected?.id);
																// 				getCentric();
																// 			}}
																// 			currentSelected={currentSelected}
																// 			selected={selected}
																// 			isGeneric={false}
																// 			macId={notificationMacId}
																// 		/>
																// 	</Collapse>
																) : setting?.setting === "SOS" ? (
																	<Collapse in={selectedEdit === index}>
																		<SOSInputs
																			setting={setting}
																			snackbarNotification={
																				snackbarNotification
																			}
																			handleFinish={() => {
																				getRevisedSeniors(selected?.id);
																				getCentric();
																			}}
																			currentSelected={currentSelected}
																			selected={selected}
																			isGeneric={false}
																			macId={notificationMacId}
																		/>
																	</Collapse>
																) : setting?.setting === "FallDetection" ? (
																	<Collapse in={selectedEdit === index}>
																		<SOSInputs
																			setting={setting}
																			snackbarNotification={
																				snackbarNotification
																			}
																			handleFinish={() => {
																				getRevisedSeniors(selected?.id);
																				getCentric();
																			}}
																			currentSelected={currentSelected}
																			selected={selected}
																			isGeneric={false}
																			macId={notificationMacId}
																		/>
																	</Collapse>
																) : setting?.setting === "BathRoom" ? (
																	<Collapse in={selectedEdit === index}>
																		<BathRoomInputs
																			setting={setting}
																			snackbarNotification={
																				snackbarNotification
																			}
																			handleFinish={() => {
																				getRevisedSeniors(selected?.id);
																				getCentric();
																			}}
																			currentSelected={currentSelected}
																			selected={selected}
																			isGeneric={false}
																			macId={notificationMacId}
																		/>
																	</Collapse>
																) : null}
															</Box>
														</Box>
													</Paper>
												</>
											)
											)
										})
										
										// )
										// : (
										//   <Box className="no-notifications">
										//     <Typography>No senior selected</Typography>
										//   </Box>
										// )
									)}

									{subTab === 1 && (
										<SubTabThreeDoor
											goBack={goBackToNotifications}
											setInvalidSenior={setInvalid}
											snackbarNotification={snackbarNotification}
											addOrSave={addOrSave}
											currentSelected={currentSelected}
											selectedSenior={selected}
											macId={notificationMacId}
										/>
									)}
									{subTab === 2 && (
										<SubTabThreePill
											goBack={goBackToNotifications}
											setInvalidSenior={setInvalid}
											snackbarNotification={snackbarNotification}
											addOrSave={addOrSave}
											currentSelected={currentSelected}
											selectedSenior={selected}
											macId={notificationMacId}
										/>
									)}
									{subTab === 3 && (
										<SubTabThreeShower
											goBack={goBackToNotifications}
											setInvalidSenior={setInvalid}
											snackbarNotification={snackbarNotification}
											addOrSave={addOrSave}
											currentSelected={currentSelected}
											selectedSenior={selected}
											macId={notificationMacId}
										/>
									)}
									{subTab === 4 && (
										<SubTabThreeSos
											goBack={goBackToNotifications}
											setInvalidSenior={setInvalid}
											snackbarNotification={snackbarNotification}
											addOrSave={addOrSave}
											currentSelected={currentSelected}
											selectedSenior={selected}
											macId={notificationMacId}
										/>
									)}
									{subTab === 5 && (
										<SubTabThreeSleepBelt
											goBack={goBackToNotifications}
											setInvalidSenior={setInvalid}
											snackbarNotification={snackbarNotification}
											addOrSave={addOrSave}
											currentSelected={currentSelected}
											selectedSenior={selected}
											macId={notificationMacId}
										/>
									)}
									{subTab === 6 && (
										<SubTabThreeSleepBelt
											goBack={goBackToNotifications}
											setInvalidSenior={setInvalid}
											snackbarNotification={snackbarNotification}
											addOrSave={addOrSave}
											currentSelected={currentSelected}
											selectedSenior={selected}
											couch={true}
											macId={notificationMacId}
										/>
									)}
									{subTab === 7 && (
										<SubTabThreeFallDetection
											goBack={goBackToNotifications}
											setInvalidSenior={setInvalid}
											snackbarNotification={snackbarNotification}
											addOrSave={addOrSave}
											currentSelected={currentSelected}
											selectedSenior={selected}
											macId={notificationMacId}
										/>
									)}
									{subTab === 8 && (
										<SubTabThreeBathRoom
											goBack={goBackToNotifications}
											setInvalidSenior={setInvalid}
											snackbarNotification={snackbarNotification}
											addOrSave={addOrSave}
											currentSelected={currentSelected}
											selectedSenior={selected}
											macId={notificationMacId}
										/>
									)}
								</Box>
							)}
						</Box>
					</Box>
				</Box>
			</Box>
			<SeniorDetailsDialog
				open={handleSeniorOpen}
				handleClose={() => setHandleSeniorOpen(false)}
				seniorId={selectedSeniorData?._id}
				details={selectedSeniorData}
			/>
			<ConfirmationDialog
				text={
					currentSelected === "seniorSpecific" ? (
						<>
							<p style={{ margin: "0.25rem 0", textAlign: "center" }}>
								Please Integrate the Sensor With NHUB to add CEN
							</p>
						</>
					) : (
						<>
							<p style={{ margin: "0.25rem 0", textAlign: "center" }}>
								This Sensor is not integrated with NHUB
							</p>
							<p style={{ margin: "0.25rem 0", textAlign: "center" }}>
								Do you want to add CEN for this?
							</p>
						</>
					)
				}
				open={showSensorConfigWarning}
				handleClose={(val) => {
					if (val) {
						setSubTab(tabToBeSelected);
					}
					setShowSensorConfigWarning(false);
				}}
				currentSelected={currentSelected}
			/>
			<DeleteConfirmationDialog
				func={() => {
					currentSelected === "generic"
						? handleDeleteGeneric(handleDialogOpen[1])
						: handleDeleteCentric(handleDialogOpen[1]);
				}}
				open={handleDialogOpen[0]}
				handleClose={() => setHandleDialogOpen([false, -1])}
			/>
		</>
	);
};

export default TabThree;
