import { Dialog, DialogContent } from '@mui/material';
import './SeniorDetailsDialog.scss';
import locationIcon from 'assets/img/location-white.svg';
import mail from 'assets/img/mail.svg';
import phoneIcon from 'assets/img/phone.svg';
import closeIcon from 'assets/img/close.svg';
import avatar from 'assets/img/default.svg';

const SeniorDetailsDialog = ({ open, handleClose, details }) => {
    return (
        <>
            <Dialog
                maxWidth={'xs'}
                open={open}
                onClose={handleClose}
                fullWidth={true}
                className={'senior-info'}
            >
                <DialogContent className={'senior-info-card'}>
                    <div>
                        <div className={`user-profile`}>
                            <img src={closeIcon} alt="close" className={`close-icon cursor`} onClick={handleClose} />
                            {details?.profile ?
                                <img src={details?.profile} alt='user' className={`user-image`} /> :
                                <img src={avatar} alt='user' className={`user-image`} />}
                            <p>{details?.fname} {details?.lname}</p>
                            <span>Age {details?.age}, {details?.gender}</span>
                        </div>
                        <div className={`user-details`}>
                            <div className='d-flex'>
                                <span><img src={mail} alt="mail" className={`icon`} />{details?.email || "-"}</span>
                                <span><img src={phoneIcon} alt="phone" className={`icon`} />{details?.phone || "-"}</span>
                            </div>
                            <div className='d-flex'>
                                <span className='span-location'><img src={locationIcon} alt="location" className={'location-custom'} /> {details?.address}, Zip code - {details?.zipcode}</span>
                            </div>
                        </div>
                        <div className={`user-measurements d-flex`}>
                            <div>
                                <div>Height</div>
                                <div className='txt-center'>{
                                    details?.height_unit === 'ft' ?
                                        (
                                            `${details?.height ? `${details?.height}'` : details?.inchs ? '' : '-'} ${details?.inchs ? `${details?.inchs}''` : ''}`
                                        ) : (
                                            `${details?.height || '-'} ${details?.height_unit || ''}`
                                        )
                                }</div>
                            </div>
                            <div>
                                <div>Weight</div>
                                <div className='txt-center'>
                                {details?.weight_unit === 'lbs' ?
                                        (
                                            `${details?.weight ? `${details?.weight}` : '-'} ${details?.weight ? `${details?.weight_unit}` : ''}`
                                        ) : (
                                            `${details?.weight ? `${details?.weight}`: '-'}  ${details?.weight ? `${details?.weight_unit}` : ''}`
                                        )
                                }
                                </div>
                            </div>
                        </div>
                    </div>

                </DialogContent>
            </Dialog>
        </>
    )
}

export default SeniorDetailsDialog;
