import { Avatar, Box, Stack, Typography } from '@mui/material';
import { ReactComponent as ExecutiveIcon } from 'assets/img/executive_icon.svg';
import React from 'react';

const SeniorInfo = ({ name, age, sex, image, isExecutive }) => {
  console.log(name, age, sex, image)
  return (
    <Box
      sx={{
        minWidth: 'fit-content',
        borderRadius: '3rem',
        border: '2px solid',
        borderColor: 'primary.main',
        padding: '0.75rem',
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing="0.5rem"
        sx={{ margin: 'auto 0' }}
      >
        {console.log(isExecutive)}
        {
          isExecutive === true ?
          <Box direction="row" alignItems="center" sx={{ position: "relative" }}>
            <Avatar
              sx={{ width: '2.5rem', height: '2.5rem' }}
              src={image}
              alt=""
            />
            <ExecutiveIcon style={{ position: "absolute", top: "1.7rem", left: "1.7rem" }} />
          </Box> :
          <Avatar
            sx={{ width: '2.5rem', height: '2.5rem' }}
            src={image}
            alt=""
          />
        }
        <Box flex={1}>
          <Typography sx={{whiteSpace: 'noWrap'}} >{name}</Typography>
          <Typography
            sx={{ color: '#9c9c9c' }}
            fontSize="0.7rem"
          >{`Age ${age}, ${sex}`}</Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default SeniorInfo;
