import axios from 'axios';
import AuthServices from './services/auth-services';

const axiosInstance = axios.create()

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_REST_API_URL,
});

const getAuthToken = async () => {

  return await AuthServices.refreshToken();
 
}

const onSuccess = (response) => Promise.resolve(response);
const onError = ({ response }) => {

  const originalRequest = response?.config;
  
  if ((response?.status === 401) && !originalRequest._retry) {
    

    originalRequest._retry = true;

    return getAuthToken()
      .then(async ({access_token}) => {
        localStorage.setItem("authToken", access_token)
        originalRequest.headers['Authorization'] = `Bearer ${access_token}`;
        return axiosInstance(originalRequest)
      })
      .catch(err => {
        console.log("error from https-client refresh token",err)
        if(err.response.status){
          err.data={
            message:"Token expired ,please login again"
          }
            localStorage.clear();
            setTimeout(() => window.location.href="/login",4000)
        }

        return Promise.reject(err)
      })
  }


  if (response.status === 422) {
    localStorage.clear();
    window.location.href="/login";

  }
  if (response.status === 500) {
    response.data = {
      message: 'Internal Server Error'
    }
  }

  return Promise.reject(response)
}

httpClient.interceptors.request.use(
  (req) => {
    if (req.url === '/refresh/token') {
      req.headers['Authorization'] = `Bearer ${localStorage.getItem('refreshToken')}`
    } else {
      req.headers['Authorization'] = `Bearer ${localStorage.getItem('authToken')}`
    }
    return req
  }
);

httpClient.interceptors.response.use(onSuccess, onError);

export default httpClient;
