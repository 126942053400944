import { Box, FormControl, InputBase, InputLabel, MenuItem, Select, styled } from '@mui/material';
import React from 'react'
const ModInput = styled(InputBase)({
  'label + &': {
    marginTop: '1.5rem',
    color: '#9c9c9c',
    backgroundColor: '#fff',
    borderRadius: '0.5rem',
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    width: '100%',
    fontSize: '1rem',
    padding: '0.5rem 1rem',
    color: '#000',
    '&::placeholder': {
      color: '#000',
      opacity: 0.6
    }
  },
  '&:focus': {
    color: '#9c9c9c',
  },
});
const SimpleInputField = ({ fieldType, labelText, options, multiline, placeholder, spnDisabled, spnOnChange, value, type, onChange, helperText, marginBottom0, number }) => {
  return (
    <>
      <FormControl
        fullWidth
        sx={{ backgroundColor: 'transparent', borderRadius: '0' }}
        variant="standard"
        size="small"
      >
        <InputLabel
          sx={{
            color: '#D8D8D8',
            fontSize: '1rem',
            '&.Mui-focused': {
              color: '#D8D8D8',
            },
          }}
          shrink
        >
          {labelText}
        </InputLabel>
        {fieldType === 'select' ? (
          <Select label={labelText} input={<ModInput />}>
            {options.map((option, index) => {
              return (
                <MenuItem key={index} value={option}>
                  {option[0].toUpperCase() + option.slice(1)}
                </MenuItem>
              );
            })}
          </Select>
        ) : (
          <ModInput
            multiline={multiline}
            minRows={4}
            placeholder={placeholder ? placeholder : ""}
            maxRows={10}
            disabled={spnDisabled}
            type={!!number ? "number" : "text"}
            value={value || ''}
            onChange={type === "Other" ? onChange : spnOnChange}
          />
        )}
      </FormControl>
      <Box sx={{marginTop: '-1rem', marginBottom: `${!!marginBottom0 ? '0' :'0.6em' }`}}>
        <label style={{ fontSize: '0.9rem', fontWeight: '500', color: 'red', paddingLeft: '5px' }}>{helperText}</label>
      </Box>
    </>
  );
}

export default SimpleInputField