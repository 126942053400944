import { Box, Button, Grid, MenuItem, Paper, Snackbar, Stack, styled, Typography } from '@mui/material';
import AddSeniorInput from 'components/add-senior-input/AddSeniorInput';
import SeniorInfo from 'components/senior-info/SeniorInfo';
import React, { useContext, useState } from 'react';
import { ReactComponent as NextIcon } from 'assets/img/next_icon.svg';

import { actions, GlobalContext } from 'context';
import AuthServices from 'api/services/auth-services';
import AlertDialog from './components/Next/dialog';
import "./scss/step3.scss";

import "./CreAccStep3.scss";
import SelectInputMod from 'app/create-account/Components/select-input-mod/SelectInputMod';
import { isPrice } from 'utils/validator';


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  boxShadow: "0 0 0 0",
  color: theme.palette.text.secondary,
}));

const contractTermsList = [
  { id: 1, value: "6 months" },
  { id: 2, value: "12 months" },
  { id: 3, value: "24 months" },
  { id: 4, value: "36 months" }
]

const agreementList = [
  { id: 1, value: 'Yes Accept' },
  { id: 1, value: 'No Decline' }
]

const initialDetails = { price: "", contract_terms: '', maintenance: '' };


const CreAccStep3 = ({ changeTab, seniorList }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { masterAccountId, contractData: { plans }, planId } = state;

  const [seniorDetails, setSeniorDetails] = useState({ ...initialDetails });
  const [inValid, setInValid] = useState({});
  const [openNextDialog, setOpenNextDialog] = useState(false)

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });


  const mandatoryInputFields = [
    'price',
    'contract_terms',
    'maintenance',
  ]

  const storeHandler = (type, payload) => dispatch({ type, payload })

  const addDetail = (key, value) => {
    if(value?.length === 1 && value === '0'){
      setInValid({ ...inValid, [key]: true })
    }
    else{
      const data = { ...seniorDetails, [key]: value };
      setInValid({ ...inValid, [key]: false })
      setSeniorDetails({ ...data });
    }

  }

  const validate = () => {
    let invalidFields = [];
    const newInValid = {};
    mandatoryInputFields.forEach(item => {

      if (item === 'price' && !isPrice(seniorDetails[item])) {
        invalidFields.push(item)
        newInValid[item] = true;
      }

      if (!seniorDetails[item]) {
        invalidFields.push(item)
        newInValid[item] = true;
      }
    })

    if (invalidFields.length) {
      setInValid({ ...newInValid });
    }
    else {
      for (let key in inValid) {
        if (inValid[key]) {
          return;
        }
      }
      addSenior();
    }
  }

  const snackbarNotification = (data) => {
    setSnackBarContent(data)
    setShowSnackBar(true)
  }


  const addSenior = async () => {

    const formData = new FormData();
    Object.keys(seniorDetails).forEach((key) => {

      if (key === 'maintenance') {
        if (seniorDetails[key] === 'Yes Accept') {
          formData.append(key, true);
        } else {
          formData.append(key, false);
        }
      } else {
        formData.append(key, seniorDetails[key]);
      }

    })
    storeHandler(actions.SHOW_LOADER, true)
    try {
      await AuthServices.contractTerms(masterAccountId, formData);
      await AuthServices.finishAccountCreation(masterAccountId)
      storeHandler(actions.SHOW_LOADER, false)
      setOpenNextDialog(true)
    } catch (err) {
      const { data } = err;
      const { message } = data || '';
      snackbarNotification({
        message,
        class: 'error'
      })
      storeHandler(actions.SHOW_LOADER, false)
    }
  }

  return (
    <>
      <Paper sx={{ minWidth: '95%', marginBottom: '2rem' }}>
        <Typography sx={{ color: '#d8d8d8', marginBottom: '0.25rem' }}>
          {seniorList?.length ? seniorList?.length === 1 ? "1 Senior Added" : `${seniorList?.length} Seniors Added` : "No Senior Added"}
        </Typography>
        {seniorList && <Stack className="stack" direction="row" spacing={1} sx={{ width: "100%" }}>
          {seniorList.map((senior, index) => {
            return (
              <SeniorInfo
                key={index}
                name={senior.name}
                age={senior.age}
                sex={senior.sex}
                image={senior.profileImg}
              />
            );
          })
          }
        </Stack>}
      </Paper>
      <Paper>
        <Typography sx={{ fontSize: '1rem', color: '#d8d8d8', marginLeft: '0.6rem', marginTop: "0.5rem" }}>
          Terms
        </Typography>
        <Box
          className="allDetails"
        >
          <Grid className="grid-senior" sx={{ width: { xs: '100%', sm: '100%', md: '75%' } }} container spacing={0}>

            <Grid className="grid-senior-item spcl" item xs={12} sm={6} md={4}>
              <Item>
                <AddSeniorInput startAdornment value={seniorDetails['price'] || ''} type="number" helperText={inValid?.price && 'Enter valid price'} id="price" onChange={(e) => addDetail('price', e.target.value)} label="Price" />
              </Item>
            </Grid>

            <Grid className="grid-senior-item spcl" item xs={12} sm={6} md={4}>
              <Item>
                <SelectInputMod helperText={inValid?.contract_terms && 'Select your Contract Trems'} value={seniorDetails?.contract_terms || ''} onChange={(e) => addDetail('contract_terms', e.target.value)} label="Contract Terms" required>
                  {contractTermsList?.map((option, index) => {
                    return (
                      <MenuItem key={index} value={option?.value} >
                        {option.value || ''}
                      </MenuItem>
                    );
                  })}
                </SelectInputMod>
              </Item>
            </Grid>

            <Grid className="grid-senior-item spcl" item xs={12} sm={6} md={4}>
              <Item>
                <SelectInputMod helperText={inValid?.maintenance && 'Select Your Maintenance Agreement'} value={seniorDetails?.maintenance || ''} onChange={(e) => addDetail('maintenance', e.target.value)} label="Maintenance Agreement" required>
                  {agreementList?.map((option, index) => {
                    return (
                      <MenuItem key={index} value={option?.value} >
                        {option.value || ''}
                      </MenuItem>
                    );
                  })}
                </SelectInputMod>
              </Item>
            </Grid>

          </Grid>

        </Box>
        <Box
          sx={{
            width: { xs: "100%", sm: "75%", md: "50%", maxWidth: '300px' },
            margin: '0 auto',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'column', md: 'row' }
          }}
        >
          <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              color: '#fff',
              marginTop: '2.5rem',
              width: "100%"
            }}
            onClick={validate}
            endIcon={<NextIcon />}

          >
            Finish
          </Button>

        </Box>
        <AlertDialog
          open={openNextDialog}
          text={`Added Contract & Maintenance Agreement,`}
          subText={`move to next step?`}
          handleClose={() => setOpenNextDialog(false)}
          handleAccept={changeTab}
        />
        <Snackbar open={showSnackBar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          message={snackbarContent?.message || ''}
          autoHideDuration={4000}
          onClose={() => setShowSnackBar(false)}
          sx={{
            '.MuiSnackbarContent-root': {
              color: '#fff',
              fontWeight: '400',
              backgroundColor: snackbarContent.class === 'error' ? 'red' : (snackbarContent.class === 'warning' ? '#ffcc00' : '#00b89f'),
              display: 'flex',
              justifyContent: 'center',
              borderRadius: '10rem'
            }
          }}
        />
      </Paper>
    </>
  );
};

export default CreAccStep3;
