import React, { useContext, useState, useEffect } from "react";
import { Button, Box, Stack, Typography } from "@mui/material";
import { TransparentBtn } from "../../components/styled-componets/style";
import SimpleInputField from "components/simple-inputfield/SimpleInputField";
import AuthServices from "api/services/auth-services";
import { actions, GlobalContext } from "context";
import { ReactComponent as TickIcon } from "assets/img/tick_icon.svg";
import Timepicker from "components/time-picker/Timepicker";
import { ReactComponent as AddIcon } from "assets/img/plus.svg";
import { ReactComponent as TrashICon } from "assets/img/trash-can-outline.svg";
import moment from 'moment';
import ConfirmationDialog from "components/dialogs/ConfirmationDialog/ConfirmationDialog";
import { getTimeZoneOffset, getUserTimeZome } from "utils/datetime-formatter";

const DoorInputs = ({
    currentSelected,
    selected,
    setting,
    snackbarNotification,
    handleFinish,
    isGeneric,
    ...rest
}) => {

    const { dispatch } = useContext(GlobalContext);
    const storeHandler = (type, payload) => dispatch({ type, payload });
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [settings, setSettings] = useState();
    const [invalid, setInvalid] = useState({});
    const [notificatonModes, setNotificationModes] = useState({
        email: setting?.notification_type?.email, sms: setting?.notification_type?.sms, push: setting?.notification_type?.push
    });
    const [removeIndex, setRemoveIndex] = useState();
    const [invalidModes, setInvalidModes] = useState(false);

    useEffect(() => {
        console.log("called in doorinputs",setting)
        const data = {
            ...setting,
            after: setting?.thresholds?.after,
            till: new Date(setting?.thresholds?.till),
        }
        for (let i = 0; i < data?.after.length; i++) {
            data.after[i].start_time = new Date(data.after[i].start_time)
            data.after[i].end_time = new Date(data.after[i].end_time)
            const today = new Date();
            data.after[i].start_time.setFullYear(today.getFullYear());
            data.after[i].start_time.setMonth(today.getMonth());
            data.after[i].start_time.setDate(today.getDate());
            data.after[i].end_time.setFullYear(today.getFullYear());
            data.after[i].end_time.setMonth(today.getMonth());
            data.after[i].end_time.setDate(today.getDate());
        }
        data.after = data?.after?.map((el, ind) => {
            return { ...el, err_end: '', err_start: '' }
        })
        setSettings({ ...data })
    }, [isGeneric, setting])

    const handleModeClick = (e) => {
        const mode = e.target.name;
        const prev = notificatonModes[mode];
        setNotificationModes({ ...notificatonModes, [mode]: !prev });
        invalid.modes = false;
        setInvalidModes(false);
    };

    const handleChange = (value, key) => {
        if (key === "till") {
            invalid[key] = false;
            const data = { ...settings };
            data[key] = value.toUTCString();
            setSettings(data);
        } else {
            invalid[key] = false;
            const data = { ...settings };
            data[key] = value;
            setSettings(data);
        }
    };

    const handleOpenChange = (value, key, index) => {
        const newSetting = { ...settings };
        const openArray = newSetting.after;
        if (key === "start_time") {
            openArray[index].start_time = value;
            openArray[index].err_start = null;
            if (openArray[index].err_end === "Time should not be Equal")
                openArray[index].err_end = null;
        } else if (key === "end_time") {
            openArray[index].end_time = value;
            openArray[index].err_end = null;
        }
        newSetting.after = openArray;
        setSettings(newSetting);
        setInvalid({ ...invalid, [key]: false });
    };


    const validate = () => {
        let data = settings;
        let invalidData = {};
        let isValid = true;
        Object.keys(data).forEach((key) => {
            if (data[key] === "") {
                invalidData[key] = true;
                isValid = false;
            }
        });

        const isValidModes = Object.values(notificatonModes).some((item) => item);
        setInvalidModes(!isValidModes);
        setInvalid(invalidData);

        let valid_after_values = validateAfterValues();
        if (valid_after_values) {
            let data = settings?.after;
            if (data?.length < 1) {
                valid_after_values = false;
            }
            for (let i = 0; i < data?.length; i++) {
                delete settings.after[i].err_end;
                delete settings.after[i].err_start;
            }
        }
        if (isValid && valid_after_values && (currentSelected == "generic" || isValidModes)) {
            for (let i = 0; i < settings?.after?.length; i++) {
                if (settings.after[i].start_time.getHours() >= 12) {
                    if (moment.duration(moment(settings.after[i].end_time, 'YYYY-MM-DD HH:mm:ss.SS Z').diff(moment(settings.after[i].start_time, 'YYYY-MM-DD HH:mm:ss.SS Z'))).asMinutes() < 0) {
                        const newEndDate = new Date(settings.after[i].end_time)
                        newEndDate.setDate(newEndDate.getDate() + 1);
                        settings.after[i].end_time = newEndDate
                    }
                }
            }
            handleEdit();
        }
    };

    /**
     * Convert the '10:00' string to current date given time
     * 
     */
    const convertToCurrentDateLocal = (time) => {
        let currentDT = new Date();
        let newtime = time.split(':').map((value) => parseInt(value))
        if (newtime[0] === 23) {
            currentDT.setHours(newtime[0], newtime[1], 59, 999);
        } else {
            currentDT.setHours(newtime[0], newtime[1], 0, 0)
        }
        return currentDT
    }

    const validateAfterValues = (check) => {
        const afterValues = [...settings?.after]

        // storing avaible time in pair of 2 [00:00 23:00] all time is available in start
        let availableTime = [convertToCurrentDateLocal("00:00"), convertToCurrentDateLocal("23:59")]
        let last_length = 2;
        for (let i = 0; i < afterValues?.length; i++) {
            // check if Values of Start and End is ok
            let current_start = afterValues[i].start_time;
            let current_end = afterValues[i].end_time;
            let isValid = true;
            // checking if End time is less than start time for next day value
            if (current_end > current_start) {
                //checking for 12hr condition
                if ((current_end - current_start) / 3600000 >= 12) {
                    afterValues[i].err_end = "Selected time cannot be more than 12hr"
                    isValid = false;
                } else if (current_end.getHours() === current_start.getHours() && current_end.getMinutes() === current_start.getMinutes()) {
                    isValid = false;
                    afterValues[i].err_end = "Time should not be Equal"
                }
                else {
                    afterValues[i].err_end = null;
                }

                if (!isValid) {
                    setSettings({ ...settings, after: afterValues })
                    return false;
                }

                // checking if is is avalable or not
                for (let j = 1; j < availableTime.length; j += 2) {
                    let start = availableTime[j - 1];
                    let end = availableTime[j];

                    if (start.getTime() <= current_start.getTime() && current_end.getTime() <= end.getTime()) {
                        availableTime.splice(j, 0, current_start);
                        availableTime.splice(j + 1, 0, current_end);
                        break;
                    }
                }
                if (last_length === availableTime.length) {
                    isValid = false;
                    afterValues[i].err_start = "Time is overriding other time"
                } else {
                    afterValues[i].err_end = null;
                    last_length = availableTime.length;
                }

            }
            else {
                let day_start = convertToCurrentDateLocal("00:00");
                let day_end = convertToCurrentDateLocal("23:59");
                // 12hr calculation
                let total_time = ((day_end - current_start) + (current_end - day_start)) / 3600000;
                if (!current_start) {
                    isValid = false;
                    afterValues[i].err_start = "Please enter valid time";
                    afterValues[i].err_end = "Please enter valid time";
                } else if (current_start && !current_end && check) {
                    isValid = false;
                    afterValues[i].err_end = "Please enter valid time";
                } else if ((current_end && current_start) && current_end?.getHours() === current_start?.getHours() && current_end?.getMinutes() === current_start?.getMinutes()) {
                    isValid = false;
                    afterValues[i].err_end = "Time should not be Equal"
                }
                else if (total_time >= 12) {
                    afterValues[i].err_end = "Selected time cannot be more than 12hr"
                    isValid = false;
                }
                if (!isValid) {
                    setSettings({ ...settings, after: afterValues })
                    return false;
                }
                //  2 diffrent group to check current_start - day_end, day_start - current_end
                let timebreakdown = [current_start, day_end, day_start, current_end];
                for (let pairindex = 1; pairindex < timebreakdown.length; pairindex += 2) {
                    for (let j = 1; j < availableTime.length; j += 2) {
                        let start = availableTime[j - 1];
                        let end = availableTime[j];
                        if (start <= timebreakdown[pairindex - 1] && timebreakdown[pairindex] <= end) {
                            availableTime.splice(j, 0, timebreakdown[pairindex - 1]);
                            availableTime.splice(j + 1, 0, timebreakdown[pairindex]);
                            break;
                        }
                    }
                    if (last_length === availableTime.length) {
                        isValid = false;
                        if (afterValues[i].start_time && !afterValues[i].end_time) {
                            afterValues[i].err_start = "Time is overriding other time"
                        }
                        else if (afterValues[i].end_time) {
                            afterValues[i].err_end = "Time is overriding other time"
                        }
                        break
                    } else {
                        if (!afterValues[i].err_end)
                            afterValues[i].err_end = null;
                        last_length = availableTime.length;
                    }
                }

            }
            if (!isValid) {
                setSettings({ ...settings, after: afterValues })
                return false;
            }
        }
        return true;
    }
    const handleEdit = async () => {
        for (let i = 0; i < settings.after.length; i++) {
            settings.after[i].start_time = settings.after[i].start_time.toUTCString();
            settings.after[i].end_time = settings.after[i].end_time.toUTCString();
        }
        storeHandler(actions.SHOW_LOADER, true);
        try {
            if (currentSelected === "generic") {
                const notification = {
                    ...settings,
                    timezone: getUserTimeZome(),
                    origin: getTimeZoneOffset()
                };
                const { message } = await AuthServices.editGenericNotifications(
                    notification
                );
                snackbarNotification({ message: message, class: "success" });
            } else {
                const { message } = await AuthServices.editCentricNotifications(
                    selected?.id,
                    { ...settings, noti_type: notificatonModes, timezone: getUserTimeZome(), origin: getTimeZoneOffset() }
                );
                snackbarNotification({ message: message, class: "success" });
            }
            handleFinish(true);
            // storeHandler(actions.SHOW_LOADER, false);
        } catch (err) {
            const { data } = err || {};
            const { message } = data || {};
            snackbarNotification({
                message,
                class: "error",
            });
            storeHandler(actions.SHOW_LOADER, false);
        }
    };

    const handleAddInputBox = (index) => {
        const starttime = settings?.after?.[index]?.start_time;
        const endtime = settings?.after?.[index]?.end_time;
        const isValid = validateAfterValues(true);
        if (starttime && endtime) {
            if (isValid) {
                setSettings((prevState) => {
                    const newId = prevState?.after?.length + 1;
                    const newInputBox = {
                        id: newId,
                        start_time: "",
                        end_time: "",
                        err_start: null,
                        err_end: null
                    };
                    const updatedOpen = [...prevState.after, newInputBox];
                    return { ...prevState, after: updatedOpen };
                });
            }

        }
    };

    const handleOpenRemoveDialog = (index) => {
        setRemoveIndex(index)
        setRemoveDialogOpen(true);
    };

    const handleRemoveInputBox = (id) => {
        for (let i = 0; i < settings?.after.length; i++) {
            settings.after[i].start_time = new Date(settings.after[i].start_time)
            settings.after[i].end_time = new Date(settings.after[i].end_time)
            const today = new Date();
            settings.after[i].start_time.setFullYear(today.getFullYear());
            settings.after[i].start_time.setMonth(today.getMonth());
            settings.after[i].start_time.setDate(today.getDate());
            settings.after[i].end_time.setFullYear(today.getFullYear());
            settings.after[i].end_time.setMonth(today.getMonth());
            settings.after[i].end_time.setDate(today.getDate());
        }

        validateAfterValues();
        setSettings((prevState) => {
            const updatedOpen = prevState.after.filter(
                (inputBox, index) => index != id
            );
            settings.after.map((el, ind) => {
                return el.id = ind + 1
            }
            )
            return { ...prevState, after: updatedOpen };
        });
    };
    return (
        <>
            <Stack>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Typography
                        sx={{
                            fontSize: "1rem !important",
                            marginBottom: "1rem !important",
                            marginRight: "41% !important",
                        }}
                    >
                        Door Opened
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: "1rem !important",
                            marginBottom: "1rem !important",
                            marginLeft: "2px !important",
                        }}
                    >
                        Door Not Opened By
                    </Typography>
                </Box>
                <Stack direction={"row"}>
                    <Stack
                        spacing={2}
                        sx={{
                            width: "60%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "1px",
                        }}
                    >
                        {settings?.after?.map((inputBox, index) => {
                            return (
                                <Stack
                                    key={settings?.after?.id}
                                    width={"90%"}
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "start",
                                        margin: "0",
                                    }}
                                >
                                    <Box
                                        width="45%"
                                        marginRight={"0.8rem"}
                                        marginBottom={"0.5rem"}
                                    >
                                        <Timepicker
                                            labelText="Start Time"
                                            placeholder="00:00"
                                            helperText={
                                                settings?.after?.[index]?.err_start
                                            }
                                            value={settings?.after?.[index]?.start_time || "00:00"}
                                            // disabled={settings?.after?.[index]?.end_time ? true : false}
                                            onChange={(newValue) =>
                                                handleOpenChange(newValue, "start_time", index)
                                            }
                                            disabledRanges={settings?.after}
                                        // shouldDisableTime={isDisabled}
                                        />
                                    </Box>
                                    <Box width="45%" marginBottom={"0.5rem"}>
                                        <Timepicker
                                            labelText="End Time"
                                            placeholder="00:00"
                                            helperText={
                                                settings?.after?.[index]?.err_end
                                            }
                                            value={settings?.after?.[index]?.end_time || ""}
                                            disabled={
                                                settings?.after?.[index]?.start_time ? false : true
                                            }
                                            startTime={settings?.after?.[index]?.start_time}
                                            onChange={(newValue) =>
                                                handleOpenChange(newValue, "end_time", index)
                                            }

                                        />
                                    </Box>

                                    <Box
                                        width={"10%"}
                                        alignItems={"center"}
                                        marginTop={"1.5rem"}
                                        marginLeft={"0.5rem"}
                                    >
                                        {index === 0 ? (
                                            <Box
                                                sx={{
                                                    cursor: "pointer",
                                                    border: "2px solid #00b89f",
                                                    paddingLeft: { sm: "5px", md: "5px", lg: "4px" },
                                                    paddingTop: { sm: "5px", md: "5px" },
                                                    borderRadius: "10px",
                                                }}
                                            >
                                                <AddIcon
                                                    onClick={() =>
                                                        handleAddInputBox(settings?.after?.length - 1)
                                                    }
                                                />
                                            </Box>
                                        ) : (
                                            <Box
                                                sx={{
                                                    cursor: "pointer",
                                                    border: "2px solid red",
                                                    paddingLeft: "4px",
                                                    paddingTop: "5px",
                                                    borderRadius: "10px",
                                                }}
                                            >
                                                <TrashICon
                                                    onClick={() => handleOpenRemoveDialog(index)}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                    <ConfirmationDialog
                                        text="Are you sure you want to delete this time slot?"
                                        open={removeDialogOpen}
                                        handleClose={(val) => {
                                            if (val) {
                                                handleRemoveInputBox(removeIndex);
                                            }
                                            setRemoveDialogOpen(false);
                                        }}
                                    />
                                </Stack>
                            );
                        })}
                    </Stack>
                    <Stack width={"45%"}>
                        <Box>
                            <Timepicker
                                labelText="Time"
                                placeholder="00:00"
                                helperText={invalid?.till ? "Please enter a valid time" : ""}
                                value={settings?.till}
                                onChange={(newValue) => handleChange(newValue, "till")}
                            />
                        </Box>
                    </Stack>
                </Stack>
                <Stack
                    spacing={2}
                    gap="40px"
                    width="100%"
                    display="flex"
                    flexDirection="row"
                >
                    <Stack className="w-xs-100" style={{ width: "55%" }}>
                        <Box>
                            <SimpleInputField
                                labelText="CEN for Door Opened"
                                multiline={8}
                                placeholder="Enter Critical Event Notification"
                                type="Other"
                                helperText={
                                    invalid?.message ? "Please enter a valid message" : ""
                                }
                                value={settings?.message}
                                onChange={(event) =>
                                    handleChange(event.target.value, "message")
                                }
                            />
                        </Box>
                    </Stack>
                    <Stack className="w-xs-100" style={{ width: "45%", margin: "auto" }}>
                        <Box>
                            <SimpleInputField
                                labelText="CEN for Door Not Opened By"
                                multiline={8}
                                placeholder="Enter Critical Event Notification"
                                type="Other"
                                helperText={
                                    invalid?.till_message ? "Please enter a valid message" : ""
                                }
                                value={settings?.till_message}
                                onChange={(event) =>
                                    handleChange(event.target.value, "till_message")
                                }
                            />
                        </Box>
                    </Stack>
                </Stack>
            </Stack>

            {currentSelected !== "generic" && (
                <>
                    {invalidModes && (
                        <Box sx={{ color: "red", fontSize: "0.85rem" }}>
                            Please select at least one medium
                        </Box>
                    )}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "0.8rem",
                        }}
                    >
                        <TransparentBtn
                            sx={{ color: notificatonModes?.email ? "#00b89f" : "#4d4c4c" }}
                            onClick={handleModeClick}
                            name="email"
                            variant="outlined"
                        >
                            Email
                        </TransparentBtn>
                        <TransparentBtn
                            sx={{ color: notificatonModes?.sms ? "#00b89f" : "#4d4c4c" }}
                            onClick={handleModeClick}
                            name="sms"
                            variant="outlined"
                        >
                            SMS
                        </TransparentBtn>
                        <TransparentBtn
                            sx={{ color: notificatonModes?.push ? "#00b89f" : "#4d4c4c" }}
                            onClick={handleModeClick}
                            name="push"
                            variant="outlined"
                        >
                            Push
                        </TransparentBtn>
                    </Box>
                </>
            )}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Button
                    variant="contained"
                    sx={{ color: "#fff", width: "15rem", marginBottom: "5rem" }}
                    startIcon={<TickIcon />}
                    onClick={validate}
                >
                    Update
                </Button>
            </Box>
        </>
    );
};

export default DoorInputs;