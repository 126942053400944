import { Avatar, Box, Paper, Stack, Typography } from '@mui/material';
import { ReactComponent as Location } from 'assets/img/location.svg';
import React from 'react';
import "./AccountsInfoCard.scss";
import moment from "moment/moment";
import { vpnValidator } from 'utils/validator';
import GoodWifi from "../../../../assets/img/icon _wifi__Good Strength (1).svg";
import ModerateWifi from "../../../../assets/img/icon _wifi__Moderate1.svg";
import WeakWifi from "../../../../assets/img/icon _wifi__Moderate2.svg";
import veryWeakWifi from "../../../../assets/img/icon _wifi__Weak.svg";
import WifiOff from "../../../../assets/img/wifi-off.svg";
import vpnoff from "../../../../assets/img/vpn_off.svg";
import vpnon from "../../../../assets/img/vpn_on.svg"
import LAN_Connected from 'assets/img/LAN_Connected.svg'
import LAN_Not_Connected from 'assets/img/LAN_Not_Connected.svg'
import { Tooltip } from '@mui/material';
import newGoodWifi from "../../../../assets/img/icon_newpatch_wifi_goodstrength.svg";
import newModerateWifi from "../../../../assets/img/icon_newpatch_Wifi_Moderate1.svg";
import newWeakWifi from "../../../../assets/img/icon_newpatch_Wifi_Moderate2.svg";
import newVeryWeakWifi from "../../../../assets/img/Icon_newpatch_wifi_Weak.svg";


const AccountsInfoCard = ({
  image,
  senior_id,
  name,
  age,
  sex,
  location,
  category,
  time,
  nhub,
  wifi_strength,
  network_type,
  virtual,
  room_no,
  external_wifi

}) => {

  const handleWifiStrength = (wifi_strength, external_wifi) => {
    if(external_wifi=== true){
      if (wifi_strength === "Amazing") { return GoodWifi; }
      else if (wifi_strength === "Very Good") { return ModerateWifi; }
      else if (wifi_strength === "Okay") { return WeakWifi; }
      else if (wifi_strength === "Not Good") { return veryWeakWifi; }
      else return WeakWifi
    }
    else if(external_wifi=== false || external_wifi===null){
      if (wifi_strength === "Amazing") { return newGoodWifi; }
      else if (wifi_strength === "Very Good") { return newModerateWifi; }
      else if (wifi_strength === "Okay") { return newWeakWifi; }
      else if (wifi_strength === "Not Good") { return newVeryWeakWifi; }
      else return WeakWifi
    }
  }

  return (
    <Paper className="card-paper" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', borderRadius: '1rem', width: '100%', height: '100%' }}>
      <Stack direction="row" spacing={2} flex={1}>
        <Stack alignItems="center" justifyContent="center">
          <Box sx={{ width: '4rem' }}>
            <Avatar
              sx={{ width: '4rem', height: '4rem', marginRight: '1rem', marginBottom: "0.2rem" }}
              src={image}
              alt=""
            />
          </Box>
          <Typography
            sx={{ fontSize: '0.6rem', color: '#9c9c9c', marginTop: "0.2rem" }}
          >{`ID: ${senior_id}`}</Typography>
          {!room_no?"":<Typography
            sx={{ fontSize: '0.6rem', color: '#9c9c9c', marginTop: "0.2rem" }}
          >Room No: {room_no}</Typography>}

        </Stack>
        <Box flex={1} style={{ width: '180px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
            <Typography
              sx={{
                fontSize: '1rem',
                color: 'primary.main',
                marginBottom: '0.25rem',
                hyphens: 'manual',
              }}
            >
              {name}
            </Typography>
            {virtual ? <Typography sx={{
                fontSize: '.6rem',
                color: '#272e36',
                fontWeight: 'bold',
                background: '#f2bb0f',
                border: '1px solid',
                borderRadius: '10px',
                padding: '0px 10px'
              }}>
                Virtual
              </Typography> : 
            (<div style={{ display: "flex", alignItems: 'center' }}>

              <div className='d-flex'>
                {nhub && moment.utc().diff(time, 'minutes') < 5 ?
                  !vpnValidator(nhub) ?
                    <img src={vpnon} alt="" style={{ marginRight: "10px", marginTop: "1px", width: "20px", height: "20px" }} />
                    :
                    <img src={vpnoff} alt="" style={{ marginRight: "10px", marginTop: "1px", width: "20px", height: "20px" }} />  
                  :
                  nhub && <img src={vpnoff} alt="" style={{ marginRight: "10px", marginTop: "1px", width: "20px", height: "20px" }} />}
              </div>

              {nhub && network_type && network_type === "LAN" && (
                moment.utc().diff(time, 'minutes') < 5 ?
                  <Tooltip title={''}>
                    <div className='d-flex mr-auto p-2'>
                      <img src={LAN_Connected} alt="LANIcon" style={{ marginRight: "15px", width: "20px", height: "20px" }} />
                    </div>
                  </Tooltip>
                  :
                  <Tooltip title={''}>
                    <div className='d-flex mr-auto p-2'>
                      <img src={LAN_Not_Connected} alt="LANIcon" style={{ marginRight: "15px", width: "20px", height: "20px" }} />
                    </div>
                  </Tooltip>
              )}

              {nhub && network_type && network_type === "Wi-Fi" && (
                moment.utc().diff(time, 'minutes') < 5 && wifi_strength ?
                  <Tooltip title={wifi_strength}>
                    <div className='d-flex mr-auto p-2'>
                      <img src={handleWifiStrength(wifi_strength, external_wifi)} alt="" style={{ marginRight: "15px", width: "20px", height: "20px" }} />
                    </div>
                  </Tooltip>
                  :
                  <Tooltip title={''}>
                    <div className='d-flex mr-auto p-2'>
                      <img src={WifiOff} alt="" style={{ marginRight: "10px", width: "20px", height: "20px" }} />
                    </div>
                  </Tooltip>
              )}

              {time && <Typography sx={{
                fontSize: '.6rem',
                color: '#272e36',
                fontWeight: 'bold',
                background: '#00b89f',
                border: '1px solid',
                borderRadius: '10px',
                padding: '0px 10px'
              }}>
                {/* {moment.utc().diff(time, 'minutes') < 5 ? "Active" : "Inactive"} */}
                {moment.utc().diff(time, 'minutes') < 5 ? "Active" : "Inactive"}
              </Typography>}
            </div>)
}
          </div>

          <Typography
            sx={{
              fontSize: '0.7rem',
              color: '#9c9c9c',
              marginBottom: '0.25rem',
            }}
          >{`Age ${age}, ${sex}`}</Typography>
          <Stack
            alignItems="center"
            direction="row"
            spacing={1}
            sx={{ marginBottom: '0.25rem' }}
          >
            <Location flex={1} />
            <Typography sx={{ color: '#9c9c9c', fontSize: '0.7rem' }} flex={1}>
              {location}
            </Typography>
          </Stack>
          <Typography
            sx={{
              width: 'fit-content',
              color: 'primary.main',
              fontSize: '0.6rem',
              paddingLeft: '0.5rem',
              paddingRight: '0.5rem',
              border: '1px solid',
              borderRadius: '10px',
              marginBottom: '0.25rem',
            }}
          >
            {category}
          </Typography>
        </Box>
      </Stack>
    </Paper>
  );
};

export default AccountsInfoCard;
