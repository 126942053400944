import { Box, Stack } from '@mui/material';
import SideNav from 'components/sidenav/SideNav';
import React from 'react';

const PostLoginLayout = ({ children }) => {
  return (
    <Stack direction="row" p="0.5rem" height="100vh">
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <SideNav />
      </Box>
      <Box flex={1} sx={{ padding: '1rem', overflowY: 'hidden' }}>
        {children}
      </Box>
    </Stack>
  );
};

export default PostLoginLayout;
