import { Box, IconButton, InputAdornment, TextField } from "@mui/material"
import { useState } from "react"
import styles from "./FileInput.module.scss"

const Delete = ({ color, customClasses }) => {
    return (
        <svg className={customClasses} height="512px" id="Layer_1" style={{ enableBackground: "new 0 0 512 512" }} version="1.1" viewBox="0 0 512 512" width="512px" xmlns="http://www.w3.org/2000/svg">
            <path fill={color} d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
        </svg>
    )
}

const FileInput = ({ helperText, opacity, title, handleChangeFile, name, labelText, parentClasses,fileNameOpacity, ...rest }) => {
    const [validFile, setValidFile] = useState(false)

    return (
        <div className={`${styles.uploadImport} ${parentClasses}`}>
            <form className={`reset-form`}>
                <TextField
                    type="file"
                    size='small'
                    fullWidth
                    variant='filled'
                    label= { !!fileNameOpacity ?  "" : 'Upload a File'}
                    name={name}
                    placeholder="Upload"
                    onChange={(event) => {
                        handleChangeFile(event?.target?.files[0])
                        console.log(event?.target?.files[0])
                        if (!event?.target?.files[0]) {
                            setValidFile(false)
                        } else {
                            setValidFile(true)
                        }
                    }}
                    title={title}
                    className={`${styles.customFile}`}
                    {...rest}
                    sx={{
                        'input': {
                            opacity : !!fileNameOpacity ? 1 : 0 , 
                        }
                    }}
                    InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                            validFile && <InputAdornment className={`${styles.inputEndAdornment}`}>
                                <IconButton onClick={() => {
                                    handleChangeFile(null);
                                    setValidFile(false)
                                    document.getElementsByClassName("reset-form")[0].reset();
                                }} className={`${styles.iconButton}`}>
                                    <Delete color='red' customClasses={styles.deleteIcon} />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <Box>
                    <label htmlFor="file" className={styles.txtError} style={{ fontSize: '0.65rem', fontWeight: '500', padding: '2px 0 0 10px' }}>{helperText}</label>
                </Box>
            </form>
        </div>
    )
}

export default FileInput;