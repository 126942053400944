import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import { useLocation } from "react-router-dom";
import { ReactComponent as AddDevice } from 'assets/img/add_device.svg';
import { ReactComponent as AccountsIcon } from 'assets/img/accounts-nav.svg';
import Exit from 'assets/img/exit.svg';
import shortLogo from 'assets/img/logo.png';
import VnhubNavLink from 'components/navilink/VnhubNavLink';
const VnhubSidebar = () => {

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const nhub_id = decodeURIComponent(searchParams.get('nhub_id'))
    const senior_id = decodeURIComponent(searchParams.get('senior_id'))

    const handleURL = (name) => {
        return `/vnhub/${name}?nhub_id=${encodeURIComponent(nhub_id)}&senior_id=${senior_id}`
    }

    const logOut = () => {
        window.close();
    };

    return (
        <Paper
            sx={{
                width: '16.5rem',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 0,
                backgroundColor: '#1C1C1C'
            }}
        >
            <Box>
                <img
                    style={{ width: '100%', padding: '15px 15px 0 ' }}
                    src={shortLogo}
                    alt=""
                />
                <Typography fontWeight={'bold'} color={'#00b89f'} textAlign={'center'} position={'relative'} top={'-5px'} >
                    Virtual Nhub
                </Typography>
                <Typography sx={{ml:'4rem',fontSize:'15px',color:'#525252'}}>
                {(nhub_id)}
                </Typography>
                
            </Box>
            <Box sx={{ paddingTop: 4, paddingLeft: 2 }}>
                <Typography fontSize={'13px'} color={'#525252'}>IOT Devices</Typography>
                <VnhubNavLink to={handleURL('device')} exact icon={AddDevice} text="Device" />

                <Typography fontSize={'13px'} color={'#525252'}>General</Typography>
                <VnhubNavLink to={handleURL('account')} exact icon={AccountsIcon} text="Accounts" />

                <Box
                    onClick={logOut}
                    sx={{ display: 'flex', gap: '15px', alignItems: 'center', cursor: 'pointer' }}
                >
                    <img
                        src={Exit}
                        style={{ width: '18px' }}
                    />
                    <Typography fontSize={'14px'} color="#9c9c9c">
                        Exit
                    </Typography>
                </Box>

            </Box>
            <Box></Box>
        </Paper>
    );
};

export default VnhubSidebar;
