import React from "react";
import { Box, Typography } from '@mui/material'
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          position: 'relative',
          backgroundColor: '#14B498',
          padding: '0.7rem 1rem',
          borderTopRightRadius: '0.7rem',
          borderTopLeftRadius: '0.7rem',
          borderBottomLeftRadius: '0.7rem',
        }}
      >
        <Box>
          <Typography color=" #f5f5f5" variant="h3" background-color='primary' fontWeight={3} fontSize={18} textAlign="center">
            {`${payload[0].value}`}
          </Typography>
        </Box>
      </div>
    );
  }
  return null;
};

const NotificationChart = ({notificationData}) => {
  return (
    <>
      {/* <Box
        sx={{ 
          display: 'flex',
          flexBasis: '90%',
          flexShrink: 0,
          overflowX: 'hidden',
          overflowY: 'hidden',
          width: {xs:'80vw', md: 'auto'},
        }}
      > */}
          <ResponsiveContainer width="100%">
            <AreaChart data={notificationData}
              margin={{ top: 45, right: 10, left: 0, bottom: 10 }}>
              <defs>
                <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#14B498" stopOpacity={1} />
                  <stop offset="95%" stopColor="#06787B" stopOpacity={0.2} />
                </linearGradient>
              </defs>
              <XAxis
                interval="preserveStart"
                fontSize={12}
                mirror={true}
                dataKey="day"
                // axisLine={false}
                tickLine={false}
                tickCount={30}
              />
              <YAxis hide={true} dataKey={(v) => parseInt(v.count)} axisLine={false} tickLine={false} />
              <Tooltip allowEscapeViewBox={{ x: false, y: false }} content={<CustomTooltip />} />
              <Area type="monotone" dataKey="count" strokeWidth={4} stroke="#82ca9d" fillOpacity={1} fill="url(#gradient)" />
            </AreaChart>
          </ResponsiveContainer>
     {/* </Box> */}
    </>
  );
}

export default NotificationChart;