import React, { useContext, useState } from "react";
import { Typography, Button, Box, Stack, MenuItem } from "@mui/material";
import SimpleInputField from "components/simple-inputfield/SimpleInputField";
import AuthServices from "api/services/auth-services";
import { TransparentBtn } from "../../../components/styled-componets/style";
import { actions, GlobalContext } from "context";
import { ReactComponent as TickIcon } from "assets/img/tick_icon.svg";
import SelectInputMod from 'app/create-account/Components/select-input-mod/SelectInputMod';
import { getTimeZoneOffset, getUserTimeZome } from "utils/datetime-formatter";

// const Notifications = {
//   error: { message: 'We are facing some issues!!', class: 'error' },
// }

const timings = Array.from({ length: 24 }).map((item, i) => i < 12
  ? (i === 0 ? `${12}:00 am` : `${i}:00 am`)
  : (i === 12 ? `${12}:00 pm` : `${i - 12}:00 pm`))

const refillArr = [30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180]

const SubTabThreePill = ({
  currentSelected,
  selectedSenior,
  addOrSave,
  setInvalidSenior,
  goBack,
  snackbarNotification,
  macId,
  ...rest
}) => {
  const initialState = {
    device: "PillBox",
    pills_perday: "",
    times_perday: "",
    pills: [
      {
        time: null,
        message: null,
      },
      {
        time: null,
        message: null,
      },

      {
        time: null,
        message: null,
      },
    ],
    schedule_message: "",
    refill_interval: "",
    refill_message: "",
    morning: "",
    afternoon: "",
    night: "",
  };

  const { dispatch } = useContext(GlobalContext);
  const storeHandler = (type, payload) => dispatch({ type, payload });
  const [settings, setSettings] = useState({ ...initialState });
  const [invalid, setInvalid] = useState({});
  const [invalidModes, setInvalidModes] = useState(false);
  const [medsPerDay, setMedsPerDay] = useState('3')
  const handleChange = (value, key, index, pill) => {
    let data = settings;
    if (pill) {
      const date = new Date().toLocaleDateString()
      const val = pill === "time" ? new Date(`${date} ${value}`).toUTCString() : value
      data[key][index][pill] = val;
      setInvalid({ ...invalid, [pill]: false, noPillData: false });
    } else {
      data[key] = value;
      setInvalid({ ...invalid, [key]: false });
    }
    setSettings(data);
  };

  const [notificatonModes, setNotificationModes] = useState({ email: false, sms: false, push: false });
  const handleModeClick = (e) => {
    const mode = e.target.name;
    const prev = notificatonModes[mode];
    setNotificationModes({ ...notificatonModes, [mode]: !prev });
    invalid.modes = false;
    setInvalidModes(false)

  }

  const validate = () => {
    let data = settings;
    let invalidData = {};
    if (currentSelected === "seniorSpecific") {
      if (!selectedSenior?.id) {
        invalidData.senior = true;
        setInvalidSenior({ senior: true });
      }
    }
    if (!data.refill_interval) {
      invalidData.refill_interval = true;
    }
    if (!data.refill_message) {
      invalidData.refill_message = true;
    }
    let timesChoosed = 0;

    data?.pills?.forEach(item => {
      if (item.time) {
        timesChoosed = timesChoosed + 1
      }
    })

    if (timesChoosed < Number(medsPerDay)) {
      invalidData.noPillData = true
    }

    let isAllMessageAvailalbe = data?.pills?.every(item => !!item.time ? !!item.message : true)

    if (!isAllMessageAvailalbe) {
      invalidData.noMessage = true
    }
    setInvalid(invalidData);
    const isValidModes = Object.values(notificatonModes).some(item => item)
    setInvalidModes(!isValidModes)
    if (Object.keys(invalidData).length > 0 || (!isValidModes && currentSelected !== "generic")) {
      return false;
    }
    handleAdd();
  };

  const handleAdd = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      if (currentSelected === "generic") {
        const { message } = await AuthServices.addGenericNotifications({ ...settings, timezone: getUserTimeZome(), origin: getTimeZoneOffset() });
        snackbarNotification({ message: message, class: 'success' });
      } else {
        const { message } = await AuthServices.addCentricNotifications(
          selectedSenior?.id,
          { ...settings, noti_type: notificatonModes, timezone: getUserTimeZome(), origin: getTimeZoneOffset(),mac_id:macId }
        );
        snackbarNotification({ message: message, class: 'success' });
      }
      storeHandler(actions.SHOW_LOADER, false);
      goBack();
    } catch (err) {
      const { data } = err || {};
      const { message } = data || {};
      snackbarNotification({
        message,
        class: "error",
      });
      storeHandler(actions.SHOW_LOADER, false);
    }
  }
  const isNumber = (val) => {
    const regex = new RegExp(/^\d+$/);
    return regex.test(val) || !val
  }
  return (
    <>
      <div className="d-flex flex-row justify-content-between flex-wrap">
        <Box className="w-xs-100" sx={{ "& .MuiFormControl-root": { width: "165px", marginRight: '1rem' } }}>
          <Typography sx={{ fontSize: "0.8rem", paddingBottom: "0.5rem" }}> Medications Per Day</Typography>
          <SelectInputMod sx={{ "& .MuiSelect-select": { padding: "0.5rem", paddingRight: "32px" } }} value={medsPerDay} onChange={(event) => setMedsPerDay(event.target.value)}>
            {['1', '2', '3'].map((option, index) => <MenuItem key={index} value={option} > {`Meds ${option || '0'}x`} </MenuItem>)}
          </SelectInputMod>
        </Box>
        <div className="d-flex flex-wrap flex-row justify-content-start">
          <Box className="w-xs-100" sx={{ "& .MuiFormControl-root": { width: "165px", marginRight: '1rem' } }}>
            <Typography sx={{ fontSize: "0.8rem", paddingBottom: "0.5rem" }}> Meds 1 </Typography>
            <SelectInputMod sx={{ "& .MuiSelect-select": { padding: "0.5rem", paddingRight: "32px" } }} helperText={invalid?.noPillData && !settings?.pills?.[0]?.time && 'Required'} onChange={(event) => handleChange(event.target.value, "pills", 0, "time")}>
              {timings.map((option, index) => <MenuItem key={index} value={option} > {option || ''} </MenuItem>)}
            </SelectInputMod>
          </Box>
          {medsPerDay > 1 && <Box className="w-xs-100" sx={{ "& .MuiFormControl-root": { width: "165px", marginRight: '1rem' } }}>
            <Typography sx={{ fontSize: "0.8rem", paddingBottom: "0.5rem" }}> Meds 2</Typography>
            <SelectInputMod sx={{ "& .MuiSelect-select": { padding: "0.5rem", paddingRight: "32px" } }} helperText={invalid?.noPillData && !settings?.pills?.[1]?.time && 'Required'} onChange={(event) => handleChange(event.target.value, "pills", 1, "time")}>
              {timings.map((option, index) => <MenuItem key={index} value={option} > {option || ''} </MenuItem>)}
            </SelectInputMod>
          </Box>}
          {medsPerDay > 2 && <Box className="w-xs-100" sx={{ "& .MuiFormControl-root": { width: "165px", marginRight: '1rem' } }}>
            <Typography sx={{ fontSize: "0.8rem", paddingBottom: "0.5rem" }}> Meds 3</Typography>
            <SelectInputMod sx={{ "& .MuiSelect-select": { padding: "0.5rem", paddingRight: "32px" } }} helperText={invalid?.noPillData && !settings?.pills?.[2]?.time && 'Required'} onChange={(event) => handleChange(event.target.value, "pills", 2, "time")}>
              {timings.map((option, index) => <MenuItem key={index} value={option} > {option || ''} </MenuItem>)}
            </SelectInputMod>
          </Box>}
          <Box className="w-xs-100" sx={{ "& .MuiFormControl-root": { width: "165px", marginRight: '1rem' } }}>
            <Typography sx={{ fontSize: "0.8rem", paddingBottom: "0.5rem" }}> Refill Interval</Typography>
            <SelectInputMod helperText={invalid?.refill_interval && 'Required'} sx={{ "& .MuiSelect-select": { padding: "0.5rem", paddingRight: "32px" } }} onChange={(event) => handleChange(event.target.value, "refill_interval")}>
              {refillArr.map((option, index) => <MenuItem key={index} value={option} > {`${option || '0'} days`} </MenuItem>)}
            </SelectInputMod>
          </Box>
        </div>

        {/* <Box
          className="w-xs-100"
          sx={{
            "& .MuiFormControl-root": {
              width: "150px",
            },
          }}
        >
          <SimpleInputField
            labelText="Refill Interval"
            placeholder="0"
            type="Other"
            helperText={invalid?.refill_interval && "Required"}
            value={settings?.refill_interval}
            onChange={(event) => {
              if (!isNumber(event.target.value)) return
              if (event.target.value.length <= 3) {
                handleChange(event.target.value, "refill_interval")
              }
            }
            }
          />
        </Box> */}
      </div>

      <Stack
        direction="row"
        // spacing={10}
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: 'wrap',
          justifyContent: "space-between",
          margin: "2rem 0 1rem 0",
        }}
      >

        <Stack className="w-xs-100" sx={{ width: '45%' }}>
          <Box>
            <SimpleInputField
              labelText="Medications 1"
              multiline={8}
              placeholder="Enter your message"
              maxRows={8}
              type="Other"
              helperText={((invalid.noPillData && !settings?.pills?.[0]?.message) || (invalid.noMessage && settings?.pills?.[0]?.time && !settings?.pills?.[0]?.message)) && "Required"}

              value={settings?.pills?.[0]?.message}
              onChange={(event) =>
                handleChange(event.target.value, "pills", 0, "message")
              }
            />
          </Box>
        </Stack>
        {medsPerDay > 1 && <Stack
          className="w-xs-100"
          sx={{
            width: '45%',
            justifyContent: "space-between",
          }}
        >
          <Box>
            <SimpleInputField
              labelText="Medications 2"
              multiline={8}
              placeholder="Enter your message"
              maxRows={8}
              type="Other"
              helperText={((invalid.noPillData && !settings?.pills?.[1]?.message) || (invalid.noMessage && settings?.pills?.[1]?.time && !settings?.pills?.[1]?.message)) && "Required"}
              value={settings?.pills?.[1]?.message}

              onChange={(event) =>
                handleChange(event.target.value, "pills", 1, "message")
              }
            />
          </Box>
        </Stack>}
        {medsPerDay > 2 && <Stack
          className="w-xs-100"
          sx={{
            width: '45%',
            justifyContent: "space-between",
          }}
        >
          <Box>
            <SimpleInputField
              labelText="Medications 3"
              multiline={8}
              placeholder="Enter your message"
              maxRows={8}
              type="Other"
              value={settings?.pills?.[2]?.message}
              helperText={((invalid.noPillData && !settings?.pills?.[2]?.message) || (invalid.noMessage && settings?.pills?.[2]?.time && !settings?.pills?.[2]?.message)) && "Required"}
              onChange={(event) =>
                handleChange(event.target.value, "pills", 2, "message")
              }
            />
          </Box>
        </Stack>}
        <Stack
          className="w-xs-100"
          sx={{
            width: '45%',
            justifyContent: "space-between",
          }}
        >
          <Box>
            <SimpleInputField
              labelText="Refill Message"
              multiline={8}
              helperText={invalid?.refill_message && "Refill message is required"}
              placeholder="Enter your message"
              maxRows={8}
              type="Other"
              value={settings?.refill_message}

              onChange={(event) =>
                handleChange(event.target.value, "refill_message")
              }
            />
          </Box>
        </Stack>
      </Stack>
      {
        currentSelected !== "generic" && (
          <>
            {invalidModes && <Box sx={{ color: 'red', fontSize: '0.85rem' }}>Please select at least one medium</Box>}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.8rem' }}>
              <TransparentBtn sx={{ color: notificatonModes?.email ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='email' variant="outlined">Email</TransparentBtn>
              <TransparentBtn sx={{ color: notificatonModes?.sms ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='sms' variant="outlined">SMS</TransparentBtn>
              <TransparentBtn sx={{ color: notificatonModes?.push ? '#00b89f' : '#4d4c4c' }} onClick={handleModeClick} name='push' variant="outlined">Push</TransparentBtn>
            </Box>
          </>
        )
      }
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{ color: "#fff", width: "15rem", marginBottom: "5rem" }}
          startIcon={<TickIcon />}
          onClick={validate}
        >
          Add
        </Button>
      </Box>
    </>
  );
};
export default SubTabThreePill;
