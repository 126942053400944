import { Typography, Box } from "@mui/material";
const FeatureInfo = ({
  primary,
  icon: Icon,
  IconImg,
  children,
  onClick,
  id,
  stroke,
  special,
  added,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        borderRadius: "0.5rem",
        border: "2px solid",
        borderColor: `${added || primary ? "#00B89F" : "#E5E5E5"}`,
        backgroundColor: `${primary ? "#00B89F" : ""}`,
        padding: "0.5rem",
        alignItems: "center",
        minWidth: "6rem",
        flexGrow: '1',
        maxWidth: '10rem',
        cursor:'pointer',
      }}
      onClick={onClick}
    >
      {Icon 
      ? <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: "0.5rem",
        }}
      >
        <Icon
          className={
            primary
              ? special
                ? "primary-spcl-active"
                : stroke
                  ? "primary-stroke-active"
                  : "primary-fill-active"
              : special
                ? "primary-icon-spcl"
                : stroke
                  ? "primary-icon-stroke"
                  : "primary-icon-fill"
          }
        />
      </Box> :
        <Box
          component="img"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '1.5rem',
            height: '1.5rem',
            marginRight: '0.5rem',
          }}
          src={IconImg}
        />
      }
      <Box></Box>
      <Typography sx={{ flex: 1, color: "#E5E5E5", fontSize: "0.5rem" }}>
        {children}
      </Typography>
    </Box>
  );
};

export default FeatureInfo;
